import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  List,
  ListItem,
  Divider,
  Grid,
  createStyles,
  makeStyles,
  Tooltip,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faQuestionSquare } from "@fortawesome/pro-regular-svg-icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Filter1 as Filter1Icon } from "@material-ui/icons";
import { ReactComponent as PercentIcon } from "../../../assets/percent.svg";
import DoughnutChart from "./DoughnutChart";
import { ChartInputItem, WidgetVariant } from "./types";
import { fade } from "@material-ui/core/styles/colorManipulator";
import HorizontalBarChart from "./HorizontalBarChart";
import IndustryIcon from "./IndustryIcon";
import { ReactComponent as InfoIcon } from "../../../assets/info-circle.svg";
import { FlagIcon } from "@creditsafe/portfolios-shared-components";
import { useLocation } from "react-router-dom";
import MapChart from "./MapChart";
import { generateMapColorMultiplier, getFilteredBarChartData } from "./utils";
import StatusIcon from "components/StatusIcon/StatusIcon";
import PDFBarChartTable from "./PDFBarChartTable";
import { TITLE_PROVINCE_BE, UNKNOWN, UNMATCHED } from "./constants";
import {
  percentage,
  formatNumberThousandSeparator,
  localiseThousandSeperator,
} from "appUtils";
import {
  MAX_ARRAY_SPLIT_SIZE,
  provinceMapTitles,
  provinceTitles,
} from "../Mappings/constants";
import {
  WIDGET_TYPE_BAR,
  WIDGET_TYPE_PIE,
  WIDGET_TYPE_DONUT,
  WIDGET_TYPE_MAP,
} from "./constants";
import {
  TITLE_PROVINCE_UK,
  TITLE_PROVINCE_NL,
  TITLE_STATES_US,
} from "./constants";
import { getWidgetCreditType } from "./widgets/getCreditTypeWidgets";
import { Status } from "store/types";
import AddRemoveButton from "components/AddRemoveButton";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    toggleButton: {
      height: "max-content",
      fill: "inherit",
      "&.Mui-selected": {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        fill: theme.palette.success.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          fill: theme.palette.success.contrastText,
        },
      },
    },
    grid: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
      },
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100%",
      "&.Mui-selected p": {
        fontWeight: 600,
        whiteSpace: "nowrap",
      },
    },
    nowrap: {
      whiteSpace: "nowrap",
    },
    listItemText: {
      flexBasis: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    marginLeft: {
      marginLeft: "-8px",
    },
    unknownFlagColor: {
      "& svg": {
        fill: theme.palette.text.primary,
      },
    },
    icon: {
      height: "30px",
      width: "30px !important",
    },
    aggregateCard: {
      height: "100%",
      borderRadius: "2px",
      padding: "16px",
      borderStyle: "solid",
      borderColor: theme.palette.grey[200],
    },
    activeCard: {
      backgroundColor: fade(theme.palette.success.main, 0.1),
      borderStyle: "solid",
      borderColor: theme.palette.success.main,
    },
    toggleIcon: {
      width: "15px",
      height: "15px",
      margin: theme.spacing(0.5, 0),
    },
    statusAndIcons: {
      "& svg": {
        marginRight: theme.spacing(1),
      },
      display: "flex",
      alignItems: "center",
    },
    leftAlignment: {
      display: "flex",
      alignItems: "center",
      flexBasis: "90%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    smallText: {
      fontSize: "150%",
    },
    titleAndInfo: {
      display: "flex",
      alignItems: "center",
    },
    infoIcon: {
      margin: theme.spacing(0, 1),
      width: "15px",
      fill: theme.palette.text.primary,
    },
    title: {
      wordWrap: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "200px",
    },
    tableCell: {
      padding: theme.spacing(0.9, 3, 0.9, 2),
      height: "35px",
    },
    tableCellMap: {
      padding: theme.spacing(0.9, 7.5, 0.9, 2),
      height: "35px",
    },
    flag: {
      marginRight: theme.spacing(3),
      height: "30px",
    },
    aggregateTable: { width: "100%" },
    aggregateChart: { width: "100%" },
    disableCard: {
      backgroundColor: "#d6d6d6",
      border: 0,
    },
  })
);
const AggregateWidget: React.FC<{
  title: string;
  data: ChartInputItem[];
  widgetType: WidgetVariant;
  selectedWidgets: string[];
  setSelectedWidgets: (value: string[]) => void;
  JSONTag: string;
  isRequired: boolean;
  printView?: boolean;
  tooltip?: string | null;
  hasPdfSpecificTitle?: boolean;
  creditTypeWidgets: string[];
  availableCreditTypes: { data?: string[]; status: Status };
  isAllowSelecting: boolean;
  selectedTabCountry?: string;
  onCreditTypeChange: (value: string) => void;
  // eslint-disable-next-line complexity
}> = ({
  title,
  data,
  widgetType,
  printView,
  selectedWidgets,
  setSelectedWidgets,
  JSONTag,
  tooltip,
  isRequired,
  hasPdfSpecificTitle,
  creditTypeWidgets,
  availableCreditTypes,
  isAllowSelecting,
  selectedTabCountry,
  onCreditTypeChange,
}) => {
  const classes = useStyles(printView);
  const { t } = useTranslation("aggregates");
  const translatedTitle = t(title);
  const pdfTranslatedTitle = t(`pdf.${title}`);
  const location = useLocation();
  const tabCountry = selectedTabCountry || "ww";
  const provinceMapChartData = data;
  const queryParams = new URLSearchParams(useLocation().search);
  const locale = queryParams.get("locale") as string;
  const filteredProvinceMapTitles = provinceMapTitles.filter(
    (province) => province !== TITLE_STATES_US
  );

  const getCountryCode = () => {
    if (printView && location) {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get("countryCode") as string;
    } else {
      return tabCountry;
    }
  };

  const country = getCountryCode();

  let other;
  if (
    provinceMapTitles.includes(title) &&
    data.length >= MAX_ARRAY_SPLIT_SIZE &&
    title !== TITLE_PROVINCE_UK &&
    title !== TITLE_PROVINCE_NL &&
    title !== TITLE_PROVINCE_BE
  ) {
    const others = data.slice(MAX_ARRAY_SPLIT_SIZE);
    const reducer = (
      accumulator: ChartInputItem,
      currentValue: ChartInputItem
    ) => {
      const unpercent = (value: string) =>
        Number(
          value
            .split("")
            .filter((x) => x !== "%")
            .join("")
        );

      const updatePercentage = (prevValue: string, newValue: string) =>
        percentage(unpercent(prevValue) + unpercent(newValue), 2);

      return {
        name: t("other"),
        value: currentValue.value + accumulator.value,
        color: "rgb(100,100,100)",
        label: t("other"),
        percentage: updatePercentage(
          accumulator.percentage,
          currentValue.percentage
        ),
      };
    };

    if (others.length > 0) {
      other = others.reduce(reducer);
      data = data.slice(0, MAX_ARRAY_SPLIT_SIZE);
    }
  }

  if (widgetType === WIDGET_TYPE_BAR) {
    data = getFilteredBarChartData(data, country, title);
  }

  const colors = data.map((x) => x.color || "");

  const [activeIndex, setActiveIndex] = useState(printView ? -1 : 0);

  const [countOrPercentage, setCountOrPercentage] = useState<
    "count" | "percentage"
  >("count");

  const displayCorrectChartType = (widgetType: WidgetVariant) => {
    switch (widgetType) {
      case WIDGET_TYPE_PIE:
      case WIDGET_TYPE_DONUT:
        return (
          <DoughnutChart
            formattedData={data}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            donut={widgetType === WIDGET_TYPE_DONUT}
            isAnimationActive={!printView}
          />
        );
      case WIDGET_TYPE_BAR:
        return (
          <HorizontalBarChart
            chartData={data}
            activeIndex={activeIndex}
            colors={colors}
            setActiveIndex={setActiveIndex}
            animationDuration={printView ? 0 : undefined}
          />
        );
      case WIDGET_TYPE_MAP:
        return (
          <Hidden mdDown={printView ? false : true}>
            <MapChart
              chartData={
                provinceMapTitles.includes(title) ? provinceMapChartData : data
              }
              colors={colors}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              name={title}
              isPrintView={printView}
              country={country}
            />
          </Hidden>
        );
      default:
        return "";
    }
  };
  const isSelected = selectedWidgets.includes(JSONTag);
  const remove = () =>
    setSelectedWidgets(selectedWidgets.filter((x) => x !== JSONTag));
  const add = () => setSelectedWidgets([...selectedWidgets, JSONTag]);
  const isAvaialable = creditTypeWidgets.some((c) => c === JSONTag);

  const creditType = getWidgetCreditType(JSONTag);

  return (
    <Paper
      data-testid={`${title}-aggregate-widget`}
      className={`${classes.aggregateCard} ${
        isSelected ? classes.activeCard : ""
      } ${!printView && !isAvaialable ? classes.disableCard : ""}`}
    >
      <div className={`${classes.header}`} data-testid={`${title}-widget`}>
        <div className={classes.titleAndInfo}>
          {printView ? (
            <p className="widgetTitle" data-testid="html-widget-title">
              {hasPdfSpecificTitle ? pdfTranslatedTitle : translatedTitle}
            </p>
          ) : (
            <Typography
              component="p"
              variant="h3"
              data-testid="widget-title"
              className={classes.smallText}
            >
              {translatedTitle}
            </Typography>
          )}
          {tooltip && (
            <Tooltip title={t(tooltip) as string}>
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          )}
        </div>

        {!printView && (
          <>
            <ToggleButtonGroup value={countOrPercentage} exclusive size="small">
              <ToggleButton
                value="count"
                className={classes.toggleButton}
                onClick={() => setCountOrPercentage("count")}
                data-testid="toggle-count"
              >
                <Filter1Icon className={classes.toggleIcon} />
              </ToggleButton>
              <ToggleButton
                value="percentage"
                className={classes.toggleButton}
                onClick={() => setCountOrPercentage("percentage")}
                data-testid="toggle-percentage"
              >
                <PercentIcon className={classes.toggleIcon} />
              </ToggleButton>
            </ToggleButtonGroup>
            <AddRemoveButton
              title={title}
              creditType={creditType}
              isRequired={isRequired}
              isAvaialable={isAvaialable}
              addAction={add}
              removeAction={remove}
              isSelected={isSelected}
              addText={t("cart.addToCart")}
              removeText={t("cart.removeFromCart")}
              availableCreditTypes={availableCreditTypes}
              isAllowSelecting={isAllowSelecting}
              onCreditTypeChange={onCreditTypeChange}
            />
          </>
        )}
      </div>
      {widgetType === WIDGET_TYPE_BAR && printView ? (
        <PDFBarChartTable title={title} data={data} />
      ) : (
        <Grid
          container
          alignItems={"stretch"}
          className={`${classes.grid}   ${
            printView && widgetType === WIDGET_TYPE_MAP && "pdfMap"
          }`}
          spacing={1}
        >
          <Grid
            item
            className={`aggregateTable ${classes.aggregateTable}`}
            md={12}
            lg={
              widgetType === WIDGET_TYPE_MAP
                ? provinceMapTitles.includes(title)
                  ? 5
                  : 3
                : widgetType === WIDGET_TYPE_PIE ||
                  widgetType === WIDGET_TYPE_DONUT
                ? 7
                : 5
            }
            data-testid="aggregate-table-list-items-grid"
          >
            {!printView ? (
              <List data-testid="data-list">
                <Divider />
                {data &&
                  data.map((datum, index) => (
                    <div key={index}>
                      <Tooltip title={datum.name}>
                        <ListItem
                          button
                          className={`${classes.listItem} ${classes.statusAndIcons}`}
                          style={{ height: `${35}px` }}
                          selected={activeIndex === index}
                          onClick={() => setActiveIndex(index)}
                          data-testid={`list-item-${index}`}
                        >
                          <div className={classes.leftAlignment}>
                            {datum.countryCode ? (
                              <div
                                className={[
                                  classes.flag,
                                  datum.countryCode?.toLowerCase() ===
                                    UNKNOWN.toLowerCase() ||
                                  datum.countryCode?.toLowerCase() ===
                                    UNMATCHED.toLowerCase()
                                    ? classes.unknownFlagColor
                                    : "",
                                ].join(" ")}
                              >
                                {datum.countryCode?.toLowerCase() ===
                                UNKNOWN.toLowerCase() ? (
                                  <FontAwesomeIcon
                                    className={classes.icon}
                                    data-testid="company-report-icon-unknown"
                                    icon={faQuestionSquare}
                                  />
                                ) : datum.countryCode?.toLowerCase() ===
                                  UNMATCHED.toLowerCase() ? (
                                  <FontAwesomeIcon
                                    className={classes.icon}
                                    data-testid="company-report-icon-unmatched"
                                    icon={faCopy}
                                  />
                                ) : (
                                  <FlagIcon
                                    countryCode={datum.countryCode}
                                    size={30}
                                  />
                                )}
                              </div>
                            ) : !provinceTitles.includes(title.split(".")[0]) &&
                              datum.name !== t("others") ? (
                              <StatusIcon color={datum.color} />
                            ) : (
                              <StatusIcon
                                color={data[0].color}
                                opacity={generateMapColorMultiplier(
                                  data,
                                  0.25,
                                  datum.percentage
                                )}
                              />
                            )}
                            {datum.activityCode !== null &&
                              datum.activityCode !== undefined && (
                                <IndustryIcon
                                  activityCode={datum.activityCode}
                                  countryCode={country}
                                />
                              )}
                            <Typography
                              variant="body2"
                              className={[
                                classes.listItemText,
                                (datum.countryCode?.toLowerCase() ===
                                  UNKNOWN.toLowerCase() ||
                                  datum.countryCode?.toLowerCase() ===
                                    UNMATCHED.toLowerCase()) &&
                                title === "CountryCode.title.ww"
                                  ? classes.marginLeft
                                  : "",
                              ].join(" ")}
                              data-testid={`list-item-name-${index}`}
                            >
                              {datum.name}
                            </Typography>
                          </div>

                          <Typography
                            variant="body2"
                            data-testid={`list-item-value-${index}`}
                            className={classes.nowrap}
                          >
                            {countOrPercentage === "count"
                              ? formatNumberThousandSeparator(datum.value)
                              : `${datum.percentage}`}
                          </Typography>
                        </ListItem>
                      </Tooltip>
                      <Divider />
                    </div>
                  ))}
                {other && (
                  <div>
                    <Tooltip title={t("other").toString()}>
                      <ListItem
                        button
                        className={`${classes.listItem} ${classes.statusAndIcons}`}
                        style={{ height: `${35}px` }}
                        selected={false}
                        data-testid={`list-item-other`}
                      >
                        <div className={classes.leftAlignment}>
                          <StatusIcon color={"grey"} />
                          <Typography
                            variant="body2"
                            className={classes.listItemText}
                            data-testid={`list-item-name-${1}`}
                          >
                            {t("other")}
                          </Typography>
                        </div>

                        <Typography
                          variant="body2"
                          data-testid={`list-item-value-${1}`}
                        >
                          {countOrPercentage === "count"
                            ? formatNumberThousandSeparator(other.value)
                            : other.percentage}
                        </Typography>
                      </ListItem>
                    </Tooltip>
                    <Divider />
                  </div>
                )}
              </List>
            ) : (
              <Table size="small" data-testid="pdf-table">
                <TableHead data-testid="pdf-table-header">
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>

                    <TableCell data-testid="pdf-table-count-header">
                      {t("count")}
                    </TableCell>
                    <TableCell data-testid="pdf-table-percentage-header">
                      {t("percentage")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((datum, index) => (
                      <TableRow key={index}>
                        <TableCell
                          className={
                            filteredProvinceMapTitles.includes(title)
                              ? `${classes.tableCellMap}`
                              : `${classes.tableCell}`
                          }
                          data-testid={`pdf-table-item-${index}`}
                        >
                          <div className={classes.statusAndIcons}>
                            {datum.countryCode ? (
                              <div
                                className={[
                                  classes.flag,
                                  datum.countryCode?.toLowerCase() ===
                                    UNKNOWN.toLowerCase() ||
                                  datum.countryCode?.toLowerCase() ===
                                    UNMATCHED.toLowerCase()
                                    ? classes.unknownFlagColor
                                    : "",
                                ].join(" ")}
                              >
                                {datum.countryCode?.toLowerCase() ===
                                UNKNOWN.toLowerCase() ? (
                                  <FontAwesomeIcon
                                    className={classes.icon}
                                    data-testid="company-report-icon-unknown"
                                    icon={faQuestionSquare}
                                  />
                                ) : datum.countryCode?.toLowerCase() ===
                                  UNMATCHED.toLowerCase() ? (
                                  <FontAwesomeIcon
                                    className={classes.icon}
                                    data-testid="company-report-icon-unmatched"
                                    icon={faCopy}
                                  />
                                ) : (
                                  <FlagIcon
                                    countryCode={datum.countryCode}
                                    size={30}
                                  />
                                )}
                              </div>
                            ) : !provinceTitles.includes(title.split(".")[0]) &&
                              datum.name !== t("others") ? (
                              <StatusIcon color={datum.color} />
                            ) : (
                              <StatusIcon
                                color={data[0].color}
                                opacity={generateMapColorMultiplier(
                                  data,
                                  0.25,
                                  datum.percentage
                                )}
                              />
                            )}

                            {datum.activityCode && (
                              <div className={classes.statusAndIcons}>
                                <IndustryIcon
                                  activityCode={datum.activityCode}
                                  countryCode={country}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          className={
                            filteredProvinceMapTitles.includes(title)
                              ? `${classes.tableCellMap} ${classes.title}`
                              : `${classes.tableCell} ${classes.title}`
                          }
                          data-testid={`pdf-table-name-value-${index}`}
                        >
                          {datum.name}
                        </TableCell>

                        <TableCell
                          data-testid={`pdf-table-count-value-${index}`}
                          className={[
                            filteredProvinceMapTitles.includes(title)
                              ? classes.tableCellMap
                              : classes.tableCell,
                            classes.nowrap,
                          ].join(" ")}
                        >
                          {localiseThousandSeperator(
                            formatNumberThousandSeparator(datum.value, locale),
                            locale
                          )}
                        </TableCell>
                        <TableCell
                          data-testid={`pdf-table-percentage-value-${index}`}
                          className={
                            filteredProvinceMapTitles.includes(title)
                              ? classes.tableCellMap
                              : classes.tableCell
                          }
                        >
                          {datum.percentage}
                        </TableCell>
                      </TableRow>
                    ))}
                  {other && (
                    <TableRow>
                      <TableCell
                        className={
                          filteredProvinceMapTitles.includes(title)
                            ? `${classes.tableCellMap}`
                            : `${classes.tableCell}`
                        }
                        data-testid={`pdf-table-item-other`}
                      >
                        <div className={classes.statusAndIcons}>
                          <StatusIcon color={"grey"} />
                        </div>
                      </TableCell>
                      <TableCell
                        className={
                          filteredProvinceMapTitles.includes(title)
                            ? `${classes.tableCellMap} ${classes.title}`
                            : `${classes.tableCell} ${classes.title}`
                        }
                        data-testid={`pdf-table-name-value-other`}
                      >
                        {t("other")}
                      </TableCell>

                      <TableCell
                        data-testid={`pdf-table-count-value-other`}
                        className={
                          filteredProvinceMapTitles.includes(title)
                            ? [classes.tableCellMap, classes.nowrap].join(" ")
                            : [classes.tableCell, classes.nowrap].join(" ")
                        }
                      >
                        {localiseThousandSeperator(
                          formatNumberThousandSeparator(other.value, locale),
                          locale
                        )}
                      </TableCell>
                      <TableCell
                        data-testid={`pdf-table-percentage-value-oother`}
                        className={
                          filteredProvinceMapTitles.includes(title)
                            ? classes.tableCellMap
                            : classes.tableCell
                        }
                      >
                        {other.percentage}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Grid>

          <Grid
            item
            className={`aggregateChart ${classes.aggregateChart}`}
            lg={
              widgetType === WIDGET_TYPE_MAP
                ? provinceMapTitles.includes(title)
                  ? 7
                  : 9
                : 5
            }
          >
            {displayCorrectChartType(widgetType)}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

AggregateWidget.defaultProps = {
  printView: false,
};

export default AggregateWidget;
