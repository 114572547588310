import { AppActionTypes, AppState, SET_REQUIRES_APP_UPDATE } from "./types";

export const initialState: AppState = {
  requiresUpdate: false,
};

export default function (
  state = initialState,
  action: AppActionTypes
): AppState {
  switch (action.type) {
    case SET_REQUIRES_APP_UPDATE: {
      return {
        ...state,
        requiresUpdate: true,
      };
    }
    default:
      return state;
  }
}
