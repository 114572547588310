import {
  EnrichmentsState,
  EnrichmentsActionTypes,
  FETCH_MAPPING_ENRICHMENT_TEMPLATES,
  CLEAR_POPUP_NOTIFICATION,
  FETCH_MAPPING_ENRICHMENT_TEMPLATES_ERROR,
  FETCH_MAPPING_ENRICHMENT_TEMPLATES_SUCCESS,
  RESET_ENRICHMENT_TEMPLATE_SAVE_STATUS,
  SAVE_ENRICHMENT_TEMPLATE,
  SAVE_ENRICHMENT_TEMPLATE_ERROR,
  SAVE_ENRICHMENT_TEMPLATE_SUCCESS,
  Enrichment,
  FETCH_ALGORITHM_BANDINGS,
  FETCH_ALGORITHM_BANDINGS_SUCCESS,
  FETCH_ALGORITHM_BANDINGS_ERROR,
} from "./types";
import { Status } from "../types";

export const initialState: EnrichmentsState = {
  allIds: [],
  byId: {},
  status: Status.unset,
  templateSavingStatus: Status.unset,
};

export default function (
  state = initialState,
  action: EnrichmentsActionTypes
): EnrichmentsState {
  switch (action.type) {
    case FETCH_MAPPING_ENRICHMENT_TEMPLATES: {
      return {
        ...state,
        allIds: [],
        byId: {},
        status: Status.loading,
      };
    }
    case FETCH_MAPPING_ENRICHMENT_TEMPLATES_SUCCESS: {
      return {
        ...state,
        allIds: action.payload.map((x: Enrichment) => x.id),
        byId: normalize(action.payload),
        status: Status.loaded,
      };
    }
    case FETCH_MAPPING_ENRICHMENT_TEMPLATES_ERROR: {
      return {
        ...state,
        status: Status.errorLoading,
      };
    }

    case FETCH_ALGORITHM_BANDINGS: {
      return {
        ...state,
        algorithBandings: {
          data: [],
          status: Status.loading,
        },
      };
    }
    case FETCH_ALGORITHM_BANDINGS_SUCCESS: {
      return {
        ...state,
        algorithBandings: {
          data: action.payload,
          status: Status.loaded,
        },
      };
    }
    case FETCH_ALGORITHM_BANDINGS_ERROR: {
      return {
        ...state,
        algorithBandings: {
          data: [],
          status: Status.errorLoading,
        },
      };
    }
    case SAVE_ENRICHMENT_TEMPLATE:
      return {
        ...state,
        status: Status.saving,
        templateSavingStatus: Status.saving,
      };
    case SAVE_ENRICHMENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: Status.saved,
        templateSavingStatus: Status.saved,
      };
    case SAVE_ENRICHMENT_TEMPLATE_ERROR:
      return {
        ...state,
        status: Status.errorSaving,
        templateSavingStatus: Status.errorSaving,
      };
    case RESET_ENRICHMENT_TEMPLATE_SAVE_STATUS:
      return {
        ...state,
        templateSavingStatus: Status.unset,
      };
    case CLEAR_POPUP_NOTIFICATION: {
      return {
        ...state,
        templateSavingStatus: Status.unset,
      };
    }
    default:
      return state;
  }
}

const normalize = (enrichments: Enrichment[]): { [id: string]: Enrichment } =>
  enrichments.reduce((acc, x) => ({ ...acc, [x.id]: x }), {});
