import * as CustomerActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Normalized, Status } from "../types";

export const RESET_SEARCH_FOR_A_CUSTOMER = "RESET_SEARCH_FOR_A_CUSTOMER";
export const SEARCH_FOR_A_CUSTOMER = "SEARCH_FOR_A_CUSTOMER";
export const SEARCH_FOR_A_CUSTOMER_SUCCESS = "SEARCH_FOR_A_CUSTOMER_SUCCESS";
export const SEARCH_FOR_A_CUSTOMER_ERROR = "SEARCH_FOR_A_CUSTOMER_ERROR";
export const SELECT_USER = "SELECT_USER";

export const FETCH_SELECTED_USER_DETAILS = "FETCH_SELECTED_USER_DETAILS";
export const FETCH_SELECTED_USER_DETAILS_SUCCESS =
  "FETCH_SELECTED_USER_DETAILS_SUCCESS";
export const FETCH_SELECTED_USER_DETAILS_ERROR =
  "FETCH_SELECTED_USER_DETAILS_ERROR";

export const CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED =
  "CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED";
export const IS_EXTERNAL_CUSTOMER_ALLOWED_SUCCESS =
  "IS_EXTERNAL_CUSTOMER_ALLOWED_SUCCESS";
export const IS_EXTERNAL_CUSTOMER_ALLOWED_ERRROR =
  "IS_EXTERNAL_CUSTOMER_ALLOWED_ERRROR";

export type CustomerServicesItem = {
  serviceId: number;
  serviceCode: string;
  description: string;
  parentServiceId: number;
  country: string;
  startDate: string;
  expireDate: string;
  name: string;
  paid: number;
  used: number;
  tnCAccepted: number;
  parentCustomerServiceId: number;
  pseudoId: string;
  sortId: number;
  isUnlimited: boolean;
};

export type PrimaryContact = {
  title: string;
  titleId: number;
  firstName: string;
  surname: string;
};

export type UsersItem = {
  userId: number;
  customerId: number;
  userName: string;
  isActive: boolean;
  isSenior: boolean;
  contactName: string;
};

export interface Customer {
  companyName: string;
  country: string;
  csuid: string;
  customerId: number;
  customerServices: CustomerServicesItem[];
  email: string;
  faxNumber: string;
  partnershipId: number;
  primaryContact: PrimaryContact;
  tel1: string;
  tel2: string;
  type: string;
  users: UsersItem[];
}

export type SelectUserPayload = {
  customerId: number;
  companyName?: string;
  userId: number;
  contactPerson: string;
  contactEmail: string;
  contactTelephone?: string;
};

export interface SelectedUser {
  data?: SelectUserPayload;
  status: Status;
}

export type CustomerState = Normalized<Customer, number> & {
  totalLength: number;
  selectedUser?: SelectedUser;
  isExternalCustomerAllowed: boolean;
};

export type CustomerActionTypes = ActionType<typeof CustomerActions>;

export type SearchForACustomerPayload = {
  customerId?: number;
  userId?: number;
  companyName?: string;
  csuid?: string;
  countryAccess?: string;
  recordLimit?: number;
};

export interface SearchForACustomerSuccessPayload {
  customers: Customer[];
  count: number;
}
