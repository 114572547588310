import { action } from "typesafe-actions";
import {
  FETCH_APP_VERSION,
  FETCH_APP_VERSION_SUCCESS,
  SET_REQUIRES_APP_UPDATE,
} from "./types";

export const fetchAppVersion = () => action(FETCH_APP_VERSION);
export const fetchAppVersionSuccess = () => action(FETCH_APP_VERSION_SUCCESS);
export const setRequiresAppUpdate = () => action(SET_REQUIRES_APP_UPDATE);
