/* eslint-disable complexity */
import {
  SettingsState,
  SettingsActionTypes,
  FETCH_MASTER_TEMPLATE,
  FETCH_MASTER_TEMPLATE_SUCCESS,
  FETCH_MASTER_TEMPLATE_ERROR,
  SAVE_MASTER_TEMPLATE,
  SAVE_MASTER_TEMPLATE_ERROR,
  SAVE_MASTER_TEMPLATE_SUCCESS,
  DELETE_MASTER_TEMPLATE,
  DELETE_MASTER_TEMPLATE_ERROR,
  DELETE_MASTER_TEMPLATE_SUCCESS,
  DELETE_ENRICHMENT_TEMPLATE,
  DELETE_ENRICHMENT_TEMPLATE_ERROR,
  DELETE_ENRICHMENT_TEMPLATE_SUCCESS,
  FETCH_ENRICHMENT_TEMPLATE,
  FETCH_ENRICHMENT_TEMPLATE_ERROR,
  FETCH_ENRICHMENT_TEMPLATE_SUCCESS,
  SETTINGS_SAVE_ENRICHMENT_TEMPLATE,
  SETTINGS_SAVE_ENRICHMENT_TEMPLATE_ERROR,
  SETTINGS_SAVE_ENRICHMENT_TEMPLATE_SUCCESS,
  DELETE_MINIMAL_MATCH_TEMPLATE,
  DELETE_MINIMAL_MATCH_TEMPLATE_ERROR,
  DELETE_MINIMAL_MATCH_TEMPLATE_SUCCESS,
  FETCH_MINIMAL_MATCH_TEMPLATE,
  FETCH_MINIMAL_MATCH_TEMPLATE_ERROR,
  FETCH_MINIMAL_MATCH_TEMPLATE_SUCCESS,
  SAVE_MINIMAL_MATCH_TEMPLATE,
  SAVE_MINIMAL_MATCH_TEMPLATE_ERROR,
  SAVE_MINIMAL_MATCH_TEMPLATE_SUCCESS,
  FETCH_MAPPING_TEMPLATE,
  FETCH_MAPPING_TEMPLATE_ERROR,
  FETCH_MAPPING_TEMPLATE_SUCCESS,
  RESET_STATUS,
  FETCH_ENRICHMENT_TEMPLATEDETAILS,
  FETCH_ENRICHMENT_TEMPLATEDETAILS_SUCCESS,
  FETCH_ENRICHMENT_TEMPLATEDETAILS_ERROR,
  FETCH_MINIMAL_MATCH_TEMPLATEDETAILS,
  FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_ERROR,
  FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_SUCCESS,
  FETCH_MAPPING_TEMPLATEDETAILS,
  FETCH_MAPPING_TEMPLATEDETAILS_SUCCESS,
  FETCH_MAPPING_TEMPLATEDETAILS_ERROR,
  SETTINGS_UPDATE_ENRICHMENT_TEMPLATE,
  RESET_SELECTED,
  UPDATE_MINIMAL_MATCH_TEMPLATE,
  FETCH_MASTER_TEMPLATE_DETAILS,
  FETCH_MASTER_TEMPLATE_DETAILS_SUCCESS,
  FETCH_MASTER_TEMPLATE_DETAILS_ERROR,
} from "./types";
import { Status } from "../types";

export const initialState: SettingsState = {
  minimalMatchTemplate: {
    data: [],
    status: Status.unset,
    errorMsg: "",
  },
  masterTemplate: {
    data: [],
    status: Status.unset,
    errorMsg: "",
  },
  enrichmentTemplate: {
    data: [],
    status: Status.unset,
    errorMsg: "",
  },
  mappingTemplate: {
    data: [],
    status: Status.unset,
    errorMsg: "",
  },
  selected: {
    enrichmentTemplate: undefined,
    mappingTemplate: undefined,
    minimalMatchTemplate: undefined,
  },
  status: {
    enrichmentTemplate: Status.unset,
    minimalMatchTemplate: Status.unset,
    masterTemplate: Status.unset,
  },
  errMessages: {},
};

export default function (
  state = initialState,
  action: SettingsActionTypes
): SettingsState {
  switch (action.type) {
    // #region Master Template

    case FETCH_MASTER_TEMPLATE: {
      return {
        ...state,
        masterTemplate: { data: [], status: Status.loading, errorMsg: "" },
      };
    }
    case FETCH_MASTER_TEMPLATE_SUCCESS: {
      return {
        ...state,
        masterTemplate: {
          data: action.payload,
          status: Status.loaded,
          errorMsg: "",
        },
      };
    }
    case FETCH_MASTER_TEMPLATE_ERROR: {
      return {
        ...state,
        masterTemplate: {
          data: [],
          status: Status.errorLoading,
          errorMsg: action.payload,
        },
      };
    }
    case SAVE_MASTER_TEMPLATE: {
      return {
        ...state,
        status: {
          ...state?.status,
          masterTemplate: Status.saving,
        },
        errMessages: {
          ...state?.errMessages,
          masterTemplate: undefined,
        },
      };
    }
    case SAVE_MASTER_TEMPLATE_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          masterTemplate: {
            data: action.payload,
            status: Status.loaded,
            errorMsg: "",
          },
        },
        status: {
          ...state?.status,
          masterTemplate: Status.saved,
        },
      };
    }
    case SAVE_MASTER_TEMPLATE_ERROR: {
      const data = action.payload;
      if (data && !data.errors) {
        data.errors = { detail: data.detail };
      }
      return {
        ...state,
        status: {
          ...state?.status,
          masterTemplate: Status.errorSaving,
        },
        errMessages: {
          ...state?.errMessages,
          masterTemplate: data,
        },
      };
    }

    case FETCH_MASTER_TEMPLATE_DETAILS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          masterTemplate: {
            data: undefined,
            status: Status.loading,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_MASTER_TEMPLATE_DETAILS_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          masterTemplate: {
            data: action.payload,
            status: Status.loaded,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_MASTER_TEMPLATE_DETAILS_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          masterTemplate: {
            data: undefined,
            status: Status.errorLoading,
            errorMsg: action.payload,
          },
        },
      };
    }

    case DELETE_MASTER_TEMPLATE: {
      return {
        ...state,
        status: {
          ...state?.status,
          masterTemplate: Status.deleting,
        },
      };
    }
    case DELETE_MASTER_TEMPLATE_SUCCESS: {
      return {
        ...state,
        status: {
          ...state?.status,
          masterTemplate: Status.deleted,
        },
      };
    }
    case DELETE_MASTER_TEMPLATE_ERROR: {
      const data = action.payload;
      if (data && !data.errors) {
        data.errors = { detail: data.detail };
      }
      return {
        ...state,
        status: {
          ...state?.status,
          masterTemplate: Status.deleteError,
        },
        errMessages: {
          ...state.errMessages,
          masterTemplate: data,
        },
      };
    }

    // #endregion

    // #region Enrichment Template
    case FETCH_ENRICHMENT_TEMPLATE: {
      return {
        ...state,
        enrichmentTemplate: { data: [], status: Status.loading, errorMsg: "" },
      };
    }
    case FETCH_ENRICHMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        enrichmentTemplate: {
          data: action.payload,
          status: Status.loaded,
          errorMsg: "",
        },
      };
    }
    case FETCH_ENRICHMENT_TEMPLATE_ERROR: {
      return {
        ...state,
        enrichmentTemplate: {
          data: [],
          status: Status.errorLoading,
          errorMsg: action.payload,
        },
      };
    }
    case FETCH_ENRICHMENT_TEMPLATEDETAILS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          enrichmentTemplate: {
            data: undefined,
            status: Status.loading,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_ENRICHMENT_TEMPLATEDETAILS_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          enrichmentTemplate: {
            data: action.payload,
            status: Status.loaded,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_ENRICHMENT_TEMPLATEDETAILS_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          enrichmentTemplate: {
            data: undefined,
            status: Status.errorLoading,
            errorMsg: action.payload,
          },
        },
      };
    }
    case SETTINGS_UPDATE_ENRICHMENT_TEMPLATE:
    case SETTINGS_SAVE_ENRICHMENT_TEMPLATE: {
      return {
        ...state,
        status: {
          ...state?.status,
          enrichmentTemplate: Status.saving,
        },
      };
    }
    case SETTINGS_SAVE_ENRICHMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          enrichmentTemplate: {
            data: action.payload,
            status: Status.loaded,
            errorMsg: "",
          },
        },
        status: {
          ...state?.status,
          enrichmentTemplate: Status.saved,
        },
      };
    }
    case SETTINGS_SAVE_ENRICHMENT_TEMPLATE_ERROR: {
      const data = action.payload;
      if (data && !data.errors) {
        data.errors = { detail: data.detail };
      }
      return {
        ...state,
        status: {
          ...state?.status,
          enrichmentTemplate: Status.errorSaving,
        },
        errMessages: {
          ...state.errMessages,
          enrichmentTemplate: data,
        },
      };
    }

    case DELETE_ENRICHMENT_TEMPLATE: {
      return {
        ...state,
        status: {
          ...state?.status,
          enrichmentTemplate: Status.deleting,
        },
      };
    }
    case DELETE_ENRICHMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        status: {
          ...state?.status,
          enrichmentTemplate: Status.deleted,
        },
      };
    }
    case DELETE_ENRICHMENT_TEMPLATE_ERROR: {
      const data = action.payload;
      if (data && !data.errors) {
        data.errors = { detail: data.detail };
      }
      return {
        ...state,
        status: {
          ...state?.status,
          enrichmentTemplate: Status.deleteError,
        },
        errMessages: {
          ...state.errMessages,
          enrichmentTemplate: data,
        },
      };
    }

    // #endregion

    // #region Minimal match template

    case FETCH_MINIMAL_MATCH_TEMPLATE: {
      return {
        ...state,
        minimalMatchTemplate: {
          data: [],
          status: Status.loading,
          errorMsg: "",
        },
      };
    }
    case FETCH_MINIMAL_MATCH_TEMPLATE_SUCCESS: {
      return {
        ...state,
        minimalMatchTemplate: {
          data: action.payload,
          status: Status.loaded,
          errorMsg: "",
        },
      };
    }
    case FETCH_MINIMAL_MATCH_TEMPLATE_ERROR: {
      return {
        ...state,
        minimalMatchTemplate: {
          data: [],
          status: Status.errorLoading,
          errorMsg: action.payload,
        },
      };
    }
    case FETCH_MINIMAL_MATCH_TEMPLATEDETAILS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          minimalMatchTemplate: {
            data: undefined,
            status: Status.loading,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          minimalMatchTemplate: {
            data: action.payload,
            status: Status.loaded,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          minimalMatchTemplate: {
            data: undefined,
            status: Status.errorLoading,
            errorMsg: action.payload,
          },
        },
      };
    }
    case UPDATE_MINIMAL_MATCH_TEMPLATE:
    case SAVE_MINIMAL_MATCH_TEMPLATE: {
      return {
        ...state,
        status: {
          ...state?.status,
          minimalMatchTemplate: Status.saving,
        },
        errMessages: {
          ...state?.errMessages,
          minimalMatchTemplate: undefined,
        },
      };
    }
    case SAVE_MINIMAL_MATCH_TEMPLATE_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          minimalMatchTemplate: {
            data: action.payload,
            status: Status.loaded,
            errorMsg: "",
          },
        },
        status: {
          ...state?.status,
          minimalMatchTemplate: Status.saved,
        },
      };
    }
    case SAVE_MINIMAL_MATCH_TEMPLATE_ERROR: {
      const data = action.payload;
      if (data && !data.errors) {
        data.errors = { detail: data.detail };
      }
      return {
        ...state,
        status: {
          ...state?.status,
          minimalMatchTemplate: Status.errorSaving,
        },
        errMessages: {
          ...state?.errMessages,
          minimalMatchTemplate: data,
        },
      };
    }

    case DELETE_MINIMAL_MATCH_TEMPLATE: {
      return {
        ...state,
        status: {
          ...state?.status,
          minimalMatchTemplate: Status.deleting,
        },
      };
    }
    case DELETE_MINIMAL_MATCH_TEMPLATE_SUCCESS: {
      return {
        ...state,
        status: {
          ...state?.status,
          minimalMatchTemplate: Status.deleted,
        },
      };
    }
    case DELETE_MINIMAL_MATCH_TEMPLATE_ERROR: {
      const data = action.payload;
      if (data && !data.errors) {
        data.errors = { detail: data.detail };
      }
      return {
        ...state,
        status: {
          ...state?.status,
          minimalMatchTemplate: Status.deleteError,
        },
        errMessages: {
          ...state?.errMessages,
          minimalMatchTemplate: action.payload,
        },
      };
    }

    // #endregion

    // #region Mapping Template

    case FETCH_MAPPING_TEMPLATE: {
      return {
        ...state,
        mappingTemplate: { data: [], status: Status.loading, errorMsg: "" },
      };
    }
    case FETCH_MAPPING_TEMPLATE_SUCCESS: {
      return {
        ...state,
        mappingTemplate: {
          data: action.payload,
          status: Status.loaded,
          errorMsg: "",
        },
      };
    }
    case FETCH_MAPPING_TEMPLATE_ERROR: {
      return {
        ...state,
        mappingTemplate: {
          data: [],
          status: Status.errorLoading,
          errorMsg: action.payload,
        },
      };
    }

    case FETCH_MAPPING_TEMPLATEDETAILS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          mappingTemplate: {
            data: undefined,
            status: Status.loading,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_MAPPING_TEMPLATEDETAILS_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          mappingTemplate: {
            data: action.payload,
            status: Status.loaded,
            errorMsg: "",
          },
        },
      };
    }
    case FETCH_MAPPING_TEMPLATEDETAILS_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          mappingTemplate: {
            data: undefined,
            status: Status.errorLoading,
            errorMsg: action.payload,
          },
        },
      };
    }

    // #endregion

    case RESET_STATUS: {
      return {
        ...state,
        status: {
          masterTemplate: Status.unset,
          enrichmentTemplate: Status.unset,
          minimalMatchTemplate: Status.unset,
        },
      };
    }

    case RESET_SELECTED: {
      return {
        ...state,
        selected: {
          enrichmentTemplate: undefined,
          mappingTemplate: undefined,
          minimalMatchTemplate: undefined,
        },
      };
    }
    default:
      return state;
  }
}
