import React from "react";
import {
  makeStyles,
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    titleGrid: {
      padding: theme.spacing(2),
    },
    icon: {
      position: "absolute",
      right: theme.spacing(0),
      cursor: "pointer",
    },
    dialogContent: {
      marginBottom: theme.spacing(0),
    },
  })
);

interface IGenericDialog {
  open: boolean;
  title?: string;
  body?: string;
  onClose(): void;
  hideCloseIcon?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  actionLabel?: string;
}

const GenericDialog: React.FC<IGenericDialog> = ({
  open,
  title,
  body,
  onClose,
  hideCloseIcon,
  disableEscapeKeyDown,
  disableBackdropClick,
  actionLabel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableBackdropClick={disableBackdropClick}
    >
      {title && (
        <Grid
          container
          className={classes.titleGrid}
          data-testid="generic-dialog"
        >
          <Typography variant="h2" data-testid="dialog-title">
            {title}
          </Typography>
        </Grid>
      )}
      {!hideCloseIcon && (
        <CloseIcon
          className={classes.icon}
          data-testid="dialog-close-button"
          onClick={onClose}
        />
      )}
      {body && (
        <DialogContent>
          <DialogContentText
            className={classes.dialogContent}
            data-testid="dialog-text"
          >
            {body}
          </DialogContentText>
        </DialogContent>
      )}
      {actionLabel && (
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onClose}>
            {actionLabel}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default GenericDialog;
