import { Epic } from "../rootEpic";
import {
  FETCH_ALGORITHM_BANDINGS,
  FETCH_MAPPING_ENRICHMENT_TEMPLATES,
  SAVE_ENRICHMENT_TEMPLATE,
  SAVE_ENRICHMENT_TEMPLATE_SUCCESS,
} from "./types";
import { of, timer } from "rxjs";
import { map, switchMap, filter, catchError } from "rxjs/operators";
import {
  clearPopupDialogAction,
  fetchMappingEnrichmentTemplatesError,
  fetchMappingEnrichmentTemplatesSuccess,
  saveEnrichmentTemplateSuccess,
  saveEnrichmentTemplateError,
  fetchAlgorithmBandingsSuccess,
  fetchAlgorithmBandingsError,
} from "./actions";
import { isOfType, ActionType } from "typesafe-actions";
import config from "../../config";

export const fetchEnrichmentMappingsEpic: Epic<
  | ActionType<typeof fetchMappingEnrichmentTemplatesSuccess>
  | ActionType<typeof fetchMappingEnrichmentTemplatesError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_MAPPING_ENRICHMENT_TEMPLATES)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates?creditType=${payload.creditType}`,
        })
        .pipe(
          map(({ response }) =>
            fetchMappingEnrichmentTemplatesSuccess(response)
          ),
          catchError(() => of(fetchMappingEnrichmentTemplatesError()))
        )
    )
  );

export const fetchAlgorithmBandingsEpic: Epic<
  | ActionType<typeof fetchAlgorithmBandingsSuccess>
  | ActionType<typeof fetchAlgorithmBandingsError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_ALGORITHM_BANDINGS)),
    switchMap(() =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/metadata/algorithmbandings`,
        })
        .pipe(
          map(({ response }) => fetchAlgorithmBandingsSuccess(response)),
          catchError(() => of(fetchAlgorithmBandingsError()))
        )
    )
  );

export const saveEnrichmentTemplateEpic: Epic<
  | ActionType<typeof saveEnrichmentTemplateSuccess>
  | ActionType<typeof saveEnrichmentTemplateError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(SAVE_ENRICHMENT_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: payload,
        })
        .pipe(
          map(() => saveEnrichmentTemplateSuccess()),
          catchError(() => of(saveEnrichmentTemplateError()))
        );
    })
  );

export const clearPopupDialogEpic: Epic<ActionType<
  typeof clearPopupDialogAction
>> = (action$) =>
  action$.pipe(
    filter(isOfType(SAVE_ENRICHMENT_TEMPLATE_SUCCESS)),
    switchMap(() => timer(2000).pipe(map(clearPopupDialogAction)))
  );
