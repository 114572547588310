import {
  USER_SIGNED_IN,
  UserActionTypes,
  UserState,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_REMAINING_CREDITS_SUCCESS,
  FETCH_USER_DETAILS_ERROR,
  FETCH_ENTITY_TEL_SUCCESS,
  IS_USER_ALLOWED_FOR_SEARCHPANEL_SUCCESS,
  CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL,
  IS_USER_ALLOWED_FOR_SEARCHPANEL_ERROR,
  FETCH_MONITORING_PORTFOLIOS,
  FETCH_MONITORING_PORTFOLIOS_SUCCESS,
  FETCH_MONITORING_PORTFOLIOS_ERROR,
} from "./types";
import config from "../../config";
import { Status } from "../types";
import { getService, getServiceByContry } from "appUtils";

export const initialState: UserState = {
  userId: null,
  isSignedIn: false,
  countryCode: "GB",
  languageCode: "en-GB",
  shouldUseUpp: false,
  favouriteCountries: ["US", "WW"],
  remainingCredits: {
    countryCode: "00",
    credits: 0,
    creditType: "standard",
    used: 0,
    paid: 0,
    isUnlimited: false,
  },
  isInternal: false,
  status: Status.unset,
  entityTel: "855-551-6903",
  isUserAllowedForSearchPanel: false,
  isUserFirstLogin: false,
  isMonitoringServiceEnabled: false,
};

export default function (
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case USER_SIGNED_IN: {
      return {
        ...state,
        userId: action.payload,
        isSignedIn: true,
        status: Status.loading,
      };
    }
    case FETCH_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        favouriteCountries: ["WW", action.payload.countryCode],
        status: Status.loaded,
      };
    }
    case FETCH_USER_DETAILS_ERROR: {
      return {
        ...state,
        status: Status.errorLoading,
      };
    }
    case FETCH_REMAINING_CREDITS_SUCCESS: {
      const service = getService(action.payload, config.SERVICE_ID);
      const creditsByCountry = getServiceByContry(
        action.payload,
        config.SERVICE_ID
      );
      const internationalMonitoringService = getService(
        action.payload,
        config.INTERNATIONAL_MONITORING_SERVICE_ID
      );
      const domesticMonitoringService = getService(
        action.payload,
        config.DOMESTIC_MONITORING_SERVICE_ID
      );

      let isMonitoringServiceEnabled = true;
      if (
        (internationalMonitoringService === null ||
          internationalMonitoringService === undefined) &&
        (domesticMonitoringService === null ||
          domesticMonitoringService === undefined)
      ) {
        isMonitoringServiceEnabled = false;
      }

      const _state = {
        ...state,
        isMonitoringServiceEnabled: isMonitoringServiceEnabled,
      };

      if (!service) {
        return _state;
      }
      return {
        ..._state,
        remainingCredits: service || {},
        remainingCreditsByCreditType: creditsByCountry || [],
      };
    }
    case FETCH_ENTITY_TEL_SUCCESS: {
      return {
        ...state,
        entityTel: action.payload,
      };
    }
    case CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL: {
      return {
        ...state,
        isUserAllowedForSearchPanel: state.isUserAllowedForSearchPanel,
        isUserFirstLogin: state.isUserFirstLogin,
      };
    }
    case IS_USER_ALLOWED_FOR_SEARCHPANEL_SUCCESS: {
      return {
        ...state,
        isUserAllowedForSearchPanel: action.payload,
        isUserFirstLogin: true,
      };
    }
    case IS_USER_ALLOWED_FOR_SEARCHPANEL_ERROR: {
      return {
        ...state,
        isUserAllowedForSearchPanel: false,
        isUserFirstLogin: false,
      };
    }
    case FETCH_MONITORING_PORTFOLIOS: {
      return {
        ...state,
        monitoringPortfolios: { data: [], status: Status.loading },
      };
    }
    case FETCH_MONITORING_PORTFOLIOS_SUCCESS: {
      return {
        ...state,
        monitoringPortfolios: { data: action.payload, status: Status.loaded },
      };
    }
    case FETCH_MONITORING_PORTFOLIOS_ERROR: {
      return {
        ...state,
        monitoringPortfolios: { data: [], status: Status.errorLoading },
      };
    }
    default:
      return state;
  }
}
