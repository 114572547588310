import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import { initReactI18next } from "react-i18next";
import { LocaleDateOptions } from "./types";
import numbro from "numbro";
import moment from "moment";
import set from "lodash/set";
import { localeConfig } from "./i18n-locale-configs";
import { IConfig } from "../config";

export const initLanguages = (localeConfig: LocaleDateOptions[]) => {
  localeConfig.forEach(
    ({ code, overrides, numbroLanguage, momentLocaleParent }) => {
      if (overrides) {
        overrides.forEach(({ path, pathValue }) => {
          set(numbroLanguage, path, pathValue);
        });
      }

      numbroLanguage && numbro.registerLanguage(numbroLanguage);

      momentLocaleParent &&
        moment.defineLocale(code, { parentLocale: momentLocaleParent });
    }
  );
};

const initI18nClient = async (config: IConfig) => {
  i18n.use(LanguageDetector).use(Backend).use(initReactI18next);

  if (!i18n.isInitialized) {
    await i18n.init({
      fallbackLng: "en-US",
      saveMissing: false,
      load: "currentOnly",
      ns: [
        "global",
        "aggregates",
        "jobs",
        "mappingsPage",
        "newFileInfo",
        "pdfs",
      ],
      defaultNS: "global",
      lng: "en-US",
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      react: {
        wait: true,
        useSuspense: false,
      },
      backend: {
        backends: [XHR, XHR],
        backendOptions: [
          {
            loadPath: config.LOCIZE_ENABLED
              ? `${config.UPP_API_URL}/i18n?product=dataCleaning&namespace={{ns}}&locale={{lng}}`
              : "/locales/{{ns}}/{{lng}}.translation.json",
          },
          {
            loadPath: "/locales/{{ns}}/{{lng}}.translation.json",
          },
        ],
      },
      debug: config.DEBUG_MODE,
    });

    initLanguages(localeConfig);
  }

  i18n.on("languageChanged", (lng) => {
    moment.locale(lng);
  });
};

export { initI18nClient };

export default i18n;
