import {
  MappingsActionTypes,
  MappingsState,
  FETCH_MAPPING_TEMPLATES,
  FETCH_MAPPING_TEMPLATES_SUCCESS,
  FETCH_MAPPING_TEMPLATES_ERROR,
  Mapping,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_ERROR,
  CLEAR_POPUP_NOTIFICATION,
  RESET_TEMPLATE_SAVE_STATUS,
} from "./types";
import { Status } from "../types";

export const initialState: MappingsState = {
  allIds: [],
  byId: {},
  status: Status.unset,
  templateSavingStatus: Status.unset,
};

export default function (
  state = initialState,
  action: MappingsActionTypes
): MappingsState {
  switch (action.type) {
    case FETCH_MAPPING_TEMPLATES: {
      return {
        ...state,
        allIds: [],
        byId: {},
        status: Status.loading,
      };
    }
    case FETCH_MAPPING_TEMPLATES_SUCCESS: {
      return {
        ...state,
        allIds: action.payload.map((x: Mapping) => x.id),
        byId: normalize(action.payload),
        status: Status.loaded,
      };
    }
    case FETCH_MAPPING_TEMPLATES_ERROR: {
      return {
        ...state,
        status: Status.errorLoading,
      };
    }
    case SAVE_TEMPLATE:
      return {
        ...state,
        status: Status.saving,
        templateSavingStatus: Status.saving,
      };
    case SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: Status.saved,
        templateSavingStatus: Status.saved,
      };
    case SAVE_TEMPLATE_ERROR:
      return {
        ...state,
        status: Status.errorSaving,
        templateSavingStatus: Status.errorSaving,
      };
    case RESET_TEMPLATE_SAVE_STATUS:
      return {
        ...state,
        templateSavingStatus: Status.unset,
      };
    case CLEAR_POPUP_NOTIFICATION: {
      return {
        ...state,
        templateSavingStatus: Status.unset,
      };
    }
    default:
      return state;
  }
}

const normalize = (mappings: Mapping[]): { [id: string]: Mapping } =>
  mappings.reduce((acc, x) => ({ ...acc, [x.id]: x }), {});
