import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { ReactComponent as AgricultureIcon } from "../../../assets/tractor.svg";
import { ReactComponent as MiningIcon } from "../../../assets/hard-hat.svg";
import { ReactComponent as ConstructionIcon } from "../../../assets/toolbox.svg";
import { ReactComponent as ManufacturingIcon } from "../../../assets/industry.svg";
import { ReactComponent as TransportIcon } from "../../../assets/truck-container.svg";
import { ReactComponent as WholesaleIcon } from "../../../assets/boxes.svg";
import { ReactComponent as RetailIcon } from "../../../assets/shopping-bag.svg";
import { ReactComponent as FinanceIcon } from "../../../assets/calculator-alt.svg";
import { ReactComponent as ServicesIcon } from "../../../assets/concierge-bell.svg";
import { ReactComponent as PublicAdminIcon } from "../../../assets/landmark.svg";
import { ReactComponent as NonClassifiableIcon } from "../../../assets/building.svg";
import { ReactComponent as ElectricityIcon } from "../../../assets/lightbulb-on.svg";
import { ReactComponent as WaterIcon } from "../../../assets/water.svg";
import { ReactComponent as CarsIcon } from "../../../assets/cars.svg";
import { ReactComponent as HotelIcon } from "../../../assets/hotel.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/phone.svg";
import { ReactComponent as HomeIcon } from "../../../assets/home.svg";
import { ReactComponent as ScienceIcon } from "../../../assets/dna.svg";
import { ReactComponent as AdminIcon } from "../../../assets/user-headset.svg";
import { ReactComponent as EducationIcon } from "../../../assets/graduation-cap.svg";
import { ReactComponent as HealthIcon } from "../../../assets/user-nurse.svg";
import { ReactComponent as ArtsIcon } from "../../../assets/theater-masks.svg";
import { ReactComponent as UndifferentiatiedGoodsIcon } from "../../../assets/users-class.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/globe.svg";
import { ReactComponent as ComputerIcon } from "../../../assets/computer.svg";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: "20px",
    },
  })
);

const IndustryIcon: React.FC<{ activityCode: number; countryCode: string }> = ({
  activityCode,
  countryCode,
}) => {
  const classes = useStyles();

  const getIconBySICUS = (activityCode: number) => {
    const SIC = String(activityCode).padStart(4, "0");
    const firstTwoDigits = Number(SIC.slice(0, 2));
    switch (true) {
      case firstTwoDigits < 10:
        return AgricultureIcon;
      case firstTwoDigits < 15:
        return MiningIcon;
      case firstTwoDigits < 20:
        return ConstructionIcon;
      case firstTwoDigits < 40:
        return ManufacturingIcon;
      case firstTwoDigits < 50:
        return TransportIcon;
      case firstTwoDigits < 52:
        return WholesaleIcon;
      case firstTwoDigits < 60:
        return RetailIcon;
      case firstTwoDigits < 70:
        return FinanceIcon;
      case firstTwoDigits < 91:
        return ServicesIcon;
      case firstTwoDigits < 99:
        return PublicAdminIcon;
      default:
        return NonClassifiableIcon;
    }
  };

  // eslint-disable-next-line complexity
  const getIconBySICUKFR = (activityCode: number) => {
    switch (true) {
      case activityCode < 400:
        return AgricultureIcon;
      case activityCode < 1000:
        return MiningIcon;
      case activityCode < 3500:
        return ManufacturingIcon;
      case activityCode < 3600:
        return ElectricityIcon;
      case activityCode < 4100:
        return WaterIcon;
      case activityCode < 4500:
        return ConstructionIcon;
      case activityCode < 4900:
        return CarsIcon;
      case activityCode < 5500:
        return TransportIcon;
      case activityCode < 5800:
        return HotelIcon;
      case activityCode < 6400:
        return PhoneIcon;
      case activityCode < 6800:
        return FinanceIcon;
      case activityCode < 6900:
        return HomeIcon;
      case activityCode < 7700:
        return ScienceIcon;
      case activityCode < 8400:
        return AdminIcon;
      case activityCode < 8500:
        return PublicAdminIcon;
      case activityCode < 8600:
        return EducationIcon;
      case activityCode < 9000:
        return HealthIcon;
      case activityCode < 9400:
        return ArtsIcon;
      case activityCode < 9700:
        return NonClassifiableIcon;
      case activityCode < 9900:
        return UndifferentiatiedGoodsIcon;
      case activityCode < 10000:
        return GlobeIcon;
      default:
        return NonClassifiableIcon;
    }
  };
  // eslint-disable-next-line complexity
  const getIconBySICCodeAndCountryCode = (
    activityCode: number,
    countrycode: string
  ) => {
    switch (true) {
      case activityCode === 0:
        return GlobeIcon;
      case activityCode >= 1 && activityCode <= 3:
        return AgricultureIcon;
      case activityCode === 5 && (countrycode === "se" || countrycode === "no"):
        return MiningIcon;
      case activityCode >= 6 && activityCode <= 9:
        return MiningIcon;
      case activityCode >= 10 && activityCode <= 33:
        return ManufacturingIcon;
      case activityCode === 35:
        return ElectricityIcon;
      case activityCode >= 36 && activityCode <= 39:
        return WaterIcon;
      case activityCode >= 41 && activityCode <= 43:
        return ConstructionIcon;
      case activityCode >= 45 && activityCode <= 47:
        return WholesaleIcon;
      case activityCode >= 49 && activityCode <= 53:
        return TransportIcon;
      case activityCode >= 55 && activityCode <= 56:
        return HotelIcon;
      case activityCode >= 58 && activityCode <= 63:
        return ComputerIcon;
      case activityCode >= 64 && activityCode <= 66:
        return FinanceIcon;
      case activityCode >= 69 && activityCode <= 75:
        return ScienceIcon;
      case activityCode >= 77 && activityCode <= 82:
        return AdminIcon;
      case activityCode === 84:
        return PublicAdminIcon;
      case activityCode === 85:
        return EducationIcon;
      case activityCode >= 86 && activityCode <= 88:
        return HealthIcon;
      case activityCode >= 90 && activityCode <= 93:
        return ArtsIcon;
      case activityCode >= 94 && activityCode <= 96:
        return ServicesIcon;
      case activityCode === 68 || (activityCode >= 97 && activityCode <= 98):
        return HomeIcon;
      case activityCode === 99:
        return UndifferentiatiedGoodsIcon;
      default:
        return NonClassifiableIcon;
    }
  };

  const getIconByCountryCode = (countryCode: string) => {
    const country = countryCode.toLowerCase();
    switch (country) {
      case "uk":
      case "gb":
      case "fr":
        return getIconBySICUKFR(activityCode);
      case "nl":
      case "se":
      case "no":
        return getIconBySICCodeAndCountryCode(activityCode, country);
      case "us":
      default:
        return getIconBySICUS(activityCode);
    }
  };

  const Icon = getIconByCountryCode(countryCode);
  return <Icon className={classes.icon} />;
};

export default IndustryIcon;
