import { TFunction } from "i18next";
import { percentage } from "../../../appUtils";
import { AggregateData, AggregateObject } from "../../../store/jobs/types";
import { ChartInputItem } from "./types";
import { WIDGET_TITLE_SICCODE, UNKNOWN } from "./constants";
import { CountryCodeAggregationKey } from "../Mappings/constants";

export const formatAggregate = (
  information: AggregateData[],
  colors: string[],
  t: TFunction,
  title: string,
  countryCode: string
) => {
  switch (title.toLowerCase()) {
    case "siccode":
      return information.map((dataPoint, index) => {
        return {
          name:
            countryCode === "no"
              ? t(`SICCode.${countryCode}.${dataPoint.name.replace(".", "_")}`)
              : t(`SICCode.${countryCode}.${dataPoint.name}`),
          label: "",
          value: dataPoint.count,
          percentage: percentage(dataPoint.percentage, 2),
          color: colors[index],
          activityCode: formatSICCodeAsNumber(dataPoint.name, countryCode),
        };
      });
    case "sic07":
      return information.map((dataPoint, index) => {
        return {
          name: t(`SIC07.${dataPoint.name}`),
          label: "",
          value: dataPoint.count,
          percentage: percentage(dataPoint.percentage, 2),
          color: colors[index],
          activityCode: formatSICCodeAsNumber(dataPoint.name, countryCode),
        };
      });
    case "dbt":
      return information.map((dataPoint, index) => {
        if (countryCode === "ca") {
          return {
            name: t(
              `${title}.${dataPoint.name}${countryCode.toUpperCase()}.full`
            ),
            label: t(
              `${title}.${dataPoint.name}${countryCode.toUpperCase()}.short`
            ),
            value: dataPoint.count,
            percentage: percentage(dataPoint.percentage, 2),
            color: colors[index],
          };
        }
        return {
          name: t(`${title}.${dataPoint.name}.full`),
          label: t(`${title}.${dataPoint.name}.short`),
          value: dataPoint.count,
          percentage: percentage(dataPoint.percentage, 2),
          color: colors[index],
        };
      });
    case "salesrevenue":
    case "creditlimit":
    case "capital":
    case "totalequity":
    case "creditindex":
      return information.map((dataPoint, index) => {
        return {
          name: t(`${title}.${dataPoint.name}.full`),
          label: t(`${title}.${dataPoint.name}.short`),
          value: dataPoint.count,
          percentage: percentage(dataPoint.percentage, 2),
          color: colors[index],
        };
      });
    case "countrycode":
      return information.map((dataPoint, index) => {
        return {
          name: t(`${title}.${dataPoint.name}`),
          label: "",
          value: dataPoint.count,
          percentage: percentage(dataPoint.percentage, 2),
          color: colors[index],
          countryCode: dataPoint.name,
        };
      });
    case "noofemployees":
      if (countryCode === "fr")
        return information.map((dataPoint, index) => {
          return {
            name: t(`${title}.${dataPoint.name}${`FR`}.full`),
            label: t(`${title}.${dataPoint.name}${`FR`}.short`),
            value: dataPoint.count,
            percentage: percentage(dataPoint.percentage, 2),
            color: colors[index],
          };
        });
      return information.map((dataPoint, index) => {
        return {
          name: t(`${title}.${dataPoint.name}${`WW`}.full`),
          label: t(`${title}.${dataPoint.name}${`WW`}.short`),
          value: dataPoint.count,
          percentage: percentage(dataPoint.percentage, 2),
          color: colors[index],
        };
      });
    case "turnover":
      if (countryCode === "fr" || countryCode === "se")
        return information.map((dataPoint, index) => {
          return {
            name: t(
              `${title}.${dataPoint.name}${countryCode.toUpperCase()}.full`
            ),
            label: t(
              `${title}.${dataPoint.name}${countryCode.toUpperCase()}.short`
            ),
            value: dataPoint.count,
            percentage: percentage(dataPoint.percentage, 2),
            color: colors[index],
          };
        });
    case "status":
      if (countryCode === "be")
        return information.map((dataPoint) => {
          return {
            name: t(`${title}.${countryCode}.${dataPoint.name}`),
            label: t(`${title}.${countryCode}.${dataPoint.name}`),
            value: dataPoint.count,
            percentage: percentage(dataPoint.percentage, 2),
            color: colors[selectColor[`${dataPoint.name}`] ?? 6],
          };
        });
    case "legalform":
      if (countryCode === "ie")
        return information.map((dataPoint, index) => {
          return {
            name: t(`${title}.${dataPoint.name.replaceAll(".", "")}`),
            label: "",
            value: dataPoint.count,
            percentage: percentage(dataPoint.percentage, 2),
            color: colors[index],
          };
        });
    default:
      return information.map((dataPoint, index) => {
        return {
          name: t(`${title}.${dataPoint.name}`),
          label: "",
          value: dataPoint.count,
          percentage: percentage(dataPoint.percentage, 2),
          color: colors[index],
        };
      });
  }
};

export const formatBarChartData = (data: ChartInputItem[]) => {
  const labels = [] as string[];
  const dataPoints = [] as number[];
  data &&
    data.forEach((x) => {
      labels.push(x.name);
      dataPoints.push(x.value);
    });
  return { labels, dataPoints };
};

export const fadeRGBColors = (rgbColor: string) => {
  const RGBVals = rgbColor
    .split(/[\W]/g)
    .filter((x) => !isNaN(Number(x)) && x !== "");
  return `rgba(${RGBVals[0]}, ${RGBVals[1]}, ${RGBVals[2]}, 0.5)`;
};

export const validCountries = [
  "ww",
  "at",
  "be",
  "ca",
  "de",
  "fr",
  "ie",
  "it",
  "jp",
  "nl",
  "no",
  "se",
  "gb",
  "us",
];

export const tabComponents: {
  [key: string]: { title: string }[];
} = {
  ww: [
    { title: "ww" },
    { title: "at" },
    { title: "be" },
    { title: "ca" },
    { title: "de" },
    { title: "fr" },
    { title: "ie" },
    { title: "it" },
    { title: "jp" },
    { title: "nl" },
    { title: "no" },
    { title: "se" },
    { title: "gb" },
    { title: "us" },
  ],
  us: [{ title: "us" }],
  at: [{ title: "at" }],
  ca: [{ title: "ca" }],
  be: [{ title: "be" }],
  nl: [{ title: "nl" }],
  de: [{ title: "de" }],
  fr: [{ title: "fr" }],
  ie: [{ title: "ie" }],
  it: [{ title: "it" }],
  jp: [{ title: "jp" }],
  no: [{ title: "no" }],
  se: [{ title: "se" }],
  gb: [{ title: "gb" }],
};

export const formatSICCodeAsNumber = (SICCode: string, countryCode: string) => {
  if (SICCode.toLowerCase() === UNKNOWN.toLowerCase()) {
    return 9999; //returns a non classifiable icon
  }
  if (countryCode === "nl" || countryCode === "se" || countryCode === "no") {
    if (Number(SICCode.substring(0, 2)) === 0) {
      return Number(SICCode.substring(0, 3));
    }
    return Number(SICCode.substring(0, 2));
  }
  return Number(SICCode.replace("SICCode.", "").replace(/[^\d,]/g, ""));
};

export const percentageToDecimal = (percentage: string) => {
  const value = Number(percentage.slice(0, -1));
  return value / 100;
};

export const getAvailableTabs = (
  aggregates: AggregateObject[],
  // eslint-disable-next-line
  availableTabsValue: any,
  // eslint-disable-next-line
  setAvailableTabsValue: any,
  jobCountry?: string,
  jobId?: string
) => {
  if (jobId && availableTabsValue && availableTabsValue[jobId]) {
    return availableTabsValue[jobId];
  }

  const availableTabs =
    jobCountry && validCountries.includes(jobCountry)
      ? tabComponents[jobCountry]
      : [];

  //if the aggregates haven't yet been loaded then just return the defaults
  if (!aggregates || aggregates?.length === 0) {
    return availableTabs;
  }

  const countryCodeAggregation = aggregates.find(
    (x) => x.name === CountryCodeAggregationKey
  );

  //if the CountryCode aggregate is missing then return the defaults so we get tabs appearing
  if (!countryCodeAggregation) {
    return availableTabs;
  }

  const filteredTabs = availableTabs.filter(
    (x) =>
      x.title === "ww" ||
      countryCodeAggregation.data.some(
        (y) =>
          y.name.toLowerCase() === x.title ||
          (x.title === "gb" && y.name.toLowerCase() === "uk")
      )
  );

  jobId &&
    setAvailableTabsValue({ ...availableTabsValue, [jobId]: filteredTabs });

  return filteredTabs;
};

export const generateMapColorMultiplier = (
  chartData: ChartInputItem[],
  fallbackValue: number,
  percentage?: string
) => {
  if (percentage && chartData.length === 1 && chartData[0].name === UNKNOWN) {
    return fallbackValue;
  } else if (percentage) {
    const highestNonUnknownPercentage = chartData
      .sort((a, b) => (a.value > b.value ? -1 : 1))
      .filter((x) => x.name !== UNKNOWN)[0].percentage;
    const scaledDecimalMultiplier =
      percentageToDecimal(percentage) /
      percentageToDecimal(highestNonUnknownPercentage);
    return Math.max(scaledDecimalMultiplier, fallbackValue);
  } else {
    return fallbackValue;
  }
};

export const formatGeoName = (t: TFunction, country: string, name: string) => {
  const typeOfPrefecture = ["Fu", "Ken", "To", "Do"];

  if (!(!!country && !!name)) {
    return "";
  }

  switch (country.toLowerCase()) {
    case "jp":
      return name
        .split(" ")
        .filter((x) => !typeOfPrefecture.some((pref) => pref === x))
        .join(" ")
        .toLowerCase();
    case "nl":
    case "ca":
      const provinceName = `Province.${name.toUpperCase()}`;
      return t(provinceName).toLowerCase();
    case "it":
    case "se":
      const regionName = `Region.${name.toUpperCase()}`;
      return t(regionName).toLowerCase();
    case "us":
      const stateName = `States.${name.toUpperCase()}`;
      return t(stateName).toLowerCase();
    default:
      return t(name).toLowerCase();
  }
};

const slicedDataTop15 = [
  { country: "de", title: WIDGET_TITLE_SICCODE },
  { country: "it", title: "LegalForm" },
  { country: "nl", title: "GeneralLegalForm" },
  { country: "nl", title: WIDGET_TITLE_SICCODE },
  { country: "it", title: WIDGET_TITLE_SICCODE },
  { country: "de", title: "GeneralLegalForm" },
  { country: "be", title: "GeneralLegalForm" },
  { country: "se", title: "GeneralLegalForm" },
  { country: "se", title: WIDGET_TITLE_SICCODE },
  { country: "no", title: "GeneralLegalForm" },
  { country: "ie", title: "LegalForm" },
];
const slicedTitles = ["States", WIDGET_TITLE_SICCODE, "SIC07"];
export const getFilteredBarChartData = (
  data: ChartInputItem[],
  country: string,
  title: string
) => {
  if (
    slicedDataTop15.some(
      (obj) =>
        (country ? country : "").toLowerCase() === obj.country &&
        title.includes(obj.title)
    )
  ) {
    data = data.slice(0, 15);
  } else if (slicedTitles.some((slicedTitle) => title.includes(slicedTitle))) {
    data = data.slice(0, 8);
  }

  return data;
};

const selectColor: { [key: string]: number } = {
  Active: 0,
  Closed: 1,
  Canceled: 2,
  Published: 3,
  LegallyCreated: 4,
  Stopped: 5,
};
