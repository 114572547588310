import { Epic } from "../rootEpic";
import {
  FETCH_MAPPING_TEMPLATES,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
} from "./types";
import { of, timer } from "rxjs";
import { concatMap, map, switchMap, filter, catchError } from "rxjs/operators";
import {
  fetchMappingTemplatesSuccess,
  fetchMappingTemplatesError,
  saveTemplateSuccess,
  saveTemplateError,
  clearPopupDialogAction,
} from "./actions";
import { isOfType, ActionType } from "typesafe-actions";
import config from "../../config";

export const fetchMappingsEpic: Epic<
  | ActionType<typeof fetchMappingTemplatesSuccess>
  | ActionType<typeof fetchMappingTemplatesError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_MAPPING_TEMPLATES)),
    switchMap(() =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/mappingTemplates`,
        })
        .pipe(
          map(({ response }) => fetchMappingTemplatesSuccess(response)),
          catchError(() => of(fetchMappingTemplatesError()))
        )
    )
  );

export const saveTemplateEpic: Epic<
  ActionType<typeof saveTemplateSuccess> | ActionType<typeof saveTemplateError>
> = (action$, _$, { api, adobeEvents }) =>
  action$.pipe(
    filter(isOfType(SAVE_TEMPLATE)),
    switchMap(({ payload }) => {
      const adobeBodyData = {
        detail: {
          linkName: "Save Template Error",
          action: "User enters the existing template name",
        },
      };
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/mappingTemplates`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: payload,
        })
        .pipe(
          concatMap(() => of(saveTemplateSuccess())),
          catchError(() => {
            adobeEvents.fireActionTriggerCustomEvent(null, adobeBodyData);
            return of(saveTemplateError());
          })
        );
    })
  );

export const clearPopupDialogEpic: Epic<ActionType<
  typeof clearPopupDialogAction
>> = (action$) =>
  action$.pipe(
    filter(isOfType(SAVE_TEMPLATE_SUCCESS)),
    switchMap(() => timer(2000).pipe(map(clearPopupDialogAction)))
  );
