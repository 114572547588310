import {
  FETCH_MAPPING_TEMPLATES,
  FETCH_MAPPING_TEMPLATES_SUCCESS,
  FETCH_MAPPING_TEMPLATES_ERROR,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_ERROR,
  CLEAR_POPUP_NOTIFICATION,
  RESET_TEMPLATE_SAVE_STATUS,
} from "./types";
import { action } from "typesafe-actions";
import { Mapping, MappingPayload } from "../mappings/types";

export const fetchMappingTemplates = () => action(FETCH_MAPPING_TEMPLATES);
export const fetchMappingTemplatesSuccess = (payload: Mapping[]) =>
  action(FETCH_MAPPING_TEMPLATES_SUCCESS, payload);
export const fetchMappingTemplatesError = () =>
  action(FETCH_MAPPING_TEMPLATES_ERROR);

export const saveTemplate = (mappingTemplate: MappingPayload) =>
  action(SAVE_TEMPLATE, mappingTemplate);
export const saveTemplateSuccess = () => action(SAVE_TEMPLATE_SUCCESS);
export const saveTemplateError = () => action(SAVE_TEMPLATE_ERROR);
export const saveTemplateSaveStatusReset = () =>
  action(RESET_TEMPLATE_SAVE_STATUS);

export const clearPopupDialogAction = () => action(CLEAR_POPUP_NOTIFICATION);
