import * as MappingsActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Normalized, Status } from "../types";

export type StoredMapping = { mapping: string; value: string };

export interface MappingPayload {
  name: string;
  hasHeader: boolean;
  mappings: StoredMapping[];
}

export interface Mapping extends MappingPayload {
  id: string;
}

export type MappingsState = Normalized<Mapping, string> & {
  selected?: string;
  templateSavingStatus: Status;
};

export const FETCH_MAPPING_TEMPLATES = "FETCH_MAPPING_TEMPLATES";
export const FETCH_MAPPING_TEMPLATES_SUCCESS =
  "FETCH_MAPPING_TEMPLATES_SUCCESS";
export const FETCH_MAPPING_TEMPLATES_ERROR = "FETCH_MAPPING_TEMPLATES_ERROR";

export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_ERROR = "SAVE_TEMPLATE_ERROR";
export const RESET_TEMPLATE_SAVE_STATUS = "RESET_TEMPLATE_SAVE_STATUS";

export const CLEAR_POPUP_NOTIFICATION = "CLEAR_POPUP_NOTIFICATION";

export type InnerMapping = { mapping: string; value: string };

export type MappingsActionTypes = ActionType<typeof MappingsActions>;
