import { getSelectedJob } from "store/jobs/selectors";
import { RootState } from "../rootReducer";

export const getUserState = (store: RootState) => store.user;

export const getIsSignedIn = (store: RootState) =>
  getUserState(store).isSignedIn;

export const getIsInternalUser = (store: RootState) =>
  getUserState(store).isInternal;

export const getIsUserAllowedForSearchPanel = (store: RootState) =>
  getUserState(store).isUserAllowedForSearchPanel;

export const getIsMonitorAvailable = (store: RootState) =>
  getUserState(store).isMonitoringServiceEnabled &&
  (getSelectedJob(store)?.jobSummary?.matched || 0) > 0;

export const getIsUserFirstLogin = (store: RootState) =>
  getUserState(store).isUserFirstLogin;

export const getCustomerId = (store: RootState) =>
  getUserState(store).customer?.id;

export const getUserStoreStatus = (store: RootState) =>
  getUserState(store).status;

export const getUserId = (store: RootState) => getUserState(store).userId;

export const getUserDetails = (store: RootState) => {
  const userState = getUserState(store);
  return {
    companyName: userState.customer?.companyName,
    firstName: userState.contact?.firstName,
    surname: userState.contact?.surname,
    customerId: userState.customer?.id,
    userId: userState.userId,
    phoneNumber: userState.customer?.phoneNumber,
    emailAddress: userState.emailAddress,
  };
};

export const getFavouriteCountries = (store: RootState) =>
  getUserState(store).favouriteCountries;

export const getRemainingCredits = (store: RootState) =>
  getUserState(store).remainingCredits;

export const getRemainingCreditsByCreditType = (store: RootState) =>
  getUserState(store).remainingCreditsByCreditType;

export const getUserCountry = (store: RootState) =>
  getUserState(store).countryCode;

export const getEntityTel = (store: RootState) => getUserState(store).entityTel;

export const getShouldUseUpp = (store: RootState): boolean | undefined =>
  getUserState(store).shouldUseUpp;

export const getMonitoringPortfolios = (store: RootState) =>
  getUserState(store).monitoringPortfolios;
