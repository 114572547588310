import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchJobDetailsPdfSuccess,
  fetchAggregatesSuccess,
  fetchJobSummary,
  fetchJobSummarySuccess,
  fetchAggregatesByCountry,
  fetchJobDetails,
  updateCreditType,
} from "store/jobs/actions";
import {
  getSelectedJob,
  getAggregates,
  getAvailableCreditTypes,
  getSelectedCreditTypeWidgets,
} from "store/jobs/selectors";
import heartGraphic from "../../assets/health-check-graphic.png";
import csLogo from "../../assets/cslogo.svg";
import "./HealthCheck.css";
import AggregateWidget from "pages/Jobs/Aggregates/AggregateWidget";
import { formatAggregateData } from "pages/Jobs/Aggregates/widgets/formatAggregateData";
import { Widget, WidgetData } from "pages/Jobs/Aggregates/types";
import theme from "../../theme";
import {
  formatNumberThousandSeparator,
  localiseThousandSeperator,
  noop,
} from "appUtils";
import {
  AggregateObject,
  FetchAggregatesSuccessPayloadItem,
  Job,
  JobSummaryPayload,
} from "store/jobs/types";
import { Status } from "store/types";
import { Base64 } from "js-base64";
import i18n from "config/i18n-client";

const HealthCheck: React.FC = () => {
  const loadDynamicFile =
    !localStorage.job || !localStorage.jobSummary || !localStorage.aggregates;
  if (loadDynamicFile) {
    return <DynamicHealthCheck />;
  }

  return <StaticHealthCheck />;
};

export const StaticHealthCheck: React.FC = () => {
  const dispatch = useDispatch();

  const aggregates = useSelector(getAggregates);
  const job = useSelector(getSelectedJob);
  const summary = job?.summary;
  useEffect(() => {
    dispatch(
      fetchJobDetailsPdfSuccess(
        JSON.parse(Base64.decode(localStorage.job)) as Job
      )
    );

    dispatch(
      fetchJobSummarySuccess(
        JSON.parse(localStorage.jobSummary) as JobSummaryPayload
      )
    );
    dispatch(
      fetchAggregatesSuccess(
        JSON.parse(
          localStorage.aggregates
        ) as FetchAggregatesSuccessPayloadItem[]
      )
    );
  }, [dispatch]);

  return (
    <HealthCheckContents
      jobSummary={summary}
      aggregates={aggregates}
      dataTestId={"static"}
    />
  );
};

export const DynamicHealthCheck: React.FC = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const tabCountry = queryParams.get("countryCode") as string;
  const dispatch = useDispatch();
  const aggregates = useSelector(getAggregates);
  const job = useSelector(getSelectedJob);
  const summary = job?.summary;
  const jobId = queryParams.get("id") as string;
  useEffect(() => {
    if (jobId && !job) {
      dispatch(fetchJobDetails(jobId));
    } else {
      jobId &&
        tabCountry &&
        dispatch(fetchAggregatesByCountry(jobId, tabCountry));

      // Wait for selected job state to be populated before retrieving summary
      !summary && dispatch(fetchJobSummary(jobId));
    }
  }, [dispatch, jobId, tabCountry, job, summary]);

  useEffect(() => {
    //This condition determines if the page is ready for pdf print/capture
    if (
      aggregates?.status === Status.loaded &&
      summary?.status === Status.loaded
    ) {
      // timeout below is a temporary fix to prevent PDF rendering without a map, since it's not simple to check if the map has fully rendered
      setTimeout(() => window.print(), 800);
    }
  }, [aggregates, summary]);

  return (
    <HealthCheckContents
      jobSummary={summary}
      aggregates={aggregates}
      dataTestId={"dynamic"}
    />
  );
};

const HealthCheckContents: React.FC<{
  jobSummary?: { data: JobSummaryPayload; status: Status };
  aggregates?: {
    data: AggregateObject[];
    status: Status;
  };
  dataTestId?: string;
}> = ({ jobSummary, aggregates, dataTestId }) => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(useLocation().search);
  const tabCountry = queryParams.get("countryCode") as string;
  const locale = queryParams.get("locale") as string;
  const tel = queryParams.get("tel") as string;
  const availableCreditTypes = useSelector(getAvailableCreditTypes);
  const creditTypeWidgets = useSelector(
    getSelectedCreditTypeWidgets
  ) as string[];

  useEffect(() => {
    if (locale !== "" && locale !== null && locale !== undefined)
      i18n.changeLanguage(locale);
  }, [locale]);

  const { t } = useTranslation(["aggregates", "pdfs"]);
  const calculateTrilliumPercentage = (value: number) =>
    jobSummary && ((value / jobSummary.data.totalRows) * 100).toFixed(2);

  const generateTrilliumWidget = () => {
    return (
      jobSummary &&
      ({
        title: "TrilliumMatches.title",
        data: [
          {
            name: t("TrilliumMatches.matched"),
            value: jobSummary.data.matched,
            percentage: `${calculateTrilliumPercentage(
              jobSummary.data.matched
            )}%`,
            color: theme.palette.success.main,
          },
          {
            name: t("TrilliumMatches.unmatched"),
            value: jobSummary.data.unmatched,
            percentage: `${calculateTrilliumPercentage(
              jobSummary.data.unmatched
            )}%`,
            color: theme.palette.primary.main,
          },
          {
            name: t("TrilliumMatches.duplicates"),
            value: jobSummary.data.duplicates,
            percentage: `${calculateTrilliumPercentage(
              jobSummary.data.duplicates
            )}%`,
            color: theme.palette.grey[600],
          },
        ] as WidgetData[],
        JSONTag: "",

        widgetType: "pie",
      } as Widget)
    );
  };

  const formatAggregateDataForPrint = (countryTab: string) => {
    if (aggregates?.data) {
      const formattedAggregates = formatAggregateData(
        t,
        aggregates.data,
        countryTab
      );

      formattedAggregates.forEach((item) => {
        item.widgets = item.widgets.filter(
          (widget) => widget.widgetType !== "unset"
        );
        item.widgets.map(
          (widget) =>
            (widget.widgetType =
              widget.widgetType === "donut" ? "pie" : widget.widgetType)
        );
      });

      const trilliumWidget = generateTrilliumWidget();

      const output = [trilliumWidget].concat(
        formattedAggregates
          .map((x) => x.widgets)
          .reduce((acc, curr) => acc.concat(curr))
      );

      return output;
    }

    return [];
  };

  const groupsData = (countryTab: string) => {
    return countryTab ? formatAggregateDataForPrint(countryTab) : [];
  };

  const generateCoverPage = () => (
    <div className="coverPage">
      <img className="logo" src={csLogo} alt="Logo" />
      <div className="heading graphicSection">
        <img src={heartGraphic} alt="" />
        <span>{t("pdfs:title")}</span>
      </div>
      <span>{`${t("pdfs:contact")} ${tel}.`}</span>
    </div>
  );

  const generateHeader = () => {
    return (
      <div className="pageHeader">
        <div className="heading left">
          <span>{t("pdfs:heading")}</span>
        </div>
        <div className="right">
          <img src={csLogo} alt="Creditsafe logo" width="200px" />
          <span>
            {t("pdfs:headingPt1")}
            {localiseThousandSeperator(
              formatNumberThousandSeparator(jobSummary?.data.totalRows, locale),
              locale
            )}
          </span>
          <span>
            {t("pdfs:headingPt2")}
            {localiseThousandSeperator(
              formatNumberThousandSeparator(jobSummary?.data.matched, locale),
              locale
            )}
          </span>
        </div>
      </div>
    );
  };

  const generateWidgets = () => {
    return groupsData(tabCountry === "uk" ? "gb" : tabCountry).map(
      (widget, index) =>
        widget &&
        widget.data.length > 0 && (
          <div
            className={`${widget.widgetType} widget`}
            key={`widget_${index}`}
          >
            {index % 2 === 0 ? generateHeader() : ""}
            <AggregateWidget
              key={index}
              title={widget.title}
              data={widget.data}
              widgetType={widget.widgetType}
              printView={true}
              selectedWidgets={[""]}
              setSelectedWidgets={noop}
              JSONTag={widget.JSONTag}
              isRequired={false}
              hasPdfSpecificTitle={widget.hasPdfSpecificTitle}
              creditTypeWidgets={creditTypeWidgets}
              availableCreditTypes={availableCreditTypes}
              isAllowSelecting={false}
              onCreditTypeChange={(newValue) =>
                dispatch(updateCreditType(newValue))
              }
            />
          </div>
        )
    );
  };

  //The pdf file name is prefilled based on the title
  document.title = `Creditsafe - ${t("pdfs:report")}`;

  return (
    <div data-testid={dataTestId}>
      {generateCoverPage()}
      {generateWidgets()}
    </div>
  );
};

export default HealthCheck;
