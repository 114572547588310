import * as UserActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Status, StoreStatus } from "../types";
import { CREDIT_TYPE_VALUE_TYPE } from "pages/Jobs/CreditType";

export const USER_SIGNED_IN = "USER_SIGNED_IN";

export const data: MonitoringPortfolio[] = [
  {
    portfolioId: 109220,
    name: "Default",
    isDefault: true,
    emails: [
      {
        firstNAme: "",
        lastName: "",
        emailAddress: "anjali.nagururi@creditsafe.com",
      },
    ],
    isSharedPortfolio: 0,
  },
  {
    portfolioId: 109221,
    name: "first",
    isDefault: false,
    emails: [
      {
        firstNAme: "",
        lastName: "",
        emailAddress: "anjali.nagururi@creditsafe.com",
      },
    ],
    isSharedPortfolio: 0,
  },
  {
    portfolioId: 109222,
    name: "second",
    isDefault: false,
    emails: [
      {
        firstNAme: "",
        lastName: "",
        emailAddress: "anjali.nagururi@creditsafe.com",
      },
    ],
    isSharedPortfolio: 0,
  },
  {
    portfolioId: 109223,
    name: "Third",
    isDefault: false,
    emails: [
      {
        firstNAme: "",
        lastName: "",
        emailAddress: "anjali.nagururi@creditsafe.com",
      },
    ],
    isSharedPortfolio: 0,
  },
  {
    portfolioId: 109224,
    name: "Fourth",
    isDefault: false,
    emails: [
      {
        firstNAme: "",
        lastName: "",
        emailAddress: "anjali.nagururi@creditsafe.com",
      },
    ],
    isSharedPortfolio: 0,
  },
];

export interface UserState extends StoreStatus {
  userId: string | null;
  isSignedIn: boolean;
  username?: string | null;
  countryCode: string;
  languageCode: string;
  contact?: {
    title: string;
    firstName: string;
    surname: string;
  };
  emailAddress?: string;
  customer?: {
    id: number;
    companyName: string;
    phoneNumber: string;
    emailAddress: string;
    contact: {
      title: string;
      firstName: string;
      surname: string;
    };
  };
  lastLoginDate?: string;
  owningEntity?: string;
  shouldUseUpp: boolean;
  registrationNumber?: string;
  favouriteCountries: string[];
  remainingCredits: CreditsByCountry;
  remainingCreditsByCreditType?: CreditsByCreditType[];
  isInternal: boolean;
  entityTel: string;
  isUserAllowedForSearchPanel: boolean;
  isUserFirstLogin: boolean;
  isMonitoringServiceEnabled: boolean;
  monitoringPortfolios?: { data: MonitoringPortfolio[]; status: Status };
}

export interface CreditsByCountry {
  countryCode: string;
  credits: number;
  expriryDate?: string;
  isUnlimited: boolean;
  creditType: CREDIT_TYPE_VALUE_TYPE;
  used: number;
  paid: number;
}

export interface CreditsByCreditType {
  creditType: string;
  remainingCreditsForCountryByCreditType: CreditsByCountry[];
}

export const FETCH_MONITORING_PORTFOLIOS = "FETCH_MONITORING_PORTFOLIOS";

export const FETCH_MONITORING_PORTFOLIOS_SUCCESS =
  "FETCH_MONITORING_PORTFOLIOS_SUCCESS";

export const FETCH_MONITORING_PORTFOLIOS_ERROR =
  "FETCH_MONITORING_PORTFOLIOS_ERROR";

export interface MonitoringPortfolio {
  portfolioId: number;
  name: string;
  isDefault: boolean;
  emails: { firstNAme: string; lastName: string; emailAddress: string }[];
  isSharedPortfolio: number;
}

export type UserSignedInPayload = string;

export interface UserSignedInAction {
  type: typeof USER_SIGNED_IN;
  payload: UserSignedInPayload;
}

export type UserActionTypes = ActionType<typeof UserActions>;

export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_ERROR = "FETCH_USER_DETAILS_ERROR";

export const FETCH_ENTITY_TEL_SUCCESS = "FETCH_ENTITY_TEL_SUCCESS";

export type FetchUserDetailsSuccessPayload = {
  userId: string | null;
  username?: string | null;
  countryCode: string;
  languageCode: string;
  contact?: { title: string; firstName: string; surname: string };
  emailAddress?: string;
  customer?: {
    id: number;
    companyName: string;
    phoneNumber: string;
    emailAddress: string;
    contact: { title: string; firstName: string; surname: string };
  };
  lastLoginDate?: string;
  owningEntity?: string;
  shouldUseUpp: boolean;
  registrationNumber?: string;
  favouriteCountries: string[];
};

export const FETCH_REMAINING_CREDITS_SUCCESS =
  "FETCH_REMAINING_CREDITS_SUCCESS";
export const FETCH_REMAINING_CREDITS_ERROR = "FETCH_REMAINING_CREDITS_ERROR";

export interface ServiceLine {
  paid: number;
  used: number;
  customerServiceId: number;
  parentCustomerServiceId: number;
  isUnlimited: boolean;
  parentServiceId: number;
  description: string;
  country: string;
  startDate: string;
  expireDate: string;
  name: string;
  tnCAccepted: boolean;
  serviceCode: string;
  pseudoId: string;
  sortId: number;
  serviceId: number;
}

export const CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL =
  "CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL";

export const IS_USER_ALLOWED_FOR_SEARCHPANEL_SUCCESS =
  "IS_USER_ALLOWED_FOR_SEARCHPANEL_SUCCESS";

export const IS_USER_ALLOWED_FOR_SEARCHPANEL_ERROR =
  "IS_USER_ALLOWED_FOR_SEARCHPANEL_ERROR";
