import * as types from "./types";
import { action } from "typesafe-actions";
import { Status } from "../types";
import { StoredMapping, Mapping, InnerMapping } from "../mappings/types";
import { MatchingStatus } from "pages/Jobs/JobRecords/constants";
import { Enrichment } from "store/enrichments/types";
import { ServiceLine } from "store/user/types";
import { LoadingEnum } from "pages/Jobs/Status";

export const fetchJobs = (params: types.JobParams) =>
  action(types.FETCH_JOBS, params);
export const fetchJobsSuccess = (jobs: types.Job[], count: number) =>
  action(types.FETCH_JOBS_SUCCESS, { jobs, count });
export const fetchJobsError = () => action(types.FETCH_JOBS_ERROR);

export const fetchJobDetails = (payload: string) =>
  action(types.FETCH_JOB_DETAILS, payload);
export const fetchJobDetailsSuccess = (payload: types.Job) =>
  action(types.FETCH_JOB_DETAILS_SUCCESS, payload);
export const fetchJobDetailsError = () => action(types.FETCH_JOB_DETAILS_ERROR);

export const fetchJobContact = (payload: string) =>
  action(types.FETCH_JOB_CONTACT, payload);
export const fetchJobContactSuccess = (payload: types.Contact) =>
  action(types.FETCH_JOB_CONTACT_SUCCESS, payload);
export const fetchJobContactError = () => action(types.FETCH_JOB_CONTACT_ERROR);

export const fetchFileDetails = (payload: string) =>
  action(types.FETCH_FILE_DETAILS, payload);
export const fetchFileDetailsSuccess = (payload: types.JobFile[]) =>
  action(types.FETCH_FILE_DETAILS_SUCCESS, payload);
export const fetchFileDetailsError = () =>
  action(types.FETCH_FILE_DETAILS_ERROR);

export const fetchJobSample = (payload: types.FetchJobSamplePayload) =>
  action(types.FETCH_JOB_SAMPLE, payload);
export const fetchJobSampleSuccess = (payload: {
  id: string;
  sample: string[][];
}) => action(types.FETCH_JOB_SAMPLE_SUCCESS, payload);
export const fetchJobSampleError = () => action(types.FETCH_JOB_SAMPLE_ERROR);

export const fetchJobMappings = (payload: { id: string }) =>
  action(types.FETCH_JOB_MAPPINGS, payload);

export const fetchJobMappingSuccess = (payload: InnerMapping[]) =>
  action(types.FETCH_JOB_MAPPING_SUCCESS, payload);
export const fetchJobMappingError = () => action(types.FETCH_JOB_MAPPING_ERROR);

export const createJob = (payload: types.CreateJobPayload) =>
  action(types.CREATE_JOB, payload);
export const createJobSuccess = (payload: types.Job) =>
  action(types.CREATE_JOB_SUCCESS, payload);
export const createJobError = (errorMsg?: string) =>
  action(types.CREATE_JOB_ERROR, errorMsg);
export const unsetJobError = () => action(types.UNSET_JOB_ERROR);

export const fetchAggregates = (payload: string) =>
  action(types.FETCH_AGGREGATES, payload);
export const fetchAggregatesSuccess = (
  payload: types.FetchAggregatesSuccessPayloadItem[]
) => action(types.FETCH_AGGREGATES_SUCCESS, payload);
export const fetchAggregatesError = () => action(types.FETCH_AGGREGATES_ERROR);

export const fetchJobSummary = (payload: string) =>
  action(types.FETCH_JOB_SUMMARY, payload);
export const fetchJobSummarySuccess = (payload: types.JobSummaryPayload) =>
  action(types.FETCH_JOB_SUMMARY_SUCCESS, payload);
export const fetchJobSummaryError = () => action(types.FETCH_JOB_SUMMARY_ERROR);

export const submitJob = (id: string) => action(types.SUBMIT_JOB, id);
export const submitJobSuccess = (id: string) =>
  action(types.SUBMIT_JOB_SUCCESS, id);

export const submitJobError = (error: string[]) =>
  action(types.SUBMIT_JOB_ERROR, error);

export const setMappings = (mappings: StoredMapping[]) =>
  action(types.SET_MAPPINGS, mappings);

export const setDuplicateMappings = (mappings: string[]) =>
  action(types.SET_DUPLICATE_MAPPINGS, mappings);

export const loadMappingTemplate = (id: string) =>
  action(types.LOAD_MAPPING_TEMPLATE, id);
export const loadMappingTemplateSuccess = (payload: Mapping) =>
  action(types.LOAD_MAPPING_TEMPLATE_SUCCESS, payload);
export const loadMappingTemplateError = () =>
  action(types.LOAD_MAPPING_TEMPLATE_ERROR);

export const loadMappingEnrichmentTemplate = (id: string) =>
  action(types.LOAD_MAPPING_ENRICHMENT_TEMPLATE, id);
export const loadMappingEnrichmentTemplateSuccess = (payload: Enrichment) =>
  action(types.LOAD_MAPPING_ENRICHMENT_TEMPLATE_SUCCESS, payload);
export const loadMappingEnrichmentTemplateError = () =>
  action(types.LOAD_MAPPING_ENRICHMENT_TEMPLATE_ERROR);

export const saveJobMappings = (payload: types.SaveJobMappingsPayload) =>
  action(types.SAVE_JOB_MAPPINGS, payload);
export const saveJobMappingsSuccess = (id: string, isDraft: boolean) =>
  action(types.SAVE_JOB_MAPPINGS_SUCCESS, { id, isDraft });
export const saveJobMappingsError = () => action(types.SAVE_JOB_MAPPINGS_ERROR);
export const dismissSaveMessage = () => action(types.DISMISS_SAVE_MESSAGE);

export const confirmSelection = (payload: string) =>
  action(types.CONFIRM_SELECTION, payload);
export const confirmSelectionSuccess = (id: string) =>
  action(types.CONFIRM_SELECTION_SUCCESS, id);
export const confirmSelectionError = () =>
  action(types.CONFIRM_SELECTION_ERROR);

export const fetchEnrichedJobFile = (id: string, type: types.FileType) =>
  action(types.FETCH_ENRICHED_FILE, { id, type });
export const fetchEnrichedJobFileSuccess = (
  payload: types.FetchFileSuccessPayload
) => action(types.FETCH_ENRICHED_FILE_SUCCESS, { payload });
export const fetchEnrichedJobFileError = () =>
  action(types.FETCH_ENRICHED_FILE_ERROR);

export const setFileDownloadStatus = (status: Status) =>
  action(types.SET_FILE_DOWNLOAD_STATUS, status);

export const fetchSelectedWidgets = (id: string) =>
  action(types.FETCH_SELECTED_WIDGETS, id);

export const fetchSelectedWidgetsSuccess = (
  payload: { enrichment: string }[]
) => action(types.FETCH_SELECTED_WIDGETS_SUCCESS, { payload });

export const fetchSelectedWidgetsError = () =>
  action(types.FETCH_SELECTED_WIDGETS_ERROR);

export const fetchAggregatesByCountry = (id: string, countryCode: string) =>
  action(types.FETCH_AGGREGATES_BY_COUNTRY, { id, countryCode });

export const setSelectedTabCountry = (countryCode: string) =>
  action(types.SET_SELECTED_TAB_COUNTRY, countryCode);

export const fetchJobEnrichmentSpendEstimate = (payload: {
  id: string;
  companyAppendOption: string | null;
}) => action(types.FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE, payload);
export const fetchJobEnrichmentSpendEstimateSuccess = (
  payload: types.JobEnrichmentSpendEstimate
) => action(types.FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_SUCCESS, payload);
export const fetchJobEnrichmentSpendEstimateError = () =>
  action(types.FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_ERROR);

export const saveEnrichments = (payload: types.ConfirmSelectionPayload) =>
  action(types.SAVE_ENRICHMENTS, payload);
export const saveEnrichmentsSuccess = () =>
  action(types.SAVE_ENRICHMENTS_SUCCESS);
export const saveEnrichmentsError = () => action(types.SAVE_ENRICHMENTS_ERROR);

export const fetchHealthCheckPdf = (
  payload: types.FetchHealthCheckPdfPayload
) => action(types.FETCH_HEALTH_CHECK_PDF, payload);
export const fetchHealthCheckPdfSuccess = (
  payload: types.FetchFileSuccessPayload
) => action(types.FETCH_HEALTH_CHECK_PDF_SUCCESS, payload);
export const fetchHealthCheckPdfError = () =>
  action(types.FETCH_HEALTH_CHECK_PDF_ERROR);

export const fetchJobDetailsPdfSuccess = (payload: types.Job) =>
  action(types.FETCH_JOB_DETAILS_PDF_SUCCESS, payload);

export const updateJobEnrichmentSettings = (payload: {
  id: string;
  body: types.UpdateJobEnrichmentsSettingsPayload;
}) => action(types.UPADATE_JOB_ENRICHMENT_SETTINGS, payload);
export const updateJobEnrichmentSettingsSuccess = () =>
  action(types.UPADATE_JOB_ENRICHMENT_SETTINGS_SUCCESS);
export const updateJobEnrichmentSettingsError = () =>
  action(types.UPADATE_JOB_ENRICHMENT_SETTINGS_ERROR);

export const archiveJob = (payload: { id: string }) =>
  action(types.ARCHIVE_JOB, payload);
export const archiveJobSuccess = () => action(types.ARCHIVE_JOB_SUCCESS);
export const archiveJobError = () => action(types.ARCHIVE_JOB_ERROR);
export const archiveStatusReset = () => action(types.RESET_ARCHIVE_JOB_STATUS);

export const fetchJobRecords = (payload: types.JobRecordPayload) =>
  action(types.FETCH_JOB_RECORDS, payload);
export const fetchJobRecordSuccess = (
  JobRecords: types.JobRecord[],
  count: number
) => action(types.FETCH_JOB_RECORDS_SUCCESS, { JobRecords, count });
export const fetchJobRecordError = () => action(types.FETCH_JOB_RECORDS_ERROR);

export const setJobHeaderClass = (payload: { headerClass: boolean }) =>
  action(types.SET_JOB_HEADER_CLASS, payload);

export const fetchCompanyRecords = (payload: types.CompanyRecordPayload) =>
  action(types.FETCH_COMPANY_RECORDS, payload);
export const fetchCompanyRecordSuccess = (
  CompanyRecords: types.CompanyRecord[],
  count: number
) => action(types.FETCH_COMPANY_RECORDS_SUCCESS, { CompanyRecords, count });
export const fetchCompanyRecordError = () =>
  action(types.FETCH_COMPANY_RECORDS_ERROR);

export const manualMatchRecord = (payload: {
  id: string;
  body: types.ManualMatchRecordPayload;
  referenceNumber: number;
  status: MatchingStatus;
  page: number;
  limit: number;
}) => action(types.MANUAL_MATCH_RECORD, payload);
export const manualMatchRecordSuccess = (payload: types.JobRecordPayload) =>
  action(types.MANUAL_MATCH_RECORD_SUCCESS, payload);
export const manualMatchRecordError = () =>
  action(types.MANUAL_MATCH_RECORD_ERROR);

export const manualMatchOrUnmatchReset = () =>
  action(types.RESET_MANUAL_MATCH_OR_UNMATCH_STATUS);

export const submitJobEnrichReason = (payload: {
  id: string;
  reasonId: number;
}) => action(types.SUBMIT_JOB_ENRICH_REASON, payload);
export const submitJobEnrichReasonSuccess = () =>
  action(types.SUBMIT_JOB_ENRICH_REASON_SUCCESS);
export const submitJobEnrichReasonError = () =>
  action(types.SUBMIT_JOB_ENRICH_REASON_ERROR);

export const unMatchRecord = (payload: {
  id: string;
  referenceNumber: number;
  status: MatchingStatus;
  page: number;
  limit: number;
}) => action(types.UNMATCH_RECORD, payload);
export const unMatchRecordSuccess = (payload: types.JobRecordPayload) =>
  action(types.UNMATCH_RECORD_SUCCESS, payload);
export const unMatchRecordError = () => action(types.UNMATCH_RECORD_ERROR);

export const storeHasHeader = (hasHeader: boolean) =>
  action(types.SET_HASHEADER, hasHeader);

export const createJobMonitor = (payload: types.CreateJobMonitorPayload) =>
  action(types.CREATE_JOB_MONITOR, payload);

export const createJobMonitorSuccess = () =>
  action(types.CREATE_JOB_MONITOR_SUCCESS);

export const createJobMonitorError = () =>
  action(types.CREATE_JOB_MONITOR_ERROR);

export const updateJobContactDetails = (
  payload: types.UpdateJobDetailsPayload
) => action(types.UPDATE_JOB_CONTACT, payload);

export const updateJobContactDetailsSuccess = (
  payload: types.UpdateJobDetailsResponse
) => action(types.UPDATE_JOB_CONTACT_SUCCESS, payload);

export const updateJobContactDetailsError = (errorMsg?: string) =>
  action(types.UPDATE_JOB_CONTACT_ERROR, errorMsg);

export const updateJobStatusReset = () =>
  action(types.RESET_JOB_CONTACT_STATUS);

export const resetMonitoringSavingStatus = () =>
  action(types.RESET_MONITORING_SAVING_STATUS);

export const fetchEnrichmentSettings = (jodId: string) =>
  action(types.FETCH_ENRICHMENT_SETTINGS, jodId);

export const fetchEnrichmentSettingsSuccess = (
  enrichmentSettings: types.EnrichmentSettings
) => action(types.FETCH_ENRICHMENT_SETTINGS_SUCCESS, enrichmentSettings);

export const updateCreditType = (creditType: string | undefined) =>
  action(types.UPDATE_CREDIT_TYPE, creditType);

export const fetchEnrichmentSettingsError = () =>
  action(types.FETCH_ENRICHMENT_SETTINGS_ERROR);

export const setCreditTypeWidgets = (widgets: string[]) =>
  action(types.SET_CREDIT_TYPE_WIDGETS, widgets);

export const fetchAvailableCreditTypes = (payload: string) =>
  action(types.FETCH_AVAILABLE_CREDIT_TYPES, payload);

export const fetchAvailableCreditTypesSuccess = (payload: ServiceLine[]) =>
  action(types.FETCH_AVAILABLE_CREDIT_TYPES_SUCCESS, payload);

export const fetchAvailableCreditTypesError = () =>
  action(types.FETCH_AVAILABLE_CREDIT_TYPES_ERROR);

export const updateOneStepStatus = (payload: {
  status: LoadingEnum;
  errorMsg: string;
}) => action(types.UPDATE_ONESTEP_STATUS, payload);

export const setAutoEnrichJobId = (jobId: string) =>
  action(types.SET_AUTO_ENRICH_JOBID, jobId);

export const assignMasterTemplate = (payload: {
  jobId: string;
  masterTemplateId: string;
}) => action(types.ASSIGN_MASTER_TEMPLATE, payload);
export const validateJobMappings = (payload: { jobId: string }) =>
  action(types.VALIDATE_JOB_MAPPINGS, payload);

export const uploadJob = (payload: {
  jobId: string;
  body: types.CreateJobPayload;
}) => action(types.UPLOAD_JOB, payload);
export const uploadJobSuccess = () => action(types.UPLOAD_JOB_SUCCESS);
export const uploadJobError = (errorMsg?: string) =>
  action(types.UPLOAD_JOB_ERROR, errorMsg);

export const fetchJobAutoEnrichmentData = (payload: string) =>
  action(types.FETCH_JOB_AUTO_ENRICHMENT, payload);
export const fetchJobAutoEnrichmentDataSuccess = (
  payload: types.JobAutoEnrichment
) => action(types.FETCH_JOB_AUTO_ENRICHMENT_SUCCESS, payload);
export const fetchJobAutoEnrichmentDataError = () =>
  action(types.FETCH_JOB_AUTO_ENRICHMENT_ERROR);
export const setSelectedAlgorithmBanding = (payload: string[]) =>
  action(types.SET_SELECTED_ALGORITHM_BANDING, payload);

export const fetchManualMatchStatus = (payload: { jobid: string }) =>
  action(types.FETCH_MANUALMATCH_STATUS, payload);
export const fetchManualMatchStatusSuccess = (payload: {
  reAggregateJobStatusInprogress: boolean;
  isManualMatchRecordsExists: boolean;
}) => action(types.FETCH_MANUALMATCH_STATUS_SUCCESS, payload);
export const fetchManualMatchStatusError = () =>
  action(types.FETCH_MANUALMATCH_STATUS_ERROR);

export const reAggregateJob = (payload: string) =>
  action(types.RE_AGGREGATE_JOB, payload);
export const reAggregateJobSuccess = (payload: { jobid: string }) =>
  action(types.RE_AGGREGATE_JOB_SUCCESS, payload);
export const reAggregateJobError = () => action(types.RE_AGGREGATE_JOB_ERROR);

export const resetAutoEnrichmentJobStatus = () =>
  action(types.RESET_AUTO_ENRICHMENT_JOB_STATUS);

export const resetReaggregationStatus = () =>
  action(types.RESET_RE_AGGREGATE_STATUS);
