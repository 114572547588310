import {
  CustomerState,
  CustomerActionTypes,
  SEARCH_FOR_A_CUSTOMER,
  SEARCH_FOR_A_CUSTOMER_SUCCESS,
  SEARCH_FOR_A_CUSTOMER_ERROR,
  SELECT_USER,
  Customer,
  FETCH_SELECTED_USER_DETAILS,
  FETCH_SELECTED_USER_DETAILS_SUCCESS,
  FETCH_SELECTED_USER_DETAILS_ERROR,
  RESET_SEARCH_FOR_A_CUSTOMER,
  CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED,
  IS_EXTERNAL_CUSTOMER_ALLOWED_SUCCESS,
  IS_EXTERNAL_CUSTOMER_ALLOWED_ERRROR,
} from "./types";
import { Status } from "../types";

export const initialState: CustomerState = {
  allIds: [],
  byId: {},
  totalLength: 0,
  status: Status.unset,
  isExternalCustomerAllowed: true,
};

export default function (
  state = initialState,
  action: CustomerActionTypes
): CustomerState {
  switch (action.type) {
    case SELECT_USER: {
      return {
        ...state,
        selectedUser: { data: action.payload, status: Status.set },
      };
    }
    case RESET_SEARCH_FOR_A_CUSTOMER: {
      return {
        ...state,
        allIds: [],
        byId: {},
        totalLength: 0,
        status: Status.unset,
        selectedUser: undefined,
      };
    }
    case SEARCH_FOR_A_CUSTOMER: {
      return {
        ...state,
        allIds: [],
        byId: {},
        totalLength: 0,
        status: Status.loading,
      };
    }
    case SEARCH_FOR_A_CUSTOMER_SUCCESS: {
      return {
        ...state,
        allIds: action.payload.customers.map((x) => x.customerId),
        byId: normalize(action.payload.customers),
        totalLength: action.payload.count,
        status: Status.loaded,
      };
    }
    case SEARCH_FOR_A_CUSTOMER_ERROR: {
      return {
        ...state,
        status: Status.errorLoading,
      };
    }
    case FETCH_SELECTED_USER_DETAILS: {
      return {
        ...state,
        selectedUser: {
          data: state.selectedUser?.data,
          status: Status.loading,
        },
      };
    }
    case FETCH_SELECTED_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        selectedUser: { data: action.payload, status: Status.loaded },
      };
    }
    case FETCH_SELECTED_USER_DETAILS_ERROR: {
      return {
        ...state,
        selectedUser: { status: Status.errorLoading },
      };
    }
    case CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED: {
      return {
        ...state,
        isExternalCustomerAllowed: state.isExternalCustomerAllowed,
      };
    }
    case IS_EXTERNAL_CUSTOMER_ALLOWED_SUCCESS: {
      return {
        ...state,
        isExternalCustomerAllowed: action.payload,
      };
    }
    case IS_EXTERNAL_CUSTOMER_ALLOWED_ERRROR: {
      return {
        ...state,
        isExternalCustomerAllowed: false,
      };
    }
    default:
      return state;
  }
}

const normalize = (customers: Customer[]): { [customerId: number]: Customer } =>
  customers
    .map((x) => ({ ...x, users: x.users.filter((y) => y.isActive) }))
    .filter((x) => x.users.length > 0)
    .reduce(
      (acc, x) => ({
        ...acc,
        [x.customerId]: {
          ...x,
        },
      }),
      {}
    );
