import * as types from "./types";
import { Status } from "../types";
import { getServiceByContry } from "appUtils";
import config from "config";
import { LoadingEnum } from "pages/Jobs/Status";

export const initialState: types.JobsState = {
  allIds: [],
  byId: {},
  aggregates: {
    data: [],
    status: Status.unset,
  },
  archieve: {
    status: Status.unset,
  },
  status: Status.unset,
  selected: {
    status: Status.unset,
    enrichments: [],
  },
  jobRecords: {
    data: [],
    status: Status.unset,
    totalCount: 0,
  },
  companyRecords: {
    data: [],
    status: Status.unset,
    totalCount: 0,
  },
  headerClass: false,
  hasHeader: false,
  jobUpdateStatus: Status.unset,
  duplicateMappings: [],
  manualMatchOrUnmatch: {
    status: Status.unset,
  },
  autoEnrichJob: {
    errorMsg: "",
    status: LoadingEnum.unset,
    jobId: "",
  },
};

// eslint-disable-next-line complexity
export default function (
  state = initialState,
  action: types.JobsActionTypes
): types.JobsState {
  switch (action.type) {
    case types.FETCH_JOBS: {
      return {
        ...state,
        allIds: [],
        byId: {},
        status: Status.loading,
        aggregates: {
          ...initialState.aggregates,
        },
        selected: {
          ...initialState.selected,
        },
      };
    }
    case types.CONFIRM_SELECTION: {
      return {
        ...state,
        selected: { ...state.selected, status: Status.enriching },
        autoEnrichmentData: undefined,
      };
    }
    case types.FETCH_JOB_DETAILS: {
      return {
        ...state,
        selected: { ...state.selected, status: Status.loading },
        headerClass: false,
      };
    }
    case types.FETCH_JOB_CONTACT: {
      return {
        ...state,
        contact: { data: undefined, status: Status.loading },
      };
    }
    case types.FETCH_JOB_CONTACT_SUCCESS: {
      return {
        ...state,
        contact: { data: action.payload, status: Status.loaded },
      };
    }
    case types.FETCH_JOB_CONTACT_ERROR: {
      return {
        ...state,
        contact: { data: undefined, status: Status.errorLoading },
      };
    }
    case types.FETCH_ENRICHMENT_SETTINGS: {
      return {
        ...state,
        enrichmentSettings: { data: undefined, status: Status.loading },
      };
    }
    case types.FETCH_ENRICHMENT_SETTINGS_SUCCESS: {
      return {
        ...state,
        enrichmentSettings: { data: action.payload, status: Status.loaded },
      };
    }
    case types.FETCH_ENRICHMENT_SETTINGS_ERROR: {
      return {
        ...state,
        enrichmentSettings: { data: undefined, status: Status.errorLoading },
      };
    }
    case types.UPDATE_CREDIT_TYPE: {
      return {
        ...state,
        enrichmentSettings: {
          data: state.enrichmentSettings?.data
            ? { ...state.enrichmentSettings.data, creditType: action.payload }
            : { headQuarters: undefined, creditType: action.payload },
          status: Status.loaded,
        },
      };
    }
    case types.CREATE_JOB_MONITOR: {
      return {
        ...state,
        createMonitorStatus: Status.saving,
        createMonitorType: action.payload.monitorType.toLocaleLowerCase(),
      };
    }
    case types.CREATE_JOB_MONITOR_SUCCESS: {
      return {
        ...state,
        createMonitorStatus: Status.saved,
      };
    }
    case types.CREATE_JOB_MONITOR_ERROR: {
      return {
        ...state,
        createMonitorStatus: Status.errorSaving,
      };
    }
    case types.RESET_MONITORING_SAVING_STATUS: {
      return {
        ...state,
        createMonitorStatus: Status.unset,
      };
    }
    case types.CREATE_JOB:
    case types.SUBMIT_JOB: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status:
            action.type === types.SUBMIT_JOB
              ? Status.submitting
              : Status.saving,
          submitJobErrorMsg: [],
        },
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status:
            action.type === types.CREATE_JOB
              ? LoadingEnum.creating
              : LoadingEnum.submitting,
          errorMsg: "",
        },
      };
    }
    case types.FETCH_JOB_SAMPLE: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: state.selected.data
            ? {
                ...state.selected.data,
                sample: { data: [], status: Status.loading },
              }
            : state.selected.data,
          status: state.selected.status,
        },
      };
    }
    case types.CONFIRM_SELECTION_SUCCESS: {
      return {
        ...state,
        selected: { ...state.selected, status: Status.loaded },
      };
    }
    case types.FETCH_JOB_SUMMARY: {
      return state.selected?.data?.summary
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                summary: {
                  ...state.selected.data.summary,
                  status: Status.loading,
                },
              },
            },
          }
        : state;
    }
    case types.FETCH_AGGREGATES: {
      return {
        ...state,
        aggregates: {
          ...state.aggregates,
          data: [],
          status: Status.loading,
        },
      };
    }
    case types.FETCH_FILE_DETAILS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: state.selected.data
            ? {
                ...state.selected.data,
                fileDetails: { status: Status.loading },
              }
            : state.selected.data,
        },
      };
    }
    case types.FETCH_JOBS_SUCCESS: {
      return {
        ...state,
        allIds: action.payload.jobs.map((x) => x.id),
        byId: normalize(action.payload.jobs),
        totalCount: action.payload.count,
        status: Status.loaded,
      };
    }
    case types.FETCH_JOB_DETAILS_SUCCESS:
    case types.FETCH_JOB_DETAILS_PDF_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...action.payload,
            enrichments: { data: [], status: Status.loading },
          },
          status: Status.loaded,
        },
      };
    }
    case types.CREATE_JOB_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: action.payload,
          status: Status.saved,
        },
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.created,
          errorMsg: "",
        },
      };
    }
    case types.FETCH_FILE_DETAILS_SUCCESS: {
      return {
        ...state,
        hasHeader:
          action.payload.length > 0
            ? action.payload[0].hasHeader
            : state.hasHeader,

        selected:
          state.selected?.data && action.payload.length > 0
            ? {
                ...state.selected,
                data: {
                  ...state.selected.data,
                  fileDetails: {
                    data: action.payload[0],
                    status: Status.loaded,
                  },
                },
              }
            : {
                ...state.selected,
                status: Status.errorLoading,
              },
      };
    }
    case types.FETCH_JOB_SAMPLE_SUCCESS:
      return {
        ...state,
        selected: state.selected?.data
          ? {
              ...state.selected,
              data: {
                ...state.selected.data,
                sample: { data: action.payload.sample, status: Status.loaded },
                // mappings: { data: [], status: Status.loading },
              },
            }
          : {
              ...state.selected,
              status: Status.errorLoading,
            },
      };
    case types.FETCH_JOB_MAPPING_SUCCESS:
      return {
        ...state,
        selected: state.selected?.data
          ? {
              ...state.selected,
              data: {
                ...state.selected?.data,
                mappings: {
                  data: action.payload,
                  status: Status.loaded,
                },
              },
            }
          : {
              ...state.selected,
              status: Status.errorLoading,
            },
      };
    case types.FETCH_AGGREGATES_SUCCESS: {
      return {
        ...state,
        aggregates: {
          data: action.payload.map((x) => ({
            name: x.aggregationType,
            data: x.aggregations,
            countryCode: x.countryCode,
          })),
          status: Status.loaded,
        },
      };
    }
    case types.FETCH_AGGREGATES_ERROR: {
      return {
        ...state,
        aggregates: {
          data: [],
          status: Status.errorLoading,
        },
      };
    }
    case types.DISMISS_SAVE_MESSAGE: {
      return state.selected?.data?.mappings
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected?.data,
                mappings: {
                  data: state.selected.data.mappings.data,
                  status: Status.set,
                },
              },
            },
          }
        : state;
    }
    case types.LOAD_MAPPING_TEMPLATE: {
      return state.selected?.data?.mappings
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                mappings: {
                  data: state.selected.data.mappings.data,
                  status: Status.loading,
                },
              },
            },
          }
        : state;
    }
    case types.LOAD_MAPPING_ENRICHMENT_TEMPLATE: {
      return state.selected?.data?.enrichments
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                enrichments: {
                  data: state.selected.data.enrichments.data,
                  status: Status.loading,
                },
              },
            },
          }
        : state;
    }
    case types.SAVE_JOB_MAPPINGS: {
      return state.selected?.data?.mappings
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                mappings: {
                  data: state.selected?.data?.mappings.data,
                  status: Status.saving,
                },
              },
            },
          }
        : state;
    }
    case types.SAVE_JOB_MAPPINGS_SUCCESS: {
      return state.selected?.data?.mappings
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                mappings: {
                  data: state.selected.data.mappings.data,
                  status: Status.saved,
                },
              },
            },
          }
        : state;
    }
    case types.SAVE_JOB_MAPPINGS_ERROR: {
      return state.selected?.data?.mappings
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                mappings: {
                  data: state.selected.data.mappings.data,
                  status: Status.errorSaving,
                },
              },
            },
          }
        : state;
    }
    case types.FETCH_JOB_SUMMARY_SUCCESS: {
      return state.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                summary: {
                  data: { ...action.payload },
                  status: Status.loaded,
                },
              },
            },
          }
        : state;
    }
    case types.SUBMIT_JOB_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: state.selected.data,
          status: Status.saved,
          submitJobErrorMsg: [],
        },
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.submitted,
          errorMsg: "",
        },
      };
    }
    case types.LOAD_MAPPING_TEMPLATE_SUCCESS: {
      return state.selected?.data?.mappings
        ? {
            ...state,
            hasHeader: action.payload.hasHeader,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                mappings: {
                  data: action.payload.mappings,
                  status: Status.loaded,
                },
              },
              status: state.selected.status,
            },
          }
        : state;
    }
    case types.SET_SELECTED_ALGORITHM_BANDING: {
      return state.selected?.data?.enrichments
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                enrichments: {
                  ...state.selected.data.enrichments,
                  algorithmBanding: action.payload,
                  status: Status.loaded,
                },
              },
              status: state.selected.status,
            },
          }
        : state;
    }
    case types.LOAD_MAPPING_ENRICHMENT_TEMPLATE_SUCCESS: {
      return state.selected?.data?.enrichments
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                enrichments: {
                  data: action.payload.enrichmentTemplateMappings,
                  algorithmBanding: action.payload.algorithmBanding,
                  status: Status.loaded,
                },
              },
              status: state.selected.status,
            },
          }
        : state;
    }
    case types.FETCH_JOB_SUMMARY_ERROR: {
      return state.selected?.data?.summary
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                summary: {
                  ...state.selected.data.summary,
                  status: Status.errorLoading,
                },
              },
            },
          }
        : state;
    }
    case types.FETCH_JOB_DETAILS_ERROR: {
      return {
        ...state,
        selected: { ...state.selected, status: Status.errorLoading },
      };
    }
    case types.CONFIRM_SELECTION_ERROR: {
      return {
        ...state,
        selected: { ...state.selected, status: Status.errorEnriching },
      };
    }
    case types.FETCH_FILE_DETAILS_ERROR: {
      return {
        ...state,
        selected: state.selected.data
          ? {
              ...state.selected,
              data: {
                ...state.selected.data,
                fileDetails: { status: Status.errorLoading },
              },
            }
          : { ...state.selected, status: Status.errorLoading },
      };
    }
    case types.FETCH_JOB_SAMPLE_ERROR: {
      return {
        ...state,
        selected: state.selected.data
          ? {
              ...state.selected,
              data: {
                ...state.selected.data,
                sample: { data: [], status: Status.errorLoading },
              },
            }
          : { ...state.selected, status: Status.errorLoading },
      };
    }
    case types.FETCH_JOBS_ERROR: {
      return {
        ...state,
        status: Status.errorLoading,
      };
    }
    case types.CREATE_JOB_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: Status.errorSaving,
          statusMsg: action.payload,
        },
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.createError,
          errorMsg: action.payload || "Error occured while creating job",
        },
      };
    }
    case types.UPDATE_ONESTEP_STATUS: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: action.payload.status,
          errorMsg: action.payload.errorMsg,
        },
      };
    }
    case types.SET_AUTO_ENRICH_JOBID: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          jobId: action.payload,
        },
      };
    }
    case types.UNSET_JOB_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: Status.unset,
        },
      };
    }
    case types.SUBMIT_JOB_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: Status.submitError,
          submitJobErrorMsg: action.payload,
        },
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.submitError,
          errorMsg: "",
        },
      };
    }
    case types.SET_MAPPINGS: {
      return state.selected?.data?.mappings
        ? {
            ...state,
            selected: {
              ...state.selected,

              data: {
                ...state.selected.data,
                mappings: { data: action.payload, status: Status.set },
              },
            },
          }
        : state;
    }

    case types.SET_DUPLICATE_MAPPINGS: {
      return {
        ...state,
        duplicateMappings: action.payload,
      };
    }

    case types.SET_HASHEADER: {
      return {
        ...state,
        hasHeader: action.payload,
      };
    }

    case types.FETCH_SELECTED_WIDGETS: {
      return {
        ...state,
        selected: { ...state.selected, status: Status.loading },
      };
    }
    case types.FETCH_SELECTED_WIDGETS_SUCCESS: {
      const enrichments = getEnrichmentValuesArray(action.payload.payload);
      return {
        ...state,
        selected: { ...state.selected, enrichments, status: Status.loaded },
      };
    }
    case types.FETCH_SELECTED_WIDGETS_ERROR: {
      return {
        ...state,
        selected: { ...state.selected, status: Status.errorLoading },
      };
    }
    case types.FETCH_ENRICHED_FILE_ERROR:
    case types.FETCH_HEALTH_CHECK_PDF_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          fileDownloadStatus: Status.errorLoading,
        },
      };
    }
    case types.SET_FILE_DOWNLOAD_STATUS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          fileDownloadStatus: action.payload,
        },
      };
    }
    case types.SET_SELECTED_TAB_COUNTRY: {
      return {
        ...state,
        selected: {
          ...state.selected,
          selectedTabCountry:
            action.payload.toLowerCase() === "gb" ? "uk" : action.payload,
        },
      };
    }
    case types.FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE: {
      return {
        ...state,
        selected: {
          ...state.selected,
          spendEstimate: { status: Status.loading },
        },
      };
    }
    case types.FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          spendEstimate: { value: action.payload, status: Status.loaded },
        },
      };
    }
    case types.FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          spendEstimate: {
            status: Status.errorLoading,
          },
        },
      };
    }
    case types.SAVE_ENRICHMENTS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: Status.enrichmentsSaving,
        },
      };
    }
    case types.SAVE_ENRICHMENTS_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: Status.saved,
        },
      };
    }
    case types.SAVE_ENRICHMENTS_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: Status.errorEnriching,
        },
      };
    }
    case types.ARCHIVE_JOB: {
      return {
        ...state,
        archieve: { status: Status.archiving },
      };
    }
    case types.ARCHIVE_JOB_SUCCESS: {
      return {
        ...state,
        archieve: { status: Status.archived },
      };
    }
    case types.ARCHIVE_JOB_ERROR: {
      return {
        ...state,
        archieve: { status: Status.errorArchiving },
      };
    }
    case types.RESET_ARCHIVE_JOB_STATUS: {
      return {
        ...state,
        archieve: { status: Status.unset },
      };
    }
    case types.SET_JOB_HEADER_CLASS: {
      return {
        ...state,
        headerClass: action.payload.headerClass,
      };
    }
    case types.FETCH_JOB_RECORDS: {
      return {
        ...state,
        jobRecords: {
          ...state.jobRecords,
          data: [],
          status: Status.loading,
        },
      };
    }
    case types.FETCH_JOB_RECORDS_SUCCESS: {
      return {
        ...state,
        jobRecords: {
          data: action.payload.JobRecords,
          status: Status.loaded,
          totalCount: action.payload.count,
        },
      };
    }
    case types.FETCH_JOB_RECORDS_ERROR: {
      return {
        ...state,
        jobRecords: {
          data: [],
          status: Status.errorLoading,
          totalCount: 0,
        },
      };
    }
    case types.FETCH_COMPANY_RECORDS: {
      return {
        ...state,
        companyRecords: {
          ...state.companyRecords,
          data: [],
          status: Status.loading,
        },
      };
    }
    case types.FETCH_COMPANY_RECORDS_SUCCESS: {
      return {
        ...state,
        companyRecords: {
          data: action.payload.CompanyRecords,
          status: Status.loaded,
          totalCount: action.payload.count,
        },
      };
    }
    case types.FETCH_COMPANY_RECORDS_ERROR: {
      return {
        ...state,
        companyRecords: {
          data: [],
          status: Status.errorLoading,
          totalCount: 0,
        },
      };
    }
    case types.FETCH_AVAILABLE_CREDIT_TYPES: {
      return {
        ...state,
        selected: {
          ...state.selected,
          availableCreditTypes: {
            data: [],
            status: Status.loading,
          },
        },
      };
    }
    case types.FETCH_AVAILABLE_CREDIT_TYPES_SUCCESS: {
      const creditsByCountry = getServiceByContry(
        action.payload,
        config.SERVICE_ID
      );

      return {
        ...state,
        selected: {
          ...state.selected,
          availableCreditTypes: {
            data: creditsByCountry?.map((c) => c.creditType) || [],
            status: Status.loaded,
          },
        },
      };
    }
    case types.FETCH_AVAILABLE_CREDIT_TYPES_ERROR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          availableCreditTypes: {
            data: [],
            status: Status.errorLoading,
          },
        },
      };
    }
    case types.MANUAL_MATCH_RECORD: {
      return {
        ...state,
        manualMatchOrUnmatch: { status: Status.saving },
      };
    }
    case types.MANUAL_MATCH_RECORD_SUCCESS: {
      return {
        ...state,
        manualMatchOrUnmatch: { status: Status.manuallyMatched },
      };
    }
    case types.MANUAL_MATCH_RECORD_ERROR: {
      return {
        ...state,
        manualMatchOrUnmatch: { status: Status.manuallyMatchedError },
      };
    }
    case types.UNMATCH_RECORD: {
      return {
        ...state,
        manualMatchOrUnmatch: { status: Status.saving },
      };
    }
    case types.UNMATCH_RECORD_SUCCESS: {
      return {
        ...state,
        manualMatchOrUnmatch: { status: Status.unmatched },
      };
    }
    case types.UNMATCH_RECORD_ERROR: {
      return {
        ...state,
        manualMatchOrUnmatch: { status: Status.unmatchedError },
      };
    }
    case types.RESET_MANUAL_MATCH_OR_UNMATCH_STATUS: {
      return {
        ...state,
        manualMatchOrUnmatch: { status: Status.unset },
      };
    }

    case types.UPDATE_JOB_CONTACT: {
      return {
        ...state,
        jobUpdateStatus: Status.updating,
      };
    }

    case types.UPDATE_JOB_CONTACT_SUCCESS: {
      const selectedJob = state.selected.data;
      if (selectedJob) {
        selectedJob.name = action.payload.jobName;
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          data: selectedJob,
        },
        contact: {
          data: action.payload.contact,
          status: state.contact?.status || Status.unset,
        },

        jobUpdateStatus: Status.updated,
      };
    }

    case types.UPDATE_JOB_CONTACT_ERROR: {
      return {
        ...state,
        jobUpdateStatus: Status.updateError,
        selected: {
          ...state.selected,
          statusMsg: action.payload,
        },
      };
    }

    case types.ASSIGN_MASTER_TEMPLATE: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.assigningMasterTemplate,
          errorMsg: "",
        },
      };
    }

    case types.VALIDATE_JOB_MAPPINGS: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.validating,
          errorMsg: "",
        },
      };
    }

    case types.RESET_JOB_CONTACT_STATUS: {
      return {
        ...state,
        jobUpdateStatus: Status.unset,
      };
    }

    case types.SET_CREDIT_TYPE_WIDGETS: {
      return {
        ...state,
        creditTypeWidgets: action.payload,
      };
    }

    case types.UPLOAD_JOB: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.uploading,
          errorMsg: "",
        },
      };
    }

    case types.UPLOAD_JOB_SUCCESS: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.uploaded,
          errorMsg: "",
        },
      };
    }

    case types.UPLOAD_JOB_ERROR: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.uploadError,
          errorMsg: action.payload || "",
        },
      };
    }

    case types.FETCH_JOB_AUTO_ENRICHMENT: {
      return {
        ...state,
        autoEnrichmentData: {
          ...state.autoEnrichmentData,
          data: undefined,
          status: Status.loading,
        },
      };
    }

    case types.FETCH_JOB_AUTO_ENRICHMENT_SUCCESS: {
      return {
        ...state,
        autoEnrichmentData: {
          ...state.autoEnrichmentData,
          data: action.payload,
          status: Status.loaded,
        },
      };
    }

    case types.FETCH_JOB_AUTO_ENRICHMENT_ERROR: {
      return {
        ...state,
        autoEnrichmentData: {
          ...state.autoEnrichmentData,
          data: undefined,
          status: Status.errorLoading,
        },
      };
    }
    case types.FETCH_MANUALMATCH_STATUS: {
      return state?.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                manualMatchStatus: {
                  data: undefined,
                  status: Status.loading,
                },
              },
            },
          }
        : state;
    }

    case types.FETCH_MANUALMATCH_STATUS_SUCCESS: {
      return state?.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                manualMatchStatus: {
                  data: action.payload,
                  status: Status.loaded,
                },
              },
            },
          }
        : state;
    }

    case types.FETCH_MANUALMATCH_STATUS_ERROR: {
      return state?.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                manualMatchStatus: {
                  data: undefined,
                  status: Status.errorLoading,
                },
              },
            },
          }
        : state;
    }

    case types.RE_AGGREGATE_JOB: {
      return state?.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                reAggregateJobStatus: Status.saving,
              },
            },
          }
        : state;
    }

    case types.RE_AGGREGATE_JOB_SUCCESS: {
      return state?.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                manualMatchStatus: state.selected.data.manualMatchStatus
                  ? {
                      ...state.selected.data.manualMatchStatus,
                      data: state.selected.data.manualMatchStatus?.data
                        ? {
                            ...state.selected.data.manualMatchStatus?.data,
                            isManualMatchRecordsExists: true,
                          }
                        : undefined,
                    }
                  : undefined,
                reAggregateJobStatus: Status.saved,
              },
            },
          }
        : state;
    }

    case types.RE_AGGREGATE_JOB_ERROR: {
      return state?.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                reAggregateJobStatus: Status.errorSaving,
              },
            },
          }
        : state;
    }

    case types.RESET_RE_AGGREGATE_STATUS: {
      return state?.selected?.data
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                reAggregateJobStatus: Status.unset,
              },
            },
          }
        : state;
    }

    case types.RESET_AUTO_ENRICHMENT_JOB_STATUS: {
      return {
        ...state,
        autoEnrichJob: {
          ...state.autoEnrichJob,
          status: LoadingEnum.unset,
        },
      };
    }

    case types.SET_SELECTED_ALGORITHM_BANDING: {
      return state.selected?.data?.enrichments
        ? {
            ...state,
            selected: {
              ...state.selected,
              data: {
                ...state.selected.data,
                enrichments: {
                  ...state.selected.data.enrichments,
                  algorithmBanding: action.payload,
                  status: Status.loaded,
                },
              },
              status: state.selected.status,
            },
          }
        : state;
    }
    default:
      return state;
  }
}

const normalize = (jobs: types.Job[]): { [id: string]: types.Job } =>
  jobs.reduce((acc, x) => ({ ...acc, [x.id]: x }), {});

const getEnrichmentValuesArray = (enrichments: { enrichment: string }[]) =>
  enrichments.map((x) => x.enrichment);
