import { Epic } from "../rootEpic";
import {
  DELETE_ENRICHMENT_TEMPLATE,
  DELETE_MASTER_TEMPLATE,
  DELETE_MINIMAL_MATCH_TEMPLATE,
  FETCH_ENRICHMENT_TEMPLATE,
  FETCH_ENRICHMENT_TEMPLATEDETAILS,
  FETCH_MAPPING_TEMPLATE,
  FETCH_MAPPING_TEMPLATEDETAILS,
  FETCH_MASTER_TEMPLATE,
  FETCH_MINIMAL_MATCH_TEMPLATE,
  FETCH_MINIMAL_MATCH_TEMPLATEDETAILS,
  SETTINGS_SAVE_ENRICHMENT_TEMPLATE,
  SAVE_MINIMAL_MATCH_TEMPLATE,
  SETTINGS_UPDATE_ENRICHMENT_TEMPLATE,
  UPDATE_MINIMAL_MATCH_TEMPLATE,
  SAVE_MASTER_TEMPLATE,
  FETCH_MASTER_TEMPLATE_DETAILS,
} from "./types";
import { of } from "rxjs";
import { map, switchMap, filter, catchError } from "rxjs/operators";
import {
  fetchEnrichTemplateError,
  fetchEnrichTemplateSuccess,
  fetchMasterTemplateError,
  fetchMasterTemplateSuccess,
  fetchMappingTemplateSuccess,
  fetchMappingTemplateError,
  fetchMinimalMatchTemplateSuccess,
  fetchMinimalMatchTemplateError,
  deleteMasterTemplateSuccess,
  deleteMasterTemplateError,
  deleteEnrichTemplateSuccess,
  deleteEnrichTemplateError,
  deleteMinimalMatchTemplateSuccess,
  deleteMinimalMatchTemplateError,
  saveMinMatchTemplateSuccess,
  saveMinMatchTemplateError,
  fetchMinimalMatchTemplate,
  fetchEnrichTemplateDetailsError,
  fetchEnrichTemplateDetailsSuccess,
  fetchEnrichTemplate,
  saveSettingsEnrichTemplateError,
  saveSettingsEnrichTemplateSuccess,
  fetchMinimalMatchTemplateDetailsError,
  fetchMinimalMatchTemplateDetailsSuccess,
  fetchMappingTemplateDetailsSuccess,
  fetchMappingTemplateDetailsError,
  saveMasterTemplateSuccess,
  saveMasterTemplateError,
  fetchMasterTemplateDetailsSuccess,
  fetchMasterTemplateDetailsError,
  fetchMasterTemplate,
} from "./actions";
import { isOfType, ActionType } from "typesafe-actions";

// #region Master Template
export const fetchMasterTemplatesEpic: Epic<
  | ActionType<typeof fetchMasterTemplateSuccess>
  | ActionType<typeof fetchMasterTemplateError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_MASTER_TEMPLATE)),
    switchMap(() => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/masterTemplate`,
        })
        .pipe(
          map(({ response }) => {
            return fetchMasterTemplateSuccess(response);
          }),
          catchError((err) => {
            return of(fetchMasterTemplateError(err));
          })
        );
    })
  );

export const fetchMasterTemplateDetailsEpic: Epic<
  | ActionType<typeof fetchMasterTemplateDetailsSuccess>
  | ActionType<typeof fetchMasterTemplateDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_MASTER_TEMPLATE_DETAILS)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/masterTemplate/${payload}`,
        })
        .pipe(
          map(({ response }) => {
            return fetchMasterTemplateDetailsSuccess(response);
          }),
          catchError((err) => {
            return of(fetchMasterTemplateDetailsError(err));
          })
        );
    })
  );

export const saveMasterTemplateEpic: Epic<
  | ActionType<typeof saveMasterTemplateSuccess>
  | ActionType<typeof saveMasterTemplateError>
  | ActionType<typeof fetchMasterTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SAVE_MASTER_TEMPLATE)),
    switchMap(({ payload }) => {
      const id = payload.id;
      return api
        .request({
          method:
            id === "00000000-0000-0000-0000-000000000000" ? "POST" : "PUT",
          url:
            id === "00000000-0000-0000-0000-000000000000"
              ? `${config.DATA_CLEANING_API_URL}/masterTemplate`
              : `${config.DATA_CLEANING_API_URL}/masterTemplate/${id}`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: {
            name: payload.name,
            minimumMatchTemplateId: payload.minimumMatchTemplateId,
            enrichmentTemplateId: payload.enrichmentTemplateId,
            mappingTemplateId: payload.mappingTemplateId,
          },
        })
        .pipe(
          switchMap((response) => {
            return of(
              saveMasterTemplateSuccess(response.response),
              fetchMasterTemplate()
            );
          }),
          catchError((err) => of(saveMasterTemplateError(err.response)))
        );
    })
  );

export const deleteMasterTemplateEpic: Epic<
  | ActionType<typeof deleteMasterTemplateSuccess>
  | ActionType<typeof deleteMasterTemplateError>
  | ActionType<typeof fetchMasterTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(DELETE_MASTER_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "DELETE",
          url: `${config.DATA_CLEANING_API_URL}/masterTemplate/${payload.id}`,
        })
        .pipe(
          switchMap(() => {
            return of(deleteMasterTemplateSuccess(), fetchMasterTemplate());
          }),
          catchError((err) => {
            return of(deleteMasterTemplateError(err));
          })
        );
    })
  );

// #endregion

// #region Enrichment Template
export const fetchEnrichmentTemplateEpic: Epic<
  | ActionType<typeof fetchEnrichTemplateSuccess>
  | ActionType<typeof fetchEnrichTemplateError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ENRICHMENT_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates?isConfigured=${payload}`,
        })
        .pipe(
          map(({ response }) => {
            return fetchEnrichTemplateSuccess(response);
          }),
          catchError((err) => {
            return of(fetchEnrichTemplateError(err));
          })
        );
    })
  );

export const fetchEnrichmentTemplateDetailsEpic: Epic<
  | ActionType<typeof fetchEnrichTemplateDetailsSuccess>
  | ActionType<typeof fetchEnrichTemplateDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ENRICHMENT_TEMPLATEDETAILS)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates/${payload}`,
        })
        .pipe(
          map(({ response }) => {
            return fetchEnrichTemplateDetailsSuccess(response);
          }),
          catchError((err) => {
            return of(fetchEnrichTemplateDetailsError(err));
          })
        );
    })
  );

export const createEnrichmentTemplateEpic: Epic<
  | ActionType<typeof saveSettingsEnrichTemplateSuccess>
  | ActionType<typeof saveSettingsEnrichTemplateError>
  | ActionType<typeof fetchEnrichTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SETTINGS_SAVE_ENRICHMENT_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: payload,
        })
        .pipe(
          switchMap((response) => {
            return of(
              saveSettingsEnrichTemplateSuccess(response.response),
              fetchEnrichTemplate()
            );
          }),
          catchError((err) => of(saveSettingsEnrichTemplateError(err.response)))
        );
    })
  );

export const updateEnrichmentTemplateEpic: Epic<
  | ActionType<typeof saveSettingsEnrichTemplateSuccess>
  | ActionType<typeof saveSettingsEnrichTemplateError>
  | ActionType<typeof fetchEnrichTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SETTINGS_UPDATE_ENRICHMENT_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates/${payload.id}`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: payload,
        })
        .pipe(
          switchMap((response) => {
            return of(
              saveSettingsEnrichTemplateSuccess(response.response),
              fetchEnrichTemplate()
            );
          }),
          catchError((err) => of(saveSettingsEnrichTemplateError(err.response)))
        );
    })
  );

export const deleteEnrichmentTemplateEpic: Epic<
  | ActionType<typeof deleteEnrichTemplateSuccess>
  | ActionType<typeof deleteEnrichTemplateError>
  | ActionType<typeof fetchEnrichTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(DELETE_ENRICHMENT_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "DELETE",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates/${payload.id}`,
        })
        .pipe(
          switchMap(() => {
            return of(deleteEnrichTemplateSuccess(), fetchEnrichTemplate());
          }),
          catchError((err) => {
            return of(deleteEnrichTemplateError(err.response));
          })
        );
    })
  );

// #endregion

// #region Mapping Template
export const fetchMappingTemplateEpic: Epic<
  | ActionType<typeof fetchMappingTemplateSuccess>
  | ActionType<typeof fetchMappingTemplateError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_MAPPING_TEMPLATE)),
    switchMap(() => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/mappingTemplates`,
        })
        .pipe(
          map(({ response }) => {
            return fetchMappingTemplateSuccess(response);
          }),
          catchError((err) => {
            return of(fetchMappingTemplateError(err));
          })
        );
    })
  );

export const fetchMappingTemplateDetailsEpic: Epic<
  | ActionType<typeof fetchMappingTemplateDetailsSuccess>
  | ActionType<typeof fetchMappingTemplateDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_MAPPING_TEMPLATEDETAILS)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/mappingTemplates/${payload}`,
        })
        .pipe(
          map(({ response }) => {
            return fetchMappingTemplateDetailsSuccess(response);
          }),
          catchError((err) => {
            return of(fetchMappingTemplateDetailsError(err));
          })
        );
    })
  );

// #endregion

// #region Minimal Match Template
export const fetchMinimalMatchTemplateEpic: Epic<
  | ActionType<typeof fetchMinimalMatchTemplateSuccess>
  | ActionType<typeof fetchMinimalMatchTemplateError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_MINIMAL_MATCH_TEMPLATE)),
    switchMap(() => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/minimumMatchTemplate`,
        })
        .pipe(
          map(({ response }) => {
            return fetchMinimalMatchTemplateSuccess(response);
          }),
          catchError((err) => {
            return of(fetchMinimalMatchTemplateError(err));
          })
        );
    })
  );

export const fetchMinimalMatchTemplateDetailsEpic: Epic<
  | ActionType<typeof fetchMinimalMatchTemplateDetailsSuccess>
  | ActionType<typeof fetchMinimalMatchTemplateDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_MINIMAL_MATCH_TEMPLATEDETAILS)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/minimumMatchTemplate/${payload}`,
        })
        .pipe(
          map(({ response }) => {
            return fetchMinimalMatchTemplateDetailsSuccess(response);
          }),
          catchError((err) => {
            return of(fetchMinimalMatchTemplateDetailsError(err));
          })
        );
    })
  );

export const saveMinimalMatchTemplateEpic: Epic<
  | ActionType<typeof saveMinMatchTemplateSuccess>
  | ActionType<typeof saveMinMatchTemplateError>
  | ActionType<typeof fetchMinimalMatchTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SAVE_MINIMAL_MATCH_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/minimumMatchTemplate`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: payload,
        })
        .pipe(
          switchMap((response) => {
            return of(
              saveMinMatchTemplateSuccess(response.response),
              fetchMinimalMatchTemplate()
            );
          }),
          catchError((err) => of(saveMinMatchTemplateError(err.response)))
        );
    })
  );

export const updateMinimalMatchTemplateEpic: Epic<
  | ActionType<typeof saveMinMatchTemplateSuccess>
  | ActionType<typeof saveMinMatchTemplateError>
  | ActionType<typeof fetchMinimalMatchTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(UPDATE_MINIMAL_MATCH_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/minimumMatchTemplate/${payload.id}`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: payload.body,
        })
        .pipe(
          switchMap((response) => {
            return of(
              saveMinMatchTemplateSuccess(response.response),
              fetchMinimalMatchTemplate()
            );
          }),
          catchError((err) => of(saveMinMatchTemplateError(err.response)))
        );
    })
  );

export const deleteMinimalMatchTemplateEpic: Epic<
  | ActionType<typeof deleteMinimalMatchTemplateError>
  | ActionType<typeof deleteMinimalMatchTemplateSuccess>
  | ActionType<typeof fetchMinimalMatchTemplate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(DELETE_MINIMAL_MATCH_TEMPLATE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "DELETE",
          url: `${config.DATA_CLEANING_API_URL}/minimumMatchTemplate/${payload.id}`,
        })
        .pipe(
          switchMap(() => {
            return of(
              deleteMinimalMatchTemplateSuccess(),
              fetchMinimalMatchTemplate()
            );
          }),
          catchError((err) => {
            return of(deleteMinimalMatchTemplateError(err.response));
          })
        );
    })
  );

// #endregion
