import { Epic } from "../rootEpic";
import { FETCH_USER_DETAILS_SUCCESS } from "../user/types";
import { EMPTY } from "rxjs";
import { filter, switchMapTo, tap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import some from "lodash/some";
import { localeConfig } from "config/i18n-locale-configs";

export const setDefaultLocaleEpic: Epic<never> = (action$, _$, { i18n }) =>
  action$.pipe(
    filter(isOfType(FETCH_USER_DETAILS_SUCCESS)),
    tap(({ payload }) => {
      if (
        i18n.language !== payload.languageCode &&
        some(localeConfig, { code: payload.languageCode })
      ) {
        i18n.changeLanguage(payload.languageCode);
      }
    }),
    switchMapTo(EMPTY)
  );
