import { Job, JobRecord } from "store/jobs/types";
import {
  TITLE_PROVINCE_JP,
  TITLE_REGION_IT,
  WIDGET_TITLE_PROVINCE,
  WIDGET_TITLE_REGION,
  TITLE_REGION_DE,
  TITLE_REGION_SE,
  TITLE_PROVINCE_UK,
  TITLE_STATES_US,
  WIDGET_TITLE_STATES,
  TITLE_PROVINCE_NL,
  TITLE_PROVINCE_BE,
} from "../Aggregates/constants";

export const validMappingValues = [
  "companyId",
  "orgNumber",
  "vatNumber",
  "taxCode",
  "name",
  "tradingName",
  "aliasName",
  "previousName",
  "phone",
  "email",
  "address1",
  "address2",
  "address3",
  "address5",
  "town",
  "postcode",
  "region",
  "country",
  "personalID",
  "personalLimit",
];

export const CountryCodeAggregationKey = "CountryCode";

export const MAX_ARRAY_SPLIT_SIZE = 10;

export const countryListJSONMaps: { [key: string]: string } = {
  jp: "/assets/japan-provinces.json",
  nl: "/assets/netherlands-provinces.json",
  it: "/assets/italy-region.json",
  ca: "/assets/canada-provinces.json",
  de: "/assets/germany-region.json",
  se: "/assets/sweden-region.json",
  uk: "/assets/united-kingdom-region.json",
  us: "/assets/USA-States.json",
  be: "/assets/belgium-provinces.json",
};

export const countryGeoPropIdentifier: { [key: string]: string } = {
  jp: "nam",
  nl: "name",
  it: "reg_name",
  ca: "name",
  de: "name",
  se: "name",
  uk: "name",
  us: "name",
  be: "name",
};

export const countryGeoProjectionConfig: {
  [key: string]: Record<string, unknown>;
} = {
  jp: { rotate: [-138, -38, -16], scale: 1510 },
  nl: { rotate: [-548, -488, -560], scale: 5000, center: [-1, 0] },
  it: { scale: 150, center: [40, -168] },
  ca: { rotate: [25, -10, -2], center: [30, 32] },
  de: { rotate: [-548, -488, -510], scale: 2600, center: [3, -1] },
  se: { center: [-21, 0] },
  uk: { rotate: [-548, -488, -540], scale: 900, center: [-5, 4] },
  us: { center: [0, 10] },
  be: { rotate: [-549, -489, -528], scale: 4800, center: [-3, -1] },
};

export const countryGeoHeight: { [key: string]: number } = {
  jp: 600,
  nl: 390,
  it: 168,
  ca: 203,
  de: 400,
  se: 500,
  uk: 200,
  us: 420,
  be: 210,
};

export const countryGeoWidth: { [key: string]: number } = {
  ca: 600,
  uk: 320,
  it: 260,
  nl: 580,
  be: 330,
};

export const provinceMapTitles = [
  TITLE_PROVINCE_JP,
  TITLE_REGION_IT,
  TITLE_REGION_DE,
  TITLE_REGION_SE,
  TITLE_PROVINCE_UK,
  TITLE_STATES_US,
  TITLE_PROVINCE_NL,
  TITLE_PROVINCE_BE,
];

export const provinceTitles = [
  WIDGET_TITLE_PROVINCE,
  WIDGET_TITLE_REGION,
  WIDGET_TITLE_STATES,
];

export const getJobMappings = (job: Job | undefined, jobRecord: JobRecord) => {
  const jobMappings: {
    mapping: string;
    value: string | number | boolean;
  }[] = [];
  if (job !== undefined) {
    job?.mappings?.data.map((row) => {
      const mappingColumn = row.mapping.replace("countryCode", "country");
      jobMappings.push({
        mapping: mappingColumn || "",
        value: jobRecord[mappingColumn as keyof typeof jobRecord],
      });
    });
  }

  return jobMappings;
};
