import { combineReducers } from "redux";
import user, { initialState as initialUserState } from "./user/reducer";
import jobs, { initialState as initialJobsState } from "./jobs/reducer";
import admin, { initialState as adminState } from "./admin/reducer";
import settings, { initialState as settingsState } from "./settings/reducer";
import mappings, {
  initialState as initialMappingsState,
} from "./mappings/reducer";
import enrichments, {
  initialState as initialEnrichmentsState,
} from "./enrichments/reducer";
import i18n, { initialState as initialI18nState } from "./i18n/reducer";
import customer, {
  initialState as initialCustomerState,
} from "./customer/reducer";
import app, { initialState as initalAppState } from "./app/reducer";

const rootReducer = combineReducers({
  user,
  jobs,
  mappings,
  enrichments,
  i18n,
  customer,
  app,
  admin,
  settings,
});

export type RootState = ReturnType<typeof rootReducer>;

export const initialState = {
  user: initialUserState,
  jobs: initialJobsState,
  mappings: initialMappingsState,
  enrichments: initialEnrichmentsState,
  i18n: initialI18nState,
  customer: initialCustomerState,
  app: initalAppState,
  admin: adminState,
  settings: settingsState,
};

export default rootReducer;
