import { get } from "lodash";
import { EMPTY, timer, of } from "rxjs";
import { catchError, filter, switchMap, takeUntil } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";
import { POLL_INTERVAL_MINUTES } from "../../constants";
import { Epic } from "../rootEpic";
import { fetchAppVersionSuccess, setRequiresAppUpdate } from "./actions";
import { FETCH_APP_VERSION, SET_REQUIRES_APP_UPDATE } from "./types";

export const fetchAppVersionEpic: Epic<
  | ActionType<typeof fetchAppVersionSuccess>
  | ActionType<typeof setRequiresAppUpdate>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_APP_VERSION)),
    switchMap(() =>
      timer(0, POLL_INTERVAL_MINUTES * 60000).pipe(
        switchMap(() =>
          api
            .request({
              method: "GET",
              url: `/version.json`,
            })
            .pipe(
              switchMap(({ response }) => {
                const appVersion = get(response, "version", null);
                const currentVersion = get(window, "environment.version", null);
                if (appVersion !== currentVersion) {
                  return of(setRequiresAppUpdate(), fetchAppVersionSuccess());
                }
                return of(fetchAppVersionSuccess());
              }),
              catchError(() => EMPTY)
            )
        ),
        catchError(() => EMPTY),
        takeUntil(action$.pipe(filter(isOfType(SET_REQUIRES_APP_UPDATE))))
      )
    )
  );
