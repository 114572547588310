import { forkJoin, of, EMPTY } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";
import {
  getNonEmptyQueryParams,
  isNameRegNumberSearch,
  isSafeNumber,
} from "../../appUtils";
import { validMappingValues } from "../../pages/Jobs/Mappings/constants";
import { Source } from "../../pages/Jobs/Source";
import { JobStatus, LoadingEnum } from "../../pages/Jobs/Status";
import { Epic } from "../rootEpic";
import * as actions from "./actions";
import {
  CONFIRM_SELECTION,
  CONFIRM_SELECTION_SUCCESS,
  CREATE_JOB,
  FETCH_AGGREGATES,
  FETCH_AGGREGATES_BY_COUNTRY,
  FETCH_ENRICHED_FILE,
  FETCH_FILE_DETAILS,
  FETCH_JOBS,
  FETCH_JOB_DETAILS,
  FETCH_JOB_DETAILS_SUCCESS,
  FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE,
  FETCH_JOB_SAMPLE,
  FETCH_JOB_SAMPLE_SUCCESS,
  FETCH_JOB_SUMMARY,
  FETCH_SELECTED_WIDGETS,
  LOAD_MAPPING_TEMPLATE,
  SAVE_JOB_MAPPINGS,
  SAVE_JOB_MAPPINGS_SUCCESS,
  SUBMIT_JOB,
  SUBMIT_JOB_SUCCESS,
  SAVE_ENRICHMENTS,
  FETCH_HEALTH_CHECK_PDF,
  UPADATE_JOB_ENRICHMENT_SETTINGS,
  ARCHIVE_JOB,
  FETCH_JOB_RECORDS,
  FETCH_COMPANY_RECORDS,
  MANUAL_MATCH_RECORD,
  LOAD_MAPPING_ENRICHMENT_TEMPLATE,
  FETCH_JOB_MAPPINGS,
  SUBMIT_JOB_ENRICH_REASON,
  CompanyRecordPayload,
  UNMATCH_RECORD,
  JobRecordPayload,
  UNMATCH_RECORD_SUCCESS,
  MANUAL_MATCH_RECORD_SUCCESS,
  FETCH_JOB_CONTACT,
  CREATE_JOB_MONITOR,
  UPDATE_JOB_CONTACT,
  FETCH_ENRICHMENT_SETTINGS,
  FETCH_AVAILABLE_CREDIT_TYPES,
  ASSIGN_MASTER_TEMPLATE,
  VALIDATE_JOB_MAPPINGS,
  UPLOAD_JOB,
  FETCH_JOB_AUTO_ENRICHMENT,
  FETCH_MANUALMATCH_STATUS,
  RE_AGGREGATE_JOB,
  RE_AGGREGATE_JOB_SUCCESS,
} from "./types";
import queryString from "query-string";
import { has } from "lodash";
import { MatchingStatus } from "pages/Jobs/JobRecords/constants";

export const fetchJobsEpic: Epic<
  | ActionType<typeof actions.fetchJobsSuccess>
  | ActionType<typeof actions.fetchJobsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOBS)),
    switchMap(({ payload }) => {
      const queryParams = getNonEmptyQueryParams({
        source: Source.dataCleaning,
        ...payload,
      });
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs?${queryParams}`,
        })
        .pipe(
          map(({ response, xhr }) => {
            const count = parseInt(
              xhr.getResponseHeader("X-Total-Count") || "0",
              10
            );
            return actions.fetchJobsSuccess(response, count);
          }),
          catchError(() => of(actions.fetchJobsError()))
        );
    })
  );

export const fetchJobDetailsEpic: Epic<
  | ActionType<typeof actions.fetchJobDetailsSuccess>
  | ActionType<typeof actions.fetchJobDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType([FETCH_JOB_DETAILS, CONFIRM_SELECTION_SUCCESS])),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}`,
        })
        .pipe(
          switchMap(({ response }) =>
            of(actions.fetchJobDetailsSuccess(response))
          ),
          catchError(() => of(actions.fetchJobDetailsError()))
        )
    )
  );

export const fetchJobContactEpic: Epic<
  | ActionType<typeof actions.fetchJobContactSuccess>
  | ActionType<typeof actions.fetchJobContactError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType([FETCH_JOB_CONTACT])),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/contact`,
        })
        .pipe(
          switchMap(({ response }) =>
            of(actions.fetchJobContactSuccess(response))
          ),
          catchError(() => of(actions.fetchJobContactError()))
        )
    )
  );

export const fetchJobAutoEnrichmentDataEpic: Epic<
  | ActionType<typeof actions.fetchJobAutoEnrichmentDataSuccess>
  | ActionType<typeof actions.fetchJobAutoEnrichmentDataError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType([FETCH_JOB_AUTO_ENRICHMENT])),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/jobautoenrichmentdata`,
        })
        .pipe(
          switchMap(({ response }) =>
            of(actions.fetchJobAutoEnrichmentDataSuccess(response))
          ),
          catchError(() => of(actions.fetchJobAutoEnrichmentDataError()))
        )
    )
  );

export const fetchEnrichmentSettingsEpic: Epic<
  | ActionType<typeof actions.fetchEnrichmentSettingsSuccess>
  | ActionType<typeof actions.fetchEnrichmentSettingsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType([FETCH_ENRICHMENT_SETTINGS])),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/enrichmentSettings`,
        })
        .pipe(
          switchMap(({ response }) =>
            of(actions.fetchEnrichmentSettingsSuccess(response))
          ),
          catchError(() => of(actions.fetchEnrichmentSettingsError()))
        )
    )
  );

export const createJobMonitorEpic: Epic<
  | ActionType<typeof actions.createJobMonitorSuccess>
  | ActionType<typeof actions.createJobMonitorError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType([CREATE_JOB_MONITOR])),
    switchMap(({ payload }) =>
      api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobmonitor/${payload.jobId}/monitor`,
          body: {
            monitoringPortfolioId: payload.monitoringPortfolioId,
            email: payload.email,
            monitorType: payload.monitorType,
          },
        })
        .pipe(
          switchMap(() => of(actions.createJobMonitorSuccess())),
          catchError(() => of(actions.createJobMonitorError()))
        )
    )
  );

export const fetchFileDetails: Epic<ActionType<
  typeof actions.fetchFileDetails
>> = (action$) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_DETAILS_SUCCESS)),
    switchMap(({ payload }) => of(actions.fetchFileDetails(payload.id)))
  );

export const fetchFileDetailsEpic: Epic<
  | ActionType<typeof actions.fetchFileDetailsSuccess>
  | ActionType<typeof actions.fetchFileDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_FILE_DETAILS)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/files?active=true`,
        })
        .pipe(
          switchMap(({ response }) =>
            of(actions.fetchFileDetailsSuccess(response))
          ),
          catchError(() => of(actions.fetchFileDetailsError()))
        )
    )
  );

export const conditionallyFetchSample: Epic<ActionType<
  typeof actions.fetchJobSample
>> = (action$) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_DETAILS_SUCCESS)),
    filter(({ payload }) => payload.status === JobStatus.uploaded),
    switchMap(({ payload }) =>
      of(
        actions.fetchJobSample({
          id: payload.id,
          queryParams: { _page: 1, _limit: 26 },
        })
      )
    )
  );

export const fetchJobSampleEpic: Epic<ActionType<
  typeof actions.fetchJobSampleSuccess | typeof actions.fetchJobSampleError
>> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_SAMPLE)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.id}/files?active=true`,
        })
        .pipe(
          switchMap((files) =>
            api
              .request({
                method: "GET",
                url: `${config.DATA_CLEANING_API_URL}/jobs/${
                  payload.id
                }/files/${
                  files.response[0].id
                }/_sample?${getNonEmptyQueryParams(payload.queryParams || {})}`,
              })
              .pipe(
                map((sample) =>
                  actions.fetchJobSampleSuccess({
                    id: payload.id,
                    sample: sample.response,
                  })
                )
              )
          ),
          catchError(() => of(actions.fetchJobSampleError()))
        )
    )
  );

export const fetchJobMappingEpic: Epic<ActionType<
  typeof actions.fetchJobMappingSuccess | typeof actions.fetchJobMappingError
>> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(
      isOfType([
        FETCH_JOB_SAMPLE_SUCCESS,
        FETCH_JOB_MAPPINGS,
        FETCH_JOB_DETAILS_SUCCESS,
      ])
    ),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.id}/mappings`,
        })
        .pipe(
          map(({ response }) => actions.fetchJobMappingSuccess(response)),
          catchError((error) =>
            of(
              error.status === 404
                ? actions.fetchJobMappingSuccess([])
                : actions.fetchJobMappingError()
            )
          )
        )
    )
  );

export const uploadJobEpic: Epic<
  | ActionType<typeof actions.uploadJobSuccess>
  | ActionType<typeof actions.uploadJobError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(UPLOAD_JOB)),
    switchMap(({ payload }) => {
      const body = new FormData();
      if (payload.body.jobDetails.jobFile)
        body.append("jobFile", payload.body.jobDetails.jobFile);
      body.append("hasHeader", String(payload.body.jobDetails.hasHeader));
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/_upload`,
          headers: {},
          body,
        })
        .pipe(
          switchMap(() => of(actions.uploadJobSuccess())),
          catchError(({ xhr }) => of(actions.uploadJobError(xhr.response.type)))
        );
    })
  );

export const createJobEpic: Epic<
  | ActionType<typeof actions.createJobSuccess>
  | ActionType<typeof actions.setAutoEnrichJobId>
  | ActionType<typeof actions.createJobError>
  | ActionType<typeof actions.updateOneStepStatus>
  | ActionType<typeof actions.uploadJob>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(CREATE_JOB)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs`,
          body: {
            name: payload.jobDetails.name,
            owningCompanyName: payload.jobDetails.owningCompanyName,
            owningUserId: payload.jobDetails.owningUserId,
            owningCustomerId: payload.jobDetails.owningCustomerId,
            source: payload.jobDetails.source,
          },
        })
        .pipe(
          switchMap((job) =>
            api
              .request({
                method: "POST",
                url: `${config.DATA_CLEANING_API_URL}/jobs/${job.response.id}/contacts`,
                body: {
                  person: payload.jobDetails.owningCompanyName,
                  telephone: payload.jobDetails.telephone,
                  email: payload.jobDetails.email,
                },
              })
              .pipe(
                switchMap(() => {
                  return of(
                    actions.setAutoEnrichJobId(job.response.id),
                    actions.updateOneStepStatus({
                      status: LoadingEnum.uploading,
                      errorMsg: "",
                    }),
                    actions.uploadJob({
                      jobId: job.response.id,
                      body: payload,
                    })
                  );
                })
              )
          ),
          catchError(({ xhr }) => {
            return of(
              actions.createJobError(
                xhr.status === 403 ? "Forbidden" : xhr.response.type
              )
            );
          })
        )
    )
  );

export const fetchAggregatesEpic: Epic<
  | ActionType<typeof actions.fetchAggregatesSuccess>
  | ActionType<typeof actions.fetchAggregatesError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_AGGREGATES)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/aggregates`,
        })
        .pipe(
          map(({ response }) => actions.fetchAggregatesSuccess(response)),
          catchError(() => of(actions.fetchAggregatesError()))
        )
    )
  );

export const fetchAggregatesByCountryEpic: Epic<
  | ActionType<typeof actions.fetchAggregatesSuccess>
  | ActionType<typeof actions.fetchAggregatesError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_AGGREGATES_BY_COUNTRY)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.id}/aggregates?&countryCode=${payload.countryCode}&_limit=100`,
        })
        .pipe(
          map(({ response }) => actions.fetchAggregatesSuccess(response)),
          catchError(() => of(actions.fetchAggregatesError()))
        )
    )
  );

export const saveJobMappingsEpic: Epic<
  | ActionType<typeof actions.saveJobMappingsSuccess>
  | ActionType<typeof actions.saveJobMappingsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SAVE_JOB_MAPPINGS)),
    switchMap(({ payload }) => {
      const mappings = payload.mappings.filter(
        (innerMapping: { mapping: string; value: string }) =>
          validMappingValues.includes(innerMapping.mapping) ||
          isNaN(Number(innerMapping.value))
      );
      const requests = [
        api.request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/mappings`,
          body: { mappings },
        }),
        api.request({
          method: "PATCH",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/files/${payload.fileId}`,
          body: { hasHeader: payload.hasHeader },
        }),
      ];
      return forkJoin(requests).pipe(
        switchMap(() =>
          of(actions.saveJobMappingsSuccess(payload.jobId, payload.isDraft))
        ),
        catchError(() => of(actions.saveJobMappingsError()))
      );
    })
  );

export const conditionallySubmitJob: Epic<ActionType<
  typeof actions.submitJob
>> = (action$) =>
  action$.pipe(
    filter(isOfType(SAVE_JOB_MAPPINGS_SUCCESS)),
    filter(({ payload }) => payload.isDraft === false),
    switchMap(({ payload }) => of(actions.submitJob(payload.id)))
  );

export const submitJobEpic: Epic<ActionType<
  typeof actions.submitJobSuccess | typeof actions.submitJobError
>> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SUBMIT_JOB)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/_submit`,
        })
        .pipe(
          map(() => actions.submitJobSuccess(payload)),
          catchError((error) => {
            const err = error?.response?.errors || {};
            const response = Object.keys(err).reduce((acc, curr) => {
              return [...acc, ...err[curr]];
            }, [] as string[]);
            if (response.length === 0)
              response.push("Error while submitting job");
            return of(actions.submitJobError(response));
          })
        )
    )
  );

export const refetchJobDetails: Epic<ActionType<
  typeof actions.fetchJobDetails
>> = (action$) =>
  action$.pipe(
    filter(isOfType(SUBMIT_JOB_SUCCESS)),
    switchMap(({ payload }) => of(actions.fetchJobDetails(payload)))
  );

export const loadMappingTemplateEpic: Epic<
  | ActionType<typeof actions.loadMappingTemplateSuccess>
  | ActionType<typeof actions.loadMappingTemplateError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(LOAD_MAPPING_TEMPLATE)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/mappingTemplates/${payload}`,
        })
        .pipe(
          map(({ response }) =>
            response.mappings
              ? actions.loadMappingTemplateSuccess(response)
              : actions.loadMappingTemplateError()
          ),
          catchError(() => of(actions.loadMappingTemplateError()))
        )
    )
  );

export const loadMappingEnrichmentTemplateEpic: Epic<
  | ActionType<typeof actions.loadMappingEnrichmentTemplateSuccess>
  | ActionType<typeof actions.loadMappingEnrichmentTemplateError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(LOAD_MAPPING_ENRICHMENT_TEMPLATE)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/enrichmentTemplates/${payload}`,
        })
        .pipe(
          map(({ response }) =>
            response.enrichmentTemplateMappings
              ? actions.loadMappingEnrichmentTemplateSuccess(response)
              : actions.loadMappingEnrichmentTemplateError()
          ),
          catchError(() => of(actions.loadMappingEnrichmentTemplateError()))
        )
    )
  );

export const fetchJobSummaryEpic: Epic<
  | ActionType<typeof actions.fetchJobSummarySuccess>
  | ActionType<typeof actions.fetchJobSummaryError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_SUMMARY)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/summary`,
        })
        .pipe(
          map(({ response }) => actions.fetchJobSummarySuccess(response)),
          catchError(() => of(actions.fetchJobSummaryError()))
        )
    )
  );

export const confirmSelectionEpic: Epic<
  | ActionType<typeof actions.confirmSelectionSuccess>
  | ActionType<typeof actions.confirmSelectionError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(CONFIRM_SELECTION)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/_enrich`,
        })
        .pipe(
          map(() => actions.confirmSelectionSuccess(payload)),
          catchError(() => of(actions.confirmSelectionError()))
        );
    })
  );

export const fetchEnrichedFileEpic: Epic<
  | ActionType<typeof actions.fetchEnrichedJobFileSuccess>
  | ActionType<typeof actions.fetchEnrichedJobFileError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ENRICHED_FILE)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.id}/_enrichedFile?fileType=${payload.type}`,
        })
        .pipe(
          map(({ response }) => {
            if (response.filePath) {
              window.open(response.filePath, "_blank");
              return actions.fetchEnrichedJobFileSuccess(response);
            }
            return actions.fetchEnrichedJobFileError();
          }),
          catchError(() => of(actions.fetchEnrichedJobFileError()))
        )
    )
  );

export const fetchSelectedWidgetsEpic: Epic<
  | ActionType<typeof actions.fetchSelectedWidgetsSuccess>
  | ActionType<typeof actions.fetchSelectedWidgetsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_SELECTED_WIDGETS)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/enrichments`,
        })
        .pipe(
          map(({ response }) => actions.fetchSelectedWidgetsSuccess(response)),
          catchError(() => of(actions.fetchSelectedWidgetsError()))
        )
    )
  );

export const fetchJobEnrichmentSpendEstimateEpic: Epic<
  | ActionType<typeof actions.fetchJobEnrichmentSpendEstimateSuccess>
  | ActionType<typeof actions.fetchJobEnrichmentSpendEstimateError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url:
            payload.companyAppendOption !== null
              ? `${config.DATA_CLEANING_API_URL}/jobs/${payload.id}/enrichments/_spendEstimate?EnrichmentSettings.Headquarters=${payload.companyAppendOption}`
              : `${config.DATA_CLEANING_API_URL}/jobs/${payload.id}/enrichments/_spendEstimate`,
        })
        .pipe(
          map(({ response }) =>
            actions.fetchJobEnrichmentSpendEstimateSuccess(response)
          ),
          catchError(() => of(actions.fetchJobEnrichmentSpendEstimateError()))
        )
    )
  );

export const saveEnrichmentsEpic: Epic<
  | ActionType<typeof actions.saveEnrichmentsSuccess>
  | ActionType<typeof actions.fetchJobEnrichmentSpendEstimate>
  | ActionType<typeof actions.saveEnrichmentsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SAVE_ENRICHMENTS)),
    switchMap(({ payload }) => {
      const id = payload.id;
      return api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${id}/enrichments`,
          body: {
            enrichments: payload.enrichments.enrichments,
            creditType: payload.creditType,
            algorithmBanding: payload.algorithmBanding,
          },
        })
        .pipe(
          switchMap(() => {
            return of(
              actions.saveEnrichmentsSuccess(),
              actions.fetchJobEnrichmentSpendEstimate({
                id: id,
                companyAppendOption: payload.headQuarters,
              })
            );
          }),
          catchError(() => of(actions.saveEnrichmentsError()))
        );
    })
  );

export const fetchManualMatchStatusEpic: Epic<
  | ActionType<typeof actions.fetchManualMatchStatusSuccess>
  | ActionType<typeof actions.fetchManualMatchStatusError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(
      isOfType([
        FETCH_MANUALMATCH_STATUS,
        MANUAL_MATCH_RECORD_SUCCESS,
        UNMATCH_RECORD_SUCCESS,
        RE_AGGREGATE_JOB_SUCCESS,
      ])
    ),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobid}/iseligibleforjobreaggregation`,
          body: {},
        })
        .pipe(
          map(({ response }) =>
            actions.fetchManualMatchStatusSuccess(response)
          ),
          catchError(() => of(actions.fetchManualMatchStatusError()))
        );
    })
  );

export const reAggregateJobEpic: Epic<
  | ActionType<typeof actions.reAggregateJobSuccess>
  | ActionType<typeof actions.reAggregateJobError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(RE_AGGREGATE_JOB)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload}/reaggregatejob`,
          body: {},
        })
        .pipe(
          map(() => actions.reAggregateJobSuccess({ jobid: payload })),
          catchError(() => of(actions.reAggregateJobError()))
        );
    })
  );

export const fetchHealthCheckPdfFileEpic: Epic<
  | ActionType<typeof actions.fetchHealthCheckPdfSuccess>
  | ActionType<typeof actions.fetchHealthCheckPdfError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_HEALTH_CHECK_PDF)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${
            payload.id
          }/pdf?${queryString.stringify({ countryCode: payload.countryCode })}`,
        })
        .pipe(
          map(({ response }) => {
            if (response.filePath) {
              window.open(response.filePath, "_blank");
              return actions.fetchHealthCheckPdfSuccess(response);
            }
            return actions.fetchHealthCheckPdfError();
          }),
          catchError((error) => {
            if (error.status === 404 && payload.countryCode) {
              window.open(
                `${window.location.origin}/pdf/healthcheck?id=${payload.id}&countryCode=${payload.countryCode}&referrer`,
                "_blank",
                "noreferrer"
              );
              return EMPTY;
            }
            return of(actions.fetchHealthCheckPdfError());
          })
        );
    })
  );

export const archiveJobsEpic: Epic<
  | ActionType<typeof actions.archiveJobSuccess>
  | ActionType<typeof actions.archiveJobError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(ARCHIVE_JOB)),
    switchMap(({ payload }) => {
      const { id } = payload;
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${id}/_archive`,
        })
        .pipe(
          map(() => {
            return actions.archiveJobSuccess();
          }),
          catchError(() => of(actions.archiveJobError()))
        );
    })
  );

export const assignMasterTemplateEpic: Epic<ActionType<
  typeof actions.updateOneStepStatus
>> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(ASSIGN_MASTER_TEMPLATE)),
    switchMap(({ payload }) => {
      const { jobId, masterTemplateId } = payload;
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${jobId}/assignmastertemplate`,
          body: {
            masterTemplateId: masterTemplateId,
          },
        })
        .pipe(
          map(() => {
            return actions.updateOneStepStatus({
              status: LoadingEnum.assignedMasterTemplate,
              errorMsg: "",
            });
          }),
          catchError(() =>
            of(
              actions.updateOneStepStatus({
                status: LoadingEnum.assigningMasterTemplateError,
                errorMsg: "savingTemplateDetailsError",
              })
            )
          )
        );
    })
  );

export const validateJobMappingsEpic: Epic<ActionType<
  typeof actions.updateOneStepStatus
>> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(VALIDATE_JOB_MAPPINGS)),
    switchMap(({ payload }) => {
      const { jobId } = payload;
      return api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${jobId}/validatemappings`,
        })
        .pipe(
          map(() => {
            return actions.updateOneStepStatus({
              status: LoadingEnum.validated,
              errorMsg: "",
            });
          }),
          catchError(() =>
            of(
              actions.updateOneStepStatus({
                status: LoadingEnum.validateError,
                errorMsg: "validateJobMappingsError",
              })
            )
          )
        );
    })
  );

export const updateJobEnrichmentSettingsEpic: Epic<
  | ActionType<typeof actions.confirmSelection>
  | ActionType<typeof actions.updateJobEnrichmentSettingsError>
  | ActionType<typeof actions.fetchJobEnrichmentSpendEstimate>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(UPADATE_JOB_ENRICHMENT_SETTINGS)),
    switchMap(({ payload }) => {
      const { id, body } = payload;
      return api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${id}/EnrichmentSettings`,
          body: {
            EnrichmentSettings: body,
          },
        })
        .pipe(
          switchMap(() => {
            return of(actions.confirmSelection(id));
          }),
          catchError(() => of(actions.updateJobEnrichmentSettingsError()))
        );
    })
  );

export const submitJobEnrichReasonEpic: Epic<
  | ActionType<typeof actions.submitJobEnrichReasonSuccess>
  | ActionType<typeof actions.submitJobEnrichReasonError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SUBMIT_JOB_ENRICH_REASON)),
    switchMap(({ payload }) => {
      const { id, reasonId } = payload;
      return api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${id}/Reason`,
          body: {
            reasonId: reasonId,
          },
        })
        .pipe(
          switchMap(() => {
            return of(actions.submitJobEnrichReasonSuccess());
          }),
          catchError(() => of(actions.submitJobEnrichReasonError()))
        );
    })
  );

export const fetchJobRecordsEpic: Epic<
  | ActionType<typeof actions.fetchJobRecordSuccess>
  | ActionType<typeof actions.fetchJobRecordError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(
      isOfType([
        FETCH_JOB_RECORDS,
        MANUAL_MATCH_RECORD_SUCCESS,
        UNMATCH_RECORD_SUCCESS,
      ])
    ),
    switchMap(({ payload }) => {
      const queryParams = getNonEmptyQueryParams({
        ...payload,
      });
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobid}/records?${queryParams}`,
        })
        .pipe(
          map(({ response, xhr }) => {
            const count = parseInt(
              xhr.getResponseHeader("X-Total-Count") || "0",
              10
            );
            return actions.fetchJobRecordSuccess(response, count);
          }),
          catchError(() => of(actions.fetchJobRecordError()))
        );
    })
  );

export const fetchCompanyRecordsEpic: Epic<
  | ActionType<typeof actions.fetchCompanyRecordSuccess>
  | ActionType<typeof actions.fetchCompanyRecordError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_COMPANY_RECORDS)),
    switchMap(({ payload }) => {
      const updatedPayload = frameCompanySearchPayload(payload);
      const queryParams = getNonEmptyQueryParams({
        ...updatedPayload,
      });
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/Metadata/SearchCompanies?${queryParams}`,
        })
        .pipe(
          map(({ response, xhr }) => {
            const count = parseInt(
              xhr.getResponseHeader("X-Total-Count") || "0",
              10
            );
            return actions.fetchCompanyRecordSuccess(response, count);
          }),
          catchError(() => of(actions.fetchCompanyRecordError()))
        );
    })
  );

export const manualMatchRecordEpic: Epic<
  | ActionType<typeof actions.manualMatchRecordSuccess>
  | ActionType<typeof actions.manualMatchRecordError>
  | ActionType<typeof actions.fetchManualMatchStatus>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(MANUAL_MATCH_RECORD)),
    switchMap(({ payload }) => {
      const { id, referenceNumber, body } = payload;
      return api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${id}/ReferenceNumber/${referenceNumber}`,
          body: {
            manualMatchRecord: body,
          },
        })
        .pipe(
          switchMap(() => {
            const jobRecordPayload: JobRecordPayload = {
              jobid: payload.id,
              _limit: payload.limit,
              _page: payload.page,
              MatchingStatus:
                payload.status === MatchingStatus.Unmatched
                  ? [MatchingStatus.Unmatched]
                  : [MatchingStatus.ManuallyMatched, MatchingStatus.Matched],
            };
            return of(actions.manualMatchRecordSuccess(jobRecordPayload));
          }),
          catchError(() => of(actions.manualMatchRecordError()))
        );
    })
  );

export const updateJobContactDetailsEpic: Epic<
  | ActionType<typeof actions.updateJobContactDetailsSuccess>
  | ActionType<typeof actions.updateJobContactDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType([UPDATE_JOB_CONTACT])),
    switchMap(({ payload }) =>
      api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/Jobs/${payload.jobId}/_updateJobDetails`,
          body: {
            jobName: payload.jobName,
            contactId: payload.contactId,
            person: payload.person,
            telephone: payload.telephone,
            email: payload.email,
          },
        })
        .pipe(
          switchMap((response) =>
            of(actions.updateJobContactDetailsSuccess(response.response))
          ),
          catchError((error) => {
            return of(
              actions.updateJobContactDetailsError(
                error.status === 403 ? "Forbidden" : error.response.type
              )
            );
          })
        )
    )
  );

export const unMatchRecordEpic: Epic<
  | ActionType<typeof actions.unMatchRecordSuccess>
  | ActionType<typeof actions.unMatchRecordError>
  | ActionType<typeof actions.fetchManualMatchStatus>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(UNMATCH_RECORD)),
    switchMap(({ payload }) => {
      const { id, referenceNumber } = payload;
      return api
        .request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${id}/ReferenceNumber/${referenceNumber}/UnMatch`,
        })
        .pipe(
          switchMap(() => {
            const jobRecordPayload: JobRecordPayload = {
              jobid: payload.id,
              _limit: payload.limit,
              _page: payload.page,
              MatchingStatus:
                payload.status === MatchingStatus.Unmatched
                  ? [MatchingStatus.Unmatched]
                  : [MatchingStatus.ManuallyMatched, MatchingStatus.Matched],
            };
            return of(actions.unMatchRecordSuccess(jobRecordPayload));
          }),
          catchError(() => of(actions.unMatchRecordError()))
        );
    })
  );

export const fetchAvailableCreditTypesEpic: Epic<
  | ActionType<typeof actions.fetchAvailableCreditTypesSuccess>
  | ActionType<typeof actions.fetchAvailableCreditTypesError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_AVAILABLE_CREDIT_TYPES)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/users/${payload}/services`,
        })
        .pipe(
          map(({ response }) =>
            actions.fetchAvailableCreditTypesSuccess(response)
          ),
          catchError(() => of(actions.fetchAvailableCreditTypesError()))
        )
    )
  );

const frameCompanySearchPayload = (payload: CompanyRecordPayload) => {
  return isNameRegNumberSearch(payload)
    ? {
        ...payload,
        RegNumber: payload.name_like,
        name_like: "",
        Status: "",
        Type: "",
        OfficeType: "",
        RegNo: "",
        RegStatus: "",
        country_ne: "",
        address_city: "",
        address_street: "",
        address_postCode: "",
        address_province: "",
        PhoneNumber: "",
        websites_like: "",
        IncTradingAddresses: "",
        IncTradingNames: "",
        VatNo: "",
        Id: "",
        Language: "",
        TradeName: "",
        GroupResults: "",
        fileNo: "",
        IndustryCode: "",
        DisplayBranches: "",
      }
    : (has(payload, "RegNumber") && !!payload.RegNumber) ||
      (has(payload, "RegNo") && !!payload.RegNo)
    ? {
        ...payload,
        RegNumber: payload.RegNumber ? payload.RegNumber : payload.RegNo,
        name_like: "",
        Status: "",
        Type: "",
        OfficeType: "",
        RegNo: "",
        RegStatus: "",
        country_ne: "",
        address_city: "",
        address_street: "",
        address_postCode: "",
        address_province: "",
        PhoneNumber: "",
        websites_like: "",
        IncTradingAddresses: "",
        IncTradingNames: "",
        VatNo: "",
        Id: "",
        Language: "",
        TradeName: "",
        GroupResults: "",
        fileNo: "",
        IndustryCode: "",
        DisplayBranches: "",
      }
    : isSafeNumber(payload.name_like)
    ? {
        ...payload,
        name_like: payload.name_like,
        Status: "",
        Type: "",
        OfficeType: "",
        RegNo: "",
        RegNumber: "",
        RegStatus: "",
        country_ne: "",
        address_city: "",
        address_street: "",
        address_postCode: "",
        address_province: "",
        PhoneNumber: "",
        websites_like: "",
        IncTradingAddresses: "",
        IncTradingNames: "",
        VatNo: "",
        Id: "",
        Language: "",
        TradeName: "",
        GroupResults: "",
        fileNo: "",
        IndustryCode: "",
        DisplayBranches: "",
      }
    : payload;
};
