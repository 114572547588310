import React from "react";
import { Switch, Route } from "react-router-dom";
import AppLayout from "./containers/AppLayout";
import { useSelector } from "react-redux";
import { getIsSignedIn, getUserStoreStatus } from "./store/user/selectors";
import SignInLoading from "./pages/SignInLoading";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/en-ca";
import "moment/locale/fr";
import "@progress/kendo-theme-default/dist/all.css";
import { createBrowserHistory } from "history";
import config, { updateConfig } from "./config";
import PdfViewerRouter from "./PdfViewerRouter";
import { Status } from "./store/types";
import { initTracking, ITrackingConfig } from "@creditsafe/tracking";
import { SplitTreatments } from "@splitsoftware/splitio-react";

const reactPlugin = new ReactPlugin();

const cloudFeatureName = "UseAzure_DataCleaning";

initTracking({
  instrumentationKey: config.INSTRUMENTATION_KEY,
  extensions: [reactPlugin],
  extensionConfig: {
    [reactPlugin.identifier]: {
      history: createBrowserHistory({ basename: "" }),
    },
  },
} as ITrackingConfig);

const locales: {
  [key: string]: string;
} = {
  "en-gb": "en-gb",
  "en-us": "en-us",
  "en-ca": "en-ca",
  fr: "fr",
};

const App: React.FC = () => {
  const isSignedIn = useSelector(getIsSignedIn);
  const userStoreStatus = useSelector(getUserStoreStatus);
  const userLocale = window.navigator.language.toLocaleLowerCase();
  moment.locale(
    locales[userLocale] || locales[userLocale.substring(0, 2)] || "en-ca"
  );

  if (!isSignedIn || userStoreStatus === Status.loading) {
    return <SignInLoading />;
  }

  return (
    <SplitTreatments names={[cloudFeatureName]}>
      {({ treatments, isReady }) => {
        if (isReady && treatments[cloudFeatureName].treatment === "on") {
          updateConfig(true);
        }
        return (
          <Switch>
            {
              <Route path={"/pdf"}>
                <PdfViewerRouter />
              </Route>
            }
            <Route component={AppLayout} />
          </Switch>
        );
      }}
    </SplitTreatments>
  );
};

export default withAITracking(reactPlugin, App);
