import moment from "moment";
import queryString from "query-string";
import { get, isNil, omitBy, toUpper } from "lodash";
import { History } from "history";
import numbro from "numbro";
import { TFunction } from "i18next";
import i18n from "config/i18n-client";
import { CompanyRecordPayload } from "store/jobs/types";
import {
  CreditsByCountry,
  CreditsByCreditType,
  ServiceLine,
} from "store/user/types";

export const noop = (): void => {
  /* Do Nothing */
};

export const formatBackOfficeName = ({
  firstName = "",
  surname = "",
}: {
  firstName?: string;
  surname?: string;
}) => `${firstName} ${surname}`.trim();

export const getDateFormat = (language: string) => {
  const Dates: { [key: string]: string } = {
    "en-US": "MM/dd/yyyy",
    "en-GB": "dd/MM/yyyy",
    "fr-BE": "dd/MM/yyyy",
    "nl-BE": "dd/MM/yyyy",
    "de-DE": "dd.MM.yyyy",
    "en-CA": "dd/MM/yyyy",
    "nl-NL": "dd-MM-yyyy",
    "nb-NO": "dd.MM.yyyy",
    "fr-CA": "yyyy-MM-dd",
    "fr-FR": "dd/MM/yyyy",
    "ja-JP": "yyyy/MM/dd",
    "en-IE": "dd/MM/yyyy",
    "it-IT": "dd/MM/yyyy",
    "da-DK": "dd-MM-yyyy",
  };

  return Dates[language] || "dd/mm/yyyy";
};

export const formatDateWithlocale = (dateString: string) => {
  if (!dateString) {
    return "-";
  }
  const dateMoment = moment(dateString).local();
  return dateMoment.isValid()
    ? moment(dateString).format(
        getDateFormat(i18n.language || "en-GB").toUpperCase()
      )
    : dateString;
};

export const localiseDateString = (
  dateString: string,
  returnPrecision?: "date" | "time" | "datetime"
) => {
  if (!dateString) {
    return "-";
  }
  let precision: string;
  switch (returnPrecision) {
    case "date":
      precision = "L";
      break;
    case "time":
      precision = "LTS";
      break;
    default:
      precision = "L LTS";
      break;
  }
  const dateMoment = moment.utc(dateString).local();
  return dateMoment.isValid() ? dateMoment.format(precision) : dateString;
};

export const percentage = (x: number, digits: number) =>
  !isNaN(x) && isFinite(x)
    ? `${digits ? Math.abs(x).toFixed(digits) : x}%`
    : "";

export const getNonEmptyQueryParams = (
  payload: Record<string, unknown>
): string =>
  queryString.stringify(omitBy(payload, (x) => isNil(x) || x === ""));

export const getUrlParameters = (history: History<History.PoorMansUnknown>) =>
  queryString.parseUrl(history.location.search).query;

export const setUrlParameters = (
  history: History<History.PoorMansUnknown>,
  parameters: {
    [key: string]: number | string | string[] | null | undefined | boolean;
  }
) => {
  const newParameters = queryString.stringify(
    omitBy(parameters, (x) => isNil(x) || x === "")
  );
  history.push(`${history.location.pathname}?${newParameters}`);
};

export const appendUrlParameters = (
  updatedParams: { [key: string]: number | string },
  history: History<History.PoorMansUnknown>
) => {
  const exisitingParams = getUrlParameters(history);
  setUrlParameters(history, { ...exisitingParams, ...updatedParams });
};

export const displayValueOrSeparator = (value: string | undefined) =>
  value ? value : "-";

export const supportedCountryCodes = [
  "AT",
  "BE",
  "CA",
  "CH",
  "DE",
  "DK",
  "ES",
  "FI",
  "FR",
  "IE",
  "IT",
  "JP",
  "LI",
  "LU",
  "MX",
  "NL",
  "NO",
  "SE",
  "UK",
  "US",
  "PL",
  "GR",
  "PT",
];

export const getDropdownCountries = (t: TFunction) => {
  return {
    AT: t("global:countries.AT"),
    BE: t("global:countries.BE"),
    CA: t("global:countries.CA"),
    DE: t("global:countries.DE"),
    FR: t("global:countries.FR"),
    IE: t("global:countries.IE"),
    IT: t("global:countries.IT"),
    JP: t("global:countries.JP"),
    NL: t("global:countries.NL"),
    NO: t("global:countries.NO"),
    SE: t("global:countries.SE"),
    GB: t("global:countries.GB"),
    US: t("global:countries.US"),
  };
};

export const getSettingTabDropdownCountries = (t: TFunction) => {
  return {
    AT: t("global:countries.AT"),
    BE: t("global:countries.BE"),
    CA: t("global:countries.CA"),
    CH: t("global:countries.CH"),
    DE: t("global:countries.DE"),
    DK: t("global:countries.DK"),
    ES: t("global:countries.ES"),
    FI: t("global:countries.FI"),
    FR: t("global:countries.FR"),
    IE: t("global:countries.IE"),
    IT: t("global:countries.IT"),
    JP: t("global:countries.JP"),
    LI: t("global:countries.LI"),
    LU: t("global:countries.LU"),
    MX: t("global:countries.MX"),
    NL: t("global:countries.NL"),
    NO: t("global:countries.NO"),
    SE: t("global:countries.SE"),
    GB: t("global:countries.GB"),
    US: t("global:countries.US"),
    PL: t("global:countries.PL"),
    GR: t("global:countries.GR"),
    PT: t("global:countries.PT"),
  };
};

export const formatNumber = (number: number) => {
  return numbro(number).format({
    ...numbro.languageData().formats["fullWithNoDecimals"],
    output: "number",
  });
};

export const formatNumberThousandSeparator = (
  value: number | undefined,
  locale?: string
): string => {
  numbro.setLanguage(locale || i18n.language || "en-GB");
  const result =
    value !== undefined && !isNaN(value)
      ? numbro(value).format({
          thousandSeparated: true,
        })
      : "";
  return result;
};

export const localiseThousandSeperator = (value: string, locale: string) => {
  if (!value) {
    return value;
  }
  let thousandSeperatorValue: string;
  switch (locale) {
    case "de-DE":
    case "fr-FR":
    case "it-IT":
    case "nl-NL":
      thousandSeperatorValue = value.replace(",", ".");
      break;
    case "sv-SE":
      thousandSeperatorValue = value.replace(",", " ");
      break;
    default:
      thousandSeperatorValue = value;
      break;
  }
  return thousandSeperatorValue;
};

const searchValidCountryCodes = [
  "US",
  "BE",
  "FR",
  "IE",
  "IT",
  "NL",
  "NO",
  "SE",
  "UK",
  "DE",
  "AF",
  "AT",
  "KH",
  "CA",
  "DK",
  "FI",
  "LA",
  "LI",
  "LU",
  "MY",
  "MM",
  "ES",
  "CH",
  "TH",
  "VN",
  "WW",
  "JP",
  "BR",
  "MX",
];

export const isSafeNumber = (value: string): boolean => {
  if (isNil(value)) {
    return false;
  }

  const trimmedTerm = value.trim();
  const countryCode = trimmedTerm.substring(0, 2).toUpperCase();

  if (!searchValidCountryCodes.includes(countryCode)) {
    return false;
  }
  const regex =
    countryCode === "BE"
      ? new RegExp(`^${countryCode}\\d{8}$`, "i")
      : new RegExp(`^${countryCode}\\d{8,}$`, "i");

  const result = regex.test(`${trimmedTerm}`);
  return result;
};

const ukCompanyNumberCodesPrefix = [
  "AC",
  "ZC",
  "FC",
  "GE",
  "LP",
  "OC",
  "SE",
  "SA",
  "SZ",
  "SF",
  "GS",
  "SL",
  "SO",
  "SC",
  "NF",
  "GN",
  "NL",
  "NC",
  "R0",
  "NI",
  "IP",
  "SP",
  "IC",
  "SI",
  "NP",
  "RC",
  "SR",
  "NO",
  "CE",
  "CS",
  "NA",
  "NR",
  "NV",
  "PC",
  "RS",
  "SG",
];

const UK_REG_NO_IDENTIFIER_PREFIX = new RegExp(
  `^(${ukCompanyNumberCodesPrefix.join("|")})\\d+$`
);

const IE_REG_NUMBER = /^(IE)\d{6}$/;
const IT_REG_NUMBER = /^[A-Z]{2}[1-9][0-9]{0,7}$/;
const NO_REG_NUMBER = /^[8,9]{1}\d{8}$/;

export const isNameRegNumberSearch = (payload: CompanyRecordPayload) => {
  const name = toUpper(get(payload, "name_like"));
  const country = get(payload, "Country");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entityRegNumbers: any = {
    GB: UK_REG_NO_IDENTIFIER_PREFIX.test(name),
    UK: UK_REG_NO_IDENTIFIER_PREFIX.test(name),
    IE: IE_REG_NUMBER.test(name),
    IT: IT_REG_NUMBER.test(name),
    NO: NO_REG_NUMBER.test(name && name.replace(/ /g, "")),
  };

  const basicRegNoChecking = ["IT", "NO", "DK"].includes(country)
    ? false
    : /^\d+$/.test(name);

  if (country === "IT" && name.trim().substring(0, 2).toUpperCase() === "IT") {
    return false;
  }

  return entityRegNumbers[country] || basicRegNoChecking;
};

export const getService = (services: ServiceLine[], id: number) => {
  const now = moment.utc();
  const matches = services.filter((x) => x.serviceId === id);

  if (matches.length === 0) {
    return null;
  }

  return matches.reduce((acc, curr) => {
    if (
      now.isSameOrBefore(moment.utc(curr.expireDate)) &&
      now.isSameOrAfter(moment.utc(curr.startDate))
    ) {
      return {
        ...acc,
        country: "00",
        credits: (acc.credits || 0) + (curr.paid - curr.used),
        expriryDate:
          !acc.expriryDate || acc.expriryDate < curr.expireDate
            ? curr.expireDate
            : acc.expriryDate,
        paid: (acc.paid || 0) + curr.paid,
        used: (acc.used || 0) + curr.used,
        isUnlimited: acc.isUnlimited || curr.isUnlimited,
      };
    }

    return acc;
  }, {} as CreditsByCountry);
};

export const getServiceByContry = (services: ServiceLine[], id: number) => {
  const now = moment.utc();
  const countries = services
    .filter((x) => x.parentServiceId === id)
    .sort((a, b) => {
      return a.pseudoId > b.pseudoId ? 1 : -1;
    })
    .reduce((acc, curr) => {
      const countryCode = curr.pseudoId.substring(
        curr.pseudoId.lastIndexOf("_") + 1
      );
      const creditType = curr.pseudoId
        .replace("data_clean_", "")
        .replace(`_${countryCode}`, "");
      return [
        ...acc,
        {
          countryCode: countryCode,
          creditType: (countryCode === creditType
            ? "Standard"
            : creditType
          ).toUpperCase(),
          credits:
            now.isSameOrBefore(moment.utc(curr.expireDate)) &&
            now.isSameOrAfter(moment.utc(curr.startDate))
              ? curr.paid - curr.used
              : 0,
          expriryDate: curr.expireDate,
          isUnlimited: curr.isUnlimited,
          used: curr.used,
          paid: curr.paid,
        },
      ];
    }, [] as CreditsByCountry[]);

  return countries
    .sort((a, b) => {
      return a.creditType > b.creditType && a.countryCode < b.countryCode
        ? 1
        : -1;
    })
    .reduce((acc, curr) => {
      if (acc.some((c) => c.creditType === curr.creditType)) {
        acc
          .find((c) => c.creditType === curr.creditType)
          ?.remainingCreditsForCountryByCreditType.push(curr);
      } else {
        acc.push({
          creditType: curr.creditType,
          remainingCreditsForCountryByCreditType: [curr],
        });
      }
      return [...acc];
    }, [] as CreditsByCreditType[]);
};
