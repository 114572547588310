import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink as RouterLink } from "react-router-dom";
import AppBar from "../../components/AppBar";
import Routes from "../Routes/Routes";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import HomeIcon from "@material-ui/icons/Home";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import { SiteUnavailable } from "@creditsafe/portfolios-shared-components";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import { fireViewStartCustomEvent } from "../../analyticsHelpers";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerId,
  getIsInternalUser,
  getIsUserAllowedForSearchPanel,
  getUserCountry,
  getUserStoreStatus,
} from "../../store/user/selectors";
import { useTranslation } from "react-i18next";
import { Status } from "../../store/types";
import { fetchAppVersion } from "store/app/actions";
import { getRequiresAppUpdate } from "store/app/selectors";
import GenericDialog from "components/GenericDialog";
import siteUnavailableImage from "../../assets/under-construction.png";
import config from "../../config";
import { SettingsOutlined } from "@material-ui/icons";
import { faWrench } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: theme.spacing(2),
    },
    drawer: {
      flexShrink: 0,
    },
    navMenu: { padding: theme.spacing(0) },
    content: {
      flexGrow: 1,
      width: "calc(100vw - 275px)",
    },
    routes: {
      color: theme.palette.grey[800],
    },
    iconNamePair: {
      display: "flex",
      alignItems: "center",
      "&>svg": {
        margin: theme.spacing(0.5, 3, 0.5, 0),
      },
    },
  })
);

const featureName = "Maintenance_Datacleaning";

const AppLayout: React.FC = () => {
  const { t } = useTranslation("global");
  const isInternalUser = useSelector(getIsInternalUser);
  const customerId = useSelector(getCustomerId);
  const userStoreStatus = useSelector(getUserStoreStatus);
  const userCountry = useSelector(getUserCountry);
  const location = useLocation();
  const requiresAppUpdate = useSelector(getRequiresAppUpdate);
  const dispatch = useDispatch();

  useEffect(() => {
    const freshWorkTag = document.getElementById("freshworks-container");
    if (freshWorkTag) {
      if (["UK", "GB", "IE"].includes(userCountry || "")) {
        freshWorkTag.style.display = "block";
      } else freshWorkTag.style.display = "none";
    }
  }, [userCountry]);

  useEffect(() => {
    fireViewStartCustomEvent({ detail: { mbox: "target-global-mbox" } });
  }, [location.pathname]);

  useEffect(() => {
    if (window.environment.name !== "local") {
      dispatch(fetchAppVersion());
    }
  }, [customerId, dispatch]);
  const isUserAllowedForSearchPanel = useSelector(
    getIsUserAllowedForSearchPanel
  );

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const routes = [
    { title: t("menubar.home"), to: "/", exact: true, icon: <HomeIcon /> },
    {
      title: t("menubar.newFile"),
      to: "/new",
      icon: <InsertDriveFileOutlinedIcon />,
    },
    {
      title: t("menubar.jobs"),
      to: "/jobs",
      icon: <WorkOutlineOutlinedIcon />,
    },
  ];
  if (isInternalUser && isUserAllowedForSearchPanel)
    routes.push({
      title: t("menubar.admin"),
      to: "/admin",
      icon: <SettingsOutlined />,
    });

  routes.push({
    title: t("menubar.settings"),
    to: "/settings",
    icon: <FontAwesomeIcon icon={faWrench} size="lg" />,
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <Toolbar />
      <List className={classes.navMenu}>
        {routes.map((text) => (
          <ListItem
            className={classes.routes}
            component={RouterLink}
            to={text.to}
            key={text.title}
            activeClassName="Mui-selected"
            exact={text.exact}
            onClick={handleDrawerToggle}
          >
            <ListItemText>
              <div className={classes.iconNamePair}>
                {text.icon}
                {text.title}
              </div>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );

  const siteUnavailable = (
    title: string,
    description: string,
    button: string
  ) => (
    <SiteUnavailable
      title={title}
      description={description}
      button={button}
      image={siteUnavailableImage}
      action={config.UPP_APP_URL}
    />
  );

  const AppContent: React.FC = () => {
    switch (userStoreStatus) {
      case Status.loaded:
        return (
          <SplitTreatments names={[featureName]}>
            {({ treatments, isReady }) => {
              if (isReady && treatments[featureName].treatment === "on") {
                return siteUnavailable(
                  t("siteUnavailable.title"),
                  t("siteUnavailable.description"),
                  t("siteUnavailable.homeBtn")
                );
              }
              return (
                <>
                  <Hidden smUp implementation="js">
                    <Drawer
                      className={classes.drawer}
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      ModalProps={{
                        keepMounted: true,
                      }}
                    >
                      {drawer}
                    </Drawer>
                  </Hidden>

                  <Hidden xsDown implementation="js">
                    <Drawer className={classes.drawer} variant="permanent" open>
                      {drawer}
                    </Drawer>
                  </Hidden>

                  <main className={classes.content}>
                    <Toolbar />
                    <Routes />
                  </main>
                </>
              );
            }}
          </SplitTreatments>
        );
      case Status.errorLoading:
      default:
        return siteUnavailable(
          t("errorLoadingSite.title"),
          t("errorLoadingSite.description"),
          t("errorLoadingSite.homeBtn")
        );
    }
  };

  return (
    <div className={classes.root}>
      <AppBar onToggle={handleDrawerToggle} />
      <AppContent />
      <GenericDialog
        title={t("appUpdate.title")}
        body={t("appUpdate.description")}
        open={requiresAppUpdate}
        onClose={() => window.location.reload()}
        hideCloseIcon={true}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        actionLabel={t("appUpdate.refresh")}
      />
    </div>
  );
};

export default AppLayout;
