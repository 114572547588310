import { JobStatus } from "pages/Jobs/Status";
import { Status } from "store/types";
import { RootState } from "../rootReducer";

export const getJobsState = (store: RootState) => store.jobs;

export const getJobHeaderClass = (store: RootState) =>
  getJobsState(store).headerClass;

export const getJobsList = (store: RootState) => getJobsState(store).allIds;

export const getJobById = (store: RootState, id: string) =>
  getJobsState(store).byId[id];

export const getJobs = (store: RootState) =>
  getJobsList(store).map((id) => getJobById(store, id));

export const getTotalCount = (store: RootState) =>
  getJobsState(store).totalCount;

export const getAggregates = (store: RootState) =>
  getJobsState(store).aggregates;

export const getAggregateData = (store: RootState) =>
  getJobsState(store).aggregates?.data;

export const getAggregatesStoreStatus = (store: RootState) =>
  getJobsState(store).aggregates.status;

export const getSelectedJob = (store: RootState) =>
  getJobsState(store).selected?.data;

export const getSelectedJobStoreStatus = (store: RootState) =>
  getJobsState(store).selected?.status;

export const getSelectedJobStatusMsg = (store: RootState) =>
  getJobsState(store).selected?.statusMsg;

export const getSelectedJobSubmitStatusMsg = (store: RootState) =>
  getJobsState(store).selected?.submitJobErrorMsg;

export const getFileDetailsStoreStatus = (store: RootState) =>
  getSelectedJob(store)?.fileDetails?.status;

export const getJobsStoreStatus = (store: RootState) =>
  getJobsState(store).status;

export const getSelectedJobMappings = (store: RootState) =>
  getSelectedJob(store)?.mappings?.data || [];

export const getDuplicateMappings = (store: RootState) =>
  getJobsState(store).duplicateMappings;

export const getSelectedJobCountry = (store: RootState) =>
  getSelectedJobMappings(store).find((x) => x.mapping === "countryCode")?.value;

export const getMappingsStatus = (store: RootState) =>
  getSelectedJob(store)?.mappings?.status;

export const getSelectedEnrichments = (store: RootState) =>
  getSelectedJob(store)?.enrichments?.data;

export const getSelectedAlgorithmBandings = (store: RootState) =>
  getSelectedJob(store)?.enrichments?.algorithmBanding || [];

export const getEnrichmentMappingsStatus = (store: RootState) =>
  getSelectedJob(store)?.enrichments?.status;

export const getSelectedWidgets = (store: RootState) =>
  getJobsState(store).selected?.enrichments;

export const getFileDownloadStatus = (store: RootState) =>
  getJobsState(store).selected.fileDownloadStatus;

export const getSelectedJobNumRows = (store: RootState) =>
  getSelectedJob(store)?.jobSummary?.totalRows;

export const getSelectedTabCountry = (store: RootState) =>
  getJobsState(store).selected.selectedTabCountry;

export const getSelectedJobEnrichmentSpendEstimate = (store: RootState) =>
  getJobsState(store).selected?.spendEstimate;

export const getArchievedStatus = (store: RootState) =>
  getJobsState(store)?.archieve?.status;

export const hasValidMatchedCount = (store: RootState): boolean => {
  const job = getJobsState(store)?.selected;
  return (
    job.data?.status === JobStatus.jobMatchingComplete &&
    job.data?.jobSummary?.matched === 0 &&
    job.data?.jobSummary?.manualMatched === 0
  );
};

export const getJobRecords = (store: RootState) =>
  getJobsState(store)?.jobRecords;

export const getCompanyRecords = (store: RootState) =>
  getJobsState(store)?.companyRecords;

export const getManualMatchedOrUnmatchedStatus = (store: RootState) =>
  getJobsState(store)?.manualMatchOrUnmatch?.status;

export const getHasHeader = (store: RootState) => getJobsState(store).hasHeader;

export const getJobContact = (store: RootState) => getJobsState(store)?.contact;

export const getJobMonitorSavingStatus = (store: RootState) =>
  getJobsState(store)?.createMonitorStatus || Status.unset;

export const getCreateMonitorType = (store: RootState) =>
  getJobsState(store)?.createMonitorType || "";

export const getjobUpdateStatus = (store: RootState) =>
  getJobsState(store)?.jobUpdateStatus || Status.unset;

export const getEnrichmentSettings = (store: RootState) =>
  getJobsState(store)?.enrichmentSettings;

export const getEnrichmentSettingsStatus = (store: RootState) =>
  getJobsState(store)?.enrichmentSettings?.status || Status.unset;

export const getAvailableCreditTypes = (store: RootState) =>
  getJobsState(store)?.selected?.availableCreditTypes || {
    data: [],
    status: Status.loading,
  };

export const getSelectedCreditTypeWidgets = (store: RootState) =>
  getJobsState(store)?.creditTypeWidgets || [];

export const getAutoEnrichJobDetails = (store: RootState) =>
  getJobsState(store)?.autoEnrichJob;

export const getJobAutoEnrichmentData = (store: RootState) =>
  getJobsState(store)?.autoEnrichmentData;

export const getManualMatchStatus = (store: RootState) =>
  getSelectedJob(store)?.manualMatchStatus;

export const getRegeratePDFStatus = (store: RootState) =>
  getSelectedJob(store)?.reAggregateJobStatus;
