import { Normalized, Status, ExternalDataSource } from "../types";
import * as JobActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Source } from "../../pages/Jobs/Source";
import { History } from "history";
import { InnerMapping } from "../mappings/types";
import { JobStatus, LoadingEnum } from "../../pages/Jobs/Status";

export type JobsActionTypes = ActionType<typeof JobActions>;

export interface JobSummaryPayload {
  totalRows: number;
  matched: number;
  unmatched: number;
  duplicates: number;
  createdAt: string;
  modifiedAt: string;
  jobId: string;
}

export interface JobFile {
  id: string;
  sourceFilename: string;
  hasHeader: boolean;
  createdAt: string;
  modifiedAt: string;
  managingUserId: number;
  managingCustomerId: number;
  status: string;
  active: boolean;
}

export interface Job {
  archived: boolean;
  id: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  managingUserId: number;
  managingCustomerId: number;
  owningUserId: number;
  owningCustomerId: number;
  owningCompanyName: string;
  status: JobStatus;
  countryCode: string;
  portfolioId?: string;
  source: Source;
  jobSummary?: {
    totalRows: number;
    matched: number;
    manualMatched: number;
    unmatched: number;
    duplicates: number;
  };

  importDate?: string;
  hadDownload?: boolean;
  sample?: { data: string[][]; status: Status };
  summary?: { data: JobSummaryPayload; status: Status };
  fileDetails?: { data?: JobFile; status: Status };
  mappings?: {
    data: { mapping: string; value: string }[];
    status: Status;
  };
  enrichments?: {
    data: { enrichment: string }[];
    algorithmBanding?: string[];
    status: Status;
  };
  manualMatchStatus?: {
    data?: {
      reAggregateJobStatusInprogress: boolean;
      isManualMatchRecordsExists: boolean;
    };
    status: Status;
  };
  owningUserAccountStatus?: string;
  reAggregateJobStatus?: Status;
}

export interface JobRecord {
  companyId: string;
  country: string;
  orgNumber: string;
  referenceNumber: string;
  companyType: string;
  name: string;
  tradingName: string;
  aliasName: string;
  previousName1: string;
  previousName2: string;
  phone: string;
  email: string;
  address1: string;
  address2: string;
  address3: string;
  town: string;
  address5: string;
  postcode: string;
  region: string;
  statusCode: string;
  vatNumber: string;
  pattern: string;
  duplicate: true;
  portfolioId: string;
  userId: number;
  customerId: number;
  personalID: string;
  personalLimit: string;
}

export interface AggregateData {
  name: string;
  count: number;
  percentage: number;
}

export interface AggregateObject {
  name: string;
  data: AggregateData[];
  countryCode: string;
}

export type JobsState = Normalized<Job, string> & {
  aggregates: {
    data: AggregateObject[];
    status: Status;
  };
  selected: ExternalDataSource<Job>;
  archieve: {
    status: Status;
  };
  jobRecords: {
    data: JobRecord[];
    status: Status;
    totalCount: number;
  };
  companyRecords: {
    data: CompanyRecord[];
    status: Status;
    totalCount: number;
  };
  headerClass: boolean;
  duplicateMappings: string[];
  hasHeader: boolean;
  jobUpdateStatus: Status;
  manualMatchOrUnmatch: {
    status: Status;
  };
  contact?: { data?: Contact; status: Status };
  autoEnrichmentData?: {
    data?: JobAutoEnrichment;
    status: Status;
  };
  enrichmentSettings?: {
    data?: EnrichmentSettings;
    status: Status;
  };
  creditTypeWidgets?: string[];
  createMonitorStatus?: Status;
  createMonitorType?: string;
  autoEnrichJob: {
    jobId: string;
    status: LoadingEnum;
    errorMsg: string;
  };
};

export interface Contact {
  id: string;
  person: string;
  telephone: string;
  email: string;
  managingUserId: number;
  managingCustomerId: number;
  createdAt: string;
  modifiedAt: string;
}

export interface UpdateJobDetailsPayload {
  jobId: string | null;
  jobName: string;
  contactId: string | null;
  person: string;
  telephone: string;
  email: string;
}

export interface UpdateJobDetailsResponse {
  jobId: string;
  jobName: string;
  contact: Contact;
}

export interface EnrichmentSettings {
  headQuarters?: string;
  creditType?: string;
  algorithmBanding?: string[];
}

export interface CountrySpendEstimate {
  country: string;
  creditsRequired: number;
  creditsAvailable: boolean;
  matchedCompanies: number;
}

export interface JobEnrichmentSpendEstimate {
  valid: boolean;
  countryBreakdown: CountrySpendEstimate[];
}

export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_ERROR = "FETCH_JOBS_ERROR";

export const FETCH_JOB_DETAILS = "FETCH_JOB_DETAILS";
export const FETCH_JOB_DETAILS_SUCCESS = "FETCH_JOB_DETAILS_SUCCESS";
export const FETCH_JOB_DETAILS_ERROR = "FETCH_JOB_DETAILS_ERROR";

export const FETCH_FILE_DETAILS = "FETCH_FILE_DETAILS";
export const FETCH_FILE_DETAILS_SUCCESS = "FETCH_FILE_DETAILS_SUCCESS";
export const FETCH_FILE_DETAILS_ERROR = "FETCH_FILE_DETAILS_ERROR";

export const FETCH_JOB_SAMPLE = "FETCH_JOB_SAMPLE";
export const FETCH_JOB_SAMPLE_SUCCESS = "FETCH_JOB_SAMPLE_SUCCESS";
export const FETCH_JOB_SAMPLE_ERROR = "FETCH_JOB_SAMPLE_ERROR";

export const FETCH_JOB_MAPPINGS = "FETCH_JOB_MAPPINGS";

export interface JobAutoEnrichment {
  mmrPercentage: number;
  matchedPercentage: number;
  isRowLevelValidationFailed: boolean;
  availableCreditsByCountry: { country: string; credits: number }[];
  creditsRequiredByCountry: { country: string; credits: number }[];
  jobId: string;
}

export interface FetchJobSamplePayload {
  id: string;
  queryParams?: {
    _page?: number;
    _limit?: number;
  };
}

export const FETCH_JOB_MAPPING_SUCCESS = "FETCH_JOB_MAPPING_SUCCESS";
export const FETCH_JOB_MAPPING_ERROR = "FETCH_JOB_MAPPING_ERROR";

export const CREATE_JOB = "CREATE_JOB";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_ERROR = "CREATE_JOB_ERROR";
export const UNSET_JOB_ERROR = "UNSET_JOB_ERROR";

export const SUBMIT_JOB = "SUBMIT_JOB";
export const SUBMIT_JOB_SUCCESS = "SUBMIT_JOB_SUCCESS";
export const SUBMIT_JOB_ERROR = "SUBMIT_JOB_ERROR";

export interface CreateJobPayload {
  jobDetails: {
    name: string;
    owningCompanyName: string;
    owningUserId: number;
    owningCustomerId: number;
    telephone?: string;
    email: string;
    jobFile: File | undefined;
    hasHeader: boolean;
    source: Source;
  };
  history: History<History.PoorMansUnknown> | undefined;
}

export const FETCH_AGGREGATES = "FETCH_AGGREGATES";
export const FETCH_AGGREGATES_SUCCESS = "FETCH_AGGREGATES_SUCCESS";
export const FETCH_AGGREGATES_ERROR = "FETCH_AGGREGATES_ERROR";

export interface FetchAggregatesSuccessPayloadItem {
  aggregationType: string;
  aggregations: AggregateData[];
  countryCode: string;
  createdAt: string;
  modifiedAt: string;
  id: string;
  portfolioId: string;
}

export const CLEAR_POPUP_NOTIFICATION = "CLEAR_POPUP_NOTIFICATION";

export const SET_MAPPINGS = "SET_MAPPINGS";
export const SET_DUPLICATE_MAPPINGS = "SET_DUPLICATE_MAPPINGS";
export const SET_HASHEADER = "SET_HASHEADER";
export const AUTOMAP_HEADERS = "AUTOMAP_HEADERS";

export const LOAD_MAPPING_TEMPLATE = "LOAD_MAPPING_TEMPLATE";
export const LOAD_MAPPING_TEMPLATE_SUCCESS = "LOAD_MAPPING_TEMPLATE_SUCCESS";
export const LOAD_MAPPING_TEMPLATE_ERROR = "LOAD_MAPPING_TEMPLATE_ERROR";

export const LOAD_MAPPING_ENRICHMENT_TEMPLATE =
  "LOAD_MAPPING_ENRICHMENT_TEMPLATE";
export const LOAD_MAPPING_ENRICHMENT_TEMPLATE_SUCCESS =
  "LOAD_MAPPING_ENRICHMENT_TEMPLATE_SUCCESS";
export const LOAD_MAPPING_ENRICHMENT_TEMPLATE_ERROR =
  "LOAD_MAPPING_ENRICHMENT_TEMPLATE_ERROR";

export const SAVE_JOB_MAPPINGS = "SAVE_JOB_MAPPINGS";
export const SAVE_JOB_MAPPINGS_SUCCESS = "SAVE_JOB_MAPPINGS_SUCCESS";
export const SAVE_JOB_MAPPINGS_ERROR = "SAVE_JOB_MAPPINGS_ERROR";
export interface SaveJobMappingsPayload {
  mappings: InnerMapping[];
  jobId: string;
  fileId: string;
  hasHeader: boolean;
  isDraft: boolean;
}
export const DISMISS_SAVE_MESSAGE = "DISMISS_SAVE_MESSAGE";

export interface SaveTemplatePayload {
  jobId: string;
  message: string;
}
export interface SaveTemplateSuccessPayload {
  status: string;
}

export interface JobParams {
  jobName_like?: string;
  companyName_like?: string;
  customerId?: string;
  owningUserId?: string;
  userId?: string;
  status?: string;
  modifiedAt_gte?: string;
  modifiedAt_lte?: string;
  _sort?: string;
  _order?: string;
  _limit?: number;
  _page?: number;
  q?: string;
  source?: string;
  countries?: string;
  archived?: boolean;
}

export const FETCH_JOB_SUMMARY = "FETCH_JOB_SUMMARY";
export const FETCH_JOB_SUMMARY_SUCCESS = "FETCH_JOB_SUMMARY_SUCCESS";
export const FETCH_JOB_SUMMARY_ERROR = "FETCH_JOB_SUMMARY_ERROR";
export const CONFIRM_SELECTION = "CONFIRM_SELECTION";
export const CONFIRM_SELECTION_SUCCESS = "CONFIRM_SELECTION_SUCCESS";
export const CONFIRM_SELECTION_ERROR = "CONFIRM_SELECTION_ERROR";

export interface ConfirmSelectionPayload {
  id: string;
  enrichments: { enrichments: { enrichment: string }[] };
  headQuarters: string | null;
  creditType: string | null;
  algorithmBanding: string[];
}

export const FETCH_ENRICHED_FILE = "FETCH_ENRICHED_FILE";
export const FETCH_ENRICHED_FILE_SUCCESS = "FETCH_ENRICHED_FILE_SUCCESS";
export const FETCH_ENRICHED_FILE_ERROR = "FETCH_ENRICHED_FILE_ERROR";

export type FileType = "xlsx" | "csv";

export const SET_FILE_DOWNLOAD_STATUS = "SET_FILE_DOWNLOAD_STATUS";

export interface FetchFileSuccessPayload {
  filePath: string;
}

export const FETCH_SELECTED_WIDGETS = "FETCH_SELECTED_WIDGETS";
export const FETCH_SELECTED_WIDGETS_SUCCESS = "FETCH_SELECTED_WIDGETS_SUCCESS";
export const FETCH_SELECTED_WIDGETS_ERROR = "FETCH_SELECTED_WIDGETS_ERROR";

export const FETCH_AGGREGATES_BY_COUNTRY = "FETCH_AGGREGATES_BY_COUNTRY";

export const SET_SELECTED_TAB_COUNTRY = "SET_SELECTED_TAB_COUNTRY";

export const FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE =
  "FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE";
export const FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_SUCCESS =
  "FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_SUCCESS";
export const FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_ERROR =
  "FETCH_JOB_ENRICHMENT_SPEND_ESTIMATE_ERROR";

export const SAVE_ENRICHMENTS = "SAVE_ENRICHMENTS";
export const SAVE_ENRICHMENTS_SUCCESS = "SAVE_ENRICHMENTS_SUCCESS";
export const SAVE_ENRICHMENTS_ERROR = "SAVE_ENRICHMENTS_ERROR";

export const FETCH_HEALTH_CHECK_PDF = "FETCH_HEALTH_CHECK_PDF";
export const FETCH_HEALTH_CHECK_PDF_SUCCESS = "FETCH_HEALTH_CHECK_PDF_SUCCESS";
export const FETCH_HEALTH_CHECK_PDF_ERROR = "FETCH_HEALTH_CHECK_PDF_ERROR";

export interface FetchHealthCheckPdfPayload {
  id: string;
  countryCode?: string;
}

export const FETCH_JOB_DETAILS_PDF_SUCCESS = "FETCH_JOB_DETAILS_PDF_SUCCESS";

export const ARCHIVE_JOB = "ARCHIVE_JOB";
export const ARCHIVE_JOB_SUCCESS = "ARCHIVE_JOB_SUCCESS";
export const ARCHIVE_JOB_ERROR = "ARCHIVE_JOB_ERROR";
export const RESET_ARCHIVE_JOB_STATUS = "RESET_ARCHIVE_JOB_STATUS";

export interface UpdateJobEnrichmentsSettingsPayload {
  Headquarters: string | null;
  countries: string[];
  De_Reason?: number;
}

export const UPADATE_JOB_ENRICHMENT_SETTINGS =
  "UPADATE_JOB_ENRICHMENT_SETTINGS";
export const UPADATE_JOB_ENRICHMENT_SETTINGS_SUCCESS =
  "UPADATE_JOB_ENRICHMENT_SETTINGS_SUCCESS";
export const UPADATE_JOB_ENRICHMENT_SETTINGS_ERROR =
  "UPADATE_JOB_ENRICHMENT_SETTINGS_ERROR";

export interface JobRecordPayload {
  jobid: string;
  _limit?: number;
  _page?: number;
  _sort?: string;
  _order?: string;
  MatchingStatus?: string[];
  CompanyName?: string;
  CountryCode?: string;
}

export const FETCH_JOB_RECORDS = "FETCH_JOB_RECORDS";
export const FETCH_JOB_RECORDS_SUCCESS = "FETCH_JOB_RECORDS_SUCCESS";
export const FETCH_JOB_RECORDS_ERROR = "FETCH_JOB_RECORDS_ERROR";

export const SET_JOB_HEADER_CLASS = "SET_JOB_HEADER_CLASS";

export interface CompanyRecordPayload {
  name_like: string;
  SafeNumber: string;
  Status: string;
  Type: string;
  OfficeType: string;
  RegNo: string;
  RegNumber: string;
  RegStatus: string;
  Country: string;
  country_ne: string;
  address_city: string;
  address_street: string;
  address_postCode: string;
  address_province: string;
  PhoneNumber: string;
  websites_like: string;
  IncTradingAddresses?: boolean;
  IncTradingNames?: boolean;
  VatNo: string;
  Id: string;
  Language: string;
  TradeName: string;
  GroupResults: string;
  fileNo: string;
  IndustryCode: string;
  DisplayBranches?: boolean;
  _limit?: number;
  _page?: number;
}

export interface CompanyRecord {
  companyId: string;
  country: string;
  companyName: string;
  safeNumber: string;
  address: Address;
  status: string;
  statusDescription: string;
  type: string;
  companyNumber: string;
  fileNumber: string;
}

export interface Address {
  street: string;
  simpleValue: string;
  city: string;
  postCode: string;
}

export const FETCH_COMPANY_RECORDS = "FETCH_COMPANY_RECORDS";
export const FETCH_COMPANY_RECORDS_SUCCESS = "FETCH_COMPANY_RECORDS_SUCCESS";
export const FETCH_COMPANY_RECORDS_ERROR = "FETCH_COMPANY_RECORDS_ERROR";

export interface ManualMatchRecordPayload {
  companyId: string | null;
}

export const MANUAL_MATCH_RECORD = "MANUAL_MATCH_RECORD";
export const MANUAL_MATCH_RECORD_SUCCESS = "MANUAL_MATCH_RECORD_SUCCESS";
export const MANUAL_MATCH_RECORD_ERROR = "MANUAL_MATCH_RECORD_ERROR";

export const RESET_MANUAL_MATCH_OR_UNMATCH_STATUS =
  "RESET_MANUAL_MATCH_OR_UNMATCH_STATUS";

export const SUBMIT_JOB_ENRICH_REASON = "SUBMIT_JOB_ENRICH_REASON";
export const SUBMIT_JOB_ENRICH_REASON_SUCCESS =
  "SUBMIT_JOB_ENRICH_REASON_SUCCESS";
export const SUBMIT_JOB_ENRICH_REASON_ERROR = "SUBMIT_JOB_ENRICH_REASON_ERROR";

export const UNMATCH_RECORD = "UNMATCH_RECORD";
export const UNMATCH_RECORD_SUCCESS = "UNMATCH_RECORD_SUCCESS";
export const UNMATCH_RECORD_ERROR = "UNMATCH_RECORD_ERROR";

export const FETCH_JOB_CONTACT = "FETCH_JOB_CONTACT";
export const FETCH_JOB_CONTACT_SUCCESS = "FETCH_JOB_CONTACT_SUCCESS";
export const FETCH_JOB_CONTACT_ERROR = "FETCH_JOB_CONTACT_ERROR";

export const UPDATE_JOB_CONTACT = "UPDATE_JOB_CONTACT";
export const UPDATE_JOB_CONTACT_SUCCESS = "UPDATE_JOB_CONTACT_SUCCESS";
export const UPDATE_JOB_CONTACT_ERROR = "UPDATE_JOB_CONTACT_ERROR";
export const RESET_JOB_CONTACT_STATUS = "RESET_JOB_CONTACT_STATUS";

export interface CreateJobMonitorPayload {
  jobId: string;
  monitoringPortfolioId: number;
  email: string;
  monitorType: string;
}

export const CREATE_JOB_MONITOR = "CREATE_JOB_MONITOR";
export const CREATE_JOB_MONITOR_SUCCESS = "CREATE_JOB_MONITOR_SUCCESS";
export const CREATE_JOB_MONITOR_ERROR = "CREATE_JOB_MONITOR_ERROR";

export const FETCH_ENRICHMENT_SETTINGS = "FETCH_ENRICHMENT_SETTINGS";
export const FETCH_ENRICHMENT_SETTINGS_SUCCESS =
  "FETCH_ENRICHMENT_SETTINGS_SUCCESS";
export const FETCH_ENRICHMENT_SETTINGS_ERROR =
  "FETCH_ENRICHMENT_SETTINGS_ERROR";

export const UPDATE_CREDIT_TYPE = "UPDATE_CREDIT_TYPE";

export const RESET_MONITORING_SAVING_STATUS = "RESET_MONITORING_SAVING_STATUS";

export const SET_CREDIT_TYPE_WIDGETS = "SET_CREDIT_TYPE_WIDGETS";

export const FETCH_AVAILABLE_CREDIT_TYPES = "FETCH_AVAILABLE_CREDIT_TYPES";
export const FETCH_AVAILABLE_CREDIT_TYPES_SUCCESS =
  "FETCH_AVAILABLE_CREDIT_TYPES_SUCCESS";
export const FETCH_AVAILABLE_CREDIT_TYPES_ERROR =
  "FETCH_AVAILABLE_CREDIT_TYPES_ERROR";

export const SET_AUTO_ENRICH_JOBID = "SET_AUTO_ENRICH_JOBID";
export const UPDATE_ONESTEP_STATUS = "UPDATE_ONESTEP_STATUS";

export const ASSIGN_MASTER_TEMPLATE = "ASSIGN_MASTER_TEMPLATE";

export const VALIDATE_JOB_MAPPINGS = "VALIDATE_JOB_MAPPINGS";

export const UPLOAD_JOB = "UPLOAD_JOB";
export const UPLOAD_JOB_SUCCESS = "UPLOAD_JOB_SUCCESS";
export const UPLOAD_JOB_ERROR = "UPLOAD_JOB_ERROR";

export const FETCH_MANUALMATCH_STATUS = "FETCH_MANUALMATCH_STATUS";
export const FETCH_MANUALMATCH_STATUS_SUCCESS =
  "FETCH_MANUALMATCH_STATUS_SUCCESS";
export const FETCH_MANUALMATCH_STATUS_ERROR = "FETCH_MANUALMATCH_STATUS_ERROR";

export const FETCH_JOB_AUTO_ENRICHMENT = "FETCH_JOB_AUTO_ENRICHMENT";
export const FETCH_JOB_AUTO_ENRICHMENT_SUCCESS =
  "FETCH_JOB_AUTO_ENRICHMENT_SUCCESS";
export const FETCH_JOB_AUTO_ENRICHMENT_ERROR =
  "FETCH_JOB_AUTO_ENRICHMENT_ERROR";

export const RE_AGGREGATE_JOB = "RE_AGGREGATE_JOB";
export const RE_AGGREGATE_JOB_SUCCESS = "RE_AGGREGATE_JOB_SUCCESS";
export const RE_AGGREGATE_JOB_ERROR = "RE_AGGREGATE_JOB_ERROR";

export const SET_SELECTED_ALGORITHM_BANDING = "SET_SELECTED_ALGORITHM_BANDING";

export const RESET_AUTO_ENRICHMENT_JOB_STATUS =
  "RESET_AUTO_ENRICHMENT_JOB_STATUS";

export const RESET_RE_AGGREGATE_STATUS = "RESET_RE_AGGREGATE_STATUS";
