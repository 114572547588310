import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Auth from "@creditsafe/upp-authentication";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as Cslogo } from "../../assets/cslogo.svg";
import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";
import cslogoMini from "../../assets/csLogoMini.svg";
import { ReactComponent as ExitToAppIcon } from "../../assets/SignOut.svg";
import LanguageSwitcher from "./LanguageSwitcher";
import config from "../../config";
import { getUserId, getIsInternalUser } from "../../store/user/selectors";
import HeaderSearchBar from "./HeaderSearchBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.modal + 1,
      [theme.breakpoints.up("sm")]: {
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    title: {
      padding: theme.spacing(0.5, 1, 0, 0),
      whiteSpace: "nowrap",
    },
    searchForm: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    iconNamePair: {
      display: "flex",
      alignItems: "center",
      "&>span>svg": {
        margin: theme.spacing(0, 1),
      },
    },
    logo: {
      display: "flex",
      textDecoration: "none",
    },
    multilineButtonText: {
      lineHeight: 1.2,
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
    },
    smallText: {
      fontSize: "0.8em",
    },
    button: {
      marginRight: "2px",
    },
  })
);

interface AppBarProps {
  onToggle(): void;
}

const AppBar: React.FC<AppBarProps> = ({ onToggle }) => {
  const classes = useStyles();
  const { t } = useTranslation("global");
  const userId = useSelector(getUserId);
  const isInternalUser = useSelector(getIsInternalUser);

  return (
    <MuiAppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar>
        <Link to="/" className={classes.logo}>
          <Hidden xsDown>
            <Cslogo width="198px" />
          </Hidden>
          <Hidden smUp>
            <img style={{ width: "25px" }} src={cslogoMini} alt="Creditsafe" />
          </Hidden>
          <Typography
            variant="h6"
            className={classes.title}
            display="inline"
            color="textPrimary"
          >
            {t("html-title.title")}
          </Typography>
        </Link>

        <div className={classes.searchForm}>
          {isInternalUser && <HeaderSearchBar />}
        </div>
        <Hidden xsDown>
          <Button
            className={classes.iconNamePair}
            data-testid="my-account-button"
            aria-label={t("sidebar.profile")}
            onClick={() =>
              (window.location.href = `${config.UPP_APP_URL}/account`)
            }
          >
            <svg height="24" width="24">
              <ProfileIcon />
            </svg>
            <div className={classes.multilineButtonText}>
              {t("sidebar.profile")}
              <span className={classes.smallText} data-testid="app-bar-user-id">
                <strong>{t("sidebar.userId")}: </strong>
                {userId}
              </span>
            </div>
          </Button>
          <LanguageSwitcher />
          <Button onClick={Auth.signOut} className={classes.iconNamePair}>
            <svg height="24" width="24">
              <ExitToAppIcon />
            </svg>
            {t("sidebar.signOut")}
          </Button>
        </Hidden>
        <Hidden smUp>
          <LanguageSwitcher />
          <IconButton
            aria-label={t("openDrawer")}
            onClick={onToggle}
            color="inherit"
            data-testid="hamburgerMenuButton"
            edge="end"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
