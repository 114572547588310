import {
  CloneJobPayload,
  CLONE_JOB,
  CLONE_JOB_ERROR,
  CLONE_JOB_SUCCESS,
  AdminJob,
  FetchJobFilePayload,
  FETCH_DASHBOARD_JOB_SELECTED_WIDGETS,
  FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_SUCCESS,
  FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_ERROR,
  FETCH_DASHBOARD_JOB_DETAILS,
  FETCH_DASHBOARD_JOB_DETAILS_ERROR,
  FETCH_DASHBOARD_JOB_DETAILS_SUCCESS,
  FETCH_JOB_FILE,
  FETCH_JOB_FILE_ERROR,
  FETCH_JOB_FILE_SUCCESS,
  RESET_DASHBOARD,
  UNSET_CLONE_JOB_ERROR,
  FETCH_JOB_TRANSACTIONS,
  FETCH_JOB_TRANSACTIONS_ERROR,
  FETCH_JOB_TRANSACTIONS_SUCCESS,
  JobTransaction,
  FETCH_ADMIN_JOB_AUTO_ENRICHMENT,
  FETCH_ADMIN_JOB_AUTO_ENRICHMENT_SUCCESS,
  FETCH_ADMIN_JOB_AUTO_ENRICHMENT_ERROR,
  JobAutoEnrichment,
  FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME,
  FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_SUCCESS,
  FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_ERROR,
  jobEnrichments,
} from "./types";
import { action } from "typesafe-actions";
import {
  FetchFileSuccessPayload,
  FetchJobSamplePayload,
  FETCH_JOB_SAMPLE,
  FETCH_JOB_SAMPLE_ERROR,
  FETCH_JOB_SAMPLE_SUCCESS,
  Job,
} from "store/jobs/types";

export const fetchDashboardJobSelectedWidgets = (id: string) =>
  action(FETCH_DASHBOARD_JOB_SELECTED_WIDGETS, id);
export const fetchDashboardJobSelectedWidgetsSuccess = (
  enrichment: jobEnrichments[]
) => action(FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_SUCCESS, enrichment);
export const fetchDashboardJobSelectedWidgetsError = () =>
  action(FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_ERROR);

export const fetchJobFile = (payload: FetchJobFilePayload) =>
  action(FETCH_JOB_FILE, payload);
export const fetchJobFileSuccess = (payload: FetchFileSuccessPayload) =>
  action(FETCH_JOB_FILE_SUCCESS, payload);
export const fetchJobFileError = () => action(FETCH_JOB_FILE_ERROR);

export const fetchAdminJobAutoEnrichment = (payload: string) =>
  action(FETCH_ADMIN_JOB_AUTO_ENRICHMENT, payload);
export const fetchAdminJobAutoEnrichmentSuccess = (
  payload: JobAutoEnrichment
) => action(FETCH_ADMIN_JOB_AUTO_ENRICHMENT_SUCCESS, payload);
export const fetchAdminJobAutoEnrichmentError = () =>
  action(FETCH_ADMIN_JOB_AUTO_ENRICHMENT_ERROR);

export const fetchdashboardJobDetails = (params: { jobId: string }) =>
  action(FETCH_DASHBOARD_JOB_DETAILS, params);
export const fetchdashboardJobDetailsSuccess = (dashboardJob: AdminJob) =>
  action(FETCH_DASHBOARD_JOB_DETAILS_SUCCESS, { dashboardJob: dashboardJob });
export const fetchdashboardJobDetailsError = () =>
  action(FETCH_DASHBOARD_JOB_DETAILS_ERROR);

export const fetchdashboardJobDetailsByJobName = (params: {
  jobName: string;
  customerId: number;
}) => action(FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME, params);
export const fetchdashboardJobDetailsByJobNameSuccess = (
  dashboardJob: AdminJob
) =>
  action(FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_SUCCESS, {
    dashboardJob: dashboardJob,
  });
export const fetchdashboardJobDetailsByJobNameError = () =>
  action(FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_ERROR);

export const cloneJob = (payload: CloneJobPayload) =>
  action(CLONE_JOB, payload);
export const cloneJobSuccess = (payload: Job) =>
  action(CLONE_JOB_SUCCESS, payload);
export const cloneJobError = (errorMsg?: string) =>
  action(CLONE_JOB_ERROR, errorMsg);
export const unsetCloneJobError = () => action(UNSET_CLONE_JOB_ERROR);

export const fetchJobSample = (payload: FetchJobSamplePayload) =>
  action(FETCH_JOB_SAMPLE, payload);
export const fetchJobSampleSuccess = (payload: {
  id: string;
  sample: string[][];
}) => action(FETCH_JOB_SAMPLE_SUCCESS, payload);
export const fetchJobSampleError = () => action(FETCH_JOB_SAMPLE_ERROR);

export const fetchJobTransactions = (payload: {
  jobId: string;
  type: string;
}) => action(FETCH_JOB_TRANSACTIONS, payload);
export const fetchJobTransactionsSuccess = (payload: JobTransaction[]) =>
  action(FETCH_JOB_TRANSACTIONS_SUCCESS, payload);
export const fetchJobTransactionsError = () =>
  action(FETCH_JOB_TRANSACTIONS_ERROR);

export const resetDashboard = () => action(RESET_DASHBOARD);
