import { TFunction } from "i18next";
import { formatAggregate } from "../utils";
import { AggregateObject } from "../../../../store/jobs/types";
import {
  WidgetGroup,
  WidgetVariant,
  Widget,
  WidgetDisplayMode,
} from "../types";
import { getCountryWidgets } from "./getCountryWidgets";

export const formatAggregateData = (
  t: TFunction,
  aggregates: AggregateObject[],
  tab: string
): WidgetGroup[] => {
  const formattedTab = tab.toLowerCase();
  const countryAggregates = getCountryWidgets(formattedTab);

  return countryAggregates.map((group) => ({
    title: `${group.group}.${formattedTab}`,
    widgets: group.widgets.reduce((acc: Widget[], curr) => {
      const allMatchingAggregates = aggregates.filter(
        (x) => x.name === curr.name
      );
      const chartData =
        allMatchingAggregates.length === 1
          ? allMatchingAggregates[0]
          : allMatchingAggregates.find(
              (x) =>
                x.countryCode.toLowerCase() ===
                (formattedTab === "gb" ? "uk" : formattedTab)
            );

      return [
        ...acc,
        {
          title: `${curr.name}.title.${formattedTab}`,
          data: chartData
            ? formatAggregate(
                chartData.data,
                curr.colors,
                t,
                curr.name,
                formattedTab
              )
            : [],
          widgetType: curr.widgetType as WidgetVariant,
          JSONTag: curr.jsonTag,
          isRequired: curr.isRequired,
          tooltip: curr.hasTooltip
            ? `${curr.name}.tooltip.${tab.toLowerCase()}`
            : null,
          pdfOnly: curr.pdfOnly,
          isAddedByDefault: curr.isAddedByDefault,
          displayMode: curr.displayMode
            ? curr.displayMode
            : WidgetDisplayMode.HALF_WIDTH,
          hasPdfSpecificTitle: curr.hasPdfSpecificTitle,
        },
      ];
    }, []),
  }));
};
