import { Epic } from "../rootEpic";
import {
  CLONE_JOB,
  FETCH_DASHBOARD_JOB_DETAILS,
  FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME,
  FETCH_ADMIN_JOB_AUTO_ENRICHMENT,
  FETCH_JOB_FILE,
  FETCH_JOB_TRANSACTIONS,
  FETCH_DASHBOARD_JOB_SELECTED_WIDGETS,
} from "./types";
import { of } from "rxjs";
import { map, switchMap, filter, catchError } from "rxjs/operators";
import {
  cloneJobError,
  cloneJobSuccess,
  fetchdashboardJobDetailsByJobNameError,
  fetchdashboardJobDetailsByJobNameSuccess,
  fetchdashboardJobDetailsError,
  fetchdashboardJobDetailsSuccess,
  fetchAdminJobAutoEnrichment,
  fetchAdminJobAutoEnrichmentError,
  fetchAdminJobAutoEnrichmentSuccess,
  fetchJobFileError,
  fetchJobFileSuccess,
  fetchJobSampleError,
  fetchJobSampleSuccess,
  fetchJobTransactionsError,
  fetchJobTransactionsSuccess,
  fetchDashboardJobSelectedWidgetsSuccess,
  fetchDashboardJobSelectedWidgetsError,
} from "./actions";
import { isOfType, ActionType } from "typesafe-actions";
import config from "../../config";
import queryString from "query-string";
import { FETCH_JOB_SAMPLE } from "store/jobs/types";

export const fetchJobFileEpic: Epic<
  ActionType<typeof fetchJobFileSuccess> | ActionType<typeof fetchJobFileError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_FILE)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/admin/${
            payload.id
          }/file?${queryString.stringify({
            uploadFileType: payload.uploadFileType,
          })}`,
        })
        .pipe(
          map(({ response }) => {
            if (response.filePath) {
              window.open(response.filePath, "_blank");
              return fetchJobFileSuccess(response);
            }
            return fetchJobFileError();
          }),
          catchError(() => {
            return of(fetchJobFileError());
          })
        );
    })
  );

export const fetchdashboardJobDetailsEpic: Epic<
  | ActionType<typeof fetchdashboardJobDetailsSuccess>
  | ActionType<typeof fetchdashboardJobDetailsError>
  | ActionType<typeof fetchAdminJobAutoEnrichment>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_DASHBOARD_JOB_DETAILS)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/admin/${payload.jobId}/job`,
        })
        .pipe(
          switchMap((response) =>
            of(
              fetchdashboardJobDetailsSuccess(response.response),
              fetchAdminJobAutoEnrichment(response.response.jobDetails.id)
            )
          ),

          catchError(() => of(fetchdashboardJobDetailsError()))
        );
    })
  );

export const fetchdashboardJobDetailsByJobNameEpic: Epic<
  | ActionType<typeof fetchdashboardJobDetailsByJobNameSuccess>
  | ActionType<typeof fetchdashboardJobDetailsByJobNameError>
  | ActionType<typeof fetchAdminJobAutoEnrichment>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/admin/jobbyname?jobName=${payload.jobName}&customerId=${payload.customerId}`,
        })

        .pipe(
          switchMap((response) =>
            of(
              fetchdashboardJobDetailsByJobNameSuccess(response.response),
              fetchAdminJobAutoEnrichment(response.response.jobDetails.id)
            )
          ),

          catchError(() => of(fetchdashboardJobDetailsByJobNameError()))
        );
    })
  );

export const fetchJobAutoEnrichmentEpic: Epic<
  | ActionType<typeof fetchAdminJobAutoEnrichmentSuccess>
  | ActionType<typeof fetchAdminJobAutoEnrichmentError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_ADMIN_JOB_AUTO_ENRICHMENT)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/admin/${payload}/adminjobenrichmentdata`,
        })
        .pipe(
          map(({ response }) => {
            return fetchAdminJobAutoEnrichmentSuccess(response);
          }),
          catchError(() => of(fetchAdminJobAutoEnrichmentError()))
        );
    })
  );

export const cloneJobEpic: Epic<
  ActionType<typeof cloneJobSuccess> | ActionType<typeof cloneJobError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(CLONE_JOB)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "POST",
          url: `${config.DATA_CLEANING_API_URL}/admin/${payload.JobId}/clone`,
          body: {
            name: payload.jobName,
            Mappings: payload.Mappings,
          },
        })
        .pipe(
          switchMap((job) => of(cloneJobSuccess(job.response))),
          catchError(({ xhr }) =>
            of(
              cloneJobError(
                xhr.status === 403 ? "Forbidden" : xhr.response.type
              )
            )
          )
        )
    )
  );

export const fetchJobSampleEpic: Epic<ActionType<
  typeof fetchJobSampleSuccess | typeof fetchJobSampleError
>> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_SAMPLE)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/admin/${payload.id}/_sample`,
        })
        .pipe(
          map((sample) =>
            fetchJobSampleSuccess({
              id: payload.id,
              sample: sample.response,
            })
          )
        )
    ),
    catchError(() => of(fetchJobSampleError()))
  );

export const fetchJobTransactionsEpic: Epic<ActionType<
  typeof fetchJobTransactionsSuccess | typeof fetchJobTransactionsError
>> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_TRANSACTIONS)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/admin/${payload.jobId}/transactions?type=${payload.type}`,
        })
        .pipe(
          switchMap((response) =>
            of(fetchJobTransactionsSuccess(response.response || []))
          ),
          catchError(() => of(fetchJobTransactionsError()))
        )
    )
  );

export const fetchDashboardJobSelectedWidgetsEpic: Epic<
  | ActionType<typeof fetchDashboardJobSelectedWidgetsSuccess>
  | ActionType<typeof fetchDashboardJobSelectedWidgetsError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_DASHBOARD_JOB_SELECTED_WIDGETS)),
    switchMap(({ payload }) => {
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/admin/${payload}/enrichments`,
        })
        .pipe(
          map(({ response }) => {
            return fetchDashboardJobSelectedWidgetsSuccess(response);
          }),
          catchError(() => of(fetchDashboardJobSelectedWidgetsError()))
        );
    })
  );
