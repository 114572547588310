import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { supportedCountryCodes } from "../../../appUtils";
import { ChartInputItem } from "./types";
import { generateMapColorMultiplier } from "./utils";

const geoUrl = "/assets/world-map.json";

const useStyles = makeStyles(() =>
  createStyles({
    geography: {
      "&:focus": {
        outline: "0px",
      },
    },
  })
);

const CountryCodeMap: React.FC<{
  chartData: ChartInputItem[];
  colors: string[];
  activeCountryCode: string;
  setActiveCountryCode: (code: string) => void;
  setTooltip: (tooltip: string) => void;
}> = ({
  chartData,
  colors,
  activeCountryCode,
  setActiveCountryCode,
  setTooltip,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("aggregates");

  const checkCountryCodeEquality = (inputCode: string, ISOCode: string) => {
    return inputCode === ISOCode;
  };

  const generateColor = (countryCode: string) => {
    if (countryCode === "GB") {
      countryCode = "UK";
    }
    const isSupported = supportedCountryCodes.includes(countryCode);
    const hasData = chartData.map((x) => x.countryCode).includes(countryCode);
    if (isSupported && hasData) {
      return colors[0];
    } else if (isSupported) {
      return colors[1];
    } else {
      return colors[2];
    }
  };

  return (
    <>
      <ComposableMap
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
        height={400}
        data-testid="country-code-map"
      >
        {chartData.length > 0 && (
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const isSupported = supportedCountryCodes.includes(
                  geo.properties.ISO_A2
                );

                const d = chartData.find(
                  (s) =>
                    s.countryCode &&
                    checkCountryCodeEquality(
                      s.countryCode,
                      geo.properties.ISO_A2
                    )
                );
                const isActive = activeCountryCode === geo.properties.ISO_A2;

                return (
                  <Geography
                    key={geo.rsmKey}
                    className={classes.geography}
                    geography={geo}
                    stroke={
                      isActive &&
                      generateColor(geo.properties.ISO_A2) === colors[0]
                        ? "black"
                        : "none"
                    }
                    fill={generateColor(geo.properties.ISO_A2)}
                    fillOpacity={generateMapColorMultiplier(
                      chartData,
                      0.25,
                      d?.percentage
                    )}
                    onMouseEnter={() => {
                      setActiveCountryCode(geo.properties.ISO_A2);
                      setTooltip(
                        `${t(`CountryCode.${geo.properties.ISO_A2}`)} — ${
                          d ? d.value : isSupported ? 0 : t("notSupported")
                        }`
                      );
                    }}
                    onMouseLeave={() => {
                      setActiveCountryCode("");
                      setTooltip("");
                    }}
                  />
                );
              })
            }
          </Geographies>
        )}
      </ComposableMap>
    </>
  );
};

export default CountryCodeMap;
