import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import HealthCheck from "./pdfViews/HealthCheck/HealthCheck";

const PdfViewerRouter: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/healthcheck/:key?`}>
        <HealthCheck />
      </Route>
    </Switch>
  );
};

export default PdfViewerRouter;
