import {
  SEARCH_FOR_A_CUSTOMER,
  SEARCH_FOR_A_CUSTOMER_SUCCESS,
  SEARCH_FOR_A_CUSTOMER_ERROR,
  SELECT_USER,
  SearchForACustomerPayload,
  SearchForACustomerSuccessPayload,
  SelectUserPayload,
  FETCH_SELECTED_USER_DETAILS,
  FETCH_SELECTED_USER_DETAILS_SUCCESS,
  FETCH_SELECTED_USER_DETAILS_ERROR,
  RESET_SEARCH_FOR_A_CUSTOMER,
  CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED,
  IS_EXTERNAL_CUSTOMER_ALLOWED_SUCCESS,
  IS_EXTERNAL_CUSTOMER_ALLOWED_ERRROR,
} from "./types";
import { action } from "typesafe-actions";

export const resetSearchForACustomer = () =>
  action(RESET_SEARCH_FOR_A_CUSTOMER);

export const searchForACustomer = (payload: SearchForACustomerPayload) =>
  action(SEARCH_FOR_A_CUSTOMER, payload);

export const searchForACustomerSuccess = (
  payload: SearchForACustomerSuccessPayload
) => action(SEARCH_FOR_A_CUSTOMER_SUCCESS, payload);

export const searchForACustomerError = () =>
  action(SEARCH_FOR_A_CUSTOMER_ERROR);

export const selectUser = (payload: SelectUserPayload) =>
  action(SELECT_USER, payload);

export const fetchSelectedUserDetails = (id: number) =>
  action(FETCH_SELECTED_USER_DETAILS, id);
export const fetchSelectedUserDetailsSuccess = (payload: SelectUserPayload) =>
  action(FETCH_SELECTED_USER_DETAILS_SUCCESS, payload);
export const fetchSelectedUserDetailsError = () =>
  action(FETCH_SELECTED_USER_DETAILS_ERROR);

export const checkIsExternalCustomerAllowed = (payload: {
  customerID: number | undefined;
}) => action(CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED, payload);

export const externalCustomerAllowedSuccess = (allowed: boolean) =>
  action(IS_EXTERNAL_CUSTOMER_ALLOWED_SUCCESS, allowed);

export const externalCustomerAllowedError = () =>
  action(IS_EXTERNAL_CUSTOMER_ALLOWED_ERRROR);
