import {
  FETCH_MASTER_TEMPLATE,
  FETCH_MASTER_TEMPLATE_SUCCESS,
  FETCH_MASTER_TEMPLATE_ERROR,
  MasterTemplate,
  FETCH_MINIMAL_MATCH_TEMPLATE,
  FETCH_MINIMAL_MATCH_TEMPLATE_SUCCESS,
  FETCH_MINIMAL_MATCH_TEMPLATE_ERROR,
  FETCH_ENRICHMENT_TEMPLATE,
  FETCH_ENRICHMENT_TEMPLATE_SUCCESS,
  FETCH_ENRICHMENT_TEMPLATE_ERROR,
  FETCH_MAPPING_TEMPLATE,
  FETCH_MAPPING_TEMPLATE_SUCCESS,
  FETCH_MAPPING_TEMPLATE_ERROR,
  MinimalMatchTemplate,
  SAVE_MASTER_TEMPLATE,
  SAVE_MASTER_TEMPLATE_ERROR,
  SAVE_MASTER_TEMPLATE_SUCCESS,
  SAVE_MINIMAL_MATCH_TEMPLATE,
  SAVE_MINIMAL_MATCH_TEMPLATE_ERROR,
  SAVE_MINIMAL_MATCH_TEMPLATE_SUCCESS,
  SETTINGS_SAVE_ENRICHMENT_TEMPLATE,
  SETTINGS_SAVE_ENRICHMENT_TEMPLATE_ERROR,
  SETTINGS_SAVE_ENRICHMENT_TEMPLATE_SUCCESS,
  DELETE_ENRICHMENT_TEMPLATE,
  DELETE_ENRICHMENT_TEMPLATE_ERROR,
  DELETE_ENRICHMENT_TEMPLATE_SUCCESS,
  DELETE_MASTER_TEMPLATE,
  DELETE_MASTER_TEMPLATE_ERROR,
  DELETE_MASTER_TEMPLATE_SUCCESS,
  DELETE_MINIMAL_MATCH_TEMPLATE,
  DELETE_MINIMAL_MATCH_TEMPLATE_ERROR,
  DELETE_MINIMAL_MATCH_TEMPLATE_SUCCESS,
  RESET_STATUS,
  ErrorResponse,
  FETCH_ENRICHMENT_TEMPLATEDETAILS,
  FETCH_ENRICHMENT_TEMPLATEDETAILS_SUCCESS,
  FETCH_ENRICHMENT_TEMPLATEDETAILS_ERROR,
  FETCH_MINIMAL_MATCH_TEMPLATEDETAILS,
  FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_ERROR,
  FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_SUCCESS,
  FETCH_MAPPING_TEMPLATEDETAILS,
  FETCH_MAPPING_TEMPLATEDETAILS_SUCCESS,
  FETCH_MAPPING_TEMPLATEDETAILS_ERROR,
  SETTINGS_UPDATE_ENRICHMENT_TEMPLATE,
  RESET_SELECTED,
  MMRSavingPayload,
  UPDATE_MINIMAL_MATCH_TEMPLATE,
  FETCH_MASTER_TEMPLATE_DETAILS,
  FETCH_MASTER_TEMPLATE_DETAILS_SUCCESS,
  FETCH_MASTER_TEMPLATE_DETAILS_ERROR,
  MasterTemplatePayload,
} from "./types";
import { action } from "typesafe-actions";
import {
  Enrichment,
  SaveEnrichmentTemplatePayload,
} from "store/enrichments/types";
import { Mapping } from "store/mappings/types";

export const fetchMasterTemplate = () => action(FETCH_MASTER_TEMPLATE);
export const fetchMasterTemplateSuccess = (payload: MasterTemplate[]) =>
  action(FETCH_MASTER_TEMPLATE_SUCCESS, payload);
export const fetchMasterTemplateError = (payload: string) =>
  action(FETCH_MASTER_TEMPLATE_ERROR, payload);

export const fetchMasterTemplateDetails = (payload: string) =>
  action(FETCH_MASTER_TEMPLATE_DETAILS, payload);
export const fetchMasterTemplateDetailsSuccess = (payload: MasterTemplate) =>
  action(FETCH_MASTER_TEMPLATE_DETAILS_SUCCESS, payload);
export const fetchMasterTemplateDetailsError = (payload: string) =>
  action(FETCH_MASTER_TEMPLATE_DETAILS_ERROR, payload);

export const fetchMinimalMatchTemplate = () =>
  action(FETCH_MINIMAL_MATCH_TEMPLATE);
export const fetchMinimalMatchTemplateSuccess = (
  payload: MinimalMatchTemplate[]
) => action(FETCH_MINIMAL_MATCH_TEMPLATE_SUCCESS, payload);
export const fetchMinimalMatchTemplateError = (payload: string) =>
  action(FETCH_MINIMAL_MATCH_TEMPLATE_ERROR, payload);

export const fetchMinimalMatchTemplateDetails = (payload: string) =>
  action(FETCH_MINIMAL_MATCH_TEMPLATEDETAILS, payload);
export const fetchMinimalMatchTemplateDetailsSuccess = (
  payload: MinimalMatchTemplate
) => action(FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_SUCCESS, payload);
export const fetchMinimalMatchTemplateDetailsError = (payload: string) =>
  action(FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_ERROR, payload);

export const fetchEnrichTemplate = (payload = false) =>
  action(FETCH_ENRICHMENT_TEMPLATE, payload);
export const fetchEnrichTemplateSuccess = (payload: Enrichment[]) =>
  action(FETCH_ENRICHMENT_TEMPLATE_SUCCESS, payload);
export const fetchEnrichTemplateError = (payload: string) =>
  action(FETCH_ENRICHMENT_TEMPLATE_ERROR, payload);

export const fetchEnrichTemplateDetails = (payload: string) =>
  action(FETCH_ENRICHMENT_TEMPLATEDETAILS, payload);
export const fetchEnrichTemplateDetailsSuccess = (payload: Enrichment) =>
  action(FETCH_ENRICHMENT_TEMPLATEDETAILS_SUCCESS, payload);
export const fetchEnrichTemplateDetailsError = (payload: string) =>
  action(FETCH_ENRICHMENT_TEMPLATEDETAILS_ERROR, payload);

export const fetchMappingTemplate = () => action(FETCH_MAPPING_TEMPLATE);
export const fetchMappingTemplateSuccess = (payload: Mapping[]) =>
  action(FETCH_MAPPING_TEMPLATE_SUCCESS, payload);
export const fetchMappingTemplateError = (payload: string) =>
  action(FETCH_MAPPING_TEMPLATE_ERROR, payload);

export const fetchMappingTemplateDetails = (payload: string) =>
  action(FETCH_MAPPING_TEMPLATEDETAILS, payload);
export const fetchMappingTemplateDetailsSuccess = (payload: Mapping) =>
  action(FETCH_MAPPING_TEMPLATEDETAILS_SUCCESS, payload);
export const fetchMappingTemplateDetailsError = (payload: string) =>
  action(FETCH_MAPPING_TEMPLATEDETAILS_ERROR, payload);

export const saveMasterTemplate = (payload: MasterTemplatePayload) =>
  action(SAVE_MASTER_TEMPLATE, payload);
export const saveMasterTemplateSuccess = (payload: MasterTemplate) =>
  action(SAVE_MASTER_TEMPLATE_SUCCESS, payload);
export const saveMasterTemplateError = (payload: ErrorResponse) =>
  action(SAVE_MASTER_TEMPLATE_ERROR, payload);

export const updateMinMatchTemplate = (payload: {
  body: MMRSavingPayload;
  id: string;
}) => action(UPDATE_MINIMAL_MATCH_TEMPLATE, payload);

export const saveMinMatchTemplate = (payload: MMRSavingPayload) =>
  action(SAVE_MINIMAL_MATCH_TEMPLATE, payload);
export const saveMinMatchTemplateSuccess = (payload: MinimalMatchTemplate) =>
  action(SAVE_MINIMAL_MATCH_TEMPLATE_SUCCESS, payload);
export const saveMinMatchTemplateError = (payload: ErrorResponse) =>
  action(SAVE_MINIMAL_MATCH_TEMPLATE_ERROR, payload);

export const saveSettingsEnrichTemplate = (
  payload: SaveEnrichmentTemplatePayload
) => action(SETTINGS_SAVE_ENRICHMENT_TEMPLATE, payload);
export const saveSettingsEnrichTemplateSuccess = (payload: Enrichment) =>
  action(SETTINGS_SAVE_ENRICHMENT_TEMPLATE_SUCCESS, payload);
export const saveSettingsEnrichTemplateError = (payload: ErrorResponse) =>
  action(SETTINGS_SAVE_ENRICHMENT_TEMPLATE_ERROR, payload);

export const updateSettingsEnrichTemplate = (
  payload: SaveEnrichmentTemplatePayload
) => action(SETTINGS_UPDATE_ENRICHMENT_TEMPLATE, payload);

export const deleteMasterTemplate = (payload: { id: string }) =>
  action(DELETE_MASTER_TEMPLATE, payload);
export const deleteMasterTemplateSuccess = () =>
  action(DELETE_MASTER_TEMPLATE_SUCCESS);
export const deleteMasterTemplateError = (payload: ErrorResponse) =>
  action(DELETE_MASTER_TEMPLATE_ERROR, payload);

export const deleteMinimalMatchTemplate = (payload: { id: string }) =>
  action(DELETE_MINIMAL_MATCH_TEMPLATE, payload);
export const deleteMinimalMatchTemplateSuccess = () =>
  action(DELETE_MINIMAL_MATCH_TEMPLATE_SUCCESS);
export const deleteMinimalMatchTemplateError = (payload: ErrorResponse) =>
  action(DELETE_MINIMAL_MATCH_TEMPLATE_ERROR, payload);

export const deleteEnrichTemplate = (payload: { id: string }) =>
  action(DELETE_ENRICHMENT_TEMPLATE, payload);
export const deleteEnrichTemplateSuccess = () =>
  action(DELETE_ENRICHMENT_TEMPLATE_SUCCESS);
export const deleteEnrichTemplateError = (payload: ErrorResponse) =>
  action(DELETE_ENRICHMENT_TEMPLATE_ERROR, payload);

export const resetStatus = () => action(RESET_STATUS);
export const resetSelected = () => action(RESET_SELECTED);
