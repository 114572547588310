import { Epic } from "../rootEpic";
import {
  SEARCH_FOR_A_CUSTOMER,
  FETCH_SELECTED_USER_DETAILS,
  CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED,
} from "./types";
import { of } from "rxjs";
import { filter, switchMap, map, catchError } from "rxjs/operators";
import {
  searchForACustomerSuccess,
  searchForACustomerError,
  fetchSelectedUserDetailsSuccess,
  fetchSelectedUserDetailsError,
  externalCustomerAllowedError,
  externalCustomerAllowedSuccess,
} from "./actions";
import { isOfType, ActionType } from "typesafe-actions";
import { getNonEmptyQueryParams, formatBackOfficeName } from "../../appUtils";

export const searchForACustomerEpic: Epic<
  | ActionType<typeof searchForACustomerSuccess>
  | ActionType<typeof searchForACustomerError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(SEARCH_FOR_A_CUSTOMER)),
    switchMap(({ payload }) => {
      const queryParams = getNonEmptyQueryParams({
        ...payload,
      });
      return api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/customers?${queryParams}`,
        })
        .pipe(
          map(({ response, xhr }) => {
            const count = parseInt(
              xhr.getResponseHeader("X-Total-Count") || "0",
              10
            );
            return searchForACustomerSuccess({ customers: response, count });
          }),
          catchError(({ status }) =>
            of(
              status === 404
                ? searchForACustomerSuccess({ customers: [], count: 0 })
                : searchForACustomerError()
            )
          )
        );
    })
  );

export const fetchSelectedUserDetailsEpic: Epic<
  | ActionType<typeof fetchSelectedUserDetailsSuccess>
  | ActionType<typeof fetchSelectedUserDetailsError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_SELECTED_USER_DETAILS)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/users/${payload}`,
        })
        .pipe(
          map(({ response }) => {
            const userDetails = {
              customerId: response.customerId,
              userId: response.userId,
              contactPerson: formatBackOfficeName(response.primaryContact),
              contactEmail: response.emailAddress,
            };
            return fetchSelectedUserDetailsSuccess(userDetails);
          }),
          catchError(() => of(fetchSelectedUserDetailsError()))
        )
    )
  );

export const checkIsCustomerAllowedEpic: Epic<
  | ActionType<typeof externalCustomerAllowedSuccess>
  | ActionType<typeof externalCustomerAllowedError>
> = (action$, _$, { api, config }) =>
  action$.pipe(
    filter(isOfType(CHECK_IS_EXTERNAL_CUSTOMER_ALLOWED)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/customers/IsCustomerAllowed/${payload.customerID}`,
        })
        .pipe(
          map(({ response }) => externalCustomerAllowedSuccess(response)),
          catchError(() => of(externalCustomerAllowedError()))
        )
    )
  );
