import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "custom-event-polyfill";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Auth, { AuthenticationProvider } from "@creditsafe/upp-authentication";
import { SplitFactory } from "@splitsoftware/splitio-react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { userSignedIn } from "./store/user/actions";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "@creditsafe/portfolios-shared-components";
import { initI18nClient } from "./config/i18n-client";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/core";
import { setLocalesAction } from "./store/i18n/actions";
import Helmet from "react-helmet";
import config from "./config";
import { localeConfig } from "config/i18n-locale-configs";

const splitIOConfig = {
  core: {
    authorizationKey: config.SPLITIO_KEY,
    key: "pedro.simao",
  },
};

Auth.configure({ appName: "data-cleaning" });
initI18nClient(config);
const store = configureStore();
const history = createBrowserHistory();

store.dispatch(setLocalesAction(localeConfig));

const Root = () => (
  <SplitFactory config={splitIOConfig}>
    <Provider store={store}>
      <Router history={history}>
        <AuthenticationProvider
          onSignin={(oidcUser) =>
            store.dispatch(userSignedIn(oidcUser.profile.sub))
          }
          history={history}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet>
              <script src={config.ADOBE_ANALYTICS_SCRIPT_URL} async></script>
            </Helmet>
            <App />
          </ThemeProvider>
        </AuthenticationProvider>
      </Router>
    </Provider>
  </SplitFactory>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
