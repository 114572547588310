import * as SettingsActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Enrichment } from "store/enrichments/types";
import { Mapping } from "store/mappings/types";
import { Status } from "store/types";

export type SettingsState = {
  masterTemplate?: {
    data: MasterTemplate[];
    status: Status;
    errorMsg?: string;
  };
  minimalMatchTemplate?: {
    data: MinimalMatchTemplate[];
    status: Status;
    errorMsg?: string;
  };
  enrichmentTemplate?: {
    data: Enrichment[];
    status: Status;
    errorMsg?: string;
  };
  mappingTemplate?: {
    data: Mapping[];
    status: Status;
    errorMsg?: string;
  };
  status?: {
    masterTemplate?: Status;
    minimalMatchTemplate?: Status;
    enrichmentTemplate?: Status;
  };
  selected?: {
    enrichmentTemplate?: {
      data?: Enrichment;
      status: Status;
      errorMsg: string;
    };
    minimalMatchTemplate?: {
      data?: MinimalMatchTemplate;
      status: Status;
      errorMsg: string;
    };
    mappingTemplate?: {
      data?: Mapping;
      status: Status;
      errorMsg: string;
    };
    masterTemplate?: {
      data?: MasterTemplate;
      status: Status;
      errorMsg: string;
    };
  };
  errMessages?: {
    masterTemplate?: ErrorResponse;
    minimalMatchTemplate?: ErrorResponse;
    enrichmentTemplate?: ErrorResponse;
  };
};

export interface ErrorResponse {
  errors: Record<string, string>;
  status: number;
  detail: string;
}

export interface Template {
  id: string;
  name: string;
}

export interface MasterTemplate {
  id: string;
  name: string;
  minimumMatchTemplate: MinimalMatchTemplate;
  enrichmentTemplate: Enrichment;
  mappingTemplate: Mapping;
}

export interface MasterTemplatePayload {
  id: string;
  name: string;
  minimumMatchTemplateId: string;
  enrichmentTemplateId: string;
  mappingTemplateId: string;
}

export interface MinimalMatchTemplate {
  id: string;
  name: string;
  minimumMatchCountries?: {
    countryCode: string;
    percentage: number;
  }[];
}

export interface MMRSavingPayload {
  name: string;
  minimumMatchCountries?: {
    countryCode: string;
    percentage: number;
  }[];
}

export interface EnrichmentTemplateSavingPayload {
  id?: string;
  name: string;
  creditType: string;
  de_ReasonId?: number;
  headQuarters?: string | null;
  enrichments: string[];
  isPublic?: boolean;
  algorithmBanding?: string;
}

export type SettingsActionTypes = ActionType<typeof SettingsActions>;

export const FETCH_MASTER_TEMPLATE = "FETCH_MASTER_TEMPLATE";
export const FETCH_MASTER_TEMPLATE_SUCCESS = "FETCH_MASTER_TEMPLATE_SUCCESS";
export const FETCH_MASTER_TEMPLATE_ERROR = "FETCH_MASTER_TEMPLATE_ERROR";

export const FETCH_MASTER_TEMPLATE_DETAILS = "FETCH_MASTER_TEMPLATE_DETAILS";
export const FETCH_MASTER_TEMPLATE_DETAILS_SUCCESS =
  "FETCH_MASTER_TEMPLATE_DETAILS_SUCCESS";
export const FETCH_MASTER_TEMPLATE_DETAILS_ERROR =
  "FETCH_MASTER_TEMPLATE_DETAILS_ERROR";

export const SAVE_MASTER_TEMPLATE = "SAVE_MASTER_TEMPLATE";
export const SAVE_MASTER_TEMPLATE_SUCCESS = "SAVE_MASTER_TEMPLATE_SUCCESS";
export const SAVE_MASTER_TEMPLATE_ERROR = "SAVE_MASTER_TEMPLATE_ERROR";

export const DELETE_MASTER_TEMPLATE = "DELETE_MASTER_TEMPLATE";
export const DELETE_MASTER_TEMPLATE_SUCCESS = "DELETE_MASTER_TEMPLATE_SUCCESS";
export const DELETE_MASTER_TEMPLATE_ERROR = "DELETE_MASTER_TEMPLATE_ERROR";

export const FETCH_MINIMAL_MATCH_TEMPLATE = "FETCH_MINIMAL_MATCH_TEMPLATE";
export const FETCH_MINIMAL_MATCH_TEMPLATE_SUCCESS =
  "FETCH_MINIMAL_MATCH_TEMPLATE_SUCCESS";
export const FETCH_MINIMAL_MATCH_TEMPLATE_ERROR =
  "FETCH_MINIMAL_MATCH_TEMPLATE_ERROR";

export const FETCH_MINIMAL_MATCH_TEMPLATEDETAILS =
  "FETCH_MINIMAL_MATCH_TEMPLATEDETAILS";
export const FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_SUCCESS =
  "FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_SUCCESS";
export const FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_ERROR =
  "FETCH_MINIMAL_MATCH_TEMPLATEDETAILS_ERROR";

export const SAVE_MINIMAL_MATCH_TEMPLATE = "SAVE_MINIMAL_MATCH_TEMPLATE";
export const SAVE_MINIMAL_MATCH_TEMPLATE_SUCCESS =
  "SAVE_MINIMAL_MATCH_TEMPLATE_SUCCESS";
export const SAVE_MINIMAL_MATCH_TEMPLATE_ERROR =
  "SAVE_MINIMAL_MATCH_TEMPLATE_ERROR";

export const UPDATE_MINIMAL_MATCH_TEMPLATE = "UPDATE_MINIMAL_MATCH_TEMPLATE";
export const UPDATE_MINIMAL_MATCH_TEMPLATE_SUCCESS =
  "UPDATE_MINIMAL_MATCH_TEMPLATE_SUCCESS";
export const UPDATE_MINIMAL_MATCH_TEMPLATE_ERROR =
  "UPDATE_MINIMAL_MATCH_TEMPLATE_ERROR";

export const DELETE_MINIMAL_MATCH_TEMPLATE = "DELETE_MINIMAL_MATCH_TEMPLATE";
export const DELETE_MINIMAL_MATCH_TEMPLATE_SUCCESS =
  "DELETE_MINIMAL_MATCH_TEMPLATE_SUCCESS";
export const DELETE_MINIMAL_MATCH_TEMPLATE_ERROR =
  "DELETE_MINIMAL_MATCH_TEMPLATE_ERROR";

export const FETCH_MAPPING_TEMPLATE = "FETCH_MAPPING_TEMPLATE";
export const FETCH_MAPPING_TEMPLATE_SUCCESS = "FETCH_MAPPING_TEMPLATE_SUCCESS";
export const FETCH_MAPPING_TEMPLATE_ERROR = "FETCH_MAPPING_TEMPLATE_ERROR";

export const FETCH_MAPPING_TEMPLATEDETAILS = "FETCH_MAPPING_TEMPLATEDETAILS";
export const FETCH_MAPPING_TEMPLATEDETAILS_SUCCESS =
  "FETCH_MAPPING_TEMPLATEDETAILS_SUCCESS";
export const FETCH_MAPPING_TEMPLATEDETAILS_ERROR =
  "FETCH_MAPPING_TEMPLATEDETAILS_ERROR";

export const FETCH_ENRICHMENT_TEMPLATE = "FETCH_ENRICHMENT_TEMPLATE";
export const FETCH_ENRICHMENT_TEMPLATE_SUCCESS =
  "FETCH_ENRICHMENT_TEMPLATE_SUCCESS";
export const FETCH_ENRICHMENT_TEMPLATE_ERROR =
  "FETCH_ENRICHMENT_TEMPLATE_ERROR";

export const FETCH_ENRICHMENT_TEMPLATEDETAILS =
  "FETCH_ENRICHMENT_TEMPLATEDETAILS";
export const FETCH_ENRICHMENT_TEMPLATEDETAILS_SUCCESS =
  "FETCH_ENRICHMENT_TEMPLATEDETAILS_SUCCESS";
export const FETCH_ENRICHMENT_TEMPLATEDETAILS_ERROR =
  "FETCH_ENRICHMENT_TEMPLATEDETAILS_ERROR";

export const SETTINGS_SAVE_ENRICHMENT_TEMPLATE =
  "SETTINGS_SAVE_ENRICHMENT_TEMPLATE";
export const SETTINGS_SAVE_ENRICHMENT_TEMPLATE_SUCCESS =
  "SETTINGS_SAVE_ENRICHMENT_TEMPLATE_SUCCESS";
export const SETTINGS_SAVE_ENRICHMENT_TEMPLATE_ERROR =
  "SETTINGS_SAVE_ENRICHMENT_TEMPLATE_ERROR";

export const SETTINGS_UPDATE_ENRICHMENT_TEMPLATE =
  "SETTINGS_UPDATE_ENRICHMENT_TEMPLATE";

export const DELETE_ENRICHMENT_TEMPLATE = "DELETE_ENRICHMENT_TEMPLATE";
export const DELETE_ENRICHMENT_TEMPLATE_SUCCESS =
  "DELETE_ENRICHMENT_TEMPLATE_SUCCESS";
export const DELETE_ENRICHMENT_TEMPLATE_ERROR =
  "DELETE_ENRICHMENT_TEMPLATE_ERROR";

export const RESET_STATUS = "RESET_STATUS";
export const RESET_SELECTED = "RESET_SELECTED";
