export interface ChartInputItem {
  name: string;
  value: number;
  percentage: string;
  color: string;
  label: string;
  activityCode?: number;
  countryCode?: string;
}

export interface IndustryBreakdownItem {
  activityCode: number;
  count: number;
  percentage: number;
}

export type USState = {
  [key: string]: { count: number; percentage: number };
};

export interface WidgetData {
  name: string;
  value: number;
  percentage: string;
  color: string;
  label: string;
  activityCode?: number;
  countryCode?: string;
}

export type WidgetVariant = "pie" | "donut" | "bar" | "map" | "unset";

export enum WidgetDisplayMode {
  HALF_WIDTH = "HALF_WIDTH",
  FULL_WIDTH = "FULL_WIDTH",
}

export interface WidgetDefinition {
  name: string;
  widgetType: WidgetVariant;
  colors: string[];
  jsonTag: string;
  isRequired: boolean;
  hasTooltip: boolean;
  pdfOnly?: boolean;
  isAddedByDefault?: boolean;
  displayMode: WidgetDisplayMode;
  hasPdfSpecificTitle?: boolean;
}

export interface Widgets {
  [key: string]: WidgetDefinition;
}

export interface Widget {
  title: string;
  data: WidgetData[];
  widgetType: WidgetVariant;
  JSONTag: string;
  isRequired: boolean;
  tooltip: string | null;
  pdfOnly?: boolean;
  isAddedByDefault?: boolean;
  displayMode: WidgetDisplayMode;
  hasPdfSpecificTitle?: boolean;
}

export interface WidgetGroup {
  title: string;
  widgets: Widget[];
}

export interface Aggregate {
  name: string | number;
  count: number;
  percentage: number;
}
