import { ajax } from "rxjs/ajax";
import request from "./api/request";
import config from "../config";
import * as adobeEvents from "../analyticsHelpers";
import i18n from "../config/i18n-client";
import numbro from "numbro";

export interface EpicDependencies {
  config: typeof config;
  ajax: typeof ajax;
  localStorage: typeof localStorage;
  api: {
    request: typeof request;
  };
  adobeEvents: typeof adobeEvents;
  i18n: typeof i18n;
  numbro: typeof numbro;
}

export default {
  config,
  ajax,
  localStorage,
  api: {
    request,
  },
  i18n,
  numbro,
  adobeEvents,
};
