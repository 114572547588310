import React from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { ChartInputItem } from "./types";
import { generateMapColorMultiplier } from "./utils";
import {
  countryListJSONMaps,
  countryGeoPropIdentifier,
  countryGeoProjectionConfig,
  countryGeoHeight,
  countryGeoWidth,
} from "../Mappings/constants";
import { WIDGET_TITLE_PROVINCE } from "./constants";

const ProvinceMap: React.FC<{
  chartData: ChartInputItem[];
  colors: string[];
  activeRegion: string;
  setActiveRegion: (code: string) => void;
  setTooltip: (tooltip: string) => void;
  country: string;
  countryFormatters: (t: TFunction, country: string, name: string) => string;
}> = ({
  chartData,
  colors,
  activeRegion,
  setActiveRegion,
  setTooltip,
  country,
  countryFormatters,
}) => {
  const { t } = useTranslation("aggregates");

  const generateColor = (value: string) => {
    const hasData = chartData.map((x) => formatRegion(x.name)).includes(value);
    if (hasData) {
      return colors[0];
    } else {
      return colors[1];
    }
  };

  const formatRegion = (region: string) =>
    region
      .split(".")
      .filter((x) => x !== WIDGET_TITLE_PROVINCE)
      .join("")
      .toLowerCase();

  const unformatRegion = (formattedRegion: string) =>
    `Province.${formattedRegion.toUpperCase()}`;

  const geoUrl = countryListJSONMaps[country.toLowerCase()];
  const geoProperty = countryGeoPropIdentifier[country.toLowerCase()];

  return (
    <>
      <ComposableMap
        projectionConfig={countryGeoProjectionConfig[country.toLowerCase()]}
        height={countryGeoHeight[country.toLowerCase()]}
        width={countryGeoWidth[country.toLowerCase()]}
        data-testid="province-map"
      >
        {chartData.length > 0 && (
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const name = countryFormatters(
                  t,
                  country,
                  geo.properties[geoProperty]
                );
                const d = chartData.find((s) => formatRegion(s.name) === name);
                const isActive =
                  formatRegion(activeRegion) === name &&
                  generateColor(name) === colors[0];

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke={isActive ? "black" : "grey"}
                    strokeWidth={isActive ? 0.3 : 0.1}
                    className={"pdfGeography"}
                    fill={generateColor(name)}
                    fillOpacity={generateMapColorMultiplier(
                      chartData,
                      0.25,
                      d?.percentage
                    )}
                    onMouseEnter={() => {
                      setActiveRegion(unformatRegion(name));
                      setTooltip(`${name.toUpperCase()} — ${d ? d.value : 0}`);
                    }}
                    onMouseLeave={() => {
                      setActiveRegion("");
                      setTooltip("");
                    }}
                  />
                );
              })
            }
          </Geographies>
        )}
      </ComposableMap>
    </>
  );
};

export default ProvinceMap;
