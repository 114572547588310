export enum JobStatus {
  created = "created",
  uploading = "uploading",
  uploaded = "uploaded",
  uploadError = "uploadError",
  ready = "ready",
  matching = "matching",
  matched = "matched",
  matchingError = "matchingError",
  addingToPortfolio = "addingToPortfolio",
  addedToPortfolio = "addedToPortfolio",
  addedToPortfolioError = "addedToPortfolioError",
  aggregating = "aggregating",
  aggregatingComplete = "aggregatingComplete",
  aggregatingError = "aggregatingError",
  enriching = "enriching",
  enrichmentError = "enrichmentError",
  enriched = "enriched",
  transformFileError = "transformFileError",
  generateTrilliumFileError = "generateTrilliumFileError",
  jobMatchingCompletionError = "jobMatchingCompletionError",
  jobMatchingComplete = "jobMatchingComplete",
  readyForEnriching = "readyForEnriching",
  enrichmentComplete = "enrichmentComplete",
  enrichmentCompletionError = "enrichmentCompleteError",
}

export enum LoadingEnum {
  uploading = "uploading",
  uploaded = "uploaded",
  uploadError = "uploadError",
  validating = "validating",
  validated = "validated",
  validateError = "validateError",
  submitting = "submitting",
  submitted = "submitted",
  submitError = "submitError",
  created = "created",
  creating = "creating",
  createError = "createError",
  assigningMasterTemplate = "assigningMasterTemplate",
  assignedMasterTemplate = "assignedMasterTemplate",
  assigningMasterTemplateError = "assigningMasterTemplateError",
  unset = "unset",
}

export const healthCheckOnlyDownloadableStatuses = [
  JobStatus.jobMatchingComplete,
  JobStatus.readyForEnriching,
  JobStatus.aggregatingComplete,
  JobStatus.enriching,
  JobStatus.enrichmentCompletionError,
];

export const allDownloadsAvailableStatuses = [
  JobStatus.enriched,
  JobStatus.enrichmentComplete,
];

const enrichableStatuses = [
  JobStatus.jobMatchingComplete,
  JobStatus.enrichmentComplete,
  JobStatus.enriched,
  JobStatus.enrichmentError,
  JobStatus.enrichmentCompletionError,
];
export const allowEnriching = (status: JobStatus) =>
  enrichableStatuses.includes(status);
