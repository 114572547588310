import * as AppActions from "./actions";
import { ActionType } from "typesafe-actions";

export const FETCH_APP_VERSION = "FETCH_APP_VERSION";
export const FETCH_APP_VERSION_SUCCESS = "FETCH_APP_VERSION_SUCCESS";
export const SET_REQUIRES_APP_UPDATE = "SET_REQUIRES_APP_UPDATE";

export interface AppState {
  requiresUpdate: boolean;
}

export type AppActionTypes = ActionType<typeof AppActions>;
