export const basicWidgetJsonTags = [
  "contact.additionToAddress",
  "contact.addressLine1",
  "contact.addressLine2",
  "contact.addressLine3",
  "contact.addressLine4",
  "contact.addressLine5",
  "contact.addressLine6",
  "contact.addressType",
  "contact.city",
  "contact.contactPhone",
  "contact.country",
  "contact.eircode",
  "contact.fax",
  "contact.houseNumber",
  "contact.mailing.city",
  "contact.mailing.houseNumber",
  "contact.mailing.postalCode",
  "contact.mailing.province",
  "contact.mailing.streetName",
  "contact.municipality",
  "contact.nonMailingIndicator",
  "contact.postalCode",
  "contact.postBox",
  "contact.province",
  "contact.region",
  "contact.specialDistribution",
  "contact.state",
  "contact.streetName",
  "contact.tps",
  "contact.zip4",
  "directors.addressLine1",
  "directors.addressLine2",
  "directors.addressLine3",
  "directors.addressLine4",
  "directors.postalCode",
  "directors.birthDate",
  "directors.birthPlace",
  "directors.city",
  "directors.endDate",
  "directors.firstName",
  "directors.fullNameKana",
  "directors.lastName",
  "directors.managerPositionCode",
  "directors.title",
  "directors.title.en",
  "directors.title.fr",
  "directors.title.nl",
  "financials.accountPeriodInMonths",
  "financials.accountsEndDate",
  "financials.accountsFrom",
  "financials.accountsMonths",
  "financials.accountsOfRegularisation",
  "financials.accountsRegularisation",
  "financials.accountsStartDate",
  "financials.accountsTo",
  "financials.accountsType",
  "financials.addedValue",
  "financials.advancedPayments",
  "financials.advancedPaymentsReceived",
  "financials.amortizationAndDepreciation",
  "financials.auditFees",
  "financials.auditors",
  "financials.auditorsQualification",
  "financials.balance.1to30",
  "financials.balance.31to60",
  "financials.balance.61to90",
  "financials.balance.91plus",
  "financials.balance.current",
  "financials.balance.period1Late",
  "financials.balance.period2Late",
  "financials.balance.period3Late",
  "financials.balance.total",
  "financials.bankOverdraft",
  "financials.capital",
  "financials.capitalEmployed",
  "financials.capitalNotCalled",
  "financials.cash",
  "financials.closureDate",
  "financials.consolidatedAccounts",
  "financials.contingentLiability",
  "financials.creditorDays",
  "rating.creditworthy",
  "financials.currency",
  "financials.currentDebtRatio",
  "financials.currentRatio",
  "financials.dbt.current",
  "financials.dbt.previous",
  "financials.debtorDays",
  "financials.depositedCapital",
  "financials.depreciation",
  "financials.directorRemuneration",
  "financials.directorsEmoluments",
  "financials.dividends",
  "financials.dividendsPayable",
  "financials.employeeCosts",
  "financials.employeePensionCosts",
  "financials.equityInPercentage",
  "financials.exports",
  "financials.extraordinaryCharges",
  "financials.extraordinaryIncome",
  "financials.extraordinaryItems",
  "financials.extraordinaryProfitLoss",
  "financials.financialAssets",
  "financials.financialCharges",
  "financials.financialFixedAssets",
  "financials.financialIncome",
  "financials.financialLiabilities",
  "financials.financialProfit",
  "financials.finishedGoods",
  "financials.gearingRatio",
  "financials.grossOperatingSurplus",
  "financials.grossProfit",
  "financials.intangibleAssets",
  "financials.intangibleFixedAssets",
  "financials.interestPayments",
  "financials.inventories",
  "financials.issuedShareCapital",
  "financials.landAndBuilding",
  "financials.tradeUpdateDate",
  "financials.latestAccountDate",
  "financials.liabilities",
  "financials.liquidityAcidTest",
  "financials.liquidityRatio",
  "financials.longTermLiabilities",
  "financials.miscCurrentAssets",
  "financials.miscCurrentLiabilities",
  "financials.netAssets",
  "financials.netCashflowBeforeFinancing",
  "financials.netCashflowFromFinancing",
  "financials.netCashflowFromOperations",
  "financials.netCurrentAssets",
  "financials.netExportTurnover",
  "financials.netResult",
  "financials.netTurnover",
  "financials.netWorkingCapital",
  "financials.netWorth",
  "financials.nominalCapital",
  "financials.operatingCharges",
  "financials.operatingIncome",
  "financials.operatingProfit",
  "financials.otherAmountsReceivable",
  "financials.otherCapitalResources",
  "financials.otherCurrentAssets",
  "financials.otherDebtsAndFixedAssets",
  "financials.otherEmployeeCosts",
  "financials.otherLongTermFinance",
  "financials.otherShortTermFinancials",
  "financials.otherStocks",
  "financials.otherTangibleAssets",
  "financials.otherTermLiabilities",
  "financials.otherTermLoans",
  "financials.paidUpCapital",
  "financials.placedCapital",
  "financials.plantAndMachinery",
  "financials.prepaidExpenses",
  "financials.preProfitMargin",
  "financials.preTaxMargin",
  "financials.pretaxNetOperatingIncome",
  "financials.preTaxProfit",
  "financials.profitAfterTax",
  "financials.profitBeforeTax",
  "financials.profitLoss",
  "financials.profitLossAccount",
  "financials.rawAndConsumables",
  "financials.receivables",
  "financials.recentHighCredit",
  "financials.reserves",
  "financials.resultsAfterTaxation",
  "financials.retainedProfits",
  "financials.returnOnCapitalEmployed",
  "financials.returnOnNetAssetsEmployed",
  "financials.returnOnTotalAssetsEmployed",
  "financials.revaluationReserve",
  "financials.riskProvisions",
  "financials.salesOfGoods",
  "financials.salesWorkingCapital",
  "financials.securitiesAndCash",
  "financials.shareCapital",
  "financials.shareholderFunds",
  "financials.shareholdersEquity",
  "financials.sharePremiumAccount",
  "financials.socialSecurityContributions",
  "financials.stocks",
  "financials.stockTurnoverRatio",
  "financials.sundryReserves",
  "financials.tangibleAssets",
  "financials.tangibleFixedAssets",
  "financials.taxAndSocialLiabilities",
  "financials.taxation",
  "financials.totalAssets",
  "financials.totalCurrentAssets",
  "financials.totalCurrentLiabilities",
  "financials.totalCurrentLibilities",
  "financials.totalDebtRatio",
  "financials.totalFixedAssets",
  "financials.totalLiabilities",
  "financials.totalLongTermLiabilities",
  "financials.totalOperatingExpenses",
  "financials.totalShareholdersEquity",
  "financials.totalTermDebts",
  "financials.totalEquity",
  "financials.tradeAccountPayables",
  "financials.tradeCreditors",
  "financials.tradeDebtors",
  "financials.tradelines",
  "financials.tradingMargin",
  "financials.turnover",
  "financials.wagesSalaries",
  "financials.workingCapital",
  "financials.workInProgress",
  "financials.yearOfBalance",
  "general.acronym",
  "general.active",
  "general.activeEconomically",
  "general.activityCode",
  "general.activityCode2",
  "general.activityCode2Desc.en",
  "general.activityCode2Desc.jp",
  "general.activityCodeDesc.en",
  "general.activityCodeDesc.de",
  "general.activityCodeDesc.fr",
  "general.activityCodeDesc.it",
  "general.activityCodeDesc.jp",
  "general.activityCodeDesc.nl",
  "general.branchNumber",
  "general.companyName",
  "general.companyNameKana",
  "general.companyNameKanji",
  "general.companyNumber",
  "general.companyType",
  "general.companyTypeDesc.en",
  "general.connectId",
  "general.continuanceDate",
  "general.discontinuanceDate",
  "general.duplicate",
  "general.duplicateId",
  "general.employees",
  "general.establishDate",
  "general.exportFlag",
  "general.federalTaxId",
  "general.formationDate",
  "general.foundingDeedDate",
  "general.ggsId",
  "general.importFlag",
  "general.inactivityDate",
  "general.incorporatedState",
  "general.incorporationDate",
  "general.juridicalForm",
  "general.juridicalFormDesc.en",
  "general.juridicalFormDesc.fr",
  "general.juridicalFormDesc.nl",
  "general.kvkNumber",
  "general.legalForm",
  "general.legalFormCategory",
  "general.legalFormDesc.en",
  "general.legalFormDesc.fr",
  "general.legalFormDesc.it",
  "general.legalFormDesc.nl",
  "general.legalFormDetails",
  "general.legalName",
  "general.legalNameAlt",
  "general.locationType",
  "general.mainOfficeFlag",
  "general.managerType",
  "general.managerTypeDesc.en",
  "general.managerTypeDesc.fr",
  "general.minimalWorkforceRange",
  "general.naicsCode",
  "general.naicsCodeDesc.en",
  "general.organisationNumber",
  "general.principalActivity",
  "general.regDeletionNumber",
  "general.registrationDate",
  "general.registrationNumber",
  "general.rowNumber",
  "general.rsin",
  "general.siren",
  "general.siret",
  "general.sosAddress",
  "general.sosCharterNo",
  "general.sosCity",
  "general.sosFederalTaxId",
  "general.sosState",
  "general.sosStatus",
  "general.sosZip",
  "general.sosZip4",
  "general.status",
  "general.statusDescShort.en",
  "general.statusDescShort.fr",
  "general.statusDesc.en",
  "general.statusDesc.fr",
  "general.statusDesc.nl",
  "general.taxCode",
  "general.tradeName",
  "general.tradingName",
  "general.vatLiable",
  "general.vatNumber",
  "general.website",
  "general.workforceCode",
  "general.workforceCodeDesc.en",
  "general.workforceCodeDesc.fr",
  "group.ultimateParent.inseeCityCode",
  "group.daughter.country",
  "group.daughter.name",
  "group.headquarter.id",
  "group.parent.companyNo",
  "group.parent.country",
  "group.parent.id",
  "group.parent.limit.current.value",
  "group.parent.name",
  "group.parent.rating.current.description.en",
  "group.parent.rating.current.description.nl",
  "group.parent.rating.current.score",
  "group.parent.safeNumber",
  "group.parent.siren",
  "group.parent.siret",
  "group.ultimateParent.address",
  "group.ultimateParent.city",
  "group.ultimateParent.companyNo",
  "group.ultimateParent.contactPhone",
  "group.ultimateParent.country",
  "group.ultimateParent.id",
  "group.ultimateParent.isoCode",
  "group.ultimateParent.limit.current.value",
  "group.ultimateParent.name",
  "group.ultimateParent.poBox",
  "group.ultimateParent.postalCode",
  "group.ultimateParent.rating.current.description.en",
  "group.ultimateParent.rating.current.description.nl",
  "group.ultimateParent.rating.current.score",
  "group.ultimateParent.safeNumber",
  "group.ultimateParent.siren",
  "limit.current.date",
  "limit.current.value",
  "limit.previous.value",
  "negatives.bankruptcy",
  "negatives.bankruptcyAmount",
  "negatives.bankruptcyCount",
  "negatives.bankruptcyDate",
  "negatives.ccjCount",
  "negatives.ccjValue",
  "negatives.chequeAmount",
  "negatives.chequeCount",
  "negatives.chequeDate",
  "negatives.collectionAmount",
  "negatives.collectionCases",
  "negatives.collectionCount",
  "negatives.collectionDate",
  "negatives.delinquencyCount",
  "negatives.delinquencyAmount",
  "negatives.judgementAmount",
  "negatives.judgementCount",
  "negatives.judgementDate",
  "negatives.lienAmount",
  "negatives.lienCount",
  "negatives.lienDate",
  "negatives.ofacFlag",
  "negatives.otherItemAmount",
  "negatives.otherItemCount",
  "negatives.otherItemDate",
  "negatives.suitAmount",
  "negatives.suitCount",
  "negatives.suitDate",
  "negatives.totalCollectionAmount",
  "negatives.uccCautionaryCount",
  "negatives.uccCount",
  "rating.current",
  "rating.current.cds",
  "rating.current.colour",
  "rating.current.common",
  "rating.current.creditIndex",
  "rating.current.score",
  "rating.current.date",
  "rating.current.description.en",
  "rating.current.description.nl",
  "rating.current.description.fr",
  "rating.current.description.no",
  "rating.current.description.jp",
  "rating.current.fullDescription.en",
  "rating.current.fullDescription.no",
  "rating.previous.common",
  "rating.previous.score",
  "directors.surname",
  "directors.fullName",
  "general.principalActivity2",
  "general.principalActivity3",
  "general.principalActivity4",
  "general.principalActivity5",
  "general.mortgages.partiallySatisfied",
  "general.mortgages.satisfied",
  "general.mortgages.outstanding",
  "general.mortgages.total",
  "general.yearsTrading",
  "general.ltdNonLtdFlag",
  "contact.mailing.addressLine1",
  "contact.mailing.addressLine2",
  "contact.mailing.addressLine3",
  "contact.mailing.addressLine4",
  "directors.salutation",
  "directors.middleName",
  "directors.appointmentDate",
  "directors.nationality",
  "directors.honours",
  "directors.region",
  "directors.country",
  "financials.plAccountReserve",
  "financials.otherShortTermFinance",
  "financials.retainedProfit",
  "financials.bankOverdraftLtl",
  "financials.otherCurrentDebtors",
  "financials.costOfSales",
  "financials.paidShareCapital",
  "financials.preTaxProfitMargin",
  "financials.auditComments",
  "financials.bankers",
  "financials.bankBranchCode",
  "rating.previous.description.en",
  "rating.previous.description.no",
  "rating.previous.fullDescription.en",
  "rating.previous.fullDescription.no",
  "rating.previous.date",
  "shareholders.name",
  "shareholders.info",
  "shareholders.currency",
  "general.companyId",
  "general.legalFormDesc.de",
  "general.previousName",
  "general.registrationLocation",
  "contact.email",
  "financials.shareCapitalCurrency",
  "financials.bankCode",
  "financials.bankName",
  "rating.current.local",
  "rating.current.pod",
  "directors.gender",
  "group.parent.addressLine1",
  "group.parent.city",
  "group.parent.postalCode",
  "financials.accountingDate",
  "financials.numberOfWeeks",
  "financials.assets",
  "financials.fixedAssets",
  "financials.currentAssets",
  "financials.inventory",
  "financials.receivablesAndOther",
  "financials.therefromTradeReceivables",
  "financials.securities",
  "financials.liquidAssets",
  "financials.deferedTax",
  "financials.activeDiffBetweenAssetAllocation",
  "financials.deficitNcbCapital",
  "financials.deficitNcblLossCapital",
  "financials.balanceSheetTotalAssets",
  "financials.equity",
  "financials.subscribedCapital",
  "financials.privateAccount",
  "financials.sharesNcblUnlimitedPartners",
  "financials.sharesNcblLimitedPartners",
  "financials.fixedEquity",
  "financials.variableEquity",
  "financials.capitalReserve",
  "financials.revenueReserve",
  "financials.retainedEarnings",
  "financials.balanceSheetNetIncome",
  "financials.accruals",
  "financials.therefromAccrualsPension",
  "financials.therefromAccrualsTax",
  "financials.therefromTradePayables",
  "financials.defferedIncome",
  "financials.defferedLiabilities",
  "financials.balanceSheetTotal",
  "financials.inventoryChange",
  "financials.ownWork",
  "financials.otherOperationalRevenue",
  "financials.materials",
  "financials.staff",
  "financials.amortisation",
  "financials.otherCosts",
  "financials.operationalResult",
  "financials.therefromOtherInterestSimilarIncome",
  "financials.therefromInterestsSimilarExpenses",
  "financials.financialResult",
  "financials.ordinaryOperationalResult",
  "financials.extraordinaryResult",
  "financials.tax",
  "financials.otherTaxes",
  "financials.netIncome",
  "general.aliasName",
  "general.leiNumber",
  "general.leiRenewalDate",
  "contact.marketingAllowed",
  "general.activityCodeDesc.se",
  "financials.dbt.local",
  "financials.profit",
  "general.legalRegistrationNumber",
  "general.legalFormDesc.no",
  "contact.mailing.addessLine1",
  "contact.officeAddress1",
  "contact.officeAddress2",
  "contact.officeAddress3",
  "general.registeredInForetaksreg",
  "general.priority",
  "general.activityCode3",
  "general.activityCode3Desc.en",
  "general.sectorCode",
  "limit.previous.date",
  "financials.totalOperatingIncome",
  "financials.salesRevenue",
  "financials.otherOperatingIncome",
  "financials.costOfStocks",
  "financials.changeInStock",
  "financials.changeInWorkInProgress",
  "financials.writeDownOfFixedAssets",
  "financials.otherOperatingExpenses",
  "financials.allocatedDividend",
  "financials.revenueSubsidiaries",
  "financials.revenueGroupCompanies",
  "financials.revenueAssociatedCompanies",
  "financials.interestIncomeGroup",
  "financials.otherInterestIncome",
  "financials.otherFinancialIncome",
  "financials.changeValueCurrentAssets",
  "financials.writedownsCurrentAssets",
  "financials.writedownsFixedAssets",
  "financials.otherInterestExpensesGroup",
  "financials.otherInterestExpenses",
  "financials.otherFinancialExpenses",
  "financials.otherFinancialCosts",
  "financials.financialExpenses",
  "financials.totalNetFinancialItems",
  "financials.netProfitLoss",
  "financials.taxOnExtraordinaryResult",
  "financials.minorityShare",
  "financials.netResultAfterDividends",
  "financials.groupContributions",
  "financials.allocationOtherEquity",
  "financials.totalTransfersAllocations",
  "financials.researchAndDevelopment",
  "financials.concessions",
  "financials.deferredTaxAsset",
  "financials.goodwillIntangibleAssets",
  "financials.otherIntangibleFixedAssets",
  "financials.landAndBuildings",
  "financials.shipsRigsAircraft",
  "financials.operatingEquipment",
  "financials.otherOperationFactors",
  "financials.totalTangibleAssets",
  "financials.sharesInvestmentSubsidiaries",
  "financials.investmentsInGroupCompanies",
  "financials.investmentsInAssociatedCompanies",
  "financials.investmentsInShares",
  "financials.bondsAndAccountsReceivables",
  "financials.pensionFunds",
  "financials.otherFinancialFixedAssets",
  "financials.bonds",
  "financials.otherReceivables",
  "financials.otherReceivables2",
  "financials.otherFixedAssets",
  "financials.rawMaterials",
  "financials.otherInventories",
  "financials.totalInventories",
  "financials.tradeReceivables",
  "financials.receivablesToCompInGroup",
  "financials.totalReceivable",
  "financials.sharesAndInterestsInGroup",
  "financials.quotedInvestmentShares",
  "financials.quotedBonds",
  "financials.otherMarkedBasedFinancial",
  "financials.otherFinancialInstr",
  "financials.otherInvestments",
  "financials.cashAndBankDeposits",
  "financials.totalFunds",
  "financials.ownShares",
  "financials.sharePremiumReserve",
  "financials.otherPaidInCapital",
  "financials.totalEquityDeposits",
  "financials.valuationReserve",
  "financials.otherEquity",
  "financials.minorityInterestsGroups",
  "financials.totalRetainedEarnings",
  "financials.otherReserves",
  "financials.longtermPensionCommitments",
  "financials.deferredTaxLiabilities",
  "financials.otherProvisions",
  "financials.totalProvisionsLiabilities",
  "financials.convertibleLoans",
  "financials.otherLongtermLoans",
  "financials.longtermLiabilitiesFinInst",
  "financials.longtermGroupContrLiabilities",
  "financials.liableLoanCapital",
  "financials.otherLongtermLiabilities",
  "financials.totalOtherLongtermLiabilities",
  "financials.convertibleLoan",
  "financials.certificateLoan",
  "financials.shorttermLiabilitiesFinInst",
  "financials.payableTax",
  "financials.specialGovernmentTaxes",
  "financials.suggestedDividend",
  "financials.shorttermLiabilitiesGroup",
  "financials.otherCreditors",
  "financials.totalLiabilitiesEquity",
  "financials.solvencyRatio",
  "group.ultimateParent.entityType",
  "contact.paf.addressLine1",
  "contact.paf.addressLine2",
  "contact.paf.addressLine3",
  "contact.paf.addressLine4",
  "contact.paf.postalCode",
  "general.tradeNumber",
  "directors.state",
  "financialsNonConsolidated.accountingDate",
  "financialsNonConsolidated.numberOfWeeks",
  "financialsNonConsolidated.currency",
  "financialsNonConsolidated.assets",
  "financialsNonConsolidated.intangibleAssets",
  "financialsNonConsolidated.fixedAssets",
  "financialsNonConsolidated.financialAssets",
  "financialsNonConsolidated.currentAssets",
  "financialsNonConsolidated.inventory",
  "financialsNonConsolidated.receivablesAndOther",
  "financialsNonConsolidated.securities",
  "financialsNonConsolidated.prepaidExpenses",
  "financialsNonConsolidated.deferedTax",
  "financialsNonConsolidated.activeDiffBetweenAssetAllocation",
  "financialsNonConsolidated.deficitNcbCapital",
  "financialsNonConsolidated.deficitNcblLossCapital",
  "financialsNonConsolidated.balanceSheetTotalAssets",
  "financialsNonConsolidated.equity",
  "financialsNonConsolidated.subscribedCapital",
  "financialsNonConsolidated.privateAccount",
  "financialsNonConsolidated.sharesNcblUnlimitedPartners",
  "financialsNonConsolidated.sharesNcblLimitedPartners",
  "financialsNonConsolidated.fixedEquity",
  "financialsNonConsolidated.variableEquity",
  "financialsNonConsolidated.capitalReserve",
  "financialsNonConsolidated.revenueReserve",
  "financialsNonConsolidated.retainedEarnings",
  "financialsNonConsolidated.balanceSheetNetIncome",
  "financialsNonConsolidated.profitLoss",
  "financialsNonConsolidated.accruals",
  "financialsNonConsolidated.therefromAccrualsPension",
  "financialsNonConsolidated.therefromAccrualsTax",
  "financialsNonConsolidated.liabilities",
  "financialsNonConsolidated.therefromTradePayables",
  "financialsNonConsolidated.defferedIncome",
  "financialsNonConsolidated.defferedLiabilities",
  "financialsNonConsolidated.balanceSheetTotal",
  "financialsNonConsolidated.inventoryChange",
  "financialsNonConsolidated.ownWork",
  "financialsNonConsolidated.otherOperationalRevenue",
  "financialsNonConsolidated.materials",
  "financialsNonConsolidated.grossProfit",
  "financialsNonConsolidated.staff",
  "financialsNonConsolidated.amortisation",
  "financialsNonConsolidated.otherCosts",
  "financialsNonConsolidated.operationalResult",
  "financialsNonConsolidated.therefromOtherInterestSimilarIncome",
  "financialsNonConsolidated.therefromInterestsSimilarExpenses",
  "financialsNonConsolidated.financialResult",
  "financialsNonConsolidated.ordinaryOperationalResult",
  "financialsNonConsolidated.extraordinaryResult",
  "financialsNonConsolidated.tax",
  "financialsNonConsolidated.otherTaxes",
  "financialsNonConsolidated.netIncome",
  "financialsNonConsolidated.liquidAssets",
  "financialsNonConsolidated.therefromTradeReceivables",
  "general.safeNumber",
  "general.pbi",
  "general.pei",
  "general.numberOfEstablishments",
];

export const tooltippedWidgetJsonTags = [
  "financialsNonConsolidated.liquidAssets",
  "financialsNonConsolidated.therefromTradeReceivables",
  "financials.therefromTradeReceivables",
  "general.active",
  "financials.liquidAssets",
  "general.siret",
  "general.siren",
  "financials.accountPeriodInMonths",
  "financials.consolidatedAccounts",
  "general.companyName",
  "contact.addressLine1",
  "contact.city",
  "contact.postalCode",
  "contact.zip4",
  "general.safeNumber",
];

export const requiredWidgetJsonTags = ["general.safeNumber"];

export const requiredAndTooltippedWidgetJsonTags = ["general.safeNumber"];

export const defaultAddedWidgetJsonTags = [
  "general.pattern",
  "general.banding",
  "general.bandingDesc",
];
