import Loadable from "react-loadable";
import LoadingSpinner from "../LoadingSpinner";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyLoadable = (opts: any) => {
  return Loadable({
    ...opts,
    loading: LoadingSpinner,
  });
};

export const AsyncPageNotFound = MyLoadable({
  loader: () => import("../../pages/PageNotFound"),
});

export const AsyncHome = MyLoadable({
  loader: () => import("../../pages/Home"),
});

export const AsyncJobs = MyLoadable({
  loader: () => import("../../pages/Jobs"),
});

export const AsyncDashboard = MyLoadable({
  loader: () => import("../../pages/Admin"),
});

export const AsyncNewFile = MyLoadable({
  loader: () => import("../../pages/NewFile"),
});

export const AsyncSettings = MyLoadable({
  loader: () => import("../../pages/Settings"),
});
