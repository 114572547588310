import * as I18nActions from "./actions";
import { ActionType } from "typesafe-actions";

export const SET_LOCALES = "SET_LOCALES";
export const LOAD_LOCALES = "LOAD_LOCALES";
export const LANGUAGE_CHANGED = "LANGUAGE_CHANGED";

export interface Locale {
  code: string;
}

export type SetLocalesPayload = Locale[];

export interface I18nState {
  locales: Locale[];
}

export type I18nActionsTypes = ActionType<typeof I18nActions>;
