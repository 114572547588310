import {
  CLEAR_POPUP_NOTIFICATION,
  FETCH_ALGORITHM_BANDINGS,
  FETCH_ALGORITHM_BANDINGS_ERROR,
  FETCH_ALGORITHM_BANDINGS_SUCCESS,
  FETCH_MAPPING_ENRICHMENT_TEMPLATES,
  FETCH_MAPPING_ENRICHMENT_TEMPLATES_ERROR,
  FETCH_MAPPING_ENRICHMENT_TEMPLATES_SUCCESS,
  RESET_ENRICHMENT_TEMPLATE_SAVE_STATUS,
  SaveEnrichmentTemplatePayload,
  SAVE_ENRICHMENT_TEMPLATE,
  SAVE_ENRICHMENT_TEMPLATE_ERROR,
  SAVE_ENRICHMENT_TEMPLATE_SUCCESS,
} from "./types";
import { action } from "typesafe-actions";
import { Enrichment } from "../enrichments/types";

export const fetchMappingEnrichmentTemplates = (payload: {
  creditType: string;
}) => action(FETCH_MAPPING_ENRICHMENT_TEMPLATES, payload);
export const fetchMappingEnrichmentTemplatesSuccess = (payload: Enrichment[]) =>
  action(FETCH_MAPPING_ENRICHMENT_TEMPLATES_SUCCESS, payload);
export const fetchMappingEnrichmentTemplatesError = () =>
  action(FETCH_MAPPING_ENRICHMENT_TEMPLATES_ERROR);

export const fetchAlgorithmBandings = () => action(FETCH_ALGORITHM_BANDINGS);
export const fetchAlgorithmBandingsSuccess = (payload: string[]) =>
  action(FETCH_ALGORITHM_BANDINGS_SUCCESS, payload);
export const fetchAlgorithmBandingsError = () =>
  action(FETCH_ALGORITHM_BANDINGS_ERROR);

export const saveEnrichmentTemplate = (
  mappingTemplate: SaveEnrichmentTemplatePayload
) => action(SAVE_ENRICHMENT_TEMPLATE, mappingTemplate);
export const saveEnrichmentTemplateSuccess = () =>
  action(SAVE_ENRICHMENT_TEMPLATE_SUCCESS);
export const saveEnrichmentTemplateError = () =>
  action(SAVE_ENRICHMENT_TEMPLATE_ERROR);
export const saveEnrichmentTemplateSaveStatusReset = () =>
  action(RESET_ENRICHMENT_TEMPLATE_SAVE_STATUS);

export const clearPopupDialogAction = () => action(CLEAR_POPUP_NOTIFICATION);
