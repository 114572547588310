import {
  UserSignedInPayload,
  USER_SIGNED_IN,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_ERROR,
  FetchUserDetailsSuccessPayload,
  FETCH_REMAINING_CREDITS_SUCCESS,
  FETCH_REMAINING_CREDITS_ERROR,
  ServiceLine,
  FETCH_ENTITY_TEL_SUCCESS,
  CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL,
  IS_USER_ALLOWED_FOR_SEARCHPANEL_SUCCESS,
  IS_USER_ALLOWED_FOR_SEARCHPANEL_ERROR,
  FETCH_MONITORING_PORTFOLIOS,
  FETCH_MONITORING_PORTFOLIOS_SUCCESS,
  FETCH_MONITORING_PORTFOLIOS_ERROR,
  MonitoringPortfolio,
} from "./types";
import { action } from "typesafe-actions";

export const userSignedIn = (payload: UserSignedInPayload) =>
  action(USER_SIGNED_IN, payload);

export const fetchUserDetailsSuccess = (
  payload: FetchUserDetailsSuccessPayload
) => action(FETCH_USER_DETAILS_SUCCESS, payload);

export const fetchUserDetailsError = () => action(FETCH_USER_DETAILS_ERROR);

export const fetchRemainingCreditsSuccess = (payload: ServiceLine[]) =>
  action(FETCH_REMAINING_CREDITS_SUCCESS, payload);

export const fetchRemainingCreditsError = () =>
  action(FETCH_REMAINING_CREDITS_ERROR);

export const fetchEntityTelSuccess = (tel: string) =>
  action(FETCH_ENTITY_TEL_SUCCESS, tel);

export const checkIsUserAllowedForSearchPanel = (payload: {
  userID: string | null;
  customerID: number | undefined;
}) => action(CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL, payload);

export const userAllowedForSearchPanelSuccess = (allowed: boolean) =>
  action(IS_USER_ALLOWED_FOR_SEARCHPANEL_SUCCESS, allowed);

export const userAllowedForSearchPanelError = () =>
  action(IS_USER_ALLOWED_FOR_SEARCHPANEL_ERROR);

export const fetchMonitoringPortfolios = () =>
  action(FETCH_MONITORING_PORTFOLIOS);

export const fetchMonitoringPortfoliosSuccess = (
  monitoringPortfolios: MonitoringPortfolio[]
) => action(FETCH_MONITORING_PORTFOLIOS_SUCCESS, monitoringPortfolios);

export const fetchMonitoringPortfoliosError = () =>
  action(FETCH_MONITORING_PORTFOLIOS_ERROR);
