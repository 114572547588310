import {
  CLONE_JOB,
  CLONE_JOB_ERROR,
  CLONE_JOB_SUCCESS,
  AdminActionTypes,
  AdminJobState,
  FETCH_DASHBOARD_JOB_DETAILS,
  FETCH_DASHBOARD_JOB_DETAILS_ERROR,
  FETCH_DASHBOARD_JOB_DETAILS_SUCCESS,
  RESET_DASHBOARD,
  FETCH_JOB_TRANSACTIONS,
  FETCH_JOB_TRANSACTIONS_ERROR,
  FETCH_JOB_TRANSACTIONS_SUCCESS,
  FETCH_JOB_FILE,
  FETCH_JOB_FILE_SUCCESS,
  FETCH_JOB_FILE_ERROR,
  FETCH_ADMIN_JOB_AUTO_ENRICHMENT,
  FETCH_ADMIN_JOB_AUTO_ENRICHMENT_SUCCESS,
  FETCH_ADMIN_JOB_AUTO_ENRICHMENT_ERROR,
  FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME,
  FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_SUCCESS,
  FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_ERROR,
  FETCH_DASHBOARD_JOB_SELECTED_WIDGETS,
  FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_SUCCESS,
  FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_ERROR,
} from "./types";
import { Status } from "../types";
import {
  FETCH_JOB_SAMPLE,
  FETCH_JOB_SAMPLE_ERROR,
  FETCH_JOB_SAMPLE_SUCCESS,
} from "store/jobs/types";

export const initialState: AdminJobState = {
  allIds: [],
  byId: {},
  status: Status.unset,
  fetchJobFileStatus: Status.unset,
  Job: undefined,
  fetchJobStatus: Status.unset,
  cloneJobInfo: { jobId: "", status: Status.unset },
  jobSampleData: { data: [], status: Status.unset },
  fetchJobEnrichmentsStatus: Status.unset,
};

// eslint-disable-next-line complexity
export default function (
  state = initialState,
  action: AdminActionTypes
): AdminJobState {
  switch (action.type) {
    case FETCH_DASHBOARD_JOB_SELECTED_WIDGETS: {
      return {
        ...state,
        enrichments: undefined,
        fetchJobEnrichmentsStatus: Status.loading,
      };
    }
    case FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_SUCCESS: {
      return {
        ...state,
        enrichments: action.payload,
        fetchJobEnrichmentsStatus: Status.loaded,
      };
    }
    case FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_ERROR: {
      return {
        ...state,
        fetchJobEnrichmentsStatus: Status.errorLoading,
      };
    }

    case FETCH_DASHBOARD_JOB_DETAILS: {
      return {
        ...state,
        fetchJobStatus: Status.loading,
        Job: undefined,
      };
    }
    case FETCH_DASHBOARD_JOB_DETAILS_SUCCESS: {
      return {
        ...state,
        fetchJobStatus: Status.loaded,
        Job: action.payload.dashboardJob,
      };
    }
    case FETCH_DASHBOARD_JOB_DETAILS_ERROR: {
      return {
        ...state,
        fetchJobStatus: Status.errorLoading,
      };
    }
    case FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME: {
      return {
        ...state,
        fetchJobStatus: Status.loading,
        Job: undefined,
      };
    }
    case FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_SUCCESS: {
      return {
        ...state,
        fetchJobStatus: Status.loaded,
        Job: action.payload.dashboardJob,
      };
    }
    case FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_ERROR: {
      return {
        ...state,
        fetchJobStatus: Status.errorLoading,
        jobAutoEnrichmentData: {
          status: Status.errorLoading,
          data: undefined,
        },
      };
    }
    case FETCH_ADMIN_JOB_AUTO_ENRICHMENT: {
      return {
        ...state,
        jobAutoEnrichmentData: {
          status: Status.loading,
          data: undefined,
        },
      };
    }
    case FETCH_ADMIN_JOB_AUTO_ENRICHMENT_SUCCESS: {
      return {
        ...state,
        jobAutoEnrichmentData: {
          status: Status.loaded,
          data: action.payload,
        },
      };
    }
    case FETCH_ADMIN_JOB_AUTO_ENRICHMENT_ERROR: {
      return {
        ...state,
        jobAutoEnrichmentData: {
          status: Status.errorLoading,
          data: undefined,
        },
      };
    }
    case CLONE_JOB: {
      return {
        ...state,
        cloneJobInfo: { jobId: "", status: Status.saving, errorMsg: "" },
      };
    }
    case CLONE_JOB_SUCCESS: {
      return {
        ...state,
        cloneJobInfo: {
          jobId: action.payload.id,
          status: Status.saved,
          errorMsg: "",
        },
      };
    }
    case CLONE_JOB_ERROR: {
      return {
        ...state,
        cloneJobInfo: {
          jobId: "",
          status: Status.errorSaving,
          errorMsg: action.payload,
        },
      };
    }
    case FETCH_JOB_SAMPLE: {
      return {
        ...state,
        jobSampleData: { data: [], status: Status.loading, errorMsg: "" },
      };
    }
    case FETCH_JOB_SAMPLE_SUCCESS: {
      return {
        ...state,
        jobSampleData: {
          data: action.payload.sample,
          status: Status.loaded,
          errorMsg: "",
        },
      };
    }
    case FETCH_JOB_SAMPLE_ERROR: {
      return {
        ...state,
        jobSampleData: {
          data: [],
          status: Status.errorLoading,
          errorMsg: "Error occured while loading sample data",
        },
      };
    }
    case FETCH_JOB_FILE: {
      return {
        ...state,
        fetchJobFileStatus: Status.loading,
      };
    }
    case FETCH_JOB_FILE_SUCCESS: {
      return {
        ...state,
        fetchJobFileStatus: Status.loaded,
      };
    }
    case FETCH_JOB_FILE_ERROR: {
      return {
        ...state,
        fetchJobFileStatus: Status.errorLoading,
      };
    }
    case FETCH_JOB_TRANSACTIONS: {
      return {
        ...state,
        jobTransaction: { data: [], status: Status.loading },
      };
    }
    case FETCH_JOB_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        jobTransaction: {
          data: action.payload,
          status: Status.loaded,
          errorMsg: "",
        },
      };
    }
    case FETCH_JOB_TRANSACTIONS_ERROR: {
      return {
        ...state,
        jobTransaction: {
          data: [],
          status: Status.errorLoading,
          errorMsg: "Error occured while loading transactions",
        },
      };
    }

    case RESET_DASHBOARD: {
      return initialState;
    }

    default:
      return state;
  }
}
