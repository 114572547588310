import widgets from "./widgets";
import * as colors from "./colors";

export const getCountryWidgets = (countryCode: string) => {
  switch (countryCode.toLowerCase()) {
    case "se":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalCompanyNameWidget,
            widgets.generalTradeNameWidget,
            widgets.generalAliasNameWidget,
            widgets.generalPreviousNameWidget,
            widgets.generalOrganisationNumberWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalCompanyNumberWidget,
            widgets.generalVatNumberWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactAddressLine2Widget,
            widgets.contactAddressLine3Widget,
            widgets.contactCityWidget,
            widgets.contactAddressLine5Widget,
            widgets.contactRegionAggregateWidget,
            widgets.contactPostalCodeWidget,
            widgets.contactCountryWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            widgets.generalLeiNumberWidget,
            widgets.generalLeiRenewalDateWidget,
            widgets.contactContactPhoneWidget,
            widgets.contactMarketingAllowedWidget,
            widgets.contactEmailWidget,
            widgets.generalWebsiteWidget,
            widgets.generalCompanyTypeAggregateWidget,
            widgets.generalIncorporationDateWidget,
            widgets.generalActivityCodeAggregateWidgetPdfSpecificTitle,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCodeDescSeWidget,
            {
              ...widgets.generalEmployeesAggregateWidget,
              colors: colors.EMPLOYEE_COUNT_COLORS_GRADIENT_BLUE,
            },
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingCurrentScoreWidget,
            widgets.ratingPreviousScoreWidget,
            widgets.ratingPreviousDescriptionEnWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.ratingCreditworthyWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsDbtCurrentWidget,
            widgets.financialsDbtLocalWidget,
            widgets.financialsLatestAccountDateWidget,
            widgets.financialsConsolidatedAccountsWidget,
            widgets.financialsCurrencyWidget,
            {
              ...widgets.financialsTurnoverAggregateWidget,
              widgetType: "donut",
              colors: colors.SE_TURNOVER_COLORS,
            },
            widgets.financialsPreTaxProfitWidget,
            widgets.financialsProfitWidget,
            widgets.financialsTotalFixedAssetsWidget,
            widgets.financialsTotalCurrentAssetsWidget,
            widgets.financialsTotalCurrentLiabilitiesWidget,
            widgets.financialsTotalLongTermLiabilitiesWidget,
            widgets.financialsShareholderFundsWidget,
            widgets.financialsNetWorthWidget,
            widgets.financialsWorkingCapitalWidget,
            widgets.financialsCurrentRatioWidget,
            widgets.financialsPreTaxProfitMarginWidget,
            widgets.financialsReturnOnTotalAssetsEmployedWidget,
            widgets.financialsTotalDebtRatioWidget,
            widgets.financialsCurrentDebtRatioWidget,
            widgets.financialsShareCapitalWidget,
          ],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupParentNameWidget,
            widgets.groupParentCountryWidget,
            widgets.groupParentIdWidget,
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentCountryWidget,
            widgets.groupUltimateParentIdWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "ca":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalCompanyNumberWidget,
            widgets.generalCompanyNameWidget,
            {
              ...widgets.generalEstablishDateAggregateWidget,
              hasPdfSpecificTitle: true,
            },
          ],
        },
        {
          group: "contactInformation",
          widgets: [
            widgets.contactAddressLine1Widget,
            widgets.contactPostalCodeWidget,
            widgets.contactCityWidget,
            widgets.contactProvinceAggregateWidget,
            widgets.contactCountryWidget,
            widgets.contactContactPhoneWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            {
              ...widgets.generalEmployeesAggregateWidget,
              colors: colors.EMPLOYEE_COUNT_COLORS_GRADIENT_BLUE,
            },
            widgets.generalStatusDescEnWidget,
            widgets.generalActiveWidget,
            widgets.generalActivityCodeWidget,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalNaicsCodeWidget,
            widgets.generalNaicsCodeDescEnWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsCurrencyWidget,
            widgets.financialsTurnoverWidget,
            widgets.financialsTradelinesWidget,
            widgets.financialsBalanceTotalWidget,
            widgets.financialsRecentHighCreditWidget,
            widgets.financialsBalanceCurrentWidget,
            widgets.financialsBalancePeriod1LateWidget,
            widgets.financialsBalancePeriod2LateWidget,
            widgets.financialsBalancePeriod3LateWidget,
            widgets.financialsDbtCurrentCAAggregateWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentScoreCAAggregateWidget,
            widgets.ratingCurrentCreditIndexWidget,
            widgets.ratingCurrentCdsWidget,
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.limitCurrentValueWidget,
          ],
        },
        {
          group: "negativeInformation",
          widgets: [
            widgets.negativesDelinquencyCountWidget,
            widgets.negativesDelinquencyAmountWidget,
            widgets.negativesCollectionCountWidget,
            widgets.negativesCollectionAmountWidget,
            widgets.negativesCollectionDateWidget,
            widgets.negativesSuitCountWidget,
            widgets.negativesSuitAmountWidget,
            widgets.negativesSuitDateWidget,
            widgets.negativesJudgementCountWidget,
            widgets.negativesJudgementAmountWidget,
            widgets.negativesJudgementDateWidget,
            widgets.negativesChequeCountWidget,
            widgets.negativesChequeAmountWidget,
            widgets.negativesChequeDateWidget,
            widgets.negativesLienCountWidget,
            widgets.negativesLienAmountWidget,
            widgets.negativesLienDateWidget,
            widgets.negativesBankruptcyCountWidget,
            widgets.negativesBankruptcyAmountWidget,
            widgets.negativesBankruptcyDateWidget,
            widgets.negativesOtherItemCountWidget,
            widgets.negativesOtherItemAmountWidget,
            widgets.negativesOtherItemDateWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [widgets.directorsFullNameWidget],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupParentIdWidget,
            widgets.groupParentNameWidget,
            widgets.groupParentCountryWidget,
            widgets.groupUltimateParentIdWidget,
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentCountryWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "no":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalLegalRegistrationNumberWidget,
            widgets.generalCompanyNameWidget,
            widgets.generalPreviousNameWidget,
            widgets.generalLegalNameAltWidget,
            widgets.generalCompanyTypeAggregateWidget,
            widgets.generalLegalFormDescEnWidget,
            widgets.generalLegalFormDescNoWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactCityWidget,
            widgets.contactCountryWidget,
            widgets.contactPostalCodeWidget,
            widgets.contactMailingAddressLine1Widget,
            widgets.contactMailingCityWidget,
            widgets.contactMailingPostalCodeWidget,
            widgets.contactOfficeAddress1Widget,
            widgets.contactOfficeAddress2Widget,
            widgets.contactOfficeAddress3Widget,
            widgets.contactContactPhoneWidget,
            widgets.contactFaxWidget,
            widgets.generalRegistrationDateWidget,
            widgets.generalRegisteredInForetaksregWidget,
            widgets.generalVatNumberWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            widgets.contactEmailWidget,
            widgets.generalWebsiteWidget,
            widgets.generalPriorityWidget,
            widgets.generalStatusDescEnWidget,
            widgets.generalEmployeesAggregateWidget,
            {
              ...widgets.generalActivityCodeAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCode2Widget,
            widgets.generalActivityCode2DescEnWidget,
            widgets.generalActivityCode3Widget,
            widgets.generalActivityCode3DescEnWidget,
            widgets.generalSectorCodeWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.limitCurrentValueAggregateWidget,
            widgets.limitCurrentDateWidget,
            widgets.limitPreviousValueWidget,
            widgets.limitPreviousDateWidget,
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentDateWidget,
            widgets.ratingPreviousScoreWidget,
            widgets.ratingPreviousDateWidget,
            widgets.ratingCurrentScoreWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingCurrentDescriptionNoWidget,
            widgets.ratingCurrentFullDescriptionEnWidget,
            widgets.ratingCurrentFullDescriptionNoWidget,
            widgets.ratingPreviousCommonWidget,
            widgets.ratingPreviousDescriptionEnWidget,
            widgets.ratingPreviousDescriptionNoWidget,
            widgets.ratingPreviousFullDescriptionEnWidget,
            widgets.ratingPreviousFullDescriptionNoWidget,
          ],
        },

        {
          group: "financialInformation",
          widgets: [
            widgets.financialsShareCapitalWidget,
            widgets.financialsCurrencyWidget,
            widgets.financialsTotalOperatingIncomeWidget,
            widgets.financialsSalesRevenueAggregateWidget,
            widgets.financialsOtherOperatingIncomeWidget,
            widgets.financialsCostOfStocksWidget,
            widgets.financialsChangeInStockWidget,
            widgets.financialsChangeInWorkInProgressWidget,
            widgets.financialsWagesSalariesWidget,
            widgets.financialsDepreciationWidget,
            widgets.financialsWriteDownOfFixedAssetsWidget,
            widgets.financialsOtherOperatingExpensesWidget,
            widgets.financialsTotalOperatingExpensesWidget,
            widgets.financialsOperatingProfitWidget,
            widgets.financialsAllocatedDividendWidget,
            widgets.financialsRevenueSubsidiariesWidget,
            widgets.financialsRevenueGroupCompaniesWidget,
            widgets.financialsRevenueAssociatedCompaniesWidget,
            widgets.financialsInterestIncomeGroupWidget,
            widgets.financialsOtherInterestIncomeWidget,
            widgets.financialsOtherFinancialIncomeWidget,
            widgets.financialsFinancialIncomeWidget,
            widgets.financialsChangeValueCurrentAssetsWidget,
            widgets.financialsWritedownsCurrentAssetsWidget,
            widgets.financialsWritedownsFixedAssetsWidget,
            widgets.financialsOtherInterestExpensesGroupWidget,
            widgets.financialsOtherInterestExpensesWidget,
            widgets.financialsOtherFinancialExpensesWidget,
            widgets.financialsOtherFinancialCostsWidget,
            widgets.financialsFinancialExpensesWidget,
            widgets.financialsTotalNetFinancialItemsWidget,
            widgets.financialsProfitBeforeTaxWidget,
            widgets.financialsTaxWidget,
            widgets.financialsProfitAfterTaxWidget,
            widgets.financialsExtraordinaryResultWidget,
            widgets.financialsNetProfitLossWidget,
            widgets.financialsTaxOnExtraordinaryResultWidget,
            widgets.financialsMinorityShareWidget,
            widgets.financialsNetResultAfterDividendsWidget,
            widgets.financialsDividendsWidget,
            widgets.financialsGroupContributionsWidget,
            widgets.financialsAllocationOtherEquityWidget,
            widgets.financialsTotalTransfersAllocationsWidget,
            widgets.financialsResearchAndDevelopmentWidget,
            widgets.financialsConcessionsWidget,
            widgets.financialsDeferredTaxAssetWidget,
            widgets.financialsGoodwillIntangibleAssetsWidget,
            widgets.financialsOtherIntangibleFixedAssetsWidget,
            widgets.financialsIntangibleAssetsWidget,
            widgets.financialsLandAndBuildingsWidget,
            widgets.financialsPlantAndMachineryWidget,
            widgets.financialsShipsRigsAircraftWidget,
            widgets.financialsOperatingEquipmentWidget,
            widgets.financialsOtherOperationFactorsWidget,
            widgets.financialsTotalTangibleAssetsWidget,
            widgets.financialsSharesInvestmentSubsidiariesWidget,
            widgets.financialsInvestmentsInGroupCompaniesWidget,
            widgets.financialsInvestmentsInAssociatedCompaniesWidget,
            widgets.financialsInvestmentsInSharesWidget,
            widgets.financialsBondsAndAccountsReceivablesWidget,
            widgets.financialsPensionFundsWidget,
            widgets.financialsOtherFinancialFixedAssetsWidget,
            widgets.financialsBondsWidget,
            widgets.financialsOtherReceivablesWidget,
            widgets.financialsOtherReceivables2Widget,
            widgets.financialsOtherFixedAssetsWidget,
            widgets.financialsTotalFixedAssetsWidget,
            widgets.financialsRawMaterialsWidget,
            widgets.financialsFinishedGoodsWidget,
            widgets.financialsOtherInventoriesWidget,
            widgets.financialsTotalInventoriesWidget,
            widgets.financialsInventoriesWidget,
            widgets.financialsTradeReceivablesWidget,
            widgets.financialsReceivablesToCompInGroupWidget,
            widgets.financialsTotalReceivableWidget,
            widgets.financialsSharesAndInterestsInGroupWidget,
            widgets.financialsQuotedInvestmentSharesWidget,
            widgets.financialsQuotedBondsWidget,
            widgets.financialsOtherMarkedBasedFinancialWidget,
            widgets.financialsOtherFinancialInstrWidget,
            widgets.financialsOtherInvestmentsWidget,
            widgets.financialsOtherCurrentAssetsWidget,
            widgets.financialsCashAndBankDepositsWidget,
            widgets.financialsTotalFundsWidget,
            widgets.financialsTotalCurrentAssetsWidget,
            widgets.financialsTotalAssetsWidget,
            widgets.financialsOwnSharesWidget,
            widgets.financialsSharePremiumReserveWidget,
            widgets.financialsOtherPaidInCapitalWidget,
            widgets.financialsTotalEquityDepositsWidget,
            widgets.financialsValuationReserveWidget,
            widgets.financialsOtherEquityWidget,
            widgets.financialsMinorityInterestsGroupsWidget,
            widgets.financialsTotalRetainedEarningsWidget,
            widgets.financialsOtherReservesWidget,
            widgets.financialsTotalEquityWidget,
            widgets.financialsLongtermPensionCommitmentsWidget,
            widgets.financialsDeferredTaxLiabilitiesWidget,
            widgets.financialsOtherProvisionsWidget,
            widgets.financialsTotalProvisionsLiabilitiesWidget,
            widgets.financialsConvertibleLoansWidget,
            widgets.financialsOtherLongtermLoansWidget,
            widgets.financialsLongtermLiabilitiesFinInstWidget,
            widgets.financialsLongtermGroupContrLiabilitiesWidget,
            widgets.financialsLiableLoanCapitalWidget,
            widgets.financialsOtherLongtermLiabilitiesWidget,
            widgets.financialsTotalOtherLongtermLiabilitiesWidget,
            widgets.financialsTotalLongTermLiabilitiesWidget,
            widgets.financialsConvertibleLoanWidget,
            widgets.financialsCertificateLoanWidget,
            widgets.financialsShorttermLiabilitiesFinInstWidget,
            widgets.financialsTradeCreditorsWidget,
            widgets.financialsPayableTaxWidget,
            widgets.financialsSpecialGovernmentTaxesWidget,
            widgets.financialsSuggestedDividendWidget,
            widgets.financialsShorttermLiabilitiesGroupWidget,
            widgets.financialsOtherCreditorsWidget,
            widgets.financialsTotalCurrentLiabilitiesWidget,
            widgets.financialsTotalLiabilitiesWidget,
            widgets.financialsTotalLiabilitiesEquityWidget,
            widgets.financialsPreProfitMarginWidget,
            widgets.financialsReturnOnCapitalEmployedWidget,
            widgets.financialsReturnOnTotalAssetsEmployedWidget,
            widgets.financialsReturnOnNetAssetsEmployedWidget,
            widgets.financialsSalesWorkingCapitalWidget,
            widgets.financialsStockTurnoverRatioWidget,
            widgets.financialsDebtorDaysWidget,
            widgets.financialsCreditorDaysWidget,
            widgets.financialsCurrentRatioWidget,
            widgets.financialsLiquidityAcidTestWidget,
            widgets.financialsCurrentDebtRatioWidget,
            widgets.financialsSolvencyRatioWidget,
            widgets.financialsEquityInPercentageWidget,
            widgets.financialsTotalDebtRatioWidget,
            widgets.financialsClosureDateWidget,
            widgets.financialsAuditCommentsWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "de":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalCompanyIdWidget,
            widgets.generalCompanyNameWidget,
            widgets.generalPreviousNameWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactPostalCodeWidget,
            widgets.contactCityWidget,
            widgets.contactRegionAggregateWidget,
            {
              ...widgets.generalLtdNonLtdFlagAggregateWidget,
              jsonTag: "general.companyType",
              colors: colors.DE_COMPANY_TYPE_COLORS,
            },
            widgets.generalStatusAggregateWidget,
            {
              ...widgets.generalCompanyTypeAggregateWidget,
            },
            widgets.generalLegalFormDescEnWidget,
            widgets.generalLegalFormDescDeWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalRegistrationLocationWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            widgets.financialsShareCapitalWidget,
            widgets.financialsShareCapitalCurrencyWidget,
            widgets.financialsTurnoverWidget,
            {
              ...widgets.generalEmployeesAggregateWidget,
              colors: colors.EMPLOYEE_COUNT_COLORS_GRADIENT_BLUE,
            },
            {
              ...widgets.generalEstablishDateAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.contactContactPhoneWidget,
            widgets.contactFaxWidget,
            widgets.generalWebsiteWidget,
            widgets.contactEmailWidget,
            widgets.financialsBankCodeWidget,
            widgets.financialsBankNameWidget,
            widgets.generalActivityCodeAggregateWidgetPdfSpecificTitle,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCodeDescDeWidget,
            widgets.generalVatNumberWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentLocalAggregateWidget,
            widgets.ratingCurrentScoreWidget,
            widgets.ratingCurrentPodWidget,
            widgets.limitCurrentValueAggregateWidget,
          ],
        },

        {
          group: "financialInformation",
          widgets: [
            widgets.financialsAccountingDateWidget,
            widgets.financialsNumberOfWeeksWidget,
            widgets.financialsCurrencyWidget,
            widgets.financialsAssetsWidget,
            widgets.financialsIntangibleAssetsWidget,
            widgets.financialsFixedAssetsWidget,
            widgets.financialsFinancialAssetsWidget,
            widgets.financialsCurrentAssetsWidget,
            widgets.financialsInventoryWidget,
            widgets.financialsReceivablesAndOtherWidget,
            widgets.financialsTherefromTradeReceivablesTooltippedWidget,
            widgets.financialsSecuritiesWidget,
            widgets.financialsLiquidAssetsTooltippedWidget,
            widgets.financialsPrepaidExpensesWidget,
            widgets.financialsDeferedTaxWidget,
            widgets.financialsActiveDiffBetweenAssetAllocationWidget,
            widgets.financialsDeficitNcbCapitalWidget,
            widgets.financialsDeficitNcblLossCapitalWidget,
            widgets.financialsBalanceSheetTotalAssetsWidget,
            widgets.financialsEquityWidget,
            widgets.financialsSubscribedCapitalWidget,
            widgets.financialsPrivateAccountWidget,
            widgets.financialsSharesNcblUnlimitedPartnersWidget,
            widgets.financialsSharesNcblLimitedPartnersWidget,
            widgets.financialsFixedEquityWidget,
            widgets.financialsVariableEquityWidget,
            widgets.financialsCapitalReserveWidget,
            widgets.financialsRevenueReserveWidget,
            widgets.financialsRetainedEarningsWidget,
            widgets.financialsBalanceSheetNetIncomeWidget,
            widgets.financialsProfitLossWidget,
            widgets.financialsAccrualsWidget,
            widgets.financialsTherefromAccrualsPensionWidget,
            widgets.financialsTherefromAccrualsTaxWidget,
            widgets.financialsLiabilitiesWidget,
            widgets.financialsTherefromTradePayablesWidget,
            widgets.financialsDefferedIncomeWidget,
            widgets.financialsDefferedLiabilitiesWidget,
            widgets.financialsBalanceSheetTotalWidget,
            widgets.financialsInventoryChangeWidget,
            widgets.financialsOwnWorkWidget,
            widgets.financialsOtherOperationalRevenueWidget,
            widgets.financialsMaterialsWidget,
            widgets.financialsGrossProfitWidget,
            widgets.financialsStaffWidget,
            widgets.financialsAmortisationWidget,
            widgets.financialsOtherCostsWidget,
            widgets.financialsOperationalResultWidget,
            widgets.financialsTherefromOtherInterestSimilarIncomeWidget,
            widgets.financialsTherefromInterestsSimilarExpensesWidget,
            widgets.financialsFinancialResultWidget,
            widgets.financialsOrdinaryOperationalResultWidget,
            widgets.financialsExtraordinaryResultWidget,
            widgets.financialsTaxWidget,
            widgets.financialsOtherTaxesWidget,
            widgets.financialsNetIncomeWidget,
          ],
        },
        {
          group: "financialsNonConsolidatedInformation",
          widgets: [
            widgets.financialsNonConsolidatedAccountingDateWidget,
            widgets.financialsNonConsolidatedNumberOfWeeksWidget,
            widgets.financialsNonConsolidatedCurrencyWidget,
            widgets.financialsNonConsolidatedAssetsWidget,
            widgets.financialsNonConsolidatedIntangibleAssetsWidget,
            widgets.financialsNonConsolidatedFixedAssetsWidget,
            widgets.financialsNonConsolidatedFinancialAssetsWidget,
            widgets.financialsNonConsolidatedCurrentAssetsWidget,
            widgets.financialsNonConsolidatedInventoryWidget,
            widgets.financialsNonConsolidatedReceivablesAndOtherWidget,
            widgets.financialsNonConsolidatedTherefromTradeReceivablesTooltippedWidget,
            widgets.financialsNonConsolidatedSecuritiesWidget,
            widgets.financialsNonConsolidatedLiquidAssetsTooltippedWidget,
            widgets.financialsNonConsolidatedPrepaidExpensesWidget,
            widgets.financialsNonConsolidatedDeferedTaxWidget,
            widgets.financialsNonConsolidatedActiveDiffBetweenAssetAllocationWidget,
            widgets.financialsNonConsolidatedDeficitNcbCapitalWidget,
            widgets.financialsNonConsolidatedDeficitNcblLossCapitalWidget,
            widgets.financialsNonConsolidatedBalanceSheetTotalAssetsWidget,
            widgets.financialsNonConsolidatedEquityWidget,
            widgets.financialsNonConsolidatedSubscribedCapitalWidget,
            widgets.financialsNonConsolidatedPrivateAccountWidget,
            widgets.financialsNonConsolidatedSharesNcblUnlimitedPartnersWidget,
            widgets.financialsNonConsolidatedSharesNcblLimitedPartnersWidget,
            widgets.financialsNonConsolidatedFixedEquityWidget,
            widgets.financialsNonConsolidatedVariableEquityWidget,
            widgets.financialsNonConsolidatedCapitalReserveWidget,
            widgets.financialsNonConsolidatedRevenueReserveWidget,
            widgets.financialsNonConsolidatedRetainedEarningsWidget,
            widgets.financialsNonConsolidatedBalanceSheetNetIncomeWidget,
            widgets.financialsNonConsolidatedProfitLossWidget,
            widgets.financialsNonConsolidatedAccrualsWidget,
            widgets.financialsNonConsolidatedTherefromAccrualsPensionWidget,
            widgets.financialsNonConsolidatedTherefromAccrualsTaxWidget,
            widgets.financialsNonConsolidatedLiabilitiesWidget,
            widgets.financialsNonConsolidatedTherefromTradePayablesWidget,
            widgets.financialsNonConsolidatedDefferedIncomeWidget,
            widgets.financialsNonConsolidatedDefferedLiabilitiesWidget,
            widgets.financialsNonConsolidatedBalanceSheetTotalWidget,
            widgets.financialsNonConsolidatedInventoryChangeWidget,
            widgets.financialsNonConsolidatedOwnWorkWidget,
            widgets.financialsNonConsolidatedOtherOperationalRevenueWidget,
            widgets.financialsNonConsolidatedMaterialsWidget,
            widgets.financialsNonConsolidatedGrossProfitWidget,
            widgets.financialsNonConsolidatedStaffWidget,
            widgets.financialsNonConsolidatedAmortisationWidget,
            widgets.financialsNonConsolidatedOtherCostsWidget,
            widgets.financialsNonConsolidatedOperationalResultWidget,
            widgets.financialsNonConsolidatedTherefromOtherInterestSimilarIncomeWidget,
            widgets.financialsNonConsolidatedTherefromInterestsSimilarExpensesWidget,
            widgets.financialsNonConsolidatedFinancialResultWidget,
            widgets.financialsNonConsolidatedOrdinaryOperationalResultWidget,
            widgets.financialsNonConsolidatedExtraordinaryResultWidget,
            widgets.financialsNonConsolidatedTaxWidget,
            widgets.financialsNonConsolidatedOtherTaxesWidget,
            widgets.financialsNonConsolidatedNetIncomeWidget,
          ],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupUltimateParentSafeNumberWidget,
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentAddressWidget,
            widgets.groupUltimateParentCityWidget,
            widgets.groupUltimateParentPostalCodeWidget,
            widgets.groupParentSafeNumberWidget,
            widgets.groupParentNameWidget,
            widgets.groupParentAddressLine1Widget,
            widgets.groupParentCityWidget,
            widgets.groupParentPostalCodeWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [
            widgets.directorsFirstNameWidget,
            widgets.directorsLastNameWidget,
            widgets.directorsFullNameWidget,
            widgets.directorsGenderWidget,
            widgets.directorsAddressLine1Widget,
            widgets.directorsCityWidget,
            widgets.directorsPostalCodeWidget,
            widgets.directorsCountryWidget,
            widgets.directorsTitleEnWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "gb":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalCompanyNameWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalPrincipalActivityWidget,
            widgets.generalPrincipalActivity2Widget,
            widgets.generalPrincipalActivity3Widget,
            widgets.generalPrincipalActivity4Widget,
            widgets.generalPrincipalActivity5Widget,
            widgets.generalActivityCodeWidget,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCode2AggregateWidget,
            widgets.generalActivityCode2Widget,
            widgets.generalActivityCode2DescEnWidget,
            widgets.generalCompanyTypeWidget,
            widgets.generalIncorporationDateWidget,
            widgets.generalActiveWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            widgets.generalMortgagesPartiallySatisfiedWidget,
            widgets.generalMortgagesSatisfiedWidget,
            widgets.generalMortgagesOutstandingWidget,
            widgets.generalMortgagesTotalWidget,
            widgets.generalYearsTradingAggregateWidget,
            widgets.generalLtdNonLtdFlagAggregateWidget,
            widgets.generalEmployeesAggregateWidget,
            widgets.generalTradeNumberWidget,
          ],
        },
        {
          group: "contactInformation",
          widgets: [
            widgets.contactPostalCodeAggregateWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactAddressLine2Widget,
            widgets.contactAddressLine3Widget,
            widgets.contactAddressLine4Widget,
            widgets.contactMailingAddressLine1Widget,
            widgets.contactMailingAddressLine2Widget,
            widgets.contactMailingAddressLine3Widget,
            widgets.contactMailingAddressLine4Widget,
            widgets.contactMailingPostalCodeWidget,
            widgets.contactPafAddressLine1Widget,
            widgets.contactPafAddressLine2Widget,
            widgets.contactPafAddressLine3Widget,
            widgets.contactPafAddressLine4Widget,
            widgets.contactPafPostalCodeWidget,
            widgets.contactTpsWidget,
            widgets.contactContactPhoneWidget,
            widgets.generalWebsiteWidget,
            widgets.contactFaxWidget,
            widgets.contactCityWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentScoreWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingCurrentDateWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.limitCurrentDateWidget,
            widgets.ratingPreviousScoreWidget,
            widgets.ratingPreviousDescriptionEnWidget,
            widgets.ratingPreviousDateWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsAuditorsWidget,
            widgets.financialsRevaluationReserveWidget,
            widgets.financialsCapitalEmployedWidget,
            widgets.financialsNetCashflowFromFinancingWidget,
            widgets.financialsTotalCurrentLiabilitiesWidget,
            widgets.financialsTotalLiabilitiesWidget,
            widgets.financialsPlAccountReserveWidget,
            widgets.financialsSundryReservesWidget,
            widgets.financialsNetWorkingCapitalWidget,
            widgets.financialsTotalAssetsWidget,
            widgets.financialsGrossProfitWidget,
            widgets.financialsDividendsPayableWidget,
            widgets.financialsTangibleAssetsWidget,
            widgets.financialsIntangibleAssetsWidget,
            widgets.financialsTradeDebtorsWidget,
            widgets.financialsWorkingCapitalWidget,
            widgets.financialsOtherLongTermFinanceWidget,
            widgets.financialsLiquidityAcidTestWidget,
            widgets.financialsCashWidget,
            widgets.financialsCreditorDaysWidget,
            widgets.financialsTurnoverAggregateWidget,
            widgets.financialsDirectorsEmolumentsWidget,
            widgets.financialsWagesSalariesWidget,
            widgets.financialsMiscCurrentLiabilitiesWidget,
            widgets.financialsNetAssetsWidget,
            widgets.financialsReturnOnNetAssetsEmployedWidget,
            widgets.financialsCurrentDebtRatioWidget,
            widgets.financialsNetCashflowBeforeFinancingWidget,
            widgets.financialsShareholderFundsWidget,
            widgets.financialsAccountsToWidget,
            widgets.financialsTotalLongTermLiabilitiesWidget,
            widgets.financialsOtherShortTermFinanceWidget,
            widgets.financialsNetWorthWidget,
            widgets.financialsRetainedProfitWidget,
            widgets.financialsGearingRatioWidget,
            widgets.financialsAccountsMonthsWidget,
            widgets.financialsReservesWidget,
            widgets.financialsDebtorDaysWidget,
            widgets.financialsBankOverdraftLtlWidget,
            widgets.financialsReturnOnTotalAssetsEmployedWidget,
            widgets.financialsBankOverdraftWidget,
            widgets.financialsNetCashflowFromOperationsWidget,
            widgets.financialsInterestPaymentsWidget,
            widgets.financialsPreTaxProfitWidget,
            widgets.financialsProfitAfterTaxWidget,
            widgets.financialsOtherCurrentDebtorsWidget,
            widgets.financialsAuditFeesWidget,
            widgets.financialsTaxationWidget,
            widgets.financialsCostOfSalesWidget,
            widgets.financialsPaidShareCapitalWidget,
            widgets.financialsReturnOnCapitalEmployedWidget,
            widgets.financialsTradeCreditorsWidget,
            widgets.financialsStocksWidget,
            widgets.financialsTotalDebtRatioWidget,
            widgets.financialsConsolidatedAccountsWidget,
            widgets.financialsTotalCurrentAssetsWidget,
            widgets.financialsStockTurnoverRatioWidget,
            widgets.financialsExportsWidget,
            widgets.financialsOperatingProfitWidget,
            widgets.financialsDepreciationWidget,
            widgets.financialsPreTaxProfitMarginWidget,
            widgets.financialsAuditCommentsWidget,
            widgets.financialsMiscCurrentAssetsWidget,
            widgets.financialsTotalFixedAssetsWidget,
            widgets.financialsCurrentRatioWidget,
            widgets.financialsContingentLiabilityWidget,
            widgets.financialsBankersWidget,
            widgets.financialsBankBranchCodeWidget,
            widgets.financialsEquityInPercentageWidget,
            widgets.financialsDbtCurrentWidget,
          ],
        },
        {
          group: "negativeInformation",
          widgets: [
            widgets.negativesCcjCountAggregateWidget,
            widgets.negativesCcjValueAggregateWidget,
          ],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupParentNameWidget,
            widgets.groupParentIdWidget,
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentIdWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [
            widgets.directorsSalutationWidget,
            widgets.directorsFirstNameWidget,
            widgets.directorsMiddleNameWidget,
            widgets.directorsLastNameWidget,
            widgets.directorsFullNameWidget,
            widgets.directorsTitleEnWidget,
            widgets.directorsAppointmentDateWidget,
            widgets.directorsNationalityWidget,
            widgets.directorsBirthDateWidget,
            widgets.directorsHonoursWidget,
            widgets.directorsAddressLine1Widget,
            widgets.directorsAddressLine2Widget,
            widgets.directorsCityWidget,
            widgets.directorsRegionWidget,
            widgets.directorsCountryWidget,
            widgets.directorsPostalCodeWidget,
          ],
        },
        {
          group: "shareholderInformation",
          widgets: [
            widgets.shareholdersNameWidget,
            widgets.shareholdersInfoWidget,
            widgets.shareholdersCurrencyWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "ww":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalIncorporationDateWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalOrganisationNumberWidget,
            widgets.generalCompanyNameWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactAddressLine2Widget,
            widgets.contactAddressLine3Widget,
            widgets.contactPostalCodeWidget,
            widgets.contactCityWidget,
            widgets.contactProvinceWidget,
            widgets.contactRegionWidget,
            widgets.contactCountryAggregateWidget,
            widgets.generalActiveTooltippedWidget,
            widgets.contactContactPhoneWidget,
            widgets.generalActivityCodeAggregateWidget,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalCompanyTypeWidget,
            widgets.generalCompanyTypeDescEnWidget,
            widgets.generalLegalFormWidget,
            widgets.generalLegalFormDescEnWidget,
            widgets.generalEmployeesAggregateGlobalWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentScoreWidget,
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.limitCurrentValueWidget,
            widgets.ratingCreditworthyWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsCurrencyWidget,
            widgets.financialsTurnoverWidget,
            widgets.financialsDbtCurrentAggregateWidget,
            widgets.financialsLatestAccountDateWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [widgets.directorsFullNameWidget],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "jp":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalCompanyNumberWidget,
            widgets.generalCompanyNameKanjiWidget,
            widgets.generalCompanyNameKanaWidget,
            widgets.generalCompanyNameWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactAddressLine2Widget,
            widgets.contactAddressLine3Widget,
            widgets.contactAddressLine4Widget,
            widgets.contactAddressLine5Widget,
            widgets.contactAddressLine6Widget,
            widgets.contactContactPhoneWidget,
            widgets.contactFaxWidget,
            widgets.contactProvinceAggregateWidget,
            widgets.contactCityWidget,
            widgets.contactStreetNameWidget,
            widgets.contactPostalCodeWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            widgets.generalEstablishDateWidget,
            widgets.generalEmployeesWidget,
            {
              ...widgets.generalActivityCodeAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCodeDescJpWidget,
            widgets.generalActivityCode2Widget,
            widgets.generalActivityCode2DescEnWidget,
            widgets.generalActivityCode2DescJpWidget,
            widgets.generalWebsiteWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentDateWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.limitCurrentDateWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingCurrentDescriptionJpWidget,
            widgets.ratingCurrentColourWidget,
            widgets.ratingCurrentScoreWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [widgets.financialsCapitalAggregateWidget],
        },
        {
          group: "directorInformation",
          widgets: [
            widgets.directorsFullNameWidget,
            widgets.directorsFullNameKanaWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "it":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalGgsIdWidget,
            widgets.generalConnectIdWidget,
            widgets.generalCompanyNameWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactPostalCodeWidget,
            widgets.contactMunicipalityWidget,
            widgets.contactStateWidget,
            widgets.contactRegionAggregateWidget,
            widgets.contactProvinceWidget,
            widgets.generalActiveWidget,
            widgets.RegistrationDateWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            widgets.generalLegalFormDescEnAggregateWidget,
            widgets.generalLegalFormDescItWidget,
            widgets.generalTaxCodeWidget,
            widgets.generalVatNumberWidget,
            widgets.contactContactPhoneWidget,
            widgets.generalActivityCodeAggregateWidgetPdfSpecificTitle,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCodeDescItWidget,
            widgets.generalRegistrationDateWidget,
            widgets.generalEmployeesWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentScoreAggregateWidget,
            widgets.limitCurrentValueAggregateWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsTurnoverWidget,
            widgets.financialsProfitLossWidget,
            widgets.financialsYearOfBalanceWidget,
            widgets.financialsShareCapitalWidget,
          ],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentIdWidget,
            widgets.groupUltimateParentCountryWidget,
            widgets.groupParentNameWidget,
            widgets.groupParentIdWidget,
            widgets.groupParentCountryWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "ie":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalGgsIdWidget,
            widgets.generalConnectIdWidget,
            widgets.generalRegistrationNumberWidget,
            widgets.generalCompanyNameWidget,
            {
              ...widgets.generalLtdNonLtdFlagAggregateWidget,
              jsonTag: "general.companyType",
              colors: colors.DE_COMPANY_TYPE_COLORS,
            },
            {
              ...widgets.RegistrationDateWidget,
              jsonTag: "general.incorporationDate",
              hasPdfSpecificTitle: true,
            },
            widgets.generalPrincipalActivityWidget,
            widgets.generalActivityCodeWidget,
            widgets.generalActivityCodeDescEnWidget,
            widgets.contactAddressLine1Widget,
            widgets.contactAddressLine2Widget,
            widgets.contactAddressLine3Widget,
            widgets.contactAddressLine4Widget,
            widgets.contactPostalCodeWidget,
            widgets.contactEircodeWidget,
            widgets.generalCompanyNumberWidget,
            widgets.generalRegistrationDateWidget,
            widgets.generalCompanyTypeDescEnAggregateWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            widgets.generalEmployeesWidget,
            widgets.contactContactPhoneWidget,
            widgets.contactTpsWidget,
            widgets.generalWebsiteWidget,
            widgets.generalActiveWidget,
            widgets.generalStatusDescEnWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentScoreWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingCurrentDateWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsWorkingCapitalWidget,
            widgets.financialsNetAssetsWidget,
            widgets.financialsTotalLiabilitiesWidget,
            widgets.financialsTotalLongTermLiabilitiesWidget,
            widgets.financialsOtherLongTermFinanceWidget,
            widgets.financialsMiscCurrentLiabilitiesWidget,
            widgets.financialsOtherShortTermFinancialsWidget,
            widgets.financialsBankOverdraftWidget,
            widgets.financialsTradeCreditorsWidget,
            widgets.financialsTotalCurrentLiabilitiesWidget,
            widgets.financialsTotalAssetsWidget,
            widgets.financialsMiscCurrentAssetsWidget,
            widgets.financialsOtherCurrentAssetsWidget,
            widgets.financialsTradeDebtorsWidget,
            widgets.financialsTotalCurrentAssetsWidget,
            widgets.financialsTotalFixedAssetsWidget,
            widgets.financialsIntangibleAssetsWidget,
            widgets.financialsTangibleAssetsWidget,
            widgets.financialsStocksWidget,
            widgets.financialsCashWidget,
            widgets.financialsNetWorthWidget,
            widgets.financialsShareholderFundsWidget,
            widgets.financialsRevaluationReserveWidget,
            widgets.financialsSundryReservesWidget,
            widgets.financialsProfitLossAccountWidget,
            widgets.financialsPaidUpCapitalWidget,
            widgets.financialsAccountsTypeWidget,
            widgets.financialsAccountsFromWidget,
            widgets.financialsAccountsToWidget,
            widgets.financialsAccountsMonthsWidget,
            widgets.financialsPreTaxMarginWidget,
            widgets.financialsRetainedProfitsWidget,
            widgets.financialsDividendsPayableWidget,
            widgets.financialsProfitAfterTaxWidget,
            widgets.financialsTaxationWidget,
            widgets.financialsPreTaxProfitWidget,
            widgets.financialsInterestPaymentsWidget,
            widgets.financialsCurrencyWidget,
            widgets.financialsConsolidatedAccountsWidget,
            widgets.financialsTurnoverWidget,
            widgets.financialsExportsWidget,
            widgets.financialsCostOfSalesWidget,
            widgets.financialsGrossProfitWidget,
            widgets.financialsWagesSalariesWidget,
            widgets.financialsDirectorsEmolumentsWidget,
            widgets.financialsOperatingProfitWidget,
            widgets.financialsDepreciationWidget,
            widgets.financialsAuditFeesWidget,
            widgets.financialsCurrentRatioWidget,
            widgets.financialsNetWorkingCapitalWidget,
            widgets.financialsGearingRatioWidget,
            widgets.financialsCreditorDaysWidget,
            widgets.financialsLiquidityAcidTestWidget,
            widgets.financialsReturnOnCapitalEmployedWidget,
            widgets.financialsReturnOnTotalAssetsEmployedWidget,
            widgets.financialsCurrentDebtRatioWidget,
            widgets.financialsTotalDebtRatioWidget,
            widgets.financialsStockTurnoverRatioWidget,
            widgets.financialsReturnOnNetAssetsEmployedWidget,
            widgets.financialsNetCashflowFromOperationsWidget,
            widgets.financialsNetCashflowBeforeFinancingWidget,
            widgets.financialsNetCashflowFromFinancingWidget,
            widgets.financialsAuditorsQualificationWidget,
            widgets.financialsAuditorsWidget,
            widgets.financialsCapitalEmployedWidget,
            widgets.financialsContingentLiabilityWidget,
          ],
        },
        {
          group: "negativeInformation",
          widgets: [
            widgets.negativesCcjCountWidget,
            widgets.negativesCcjValueWidget,
          ],
        },

        {
          group: "directorInformation",
          widgets: [
            widgets.directorsFirstNameWidget,
            widgets.directorsLastNameWidget,
            widgets.directorsFullNameWidget,
            widgets.directorsTitleEnWidget,
            widgets.directorsBirthDateWidget,
            widgets.directorsCityWidget,
            widgets.directorsAddressLine1Widget,
            widgets.directorsAddressLine2Widget,
            widgets.directorsAddressLine3Widget,
            widgets.directorsAddressLine4Widget,
            widgets.directorsPostalCodeWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "nl":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalGgsIdWidget,
            widgets.generalConnectIdWidget,
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalKvkNumberWidget,
            widgets.generalBranchNumberWidget,
            widgets.contactStreetNameWidget,
            widgets.contactHouseNumberWidget,
            widgets.contactCityWidget,
            widgets.contactProvinceAggregateWidget,
            widgets.contactPostalCodeWidget,
            widgets.generalImportFlagAggregateWidget,
            widgets.generalExportFlagAggregateWidget,
            widgets.generalEmployeesAggregateWidget,
            widgets.generalCompanyTypeAggregateWidget,
            widgets.generalLegalFormDescNlWidget,
            widgets.generalCompanyTypeDescEnWidget,
            widgets.generalActivityCodeAggregateWidgetPdfSpecificTitle,
            widgets.generalActivityCodeDescNlWidget,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalIncorporationDateWidget,
            widgets.generalMainOfficeFlagWidget,
            widgets.generalRsinWidget,
            widgets.generalCompanyNameWidget,
            widgets.generalTradingNameWidget,
            widgets.generalFoundingDeedDateWidget,
            widgets.generalContinuanceDateWidget,
            widgets.generalDiscontinuanceDateWidget,
            widgets.generalRegDeletionNumberWidget,
            widgets.generalActiveWidget,
          ],
        },
        {
          group: "contactInformation",
          widgets: [
            widgets.contactMailingStreetNameWidget,
            widgets.contactMailingHouseNumberWidget,
            widgets.contactMailingCityWidget,
            widgets.contactMailingProvinceWidget,
            widgets.contactMailingPostalCodeWidget,
            widgets.contactContactPhoneWidget,
            widgets.generalWebsiteWidget,
            widgets.contactNonMailingIndicatorWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentDateWidget,
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentDescriptionNlWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingCurrentColourWidget,
            widgets.limitCurrentDateWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.ratingCurrentScoreWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsWorkingCapitalWidget,
            widgets.financialsAccountsEndDateWidget,
            widgets.financialsAccountsStartDateWidget,
            widgets.financialsConsolidatedAccountsTooltippedWidget,
            widgets.financialsTurnoverWidget,
            widgets.financialsTotalFixedAssetsWidget,
            widgets.financialsTotalCurrentAssetsWidget,
            widgets.financialsTradeCreditorsWidget,
            widgets.financialsTotalCurrentLiabilitiesWidget,
            widgets.financialsLongTermLiabilitiesWidget,
            widgets.financialsTotalEquityAggregateWidget,
            widgets.financialsNetWorthWidget,
            widgets.financialsLiquidityRatioWidget,
            widgets.financialsNetResultWidget,
            widgets.financialsNominalCapitalWidget,
            widgets.financialsPlacedCapitalWidget,
            widgets.financialsDepositedCapitalWidget,
          ],
        },

        {
          group: "groupStructure",
          widgets: [
            widgets.groupUltimateParentSafeNumberWidget,
            widgets.groupUltimateParentCompanyNoWidget,
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentCountryWidget,
            widgets.groupUltimateParentRatingCurrentScoreWidget,
            widgets.groupUltimateParentRatingCurrentDescriptionNlWidget,
            widgets.groupUltimateParentRatingCurrentDescriptionEnWidget,
            widgets.groupUltimateParentLimitCurrentValueWidget,
            widgets.groupParentSafeNumberWidget,
            widgets.groupParentCompanyNoWidget,
            widgets.groupParentNameWidget,
            widgets.groupParentCountryWidget,
            widgets.groupParentRatingCurrentScoreWidget,
            widgets.groupParentLimitCurrentValueWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [
            widgets.directorsTitleNlWidget,
            widgets.directorsFirstNameWidget,
            widgets.directorsLastNameWidget,
            widgets.directorsFullNameWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "fr":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredTooltippedWidget,
            widgets.generalSiretTooltippedWidget,
            widgets.generalSirenTooltippedWidget,
            widgets.generalCompanyNameWidget,
            widgets.generalLegalNameAltWidget,
            widgets.generalTradeNameWidget,
            widgets.generalAcronymWidget,
            {
              ...widgets.generalActivityCodeAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.generalStatusAggregateWidget,
            widgets.generalIncorporationDateWidget,
            widgets.generalGgsIdWidget,
            widgets.generalConnectIdWidget,
            widgets.generalFormationDateWidget,
            widgets.generalLegalFormWidget,
            widgets.generalVatNumberWidget,
            widgets.generalActiveEconomicallyWidget,
            widgets.groupParentSafeNumberWidget,
            widgets.groupParentSiretWidget,
            widgets.groupParentSirenWidget,
            widgets.generalLegalFormCategoryAggregateWidget,
            widgets.generalLegalFormDescEnWidget,
            widgets.generalLegalFormDescFrWidget,
            widgets.generalLegalFormDetailsWidget,
            widgets.generalStatusDescEnWidget,
            widgets.generalStatusDescFrWidget,
            widgets.generalStatusDescShortEnWidget,
            widgets.generalStatusDescShortFrWidget,
            {
              ...widgets.generalEmployeesAggregateWidget,
              colors: colors.INCREASINGLY_DARKER_GREEN_BLUE_SHADES,
            },
            widgets.generalWorkforceCodeWidget,
            widgets.generalWorkforceCodeDescEnWidget,
            widgets.generalWorkforceCodeDescFrWidget,
            widgets.generalMinimalWorkforceRangeWidget,
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCodeDescFrWidget,
            widgets.generalCompanyTypeWidget,
            widgets.generalPbiWidget,
            widgets.generalPeiWidget,
            widgets.generalNumberOfEstablishmentsWidget,
          ],
        },
        {
          group: "contactInformation",
          widgets: [
            widgets.contactAddressLine1Widget,
            widgets.contactAdditionToAddressWidget,
            widgets.contactPostalCodeWidget,
            widgets.contactCityWidget,
            widgets.contactSpecialDistributionWidget,
            widgets.contactContactPhoneWidget,
            widgets.contactFaxWidget,
            widgets.generalManagerTypeWidget,
            widgets.generalManagerTypeDescEnWidget,
            widgets.generalManagerTypeDescFrWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.ratingCurrentScoreWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            {
              ...widgets.financialsTurnoverAggregateWidget,
              widgetType: "donut",
            },
            widgets.financialsClosureDateWidget,
            widgets.financialsAccountPeriodInMonthsTooltippedWidget,
            widgets.financialsCapitalNotCalledWidget,
            widgets.financialsTotalFixedAssetsWidget,
            widgets.financialsIntangibleAssetsWidget,
            widgets.financialsTangibleAssetsWidget,
            widgets.financialsFinancialAssetsWidget,
            widgets.financialsNetCurrentAssetsWidget,
            widgets.financialsStocksWidget,
            widgets.financialsAdvancedPaymentsWidget,
            widgets.financialsReceivablesWidget,
            widgets.financialsSecuritiesAndCashWidget,
            widgets.financialsPrepaidExpensesWidget,
            widgets.financialsAccountsOfRegularisationWidget,
            widgets.financialsTotalAssetsWidget,
            widgets.financialsShareholdersEquityWidget,
            widgets.financialsShareCapitalWidget,
            widgets.financialsOtherCapitalResourcesWidget,
            widgets.financialsRiskProvisionsWidget,
            widgets.financialsLiabilitiesWidget,
            widgets.financialsFinancialLiabilitiesWidget,
            widgets.financialsAdvancedPaymentsReceivedWidget,
            widgets.financialsTradeAccountPayablesWidget,
            widgets.financialsTaxAndSocialLiabilitiesWidget,
            widgets.financialsOtherDebtsAndFixedAssetsWidget,
            widgets.financialsAccountsRegularisationWidget,
            widgets.financialsTotalLiabilitiesWidget,
            widgets.financialsSalesOfGoodsWidget,
            widgets.financialsNetTurnoverWidget,
            widgets.financialsNetExportTurnoverWidget,
            widgets.financialsOperatingChargesWidget,
            widgets.financialsOperatingProfitWidget,
            widgets.financialsFinancialIncomeWidget,
            widgets.financialsFinancialChargesWidget,
            widgets.financialsFinancialProfitWidget,
            widgets.financialsPretaxNetOperatingIncomeWidget,
            widgets.financialsExtraordinaryIncomeWidget,
            widgets.financialsExtraordinaryChargesWidget,
            widgets.financialsExtraordinaryProfitLossWidget,
            widgets.financialsNetResultWidget,
            widgets.financialsTradingMarginWidget,
            widgets.financialsAddedValueWidget,
            widgets.financialsGrossOperatingSurplusWidget,
          ],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupUltimateParentSafeNumberWidget,
            widgets.groupUltimateParentSirenWidget,
            widgets.groupUltimateParentEntityTypeWidget,
            widgets.groupUltimateParentIsoCodeWidget,
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentAddressWidget,
            widgets.groupUltimateParentPoBoxWidget,
            widgets.groupUltimateParentCityWidget,
            widgets.groupUltimateParentInseeCityCodeWidget,
            widgets.groupUltimateParentPostalCodeWidget,
            widgets.groupUltimateParentContactPhoneWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [
            widgets.directorsSalutationWidget,
            widgets.directorsFirstNameWidget,
            widgets.directorsLastNameWidget,
            widgets.directorsFullNameWidget,
            widgets.directorsBirthDateWidget,
            widgets.directorsBirthPlaceWidget,
            widgets.directorsManagerPositionCodeWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "be":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalCompanyNameWidget,
            {
              ...widgets.generalEmployeesAggregateWidget,
              colors: colors.EMPLOYEE_COUNT_COLORS_GRADIENT_BLUE,
            },
            {
              ...widgets.generalEstablishDateAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.generalRegistrationNumberWidget,
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalVatNumberWidget,
            widgets.generalBranchNumberWidget,
          ],
        },
        {
          group: "additionalInformation",
          widgets: [
            {
              ...widgets.generalActivityCodeAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalActivityCodeDescFrWidget,
            widgets.generalActivityCodeDescNlWidget,
            {
              ...widgets.generalCompanyTypeAggregateWidget,
              jsonTag: "general.juridicalForm",
            },
            widgets.generalJuridicalFormDescEnWidget,
            widgets.generalJuridicalFormDescFrWidget,
            widgets.generalJuridicalFormDescNlWidget,
            {
              ...widgets.generalStatusAggregateWidget,
              colors: colors.BE_STATUS_COLORS,
            },
            widgets.generalStatusDescEnWidget,
            widgets.generalStatusDescFrWidget,
            widgets.generalStatusDescNlWidget,
            widgets.generalVatLiableWidget,
          ],
        },
        {
          group: "contactInformation",
          widgets: [
            widgets.contactAddressLine1Widget,
            widgets.contactAddressLine2Widget,
            widgets.contactCityWidget,
            widgets.contactPostalCodeAggregateWidget,
            widgets.contactContactPhoneWidget,
            widgets.contactFaxWidget,
            widgets.contactPostBoxWidget,
            widgets.contactMailingCityWidget,
            widgets.contactMailingHouseNumberWidget,
            widgets.contactMailingStreetNameWidget,
            widgets.contactMailingPostalCodeWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentScoreWidget,
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.ratingCurrentColourWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingCurrentDescriptionFrWidget,
            widgets.ratingCurrentDescriptionNlWidget,
            widgets.limitCurrentValueAggregateWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsLatestAccountDateWidget,
            widgets.financialsAmortizationAndDepreciationWidget,
            widgets.financialsCashWidget,
            widgets.financialsCreditorDaysWidget,
            widgets.financialsCurrentDebtRatioWidget,
            widgets.financialsCurrentRatioWidget,
            widgets.financialsDebtorDaysWidget,
            widgets.financialsDirectorRemunerationWidget,
            widgets.financialsDividendsWidget,
            widgets.financialsEmployeeCostsWidget,
            widgets.financialsEmployeePensionCostsWidget,
            widgets.financialsEquityInPercentageWidget,
            widgets.financialsExtraordinaryItemsWidget,
            widgets.financialsFinancialChargesWidget,
            widgets.financialsFinancialFixedAssetsWidget,
            widgets.financialsFinancialIncomeWidget,
            widgets.financialsFinishedGoodsWidget,
            widgets.financialsGearingRatioWidget,
            widgets.financialsIntangibleFixedAssetsWidget,
            widgets.financialsInventoriesWidget,
            widgets.financialsIssuedShareCapitalWidget,
            widgets.financialsLandAndBuildingWidget,
            widgets.financialsLiquidityAcidTestWidget,
            widgets.financialsMiscCurrentAssetsWidget,
            widgets.financialsMiscCurrentLiabilitiesWidget,
            widgets.financialsNetResultWidget,
            widgets.financialsNetWorthWidget,
            widgets.financialsOperatingIncomeWidget,
            widgets.financialsOtherAmountsReceivableWidget,
            widgets.financialsOtherEmployeeCostsWidget,
            widgets.financialsOtherStocksWidget,
            widgets.financialsOtherTangibleAssetsWidget,
            widgets.financialsOtherTermLiabilitiesWidget,
            widgets.financialsOtherTermLoansWidget,
            widgets.financialsPlantAndMachineryWidget,
            widgets.financialsPreProfitMarginWidget,
            widgets.financialsProfitBeforeTaxWidget,
            widgets.financialsRawAndConsumablesWidget,
            widgets.financialsReservesWidget,
            widgets.financialsResultsAfterTaxationWidget,
            widgets.financialsReturnOnTotalAssetsEmployedWidget,
            widgets.financialsReturnOnNetAssetsEmployedWidget,
            widgets.financialsReturnOnCapitalEmployedWidget,
            widgets.financialsRevaluationReserveWidget,
            widgets.financialsSalesWorkingCapitalWidget,
            widgets.financialsSharePremiumAccountWidget,
            widgets.financialsSocialSecurityContributionsWidget,
            widgets.financialsStockTurnoverRatioWidget,
            widgets.financialsTangibleFixedAssetsWidget,
            widgets.financialsTaxationWidget,
            widgets.financialsTotalAssetsWidget,
            widgets.financialsTotalCurrentAssetsWidget,
            widgets.financialsTotalCurrentLiabilitiesWidget,
            widgets.financialsTotalDebtRatioWidget,
            widgets.financialsTotalFixedAssetsWidget,
            widgets.financialsTotalLiabilitiesWidget,
            widgets.financialsTotalOperatingExpensesWidget,
            widgets.financialsTotalShareholdersEquityWidget,
            widgets.financialsTotalTermDebtsWidget,
            widgets.financialsTradeCreditorsWidget,
            widgets.financialsTradeDebtorsWidget,
            widgets.financialsTurnoverWidget,
            widgets.financialsWagesSalariesWidget,
            widgets.financialsWorkingCapitalWidget,
            widgets.financialsWorkInProgressWidget,
          ],
        },
        {
          group: "negativeInformation",
          widgets: [
            widgets.generalInactivityDateWidget,
            widgets.negativesBankruptcyWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [
            widgets.directorsBirthDateWidget,
            widgets.directorsEndDateWidget,
            widgets.directorsFirstNameWidget,
            widgets.directorsLastNameWidget,
            widgets.directorsFullNameWidget,
            widgets.directorsTitleEnWidget,
            widgets.directorsTitleFrWidget,
            widgets.directorsTitleNlWidget,
          ],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentCountryWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "at":
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalCompanyNameTooltippedWidget,
            widgets.generalCompanyTypeWidget,
            {
              ...widgets.generalActivityCodeAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.generalEmployeesAggregateWidget,
            widgets.generalStatusWidget,
            widgets.generalIncorporatedStateWidget,
            widgets.generalIncorporationDateWidget,
            widgets.generalLegalFormWidget,
            widgets.financialsLatestAccountDateWidget,
          ],
        },
        {
          group: "contactInformation",
          widgets: [
            widgets.contactAddressLine1TooltippedWidget,
            widgets.contactCityTooltippedWidget,
            widgets.contactRegionWidget,
            widgets.contactCountryWidget,
            widgets.contactContactPhoneWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.ratingCurrentScoreWidget,
            widgets.ratingPreviousScoreWidget,
            widgets.limitPreviousValueWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
    case "us":
    default:
      return [
        {
          group: "companyInformation",
          widgets: [
            widgets.generalSafeNumberRequiredWidget,
            widgets.generalConnectIdWidget,
            widgets.generalGgsIdWidget,
            widgets.generalFederalTaxIdWidget,
            widgets.generalCompanyNameTooltippedWidget,
            widgets.generalLocationTypeWidget,
            widgets.generalCompanyTypeWidget,
            {
              ...widgets.generalActivityCodeAggregateWidget,
              hasPdfSpecificTitle: true,
            },
            widgets.generalActivityCodeDescEnWidget,
            widgets.generalNaicsCodeWidget,
            widgets.generalNaicsCodeDescEnWidget,
            widgets.generalWebsiteWidget,
            widgets.generalEstablishDateAggregateWidget,
            widgets.generalEmployeesAggregateWidget,
            widgets.generalAnnualSalesAggregateWidget,
            widgets.generalStatusDescEnWidget,
            widgets.generalLegalNameWidget,
            widgets.generalLegalNameAltWidget,
            widgets.generalSosFederalTaxIdWidget,
            widgets.generalSosCharterNoWidget,
            widgets.generalSosStatusWidget,
            widgets.generalIncorporatedStateWidget,
            widgets.generalSosAddressWidget,
            widgets.generalSosCityWidget,
            widgets.generalSosStateWidget,
            widgets.generalSosZipWidget,
            widgets.generalSosZip4Widget,
            widgets.generalIncorporationDateWidget,
            widgets.financialsLatestAccountDateWidget,
          ],
        },
        {
          group: "contactInformation",
          widgets: [
            widgets.contactAddressLine1TooltippedWidget,
            widgets.contactCityTooltippedWidget,
            widgets.contactStateTooltippedAggregateWidget,
            widgets.contactPostalCodeTooltippedWidget,
            widgets.contactZip4TooltippedWidget,
            widgets.contactRegionWidget,
            widgets.contactCountryWidget,
            widgets.contactAddressTypeWidget,
            widgets.contactContactPhoneWidget,
          ],
        },
        {
          group: "ratingAndLimit",
          widgets: [
            widgets.ratingCurrentDateWidget,
            widgets.ratingCurrentCommonAggregateWidget,
            widgets.limitCurrentValueAggregateWidget,
            widgets.ratingCurrentScoreWidget,
            widgets.ratingCurrentDescriptionEnWidget,
            widgets.ratingPreviousScoreWidget,
            widgets.ratingPreviousDescriptionEnWidget,
            widgets.limitPreviousValueWidget,
            widgets.ratingPreviousCommonWidget,
          ],
        },
        {
          group: "financialInformation",
          widgets: [
            widgets.financialsTradeUpdateDateWidget,
            widgets.financialsCurrencyWidget,
            widgets.financialsDbtCurrentAggregateWidget,
            widgets.financialsDbtPreviousWidget,
            widgets.financialsTradelinesWidget,
            widgets.financialsBalanceCurrentWidget,
            widgets.financialsBalance1to30Widget,
            widgets.financialsBalance31to60Widget,
            widgets.financialsBalance61to90Widget,
            widgets.financialsBalance91plusWidget,
            widgets.financialsBalanceTotalWidget,
            widgets.financialsRecentHighCreditWidget,
          ],
        },
        {
          group: "negativeInformation",
          widgets: [
            widgets.negativesLegalFilingsAggregateWidget,
            widgets.negativesUccCountWidget,
            widgets.negativesLienCountWidget,
            widgets.negativesSuitCountWidget,
            widgets.negativesBankruptcyCountWidget,
            widgets.negativesJudgementCountWidget,
            widgets.negativesUccCautionaryCountWidget,
            widgets.negativesLegalFilingsAmountAggregateWidget,
            widgets.negativesOfacFlagWidget,
            widgets.negativesTotalCollectionAmountWidget,
            widgets.negativesCollectionCasesWidget,
          ],
        },
        {
          group: "groupStructure",
          widgets: [
            widgets.groupDaughterNameWidget,
            widgets.groupDaughterCountryWidget,
            widgets.groupParentIdWidget,
            widgets.groupParentSafeNumberWidget,
            widgets.groupParentNameWidget,
            widgets.groupParentCountryWidget,
            widgets.groupUltimateParentIdWidget,
            widgets.groupUltimateParentSafeNumberWidget,
            widgets.groupUltimateParentNameWidget,
            widgets.groupUltimateParentCountryWidget,
            widgets.groupHeadquarterIdWidget,
          ],
        },
        {
          group: "directorInformation",
          widgets: [
            widgets.directorsFirstNameWidget,
            widgets.directorsLastNameWidget,
            widgets.directorsFullNameWidget,
            widgets.directorsAddressLine1Widget,
            widgets.directorsCityWidget,
            widgets.directorsStateWidget,
            widgets.directorsPostalCodeWidget,
            widgets.directorsTitleEnWidget,
          ],
        },
        {
          group: "internalInformation",
          widgets: [
            widgets.generalPatternDefaultAddedWidget,
            widgets.generalBandingAggregateWidget,
            widgets.generalBandingDescDefaultAddedWidget,
            widgets.generalRowNumberWidget,
            widgets.generalDuplicateWidget,
            widgets.generalDuplicateIdWidget,
          ],
        },
      ];
  }
};
