import React from "react";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { ChartInputItem } from "./types";
import IndustryIcon from "./IndustryIcon";
import { getSelectedTabCountry } from "../../../store/jobs/selectors";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import StatusIcon from "components/StatusIcon/StatusIcon";
import { getFilteredBarChartData } from "./utils";
import {
  formatNumberThousandSeparator,
  localiseThousandSeperator,
} from "appUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    statusAndIcons: {
      "& svg": {
        marginRight: theme.spacing(1),
      },
      display: "flex",
      alignItems: "center",
    },
    title: {
      wordWrap: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "200px",
    },
    tableCell: {
      padding: theme.spacing(0.9, 3, 0.9, 2),
      minHeight: "37px",
    },
    barCell: {
      borderBottom: "none",
      padding: 0,
    },
    bar: { position: "relative", top: "2px" },
    nowrap: {
      whiteSpace: "nowrap",
    },
  })
);

const PDFBarChartTable: React.FC<{
  title: string;
  data: ChartInputItem[];
}> = ({ title, data }) => {
  const classes = useStyles(true);
  const { t } = useTranslation("aggregates");
  const location = useLocation();
  const tabCountry = useSelector(getSelectedTabCountry) || "ww";
  const queryParams = new URLSearchParams(location.search);
  const locale = queryParams.get("locale") as string;

  const getCountryCode = () => {
    if (location) {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get("countryCode") as string;
    } else {
      return tabCountry;
    }
  };

  const country = getCountryCode();

  data = getFilteredBarChartData(data, country, title);

  const getBarSize = (thisCount: number, largestCount: number) => {
    return `${(thisCount / largestCount) * 100}%`;
  };

  return (
    <Table size="small" data-testid="pdf-bar-chart-table">
      <TableHead data-testid="pdf-table-header">
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>

          <TableCell data-testid="pdf-table-count-header">
            {t("count")}
          </TableCell>
          <TableCell data-testid="pdf-table-percentage-header">
            {t("percentage")}
          </TableCell>
          <TableCell className={classes.barCell}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.map((datum, index) => (
            <TableRow key={index}>
              <TableCell
                className={`${classes.tableCell}`}
                data-testid={`pdf-table-item-${index}`}
              >
                <div className={classes.statusAndIcons}>
                  <StatusIcon color={datum.color} />

                  {datum.activityCode !== null &&
                    datum.activityCode !== undefined && (
                      <div className={classes.statusAndIcons}>
                        <IndustryIcon
                          activityCode={datum.activityCode}
                          countryCode={country || "ww"}
                        />
                      </div>
                    )}
                </div>
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.title}`}
                data-testid={`pdf-table-name-value-${index}`}
              >
                {datum.name}
              </TableCell>

              <TableCell
                data-testid={`pdf-table-count-value-${index}`}
                className={[classes.tableCell, classes.nowrap].join(" ")}
              >
                {localiseThousandSeperator(
                  formatNumberThousandSeparator(datum.value, locale),
                  locale
                )}
              </TableCell>
              <TableCell
                data-testid={`pdf-table-percentage-value-${index}`}
                className={classes.tableCell}
              >
                {datum.percentage}
              </TableCell>
              <TableCell className={classes.barCell} align="center">
                <svg width="100%" height="35px" className={classes.bar}>
                  <rect
                    data-testid="bar"
                    fill={datum.color}
                    stroke={datum.color}
                    width={getBarSize(
                      datum.value,
                      Math.max(...data.map((aggregate) => aggregate.value))
                    )}
                    height="100%"
                  />
                </svg>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default PDFBarChartTable;
