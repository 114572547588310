export const CREDIT_RATING_COLORS = [
  "rgb(64,154,106)",
  "rgb(109,199,151)",
  "rgb(243,197,52)",
  "rgb(247,148,29)",
  "rgb(238,46,36)",
  "rgb(128,128,128)",
  "rgb(200,200,200)",
];
export const EMPLOYEE_COUNT_COLORS = [
  "rgb(176,224,230)",
  "rgb(173,216,230)",
  "rgb(135,206,250)",
  "rgb(135,206,235)",
  "rgb(176,196,222)",
  "rgb(100,149,237)",
  "rgb(0,0,128)",
  "rgb(0,0,139)",
  "rgb(65,105,225)",
  "rgb(100,149,237)",
  "rgb(30,144,255)",
  "rgb(25,25,112)",
];

export const COUNTRY_COLORS = [].concat(
  ...new Array(100).fill([
    "rgb(238, 46, 36)",
    "rgb(0,0,255)",
    "rgb(128,128,128)",
  ])
);

export const CREDIT_LIMIT_COLORS = [
  "rgb(176,224,230)",
  "rgb(173,216,230)",
  "rgb(135,206,250)",
  "rgb(135,206,235)",
  "rgb(176,196,222)",
  "rgb(100,149,237)",
  "rgb(0,0,128)",
  "rgb(0,0,139)",
  "rgb(65,105,225)",
];
export const INDUSTRY_COLORS = [
  "rgb(176,224,230)",
  "rgb(173,216,230)",
  "rgb(135,206,250)",
  "rgb(135,206,235)",
  "rgb(176,196,222)",
  "rgb(100,149,237)",
  "rgb(0,0,128)",
  "rgb(0,0,139)",
];

export const INDUSTRY_COLORS_FIFTEEN = [
  "rgb(16, 35, 91)",
  "rgb(16, 40, 123)",
  "rgb(19, 34, 201)",
  "rgb(28, 64, 202)",
  "rgb(40, 78, 196)",
  "rgb(57, 94, 191)",
  "rgb(76, 108, 190)",
  "rgb(98, 126, 190)",
  "rgb(112, 137, 192)",
  "rgb(137, 156, 198)",
  "rgb(147, 164, 201)",
  "rgb(171, 184, 210)",
  "rgb(204, 211, 225)",
  "rgb(226, 230, 237)",
  "rgb(240, 242, 246)",
];

export const ALGORITHMBANDING_COLORS = [
  "rgb(0,104,55)",
  "rgb(103,189,100)",
  "rgb(205,226,146)",
  "rgb(255,204,83)",
  "rgb(141, 182, 224)",
  "rgb(128,128,128)",
];

export const STATE_COLORS = [
  "rgb(0,104,55)",
  "rgb(26,152,80)",
  "rgb(102,189,99)",
  "rgb(166,217,106)",
  "rgb(217,239,139)",
  "rgb(255,255,191)",
  "rgb(254,224,139)",
  "rgb(253,174,97)",
];

export const LEGAL_FILINGS_AMOUNT_COLORS = [
  "rgb(76,83,94)",
  "rgb(247,70,74)",
  "rgb(148,159,177)",
  "rgb(253,209,7)",
  "rgb(212,204,197)",
  "rgb(196,130,173)",
  "rgb(25,25,112)",
];
export const LEGAL_FILINGS_COLORS = [
  "rgb(76,83,94)",
  "rgb(247,70,74)",
  "rgb(148,159,177)",
  "rgb(253,209,7)",
  "rgb(212,204,197)",
  "rgb(196,130,173)",
  "rgb(25,25,112)",
];

export const REVENUE_BANDING_COLORS = [
  "rgb(77,83,96)",
  "rgb(247,70,74)",
  "rgb(148,159,177)",
  "rgb(253,209,7)",
  "rgb(212,204,197)",
  "rgb(196,130,173)",
  "rgb(192,255,192)",
  "rgb(192,0,192)",
  "rgb(176,190,28)",
  "rgb(167,190,203)",
  "rgb(128,128,128)",
  "rgb(65,105,225)",
  "rgb(25,25,112)",
];

export const DBT_COLORS = [
  "rgb(0,104,55)",
  "rgb(109,199,151)",
  "rgb(243,197,52)",
  "rgb(247,148,29)",
  "rgb(238,46,36)",
  "rgb(128,128,128)",
];

export const YEARS_TRADING_COLORS = [
  "rgb(77,83,96)",
  "rgb(247,70,74)",
  "rgb(148,159,177)",
  "rgb(253,209,7)",
  "rgb(212,204,197)",
  "rgb(196,130,173)",
  "rgb(128,128,128)",
  "rgb(25,25,112)",
];

export const PASTEL_COLORS = [
  "rgb(203, 153, 124)",
  "rgb(237, 220, 210)",
  "rgb(255, 241, 230)",
  "rgb(253, 226, 228)",
  "rgb(250, 210, 225)",
  "rgb(197, 222, 221)",
  "rgb(219, 231, 228)",
  "rgb(240, 239, 235)",
  "rgb(214, 226, 223)",
  "rgb(188, 212, 230)",
  "rgb(153, 193, 222)",
  "rgb(60, 133, 185)",
  "rgb(65,105,225)",
];
export const COMPANY_TYPE_COLORS = ["rgb(162, 204, 12)", "rgb(237, 130, 36)"];

export const DE_COMPANY_TYPE_COLORS = ["rgb(0,104,55)", "rgb(103,189,100)"];
export const RANDOM_COLORS = [
  "rgb(77,83,96)",
  "rgb(247,70,74)",
  "rgb(148,159,177)",
  "rgb(253,209,7)",
  "rgb(212,204,197)",
  "rgb(196,130,173)",
  "rgb(192,255,192)",
  "rgb(192,0,192)",
  "rgb(176,190,28)",
  "rgb(167,190,203)",
  "rgb(128,128,128)",
  "rgb(65,105,225)",
  "rgb(25,25,112)",
];

export const STATUS_COLORS = [
  "rgb(64,154,106)",
  "rgb(238,46,36)",
  "rgb(243,197,52)",
  "rgb(128,128,128)",
];

export const IMPORT_FLAG_COLORS = [
  "rgb(64,154,106)",
  "rgb(238,46,36)",
  "rgb(128,128,128)",
];

export const EXPORT_FLAG_COLORS = [
  "rgb(64,154,106)",
  "rgb(238,46,36)",
  "rgb(128,128,128)",
];
export const CAPITAL_COLORS = [
  "rgb(176,224,230)",
  "rgb(173,216,230)",
  "rgb(135,206,250)",
  "rgb(135,206,235)",
  "rgb(176,196,222)",
];

export const IT_CREDIT_RATING_SCORE = [
  "rgb(64,154,106)",
  "rgb(243,197,52)",
  "rgb(238,46,36)",
  "rgb(128,128,128)",
];

export const TOP_EIGHTEEN_BLUE_SHADES_COLOR = [
  "rgb(10, 28, 97)",
  "rgb(9, 29, 128)",
  "rgb(10, 27, 141)",
  "rgb(14, 20, 167)",
  "rgb(16, 43, 164)",
  "rgb(25, 56, 161)",
  "rgb(45, 75, 160)",
  "rgb(56, 85, 161)",
  "rgb(71, 97, 163)",
  "rgb(86, 110, 167)",
  "rgb(107, 127, 173)",
  "rgb(119, 138, 178)",
  "rgb(142, 157, 188)",
  "rgb(151, 164, 192)",
  "rgb(173, 183, 204)",
  "rgb(199, 205, 218)",
  "rgb(228, 231, 236)",
  "rgb(241, 243, 245)",
];
export const DBT_CA_COLORS = [
  "rgb(0,104,55)",
  "rgb(103,189,100)",
  "rgb(205,226,146)",
  "rgb(255,236,136)",
  "rgb(255,204,83)",
  "rgb(249,151,45)",
  "rgb(238,46,36)",
  "rgb(128, 128, 128)",
];
export const EMPLOYEE_COUNT_COLORS_GRADIENT_BLUE = [
  "rgb(107, 127, 173)",
  "rgb(86, 110, 167)",
  "rgb(71, 97, 163)",
  "rgb(56, 85, 161)",
  "rgb(45, 75, 160)",
  "rgb(25, 56, 161)",
  "rgb(16, 43, 164)",
  "rgb(14, 20, 167)",
  "rgb(10, 27, 141)",
  "rgb(9, 29, 128)",
  "rgb(10, 28, 97)",
  "rgb(128, 128, 128)",
];

export const INCREASINGLY_DARKER_GREEN_BLUE_SHADES = [
  "rgb(222,244,236)",
  "rgb(214,229,231)",
  "rgb(206,213,217)",
  "rgb(152,205,203)",
  "rgb(113,190,194)",
  "rgb(81,174,184)",
  "rgb(67,156,175)",
  "rgb(54,140,165)",
  "rgb(41,124,156)",
  "rgb(29,109,148)",
  "rgb(26,96,137)",
  "rgb(30,83,123)",
  "rgb(34,70,110)",
  "rgb(38,57,96)",
  "rgb(128,128,128)",
];

export const CREDIT_INDEX_COLORS = [
  "rgb(0,104,55)",
  "rgb(103,189,100)",
  "rgb(205,226,146)",
  "rgb(255,204,83)",
  "rgb(249,151,45)",
  "rgb(238,46,36)",
  "rgb(128, 128, 128)",
];

export const BE_STATUS_COLORS = [
  "rgb(0,104,55)",
  "rgb(238,46,36)",
  "rgb(249,151,45)",
  "rgb(103,189,100)",
  "rgb(205,226,146)",
  "rgb(255,204,83)",
  "rgb(128,128,128)",
];

export const SE_TURNOVER_COLORS = [
  "rgb(255, 200, 163)",
  "rgb(255, 246, 194)",
  "rgb(237, 190, 200)",
  "rgb(237, 197, 228)",
  "rgb(219, 151, 198)",
  "rgb(171, 145, 196)",
  "rgb(181, 132, 161)",
  "rgb(146, 112, 153)",
  "rgb(128,128,128)",
];

export const INDUSTRY_COLORS_EIGHT = [
  "rgb(16, 40, 123)",
  "rgb(19, 34, 201)",
  "rgb(40, 78, 196)",
  "rgb(57, 94, 191)",
  "rgb(98, 126, 190)",
  "rgb(137, 156, 198)",
  "rgb(171, 184, 210)",
  "rgb(226, 230, 237)",
];
