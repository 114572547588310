import React from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { makeStyles, createStyles } from "@material-ui/core";
import { ChartInputItem } from "./types";
import { fadeRGBColors } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;
  return (
    <g>
      <text
        fontSize={10}
        x={cx}
        y={cy}
        textAnchor="middle"
        data-testid={`${payload.name}-active-inner-text`}
      >
        {payload.label || payload.name}
      </text>
      <text fontSize={10} x={cx} y={cy} dy={16} textAnchor="middle">
        {`(${payload.percentage})`}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        data-testid={`${payload.name}-active-outer-segment`}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 2}
        outerRadius={outerRadius + 6}
        fill={fill}
      />
    </g>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    pieChart: {
      margin: "auto",
      width: "200px",
      height: "200px",
    },
  })
);

const DoughnutChart: React.FC<{
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  formattedData: ChartInputItem[];
  donut?: boolean;
  isAnimationActive?: boolean;
}> = ({
  activeIndex,
  setActiveIndex,
  formattedData,
  donut,
  isAnimationActive,
}) => {
  const classes = useStyles();
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

  return (
    <div data-testid="pie-chart">
      <PieChart width={200} height={200} className={classes.pieChart}>
        <Pie
          startAngle={-270}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={formattedData}
          cx={100}
          cy={100}
          innerRadius={donut ? 45 : 0}
          outerRadius={80}
          dataKey="value"
          data-testid="pie"
          isAnimationActive={isAnimationActive}
        >
          {formattedData &&
            formattedData.map((entry: ChartInputItem, index: number) => (
              <Cell
                key={index}
                fill={
                  index === hoveredIndex && index !== activeIndex
                    ? fadeRGBColors(formattedData[index].color)
                    : formattedData[index].color
                }
                onClick={() => setActiveIndex(index)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                data-testid={`pie-cell-${index}`}
                aria-label={`aria-${index}`}
              />
            ))}
        </Pie>
      </PieChart>
    </div>
  );
};

DoughnutChart.defaultProps = {
  donut: false,
  isAnimationActive: true,
};

export default DoughnutChart;
