import * as MappingsActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Normalized, Status } from "../types";

export type StoredEnrichment = { enrichment: string };

export interface EnrichmentPayload {
  name: string;
  creditType: string;
  dE_Reason?: { description: string; id: number };
  headquarters: string;
  enrichmentTemplateMappings: StoredEnrichment[];
  algorithmBanding?: string[];
}

export interface SaveEnrichmentTemplatePayload {
  id?: string;
  name: string;
  creditType: string;
  de_ReasonId?: number;
  headQuarters?: string | null;
  enrichments: string[];
  isPublic?: boolean;
  algorithmBanding: string[];
}

export interface Enrichment extends EnrichmentPayload {
  id: string;
}

export type EnrichmentsState = Normalized<Enrichment, string> & {
  selected?: string;
  templateSavingStatus: Status;
  algorithBandings?: {
    data: string[];
    status: Status;
  };
};

export const FETCH_MAPPING_ENRICHMENT_TEMPLATES =
  "FETCH_MAPPING_ENRICHMENT_TEMPLATES";
export const FETCH_MAPPING_ENRICHMENT_TEMPLATES_SUCCESS =
  "FETCH_MAPPING_ENRICHMENT_TEMPLATES_SUCCESS";
export const FETCH_MAPPING_ENRICHMENT_TEMPLATES_ERROR =
  "FETCH_MAPPING_ENRICHMENT_TEMPLATES_ERROR";

export const FETCH_ALGORITHM_BANDINGS = "FETCH_ALGORITHM_BANDINGS";
export const FETCH_ALGORITHM_BANDINGS_SUCCESS =
  "FETCH_ALGORITHM_BANDINGS_SUCCESS";
export const FETCH_ALGORITHM_BANDINGS_ERROR = "FETCH_ALGORITHM_BANDINGS_ERROR";

export const SAVE_ENRICHMENT_TEMPLATE = "SAVE_ENRICHMENT_TEMPLATE";
export const SAVE_ENRICHMENT_TEMPLATE_SUCCESS =
  "SAVE_ENRICHMENT_TEMPLATE_SUCCESS";
export const SAVE_ENRICHMENT_TEMPLATE_ERROR = "SAVE_ENRICHMENT_TEMPLATE_ERROR";
export const RESET_ENRICHMENT_TEMPLATE_SAVE_STATUS =
  "RESET_ENRICHMENT_TEMPLATE_SAVE_STATUS";

export const CLEAR_POPUP_NOTIFICATION = "CLEAR_POPUP_NOTIFICATION";

export type InnerEnrichments = { enrichment: string; value: string };

export type EnrichmentsActionTypes = ActionType<typeof MappingsActions>;
