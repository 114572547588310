import React from "react";
import { Route } from "react-router-dom";
import {
  AsyncHome,
  AsyncPageNotFound,
  AsyncNewFile,
  AsyncJobs,
  AsyncDashboard,
  AsyncSettings,
} from "./LoadableRoutes";

const getAppRoutes = () => {
  return [
    <Route exact path="/" component={AsyncHome} key="home-route" />,
    <Route path="/new" component={AsyncNewFile} key="new-route" />,
    <Route path="/jobs" component={AsyncJobs} key="jobs-route" />,
    <Route path="/admin" component={AsyncDashboard} key="admin-route" />,
    <Route path="/settings" component={AsyncSettings} key="settings-route" />,
    <Route component={AsyncPageNotFound} key="not-found-route" />,
  ];
};

export default getAppRoutes;
