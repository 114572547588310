import {
  SET_LOCALES,
  SetLocalesPayload,
  LOAD_LOCALES,
  LANGUAGE_CHANGED,
} from "./types";
import { action } from "typesafe-actions";

export const setLocalesAction = (payload: SetLocalesPayload) =>
  action(SET_LOCALES, payload);

export const loadLocalesAction = (payload: { code: string }[]) =>
  action(LOAD_LOCALES, payload);

export const languageChangedAction = (language: string) =>
  action(LANGUAGE_CHANGED, language);
