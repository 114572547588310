import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import cslogo from "../../assets/cslogo.svg";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "90vh",
    },
  })
);

const SignInLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <img src={cslogo} alt="Creditsafe logo" width="200px" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1"> Signing you in... </Typography>
      </Grid>
      <Grid item>
        <CircularProgress size={60} />
      </Grid>
    </Grid>
  );
};

export default SignInLoading;
