import enGB from "numbro/languages/en-GB";
import frFR from "numbro/languages/fr-FR";
import deDE from "numbro/languages/de-DE";
import nlBE from "numbro/languages/nl-BE";
import nlNL from "numbro/languages/nl-NL";
import itIT from "numbro/languages/it-IT";
import daDK from "numbro/languages/da-DK";
import svSE from "numbro/languages/sv-SE";

export const localeConfig = [
  {
    code: "en-US",
  },
  {
    code: "en-GB",
    numbroLanguage: enGB,
    datePickerFormat: "d/M/yyyy",
  },
  {
    code: "en-CA",
    numbroLanguage: enGB,
  },
  {
    code: "it-IT",
    numbroLanguage: itIT,
  },
  {
    code: "da-DK",
    numbroLanguage: daDK,
  },
  {
    code: "fr-FR",
    numbroLanguage: frFR,
    overrides: [{ path: "delimiters.thousands", pathValue: "." }],
  },
  {
    code: "fr-BE",
    numbroLanguage: frFR,
    overrides: [
      { path: "delimiters.thousands", pathValue: "." },
      { path: "languageTag", pathValue: "fr-BE" },
    ],
    momentLocaleParent: "fr",
  },
  {
    code: "de-DE",
    numbroLanguage: deDE,
    overrides: [{ path: "delimiters.thousands", pathValue: "." }],
  },
  {
    code: "nl-BE",
    numbroLanguage: nlBE,
    overrides: [{ path: "delimiters.thousands", pathValue: "," }],
  },
  {
    code: "nl-NL",
    numbroLanguage: nlNL,
    overrides: [{ path: "delimiters.thousands", pathValue: "." }],
  },
  {
    code: "sv-SE",
    numbroLanguage: svSE,
    overrides: [{ path: "delimiters.thousands", pathValue: " " }],
  },
];
