import React from "react";

const StatusIcon: React.FC<{
  color: string;
  opacity?: number;
}> = ({ color, opacity }) => {
  return (
    <svg viewBox="0 0 30 30" width="10px" height="10px">
      <circle cx="15" cy="15" r="15" fill={color} opacity={opacity || 1} />
    </svg>
  );
};
export default StatusIcon;
