interface IEnvironment {
  name: "local" | "test" | "stage" | "production";
  version: string;
}

declare global {
  interface Window {
    environment: IEnvironment;
    digitalData: Record<string, unknown>;
  }
}

export interface IConfig {
  INSTRUMENTATION_KEY: string;
  DATA_CLEANING_API_URL: string;
  PORTFOLIOS_API_URL: string;
  UPP_API_URL: string;
  UBO_API_URL: string;
  UPP_APP_URL: string;
  ADOBE_ANALYTICS_SCRIPT_URL: string;
  SERVICE_ID: number;
  INTERNATIONAL_MONITORING_SERVICE_ID: number;
  DOMESTIC_MONITORING_SERVICE_ID: number;
  DEBUG_MODE: boolean;
  LOCIZE_ENABLED: boolean;
  SPLITIO_KEY: string;
  LINKAGES_ROUTER_URL: string;
}

const initConfig = (isAzure = false): IConfig => {
  const envName = window.environment.name;

  switch (envName) {
    case "local":
      if (process.env.REACT_APP_MOCK_SERVER) {
        return {
          INSTRUMENTATION_KEY: "",
          DATA_CLEANING_API_URL: "http://localhost:3001",
          PORTFOLIOS_API_URL: "http://localhost:3001",
          UPP_API_URL: "http://localhost:3001",
          UBO_API_URL: "http://localhost:3001",
          UPP_APP_URL: "https://test.uppdev.creditsafe.com",
          ADOBE_ANALYTICS_SCRIPT_URL:
            "//assets.adobedtm.com/a7310339adb3/bbd9fd7cb3cd/launch-3de3137505da-development.min.js",
          SERVICE_ID: 3037,
          INTERNATIONAL_MONITORING_SERVICE_ID: 3201,
          DOMESTIC_MONITORING_SERVICE_ID: 3101,
          DEBUG_MODE: true,
          LOCIZE_ENABLED: false,
          SPLITIO_KEY: "5l6lq4d3d69ko7u4vd3rvq8ddtfh0fp8c7gg",
          LINKAGES_ROUTER_URL:
            "https://mylogin.test.creditsafe.com/LinkagesPortal",
        };
      } else {
        return {
          INSTRUMENTATION_KEY: "",
          //DATA_CLEANING_API_URL: "https://localhost:5001/v1",
          DATA_CLEANING_API_URL: isAzure
            ? "https://cs-datacleaning-api-test.azurewebsites.net/v1"
            : "https://cs-datacleaning-api-test.creditsafe.com/v1",
          PORTFOLIOS_API_URL:
            "https://cs-portfolios-api-test.azurewebsites.net/v1",
          UPP_API_URL: "https://test-api.uppdev.creditsafe.com/v1",
          UBO_API_URL: "https://test-api.uppdev.creditsafe.com/ubo",
          UPP_APP_URL: "https://test.uppdev.creditsafe.com",
          ADOBE_ANALYTICS_SCRIPT_URL:
            "//assets.adobedtm.com/a7310339adb3/bbd9fd7cb3cd/launch-3de3137505da-development.min.js",
          SERVICE_ID: 3037,
          INTERNATIONAL_MONITORING_SERVICE_ID: 3201,
          DOMESTIC_MONITORING_SERVICE_ID: 3101,
          DEBUG_MODE: true,
          LOCIZE_ENABLED: false,
          SPLITIO_KEY: "5l6lq4d3d69ko7u4vd3rvq8ddtfh0fp8c7gg",
          LINKAGES_ROUTER_URL:
            "https://mylogin.test.creditsafe.com/LinkagesPortal",
        };
      }
    case "test":
      return {
        INSTRUMENTATION_KEY: "0d65004b-ef0b-4d71-9149-33e2476677cd",
        DATA_CLEANING_API_URL: isAzure
          ? "https://cs-datacleaning-api-test.azurewebsites.net/v1"
          : "https://cs-datacleaning-api-test.creditsafe.com/v1",
        PORTFOLIOS_API_URL:
          "https://cs-portfolios-api-test.azurewebsites.net/v1",
        UPP_API_URL: "https://test-api.uppdev.creditsafe.com/v1",
        UBO_API_URL: "https://test-api.uppdev.creditsafe.com/ubo",
        UPP_APP_URL: "https://test.uppdev.creditsafe.com",
        ADOBE_ANALYTICS_SCRIPT_URL:
          "//assets.adobedtm.com/a7310339adb3/bbd9fd7cb3cd/launch-3de3137505da-development.min.js",
        SERVICE_ID: 3037,
        INTERNATIONAL_MONITORING_SERVICE_ID: 3201,
        DOMESTIC_MONITORING_SERVICE_ID: 3101,
        DEBUG_MODE: false,
        LOCIZE_ENABLED: false,
        SPLITIO_KEY: "5l6lq4d3d69ko7u4vd3rvq8ddtfh0fp8c7gg",
        LINKAGES_ROUTER_URL:
          "https://mylogin.test.creditsafe.com/LinkagesPortal",
      };
    case "stage":
      return {
        INSTRUMENTATION_KEY: "54d039e0-e971-49bc-aee6-b16bb9c75b6a",
        DATA_CLEANING_API_URL: isAzure
          ? "https://cs-datacleaning-api-stage.azurewebsites.net/v1"
          : "https://cs-datacleaning-api-stage.creditsafe.com/v1",
        PORTFOLIOS_API_URL:
          "https://cs-portfolios-api-stage.azurewebsites.net/v1",
        UPP_API_URL: "https://demo-api.uppdev.creditsafe.com/v1",
        UBO_API_URL: "https://demo-api.uppdev.creditsafe.com/ubo",
        UPP_APP_URL: "https://stage.uppdev.creditsafe.com",
        ADOBE_ANALYTICS_SCRIPT_URL:
          "//assets.adobedtm.com/a7310339adb3/bbd9fd7cb3cd/launch-546aaaabae61-staging.min.js",
        SERVICE_ID: 3037,
        INTERNATIONAL_MONITORING_SERVICE_ID: 3201,
        DOMESTIC_MONITORING_SERVICE_ID: 3101,
        DEBUG_MODE: false,
        LOCIZE_ENABLED: true,
        SPLITIO_KEY: "bqpk6o1qe5kdq6lio6ig7a4ljsn9rlbbfvun",
        LINKAGES_ROUTER_URL:
          "https://mylogin-staged.creditsafe.com/LinkagesPortal",
      };
    case "production":
      return {
        INSTRUMENTATION_KEY: "5fb208fe-04a7-47f9-be6a-6378f258cd44",
        DATA_CLEANING_API_URL: isAzure
          ? "https://cs-datacleaning-api-prod.azurewebsites.net/v1"
          : "https://cs-datacleaning-api.creditsafe.com/v1",
        PORTFOLIOS_API_URL:
          "https://cs-portfolios-api-prod.azurewebsites.net/v1",
        UPP_API_URL: "https://api.app.creditsafe.com/v1",
        UBO_API_URL: "https://api.app.creditsafe.com/ubo",
        UPP_APP_URL: "https://app.creditsafe.com",
        ADOBE_ANALYTICS_SCRIPT_URL:
          "//assets.adobedtm.com/a7310339adb3/bbd9fd7cb3cd/launch-f3ab6bef1cf6.min.js",
        SERVICE_ID: 3037,
        INTERNATIONAL_MONITORING_SERVICE_ID: 3201,
        DOMESTIC_MONITORING_SERVICE_ID: 3101,
        DEBUG_MODE: false,
        LOCIZE_ENABLED: true,
        SPLITIO_KEY: "f7bml0u4ev951vnoji81o8b3q079psp27rrj",
        LINKAGES_ROUTER_URL: "https://mylogin.creditsafe.com/LinkagesPortal",
      };
  }
};

const config = initConfig();

const updateConfig = (isAzure = false) => {
  Object.assign(config, initConfig(isAzure));
  return config;
};

export { updateConfig };

export default config;
