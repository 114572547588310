import { WidgetDisplayMode, Widgets } from "../types";
import * as colors from "./colors";
import { toCamelCase } from "./utils";
import {
  basicWidgetJsonTags,
  requiredAndTooltippedWidgetJsonTags,
  requiredWidgetJsonTags,
  tooltippedWidgetJsonTags,
  defaultAddedWidgetJsonTags,
} from "./widgetJsonTags";

const basicWidgets = basicWidgetJsonTags.reduce(
  (acc, curr) => ({
    ...acc,
    [`${toCamelCase(curr)}Widget`]: {
      name: toCamelCase(curr),
      widgetType: "unset",
      colors: [],
      jsonTag: curr,
      isRequired: false,
      hasTooltip: false,
    },
  }),
  {}
);

const tooltippedWidgets = tooltippedWidgetJsonTags.reduce(
  (acc, curr) => ({
    ...acc,
    [`${toCamelCase(curr)}TooltippedWidget`]: {
      name: toCamelCase(curr),
      widgetType: "unset",
      colors: [],
      jsonTag: curr,
      isRequired: false,
      hasTooltip: true,
    },
  }),
  {}
);

const requiredWidgets = requiredWidgetJsonTags.reduce(
  (acc, curr) => ({
    ...acc,
    [`${toCamelCase(curr)}RequiredWidget`]: {
      name: toCamelCase(curr),
      widgetType: "unset",
      colors: [],
      jsonTag: curr,
      isRequired: true,
      hasTooltip: false,
    },
  }),
  {}
);

const defaultAddedWidgets = defaultAddedWidgetJsonTags.reduce(
  (acc, curr) => ({
    ...acc,
    [`${toCamelCase(curr)}DefaultAddedWidget`]: {
      name: toCamelCase(curr),
      widgetType: "unset",
      colors: [],
      jsonTag: curr,
      isRequired: false,
      hasTooltip: false,
      isAddedByDefault: true,
    },
  }),
  {}
);

const requiredAndTooltippedWidgets = requiredAndTooltippedWidgetJsonTags.reduce(
  (acc, curr) => ({
    ...acc,
    [`${toCamelCase(curr)}RequiredTooltippedWidget`]: {
      name: toCamelCase(curr),
      widgetType: "unset",
      colors: [],
      jsonTag: curr,
      isRequired: true,
      hasTooltip: false,
    },
  }),
  {}
);

const aggregateWidgets = {
  contactProvinceAggregateWidget: {
    name: "Province",
    widgetType: "map",
    colors: colors.COUNTRY_COLORS,
    jsonTag: "contact.province",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
  },
  contactCountryAggregateWidget: {
    name: "CountryCode",
    widgetType: "map",
    colors: colors.COUNTRY_COLORS,
    jsonTag: "contact.country",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
  },
  negativesCcjCountAggregateWidget: {
    name: "CCJCount",
    widgetType: "bar",
    colors: colors.YEARS_TRADING_COLORS,
    jsonTag: "negatives.ccjCount",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  negativesCcjValueAggregateWidget: {
    name: "CCJValue",
    widgetType: "bar",
    colors: colors.YEARS_TRADING_COLORS,
    jsonTag: "negatives.ccjValue",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  contactStateTooltippedAggregateWidget: {
    name: "States",
    widgetType: "map",
    colors: colors.COUNTRY_COLORS,
    jsonTag: "contact.state",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
  },
  ratingCurrentCommonAggregateWidget: {
    name: "CreditRating",
    widgetType: "donut",
    colors: colors.CREDIT_RATING_COLORS,
    jsonTag: "rating.current.common",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  contactStateAggregateWidget: {
    name: "States",
    widgetType: "bar",
    colors: colors.STATE_COLORS,
    jsonTag: "contact.state",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  financialsDbtCurrentAggregateWidget: {
    name: "DBT",
    widgetType: "donut",
    colors: colors.DBT_COLORS,
    jsonTag: "financials.dbt.current",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalActivityCodeAggregateWidget: {
    name: "SICCode",
    widgetType: "bar",
    colors: colors.INDUSTRY_COLORS_EIGHT,
    jsonTag: "general.activityCode",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
  },
  generalActivityCode2AggregateWidget: {
    name: "SIC07",
    widgetType: "bar",
    colors: colors.INDUSTRY_COLORS_EIGHT,
    jsonTag: "general.activityCode2",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
    hasPdfSpecificTitle: true,
  },
  generalAnnualSalesAggregateWidget: {
    name: "RevenueBand",
    widgetType: "donut",
    colors: colors.REVENUE_BANDING_COLORS,
    jsonTag: "general.annualSales",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalEmployeesAggregateWidget: {
    name: "NoOfEmployees",
    widgetType: "donut",
    colors: colors.EMPLOYEE_COUNT_COLORS,
    jsonTag: "general.employees",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalEstablishDateAggregateWidget: {
    name: "EstablishDate",
    widgetType: "bar",
    colors: colors.YEARS_TRADING_COLORS,
    jsonTag: "general.establishDate",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalYearsTradingAggregateWidget: {
    name: "YearsTrading",
    widgetType: "bar",
    colors: colors.YEARS_TRADING_COLORS,
    jsonTag: "general.yearsTrading",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  limitCurrentValueAggregateWidget: {
    name: "CreditLimit",
    widgetType: "donut",
    colors: colors.CREDIT_LIMIT_COLORS,
    jsonTag: "limit.current.value",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  negativesLegalFilingsAggregateWidget: {
    name: "LegalFilings",
    widgetType: "donut",
    colors: colors.LEGAL_FILINGS_COLORS,
    jsonTag: "negatives.legalFilings",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  negativesLegalFilingsAmountAggregateWidget: {
    name: "LegalFilingsAmount",
    widgetType: "donut",
    colors: colors.LEGAL_FILINGS_AMOUNT_COLORS,
    jsonTag: "negatives.legalFilingsAmount",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  contactPostalCodeAggregateWidget: {
    name: "Province",
    widgetType: "map",
    colors: colors.COUNTRY_COLORS,
    jsonTag: "contact.postalCode",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
    hasPdfSpecificTitle: true,
  },
  generalLtdNonLtdFlagAggregateWidget: {
    name: "CompanyType",
    widgetType: "donut",
    colors: colors.COMPANY_TYPE_COLORS,
    jsonTag: "general.ltdNonLtdFlag",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  financialsTurnoverAggregateWidget: {
    name: "Turnover",
    widgetType: "bar",
    colors: colors.RANDOM_COLORS,
    jsonTag: "financials.turnover",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalStatusAggregateWidget: {
    name: "Status",
    widgetType: "donut",
    colors: colors.STATUS_COLORS,
    jsonTag: "general.status",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalImportFlagAggregateWidget: {
    name: "ImportFlag",
    widgetType: "donut",
    colors: colors.IMPORT_FLAG_COLORS,
    jsonTag: "general.importFlag",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalExportFlagAggregateWidget: {
    name: "ExportFlag",
    widgetType: "donut",
    colors: colors.EXPORT_FLAG_COLORS,
    jsonTag: "general.exportFlag",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  financialsCapitalAggregateWidget: {
    name: "Capital",
    widgetType: "donut",
    colors: colors.CAPITAL_COLORS,
    jsonTag: "financials.capital",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  RegistrationDateWidget: {
    name: "RegistrationDate",
    widgetType: "bar",
    colors: colors.YEARS_TRADING_COLORS,
    jsonTag: "general.registrationDate",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalLegalFormDescEnAggregateWidget: {
    name: "LegalForm",
    widgetType: "bar",
    colors: colors.INDUSTRY_COLORS_FIFTEEN,
    jsonTag: "general.legalFormDesc.en",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
    hasPdfSpecificTitle: true,
  },
  ratingCurrentScoreAggregateWidget: {
    name: "CreditRatingScore",
    widgetType: "donut",
    colors: colors.IT_CREDIT_RATING_SCORE,
    jsonTag: "rating.current.score",
    isRequired: false,
    hasTooltip: false,
    pdfOnly: true,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalCompanyTypeAggregateWidget: {
    name: "GeneralLegalForm",
    widgetType: "bar",
    colors: colors.INDUSTRY_COLORS_FIFTEEN,
    jsonTag: "general.legalForm",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
    hasPdfSpecificTitle: true,
  },
  contactRegionAggregateWidget: {
    name: "Region",
    widgetType: "map",
    colors: colors.COUNTRY_COLORS,
    jsonTag: "contact.region",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
  },
  generalActivityCodeAggregateWidgetPdfSpecificTitle: {
    name: "SICCode",
    widgetType: "bar",
    colors: colors.INDUSTRY_COLORS_FIFTEEN,
    jsonTag: "general.activityCode",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
    hasPdfSpecificTitle: true,
  },
  financialsSalesRevenueAggregateWidget: {
    name: "SalesRevenue",
    widgetType: "donut",
    colors: colors.INDUSTRY_COLORS,
    jsonTag: "financials.salesRevenue",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalEmployeesAggregateGlobalWidget: {
    name: "NoOfEmployees",
    widgetType: "donut",
    colors: colors.EMPLOYEE_COUNT_COLORS,
    jsonTag: "general.employees",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
    hasPdfSpecificTitle: true,
  },
  financialsTotalEquityAggregateWidget: {
    name: "TotalEquity",
    widgetType: "donut",
    colors: colors.REVENUE_BANDING_COLORS,
    jsonTag: "financials.totalEquity",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalLegalFormCategoryAggregateWidget: {
    name: "LegalFormCategory",
    widgetType: "bar",
    colors: colors.TOP_EIGHTEEN_BLUE_SHADES_COLOR,
    jsonTag: "general.legalFormCategory",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  ratingCurrentScoreCAAggregateWidget: {
    name: "CreditRatingScore",
    widgetType: "donut",
    colors: colors.CREDIT_RATING_COLORS,
    jsonTag: "rating.current.score",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  financialsDbtCurrentCAAggregateWidget: {
    name: "DBT",
    widgetType: "donut",
    colors: colors.DBT_CA_COLORS,
    jsonTag: "financials.dbt.current",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
  },
  ratingCurrentLocalAggregateWidget: {
    name: "CreditIndex",
    widgetType: "donut",
    colors: colors.CREDIT_INDEX_COLORS,
    jsonTag: "rating.current.local",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
  generalCompanyTypeDescEnAggregateWidget: {
    name: "LegalForm",
    widgetType: "bar",
    colors: colors.INDUSTRY_COLORS_FIFTEEN,
    jsonTag: "general.companyTypeDesc.en",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.FULL_WIDTH,
    hasPdfSpecificTitle: true,
  },
  generalBandingAggregateWidget: {
    name: "AlgorithmBanding",
    widgetType: "bar",
    colors: colors.ALGORITHMBANDING_COLORS,
    jsonTag: "general.banding",
    isRequired: false,
    hasTooltip: false,
    displayMode: WidgetDisplayMode.HALF_WIDTH,
  },
};

const widgets = {
  ...basicWidgets,
  ...aggregateWidgets,
  ...requiredWidgets,
  ...tooltippedWidgets,
  ...requiredAndTooltippedWidgets,
  ...defaultAddedWidgets,
} as Widgets;

export default widgets;
