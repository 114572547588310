import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Button, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { History } from "history";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchForm: {
      width: "100%",
      display: "flex",
    },
    searchInput: {
      borderRadius: theme.spacing(0.5, 0, 0, 0.5),
      borderRight: "none",
      "&>fieldset": {
        borderRight: "none",
      },
    },
    btnSubmit: { borderRadius: theme.spacing(0, 0.5, 0.5, 0) },
  })
);

const searchJob = (
  value: string,
  history: History<History.PoorMansUnknown>
) => {
  if (!isEmpty(value)) history.push(`/Jobs?jobName_like=${value}`);
};

const HeaderSearchBar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("global");
  const [searchCriteria, setSearchCriteria] = useState("");
  const currentPage = history.location.pathname;

  return currentPage !== "/jobs" ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        searchJob(searchCriteria, history);
      }}
      className={classes.searchForm}
    >
      <OutlinedInput
        id="search-input"
        name="search-input"
        placeholder={t("header.placeHolder")}
        type="text"
        fullWidth
        className={classes.searchInput}
        value={searchCriteria}
        onChange={(e) => setSearchCriteria(e.target.value)}
        inputProps={{
          "data-testid": "search-input",
        }}
        margin="dense"
        startAdornment={<SearchIcon />}
      />
      <Button
        type="submit"
        variant="contained"
        className={classes.btnSubmit}
        disableElevation
        color="primary"
        size="small"
        data-testid="search-button"
      >
        {t("header.buttonText")}
      </Button>
    </form>
  ) : null;
};

export default HeaderSearchBar;
