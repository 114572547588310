import { combineEpics, Epic as ReduxObservableEpic } from "redux-observable";
import * as jobsEpics from "./jobs/epics";
import * as mappingsEpics from "./mappings/epics";
import * as enrichmentsEpics from "./enrichments/epics";
import * as userEpics from "./user/epics";
import * as customerEpics from "./customer/epics";
import * as i18nEpics from "./i18n/epics";
import * as appEpics from "./app/epics";
import * as adminJobsEpics from "./admin/epics";
import * as settingsEpics from "./settings/epics";
import { RootAction } from "./types";
import { RootState } from "./rootReducer";
import { EpicDependencies } from "./epicDependencies";

const epics = combineEpics(
  ...Object.values(jobsEpics),
  ...Object.values(userEpics),
  ...Object.values(mappingsEpics),
  ...Object.values(enrichmentsEpics),
  ...Object.values(customerEpics),
  ...Object.values(i18nEpics),
  ...Object.values(appEpics),
  ...Object.values(adminJobsEpics),
  ...Object.values(settingsEpics)
);

export type Epic<T extends RootAction> = ReduxObservableEpic<
  RootAction,
  T,
  RootState,
  EpicDependencies
>;

export default epics;
