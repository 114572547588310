import React, { useEffect } from "react";
import getAppRoutes from "../../components/AppRoutes";
import { Switch, withRouter } from "react-router-dom";
import {
  getCustomerId,
  getIsInternalUser,
  getIsUserAllowedForSearchPanel,
  getIsUserFirstLogin,
  getUserId,
} from "store/user/selectors";
import { useDispatch, useSelector } from "react-redux";
import { checkIsUserAllowedForSearchPanel } from "store/user/actions";

const Routes = () => {
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const customerId = useSelector(getCustomerId);
  const isUserAllowedForSearchPanel = useSelector(
    getIsUserAllowedForSearchPanel
  );
  const isUserFirstLogin = useSelector(getIsUserFirstLogin);
  useEffect(() => {
    if (!isUserFirstLogin) {
      dispatch(
        checkIsUserAllowedForSearchPanel({
          userID: userId,
          customerID: customerId,
        })
      );
    }
  }, [
    customerId,
    dispatch,
    isUserAllowedForSearchPanel,
    isUserFirstLogin,
    userId,
  ]);

  const isInternalUser = useSelector(getIsInternalUser);
  const appRoutes =
    isInternalUser && isUserAllowedForSearchPanel
      ? getAppRoutes()
      : getAppRoutes().filter((x) => x.key !== "admin-route");

  return (
    <div>
      <Switch>
        {appRoutes.map((Route, i) => {
          return React.cloneElement(Route, { key: `@appRoutes/${i}` });
        })}
      </Switch>
    </div>
  );
};

export default withRouter(Routes);
