import React, { useState, useEffect, ReactElement } from "react";

interface DelayedPros {
  children: ReactElement;
  waitBeforeShow?: number;
}

const Delayed: React.FC<DelayedPros> = ({
  children,
  waitBeforeShow = 1000,
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);

    return () => {
      clearTimeout(timeout);
    };
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

export default Delayed;
