import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Delayed from "../Delayed";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(5),
    },
  })
);

const LoadingSpinner: React.FC<{ dataTestId?: string }> = ({
  children = "Loading...",
  dataTestId = "loading-spinner",
}) => {
  const classes = useStyles();

  return (
    <Delayed waitBeforeShow={800}>
      <Grid
        data-testid={dataTestId}
        container
        justify="center"
        alignItems="center"
        direction="column"
        spacing={2}
        className={classes.root}
      >
        <Grid item>
          <Typography variant="subtitle1"> {children} </Typography>
        </Grid>
        <Grid item>
          <CircularProgress size={60} />
        </Grid>
      </Grid>
    </Delayed>
  );
};

export default LoadingSpinner;
