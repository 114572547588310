import { Epic } from "../rootEpic";
import {
  USER_SIGNED_IN,
  FETCH_USER_DETAILS_SUCCESS,
  CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL,
  FETCH_MONITORING_PORTFOLIOS,
} from "./types";
import { LANGUAGE_CHANGED } from "../i18n/types";
import { of, EMPTY } from "rxjs";
import {
  filter,
  switchMap,
  map,
  catchError,
  withLatestFrom,
} from "rxjs/operators";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsError,
  fetchRemainingCreditsSuccess,
  fetchRemainingCreditsError,
  userSignedIn,
  fetchEntityTelSuccess,
  userAllowedForSearchPanelSuccess,
  userAllowedForSearchPanelError,
  fetchMonitoringPortfoliosSuccess,
  fetchMonitoringPortfoliosError,
} from "./actions";
import { loadLocalesAction } from "../i18n/actions";
import { isOfType, ActionType } from "typesafe-actions";
import { AjaxResponse } from "rxjs/ajax";
import config from "../../config";

export const fetchUserDetailsEpic: Epic<
  | ActionType<typeof fetchUserDetailsSuccess>
  | ActionType<typeof fetchUserDetailsError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(USER_SIGNED_IN)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.UPP_API_URL}/users/${payload}`,
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        })
        .pipe(
          map((xhr: AjaxResponse) => fetchUserDetailsSuccess(xhr.response)),
          catchError(() => of(fetchUserDetailsError()))
        )
    )
  );

export const fetchRemainingCreditsEpic: Epic<
  | ActionType<typeof fetchRemainingCreditsSuccess>
  | ActionType<typeof fetchRemainingCreditsError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(USER_SIGNED_IN)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.UBO_API_URL}/users/${payload}/services`,
        })
        .pipe(
          map(({ response }) => fetchRemainingCreditsSuccess(response)),
          catchError(() => of(fetchRemainingCreditsError()))
        )
    )
  );

export const fetchEntityTelEpic: Epic<ActionType<
  typeof fetchEntityTelSuccess
>> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_USER_DETAILS_SUCCESS)),
    switchMap(({ payload }) => {
      const countryCode = payload.countryCode;
      return api
        .request({
          method: "GET",
          url: `${config.UBO_API_URL}/Reference/entityinfo/${countryCode}`,
        })
        .pipe(
          map(({ response }) => fetchEntityTelSuccess(response.tel)),
          catchError(() => EMPTY)
        );
    })
  );

export const fetchEntityContactDetailEpic: Epic<ActionType<
  typeof loadLocalesAction
>> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(FETCH_USER_DETAILS_SUCCESS)),
    switchMap(({ payload }) => {
      const countryCode = payload.countryCode;
      return api
        .request({
          method: "GET",
          url: `${config.UBO_API_URL}/Reference/entityinfo/${countryCode}`,
        })
        .pipe(
          map(({ response }) => {
            const { locales } = response;
            const mappedLocale = (locales as string[]).map((locale) => ({
              code: locale,
            }));
            return loadLocalesAction(mappedLocale);
          }),
          catchError(() => EMPTY)
        );
    })
  );

export const saveUserLanguageEpic: Epic<ActionType<typeof userSignedIn>> = (
  action$,
  state$,
  { api }
) =>
  action$.pipe(
    filter(isOfType(LANGUAGE_CHANGED)),
    withLatestFrom(state$),
    switchMap(([{ payload }, { user }]) => {
      const userId = user.userId;
      if (userId === null) {
        return EMPTY;
      }
      return api
        .request({
          method: "PUT",
          url: `${config.UBO_API_URL}/users/${userId}/locale?locale=${payload}`,
        })
        .pipe(
          map(() => userSignedIn(userId)),
          catchError(() => EMPTY)
        );
    })
  );

export const checkIsUserAllowedForSearchPanelEpic: Epic<
  | ActionType<typeof userAllowedForSearchPanelSuccess>
  | ActionType<typeof userAllowedForSearchPanelError>
> = (action$, _$, { api }) =>
  action$.pipe(
    filter(isOfType(CHECK_IS_USER_ALLOWED_FOR_SEARCHPANEL)),
    switchMap(({ payload }) =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/users/IsUserAllowed/${payload.userID}/Customer/${payload.customerID}`,
        })
        .pipe(
          map(({ response }) => userAllowedForSearchPanelSuccess(response)),
          catchError(() => of(userAllowedForSearchPanelError()))
        )
    )
  );

export const fetchMonitoringPortfoliosEpic: Epic<
  | ActionType<typeof fetchMonitoringPortfoliosSuccess>
  | ActionType<typeof fetchMonitoringPortfoliosError>
> = (actions$, _$, { api }) =>
  actions$.pipe(
    filter(isOfType(FETCH_MONITORING_PORTFOLIOS)),
    switchMap(() =>
      api
        .request({
          method: "GET",
          url: `${config.DATA_CLEANING_API_URL}/jobmonitor/portfolios`,
        })
        .pipe(
          map(({ response }) =>
            fetchMonitoringPortfoliosSuccess(response?.data?.portfolios || [])
          ),
          catchError(() => of(fetchMonitoringPortfoliosError()))
        )
    )
  );
