import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { fadeRGBColors } from "./utils";
import { makeStyles, createStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formatBarChartData } from "./utils";
import { ChartInputItem } from "./types";
import { ChartOptions, ChartTooltipItem } from "chart.js";

const HORIZONTAL_WORD_WRAP_TITLE_LIMIT = 40;
export const tooltipTitleCallback = (tooltipItem: ChartTooltipItem[]) => {
  if (tooltipItem.length === 0) {
    return "";
  }
  //This is horrible however the tooltip title does not have the ability to do automated word wrapping based on a maximum length.
  //Alternative is to build a custom HTML tooltip but this is a lot less code
  const label = tooltipItem[0].yLabel
    ? tooltipItem[0].yLabel.match(
        new RegExp(`.{1,${HORIZONTAL_WORD_WRAP_TITLE_LIMIT}}(\\s|$)`, "g")
      )
    : "";
  return label;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        "&>canvas": {
          margin: "auto",
        },
      },
    },
  })
);

const HorizontalBarChart: React.FC<{
  chartData: ChartInputItem[];
  activeIndex: number;
  colors: string[];
  setActiveIndex: (index: number) => void;
  animationDuration?: number;
}> = ({
  chartData,
  activeIndex,
  setActiveIndex,
  colors,
  animationDuration,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("aggregates");
  const { labels, dataPoints } = formatBarChartData(chartData);
  const activeColors = (activeIndex: number) => {
    const newColors = colors.map((x, index) =>
      index === activeIndex ? fadeRGBColors(x) : x
    );
    return newColors;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        categoryPercentage: 0.9,
        barPercentage: 0.9,
        label: t("count"),
        backgroundColor: activeColors(activeIndex),
        borderColor: colors,
        borderWidth: 1,
        hoverBackgroundColor: colors.map((x) => fadeRGBColors(x)),
        hoverBorderColor: colors,
        data: dataPoints,
      },
    ],
  };

  const options = {
    layout: { padding: { bottom: 5, top: 5 } },
    tooltips: {
      yAlign: "center",
      callbacks: {
        title: tooltipTitleCallback,
      },
    },
    legend: false,
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: animationDuration,
    },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            color: "transparent",
          },
          ticks: {
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  } as ChartOptions;

  return (
    <div className={classes.root} data-testid="horizontal-bar-chart">
      <HorizontalBar
        data={data}
        //without setting the height to a minimum and width to undefined react-chartjs is generating it's own height and width that breaks the 100% height css
        height={40}
        width={undefined}
        onElementsClick={(e) => e.length > 0 && setActiveIndex(e[0]._index)}
        options={options}
        redraw={true}
      />
    </div>
  );
};

HorizontalBarChart.defaultProps = {
  animationDuration: 1000,
};

export default HorizontalBarChart;
