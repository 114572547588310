import { UserActionTypes } from "./user/types";
import { JobEnrichmentSpendEstimate, JobsActionTypes } from "./jobs/types";
import { MappingsActionTypes } from "./mappings/types";
import { CustomerActionTypes } from "./customer/types";
import { I18nActionsTypes } from "./i18n/types";
import { AppActionTypes } from "./app/types";
import { EnrichmentsActionTypes } from "./enrichments/types";
import { AdminActionTypes } from "./admin/types";
import { SettingsActionTypes } from "./settings/types";

export enum Status {
  "loading",
  "loaded",
  "errorLoading",
  "saving",
  "saved",
  "errorSaving",
  "unset",
  "set",
  "enrichmentsSaving",
  "enriching",
  "errorEnriching",
  "archiving",
  "archived",
  "errorArchiving",
  "jobMatchingComplete",
  "unmatched",
  "manuallyMatched",
  "unmatchedError",
  "manuallyMatchedError",
  "updating",
  "updated",
  "updateError",
  "deleting",
  "deleted",
  "deleteError",
  "submitError",
  "submitting",
}

export interface StoreStatus {
  status: Status;
  statusMsg?: string;
}

export interface SpendEstimateStatus {
  status: Status;
  value?: JobEnrichmentSpendEstimate;
}

export interface ExternalDataSource<T> extends StoreStatus {
  data?: T;
  submitJobErrorMsg?: string[];
  enrichments: string[];
  fileDownloadStatus?: Status;
  selectedTabCountry?: string;
  spendEstimate?: SpendEstimateStatus;
  availableCreditTypes?: {
    data?: string[];
    status: Status;
  };
}

export interface Normalized<T, TId> extends StoreStatus {
  allIds: Array<TId>;
  byId: { [id: string]: T };
  totalCount?: number;
}

export type RootAction =
  | UserActionTypes
  | JobsActionTypes
  | MappingsActionTypes
  | EnrichmentsActionTypes
  | CustomerActionTypes
  | I18nActionsTypes
  | AppActionTypes
  | AdminActionTypes
  | SettingsActionTypes;

export type ClickEvent = React.MouseEvent<HTMLButtonElement>;
