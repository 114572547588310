import { intersection } from "lodash";

export const getCreditTypeWidgets = (creditType: string): string[] => {
  switch (creditType.toLowerCase()) {
    case "basic":
      return [
        "contact.country",
        "contact.city",
        "contact.eircode",
        "contact.mailing.city",
        "general.acronym",
        "general.aliasName",
        "general.banding",
        "general.bandingDesc",
        "general.companyId",
        "general.companyName",
        "general.companyNameKana",
        "general.companyNameKanji",
        "general.companyNumber",
        "general.companyType",
        "general.companyTypeDesc.en",
        "general.connectId",
        "general.duplicate",
        "general.duplicateId",
        "general.ggsId",
        "general.kvkNumber",
        "general.legalName",
        "general.legalNameAlt",
        "general.legalRegistrationNumber",
        "general.organisationNumber",
        "general.pattern",
        "general.regDeletionNumber",
        "general.rowNumber",
        "general.rsin",
        "general.safeNumber",
        "general.siren",
        "general.siret",
        "general.tradeName",
        "general.tradeNumber",
        "general.tradingName",
        "general.companyType",
        "general.pbi",
        "general.pei",
        "general.numberOfEstablishments",
      ];
    case "basicplus":
      return [
        ...getCreditTypeWidgets("basic"),
        "contact.additionToAddress",
        "contact.addressLine1",
        "contact.addressLine3",
        "contact.addressLine4",
        "contact.addressLine5",
        "contact.addressLine6",
        "contact.addressType",
        "contact.contactPhone",
        "contact.email",
        "contact.fax",
        "contact.houseNumber",
        "contact.mailing.addressLine1",
        "contact.mailing.addressLine2",
        "contact.mailing.addressLine3",
        "contact.mailing.addressLine4",
        "contact.mailing.houseNumber",
        "contact.mailing.postalCode",
        "contact.mailing.province",
        "contact.mailing.streetName",
        "general.managerType",
        "general.managerTypeDesc.en",
        "general.managerTypeDesc.fr",
        "contact.addressLine2",
        "contact.marketingAllowed",
        "contact.municipality",
        "contact.nonMailingIndicator",
        "contact.officeAddress1",
        "contact.officeAddress2",
        "contact.officeAddress3",
        "contact.paf.addressLine1",
        "contact.paf.addressLine2",
        "contact.paf.addressLine3",
        "contact.paf.addressLine4",
        "contact.paf.postalCode",
        "contact.postBox",
        "contact.province",
        "contact.postalCode",
        "contact.region",
        "contact.specialDistribution",
        "contact.state",
        "contact.streetName",
        "contact.tps",
        "contact.zip4",
        "financials.bankCode",
        "financials.bankName",
        "financials.consolidationCode",
        "financials.shareCapital",
        "financials.shareCapitalCurrency",
        "general.active",
        "general.activeEconomically",
        "general.activityCode",
        "general.activityCode2",
        "general.activityCode2Desc.en",
        "general.activityCode2Desc.jp",
        "general.activityCode3",
        "general.activityCode3Desc.en",
        "general.activityCodeDesc.de",
        "general.activityCodeDesc.en",
        "general.activityCodeDesc.fr",
        "general.activityCodeDesc.it",
        "general.activityCodeDesc.jp",
        "general.activityCodeDesc.nl",
        "general.activityCodeDesc.se",
        "general.annualSales",
        "general.branchNumber",
        "general.continuanceDate",
        "general.discontinuanceDate",
        "general.employees",
        "general.establishDate",
        "general.exportFlag",
        "general.formationDate",
        "general.foundingDeedDate",
        "general.importFlag",
        "general.incorporatedState",
        "general.incorporationDate",
        "general.juridicalForm",
        "general.juridicalFormDesc.en",
        "general.juridicalFormDesc.fr",
        "general.juridicalFormDesc.nl",
        "general.legalForm",
        "general.legalFormCategory",
        "general.legalFormDesc.de",
        "general.legalFormDesc.en",
        "general.legalFormDesc.fr",
        "general.legalFormDesc.it",
        "general.legalFormDesc.nl",
        "general.legalFormDesc.no",
        "general.legalFormDetails",
        "general.leiNumber",
        "general.leiRenewalDate",
        "general.locationType",
        "general.ltdNonLtdFlag",
        "general.mainOfficeFlag",
        "general.minimalWorkforceRange",
        "general.mortgages.outstanding",
        "general.mortgages.partiallySatisfied",
        "general.mortgages.satisfied",
        "general.mortgages.total",
        "general.naicsCode",
        "general.naicsCodeDesc.en",
        "general.previousName",
        "general.principalActivity",
        "general.principalActivity2",
        "general.principalActivity3",
        "general.principalActivity4",
        "general.principalActivity5",
        "general.priority",
        "general.registeredInForetaksreg",
        "general.registrationDate",
        "general.registrationLocation",
        "general.registrationNumber",
        "general.sectorCode",
        "general.sosAddress",
        "general.sosCharterNo",
        "general.sosCity",
        "general.sosFederalTaxId",
        "general.sosState",
        "general.sosStatus",
        "general.sosZip",
        "general.sosZip4",
        "general.status",
        "general.statusDesc.en",
        "general.statusDesc.fr",
        "general.statusDesc.nl",
        "general.statusDescShort.en",
        "general.statusDescShort.fr",
        "general.taxCode",
        "general.vatLiable",
        "general.vatNumber",
        "general.website",
        "general.workforceCode",
        "general.workforceCodeDesc.en",
        "general.workforceCodeDesc.fr",
        "general.yearsTrading",
        "group.parent.siren",
        "group.parent.siret",
        "group.ultimateParent.siren",
      ];
    case "standard":
      return [
        ...getCreditTypeWidgets("basicplus"),
        "directors.addressLine1",
        "directors.addressLine2",
        "directors.addressLine3",
        "directors.addressLine4",
        "directors.appointmentDate",
        "directors.birthDate",
        "directors.birthPlace",
        "directors.city",
        "directors.country",
        "directors.endDate",
        "directors.firstName",
        "directors.fullName",
        "directors.fullNameKana",
        "directors.gender",
        "directors.honours",
        "directors.lastName",
        "directors.managerPositionCode",
        "directors.middleName",
        "directors.nationality",
        "directors.postalCode",
        "directors.region",
        "directors.salutation",
        "directors.state",
        "directors.title.en",
        "directors.title.fr",
        "directors.title.nl",
        "financials.accountingDate",
        "financials.accountPeriodInMonths",
        "financials.accountsEndDate",
        "financials.accountsFrom",
        "financials.accountsMonths",
        "financials.accountsOfRegularisation",
        "financials.accountsRegularisation",
        "financials.accountsStartDate",
        "financials.accountsTo",
        "financials.accountsType",
        "financials.accruals",
        "financials.activeDiffBetweenAssetAllocation",
        "financials.addedValue",
        "financials.advancedPayments",
        "financials.advancedPaymentsReceived",
        "financials.allocatedDividend",
        "financials.allocationOtherEquity",
        "financials.amortisation",
        "financials.amortizationAndDepreciation",
        "financials.assets",
        "financials.auditComments",
        "financials.auditFees",
        "financials.auditors",
        "financials.auditorsQualification",
        "financials.balance.1to30",
        "financials.balance.31to60",
        "financials.balance.61to90",
        "financials.balance.91plus",
        "financials.balance.current",
        "financials.balance.period1Late",
        "financials.balance.period2Late",
        "financials.balance.period3Late",
        "financials.balance.total",
        "financials.balanceSheetNetIncome",
        "financials.balanceSheetTotal",
        "financials.balanceSheetTotalAssets",
        "financials.bankBranchCode",
        "financials.bankers",
        "financials.bankOverdraft",
        "financials.bankOverdraftLtl",
        "financials.bonds",
        "financials.bondsAndAccountsReceivables",
        "financials.capital",
        "financials.capitalEmployed",
        "financials.capitalNotCalled",
        "financials.capitalReserve",
        "financials.cash",
        "financials.cashAndBankDeposits",
        "financials.certificateLoan",
        "financials.changeInStock",
        "financials.changeInWorkInProgress",
        "financials.changeValueCurrentAssets",
        "financials.closureDate",
        "financials.coefficientLinear",
        "financials.concessions",
        "financials.consolidatedAccounts",
        "financials.contingentLiability",
        "financials.convertibleLoan",
        "financials.convertibleLoans",
        "financials.costOfSales",
        "financials.costOfStocks",
        "financials.creditorDays",
        "financials.currency",
        "financials.currentAssets",
        "financials.currentDebtRatio",
        "financials.currentRatio",
        "financials.dbt.current",
        "financials.dbt.local",
        "financials.dbt.previous",
        "financials.debtorDays",
        "financials.deferedTax",
        "financials.deferredTaxAsset",
        "financials.deferredTaxLiabilities",
        "financials.defferedIncome",
        "financials.defferedLiabilities",
        "financials.deficitNcbCapital",
        "financials.deficitNcblLossCapital",
        "financials.depositedCapital",
        "financials.depreciation",
        "financials.directorRemuneration",
        "financials.directorsEmoluments",
        "financials.dividends",
        "financials.dividendsPayable",
        "financials.employeeCosts",
        "financials.employeePensionCosts",
        "financials.equity",
        "financials.equityInPercentage",
        "financials.exports",
        "financials.extraordinaryCharges",
        "financials.extraordinaryIncome",
        "financials.extraordinaryItems",
        "financials.extraordinaryProfitLoss",
        "financials.extraordinaryResult",
        "financials.financialAssets",
        "financials.financialCharges",
        "financials.financialExpenses",
        "financials.financialFixedAssets",
        "financials.financialIncome",
        "financials.financialLiabilities",
        "financials.financialProfit",
        "financials.financialResult",
        "financials.finishedGoods",
        "financials.fixedAssets",
        "financials.fixedEquity",
        "financials.gearingRatio",
        "financials.goodwillIntangibleAssets",
        "financials.grossOperatingSurplus",
        "financials.grossProfit",
        "financials.groupContributions",
        "financials.intangibleAssets",
        "financials.intangibleFixedAssets",
        "financials.interestIncomeGroup",
        "financials.interestPayments",
        "financials.inventories",
        "financials.inventory",
        "financials.inventoryChange",
        "financials.investmentsInAssociatedCompanies",
        "financials.investmentsInGroupCompanies",
        "financials.investmentsInShares",
        "financials.issuedShareCapital",
        "financials.landAndBuilding",
        "financials.landAndBuildings",
        "financials.lastUpdateDate",
        "financials.latestAccountDate",
        "financials.liabilities",
        "financials.liableLoanCapital",
        "financials.liquidAssets",
        "financials.liquidityAcidTest",
        "financials.liquidityRatio",
        "financials.longtermGroupContrLiabilities",
        "financials.longTermLiabilities",
        "financials.longtermLiabilitiesFinInst",
        "financials.longtermPensionCommitments",
        "financials.materials",
        "financials.minorityInterestsGroups",
        "financials.minorityShare",
        "financials.miscCurrentAssets",
        "financials.miscCurrentLiabilities",
        "financials.netAssets",
        "financials.netCashflowBeforeFinancing",
        "financials.netCashflowFromFinancing",
        "financials.netCashflowFromOperations",
        "financials.netCurrentAssets",
        "financials.netExportTurnover",
        "financials.netIncome",
        "financials.netProfitLoss",
        "financials.netResult",
        "financials.netResultAfterDividends",
        "financials.netTurnover",
        "financials.netWorkingCapital",
        "financials.netWorth",
        "financials.nominalCapital",
        "financials.numberOfWeeks",
        "financials.operatingCharges",
        "financials.operatingEquipment",
        "financials.operatingIncome",
        "financials.operatingProfit",
        "financials.operationalResult",
        "financials.ordinaryOperationalResult",
        "financials.otherAmountsReceivable",
        "financials.otherCapitalResources",
        "financials.otherCosts",
        "financials.otherCreditors",
        "financials.otherCurrentAssets",
        "financials.otherCurrentDebtors",
        "financials.otherDebtsAndFixedAssets",
        "financials.otherEmployeeCosts",
        "financials.otherEquity",
        "financials.otherFinancialCosts",
        "financials.otherFinancialExpenses",
        "financials.otherFinancialFixedAssets",
        "financials.otherFinancialIncome",
        "financials.otherFinancialInstr",
        "financials.otherFixedAssets",
        "financials.otherIntangibleFixedAssets",
        "financials.otherInterestExpenses",
        "financials.otherInterestExpensesGroup",
        "financials.otherInterestIncome",
        "financials.otherInventories",
        "financials.otherInvestments",
        "financials.otherLongTermFinance",
        "financials.otherLongtermLiabilities",
        "financials.otherLongtermLoans",
        "financials.otherMarkedBasedFinancial",
        "financials.otherOperatingExpenses",
        "financials.otherOperatingIncome",
        "financials.otherOperationalRevenue",
        "financials.otherOperationFactors",
        "financials.otherPaidInCapital",
        "financials.otherProvisions",
        "financials.otherReceivables",
        "financials.otherReceivables2",
        "financials.otherReserves",
        "financials.otherShortTermFinance",
        "financials.otherShortTermFinancials",
        "financials.otherStocks",
        "financials.otherTangibleAssets",
        "financials.otherTaxes",
        "financials.otherTermLiabilities",
        "financials.otherTermLoans",
        "financials.ownShares",
        "financials.ownWork",
        "financials.paidShareCapital",
        "financials.paidUpCapital",
        "financials.payableTax",
        "financials.pensionFunds",
        "financials.plAccountReserve",
        "financials.placedCapital",
        "financials.plantAndMachinery",
        "financials.prepaidExpenses",
        "financials.preProfitMargin",
        "financials.preTaxMargin",
        "financials.pretaxNetOperatingIncome",
        "financials.preTaxProfit",
        "financials.preTaxProfitMargin",
        "financials.privateAccount",
        "financials.profit",
        "financials.profitAfterTax",
        "financials.profitBeforeTax",
        "financials.profitLoss",
        "financials.profitLossAccount",
        "financials.quotedBonds",
        "financials.quotedInvestmentShares",
        "financials.rawAndConsumables",
        "financials.rawMaterials",
        "financials.receivables",
        "financials.receivablesAndOther",
        "financials.receivablesToCompInGroup",
        "financials.recentHighCredit",
        "financials.researchAndDevelopment",
        "financials.reserves",
        "financials.resultsAfterTaxation",
        "financials.retainedEarnings",
        "financials.retainedProfit",
        "financials.retainedProfits",
        "financials.returnOnCapitalEmployed",
        "financials.returnOnNetAssetsEmployed",
        "financials.returnOnTotalAssetsEmployed",
        "financials.revaluationReserve",
        "financials.revenueAssociatedCompanies",
        "financials.revenueGroupCompanies",
        "financials.revenueReserve",
        "financials.revenueSubsidiaries",
        "financials.riskProvisions",
        "financials.salesOfGoods",
        "financials.salesRevenue",
        "financials.salesWorkingCapital",
        "financials.securities",
        "financials.securitiesAndCash",
        "financials.shareholderFunds",
        "financials.shareholdersEquity",
        "financials.sharePremiumAccount",
        "financials.sharePremiumReserve",
        "financials.sharesAndInterestsInGroup",
        "financials.sharesInvestmentSubsidiaries",
        "financials.sharesNcblLimitedPartners",
        "financials.sharesNcblUnlimitedPartners",
        "financials.shipsRigsAircraft",
        "financials.shorttermLiabilitiesFinInst",
        "financials.shorttermLiabilitiesGroup",
        "financials.socialSecurityContributions",
        "financials.solvencyRatio",
        "financials.specialGovernmentTaxes",
        "financials.staff",
        "financials.stocks",
        "financials.stockTurnoverRatio",
        "financials.subscribedCapital",
        "financials.suggestedDividend",
        "financials.sundryReserves",
        "financials.tangibleAssets",
        "financials.tangibleFixedAssets",
        "financials.tax",
        "financials.taxAndSocialLiabilities",
        "financials.taxation",
        "financials.taxOnExtraordinaryResult",
        "financials.therefromAccrualsPension",
        "financials.therefromAccrualsTax",
        "financials.therefromInterestsSimilarExpenses",
        "financials.therefromOtherInterestSimilarIncome",
        "financials.therefromTradePayables",
        "financials.therefromTradeReceivables",
        "financials.totalAssets",
        "financials.totalCurrentAssets",
        "financials.totalCurrentLiabilities",
        "financials.totalDebtRatio",
        "financials.totalEquity",
        "financials.totalEquityDeposits",
        "financials.totalFixedAssets",
        "financials.totalFunds",
        "financials.totalInventories",
        "financials.totalLiabilities",
        "financials.totalLiabilitiesEquity",
        "financials.totalLongTermLiabilities",
        "financials.totalNetFinancialItems",
        "financials.totalOperatingExpenses",
        "financials.totalOperatingIncome",
        "financials.totalOtherLongtermLiabilities",
        "financials.totalProvisionsLiabilities",
        "financials.totalReceivable",
        "financials.totalRetainedEarnings",
        "financials.totalShareholdersEquity",
        "financials.totalTangibleAssets",
        "financials.totalTermDebts",
        "financials.totalTransfersAllocations",
        "financials.tradeAccountPayables",
        "financials.tradeCreditors",
        "financials.tradeDebtors",
        "financials.tradelines",
        "financials.tradeReceivables",
        "financials.tradeUpdateDate",
        "financials.tradingMargin",
        "financials.turnover",
        "financials.valuationReserve",
        "financials.variableEquity",
        "financials.wagesSalaries",
        "financials.workingCapital",
        "financials.workInProgress",
        "financials.writeDownOfFixedAssets",
        "financials.writedownsCurrentAssets",
        "financials.writedownsFixedAssets",
        "financials.yearOfBalance",
        "financialsNonConsolidated.accountingDate",
        "financialsNonConsolidated.accruals",
        "financialsNonConsolidated.activeDiffBetweenAssetAllocation",
        "financialsNonConsolidated.amortisation",
        "financialsNonConsolidated.assets",
        "financialsNonConsolidated.balanceSheetNetIncome",
        "financialsNonConsolidated.balanceSheetTotal",
        "financialsNonConsolidated.balanceSheetTotalAssets",
        "financialsNonConsolidated.capitalReserve",
        "financialsNonConsolidated.currency",
        "financialsNonConsolidated.currentAssets",
        "financialsNonConsolidated.deferedTax",
        "financialsNonConsolidated.defferedIncome",
        "financialsNonConsolidated.defferedLiabilities",
        "financialsNonConsolidated.deficitNcbCapital",
        "financialsNonConsolidated.deficitNcblLossCapital",
        "financialsNonConsolidated.equity",
        "financialsNonConsolidated.extraordinaryResult",
        "financialsNonConsolidated.financialAssets",
        "financialsNonConsolidated.financialResult",
        "financialsNonConsolidated.fixedAssets",
        "financialsNonConsolidated.fixedEquity",
        "financialsNonConsolidated.grossProfit",
        "financialsNonConsolidated.intangibleAssets",
        "financialsNonConsolidated.inventory",
        "financialsNonConsolidated.inventoryChange",
        "financialsNonConsolidated.liabilities",
        "financialsNonConsolidated.liquidAssets",
        "financialsNonConsolidated.materials",
        "financialsNonConsolidated.netIncome",
        "financialsNonConsolidated.numberOfWeeks",
        "financialsNonConsolidated.operationalResult",
        "financialsNonConsolidated.ordinaryOperationalResult",
        "financialsNonConsolidated.otherCosts",
        "financialsNonConsolidated.otherOperationalRevenue",
        "financialsNonConsolidated.otherTaxes",
        "financialsNonConsolidated.ownWork",
        "financialsNonConsolidated.prepaidExpenses",
        "financialsNonConsolidated.privateAccount",
        "financialsNonConsolidated.profitLoss",
        "financialsNonConsolidated.receivablesAndOther",
        "financialsNonConsolidated.retainedEarnings",
        "financialsNonConsolidated.revenueReserve",
        "financialsNonConsolidated.securities",
        "financialsNonConsolidated.sharesNcblLimitedPartners",
        "financialsNonConsolidated.sharesNcblUnlimitedPartners",
        "financialsNonConsolidated.staff",
        "financialsNonConsolidated.subscribedCapital",
        "financialsNonConsolidated.tax",
        "financialsNonConsolidated.therefromAccrualsPension",
        "financialsNonConsolidated.therefromAccrualsTax",
        "financialsNonConsolidated.therefromInterestsSimilarExpenses",
        "financialsNonConsolidated.therefromOtherInterestSimilarIncome",
        "financialsNonConsolidated.therefromTradePayables",
        "financialsNonConsolidated.therefromTradeReceivables",
        "financialsNonConsolidated.variableEquity",
        "general.federalTaxId",
        "general.inactivityDate",
        "general.isHeadquarter",
        "group.daughter.country",
        "group.daughter.name",
        "group.headquarter.id",
        "group.parent.addressLine1",
        "group.parent.city",
        "group.parent.companyNo",
        "group.parent.country",
        "group.parent.id",
        "group.parent.limit.current.value",
        "group.parent.name",
        "group.parent.postalCode",
        "group.parent.rating.current.description.en",
        "group.parent.rating.current.description.nl",
        "group.parent.rating.current.score",
        "group.parent.safeNumber",
        "group.ultimateParent.address",
        "group.ultimateParent.city",
        "group.ultimateParent.companyNo",
        "group.ultimateParent.contactPhone",
        "group.ultimateParent.country",
        "group.ultimateParent.entityType",
        "group.ultimateParent.id",
        "group.ultimateParent.inseeCityCode",
        "group.ultimateParent.isoCode",
        "group.ultimateParent.limit.current.value",
        "group.ultimateParent.name",
        "group.ultimateParent.poBox",
        "group.ultimateParent.postalCode",
        "group.ultimateParent.rating.current.description.en",
        "group.ultimateParent.rating.current.description.nl",
        "group.ultimateParent.rating.current.score",
        "group.ultimateParent.safeNumber",
        "limit.current.date",
        "limit.current.value",
        "limit.previous.date",
        "limit.previous.value",
        "negatives.bankruptcy",
        "negatives.bankruptcyAmount",
        "negatives.bankruptcyCount",
        "negatives.bankruptcyDate",
        "negatives.ccjCount",
        "negatives.ccjValue",
        "negatives.chequeAmount",
        "negatives.chequeCount",
        "negatives.chequeDate",
        "negatives.collectionAmount",
        "negatives.collectionCases",
        "negatives.collectionCount",
        "negatives.collectionDate",
        "negatives.delinquencyAmount",
        "negatives.delinquencyCount",
        "negatives.judgementAmount",
        "negatives.judgementCount",
        "negatives.judgementDate",
        "negatives.legalFilings",
        "negatives.legalFilingsAmount",
        "negatives.lienAmount",
        "negatives.lienCount",
        "negatives.lienDate",
        "negatives.ofacFlag",
        "negatives.otherItemAmount",
        "negatives.otherItemCount",
        "negatives.otherItemDate",
        "negatives.suitAmount",
        "negatives.suitCount",
        "negatives.suitDate",
        "negatives.totalCollectionAmount",
        "negatives.uccCautionaryCount",
        "negatives.uccCount",
        "rating.creditworthy",
        "rating.current.cds",
        "rating.current.colour",
        "rating.current.common",
        "rating.current.creditIndex",
        "rating.current.date",
        "rating.current.description.en",
        "rating.current.description.fr",
        "rating.current.description.jp",
        "rating.current.description.nl",
        "rating.current.description.no",
        "rating.current.fullDescription.en",
        "rating.current.fullDescription.no",
        "rating.current.local",
        "rating.current.pod",
        "rating.current.score",
        "rating.previous.common",
        "rating.previous.date",
        "rating.previous.description.en",
        "rating.previous.description.no",
        "rating.previous.fullDescription.en",
        "rating.previous.fullDescription.no",
        "rating.previous.score",
        "shareholders.currency",
        "shareholders.info",
        "shareholders.name",
      ];
    default:
      return [];
  }
};

export const getWidgetCreditType = (jsonTag: string): string => {
  if (jsonTag === "") return "";

  const basicWidgets = getCreditTypeWidgets("basic");
  if (basicWidgets.includes(jsonTag)) return "basic";

  const basicPlusWidgets = getCreditTypeWidgets("basicplus");
  if (basicPlusWidgets.includes(jsonTag)) return "basicplus";
  else return "standard";
};

export const getMaxCreditTypeOfWidgets = (jsonTags: string[]): string => {
  if (jsonTags.length === 0) return "";

  const basicWidgets = getCreditTypeWidgets("basic");
  if (intersection(jsonTags, basicWidgets).length === jsonTags.length)
    return "basic";

  const basicPlusWidgets = getCreditTypeWidgets("basicplus");
  if (intersection(jsonTags, basicPlusWidgets).length === jsonTags.length)
    return "basicplus";
  else return "standard";
};
