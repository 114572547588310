import React, { useEffect, useState } from "react";
import { get } from "lodash";
import ReactTooltip from "react-tooltip";
import CountryCodeMap from "./CountryCodeMap";
import ProvinceMap from "./ProvinceMap";
import { ChartInputItem } from "./types";
import { formatGeoName } from "./utils";
import { provinceTitles } from "../Mappings/constants";

const MapChart: React.FC<{
  chartData: ChartInputItem[];
  colors: string[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  name: string;
  isPrintView?: boolean;
  country: string;
}> = ({
  chartData,
  colors,
  activeIndex,
  setActiveIndex,
  name,
  isPrintView,
  country,
}) => {
  const [activeRegion, setActiveRegion] = useState(
    name.includes("CountryCode")
      ? get(chartData[activeIndex], "countryCode", "")
      : get(chartData[activeIndex], "name", "")
  );

  useEffect(() => {
    if (name.includes("CountryCode")) {
      const indexOfCountry = chartData.findIndex(
        (dataItem) => dataItem.countryCode === activeRegion
      );

      activeRegion && indexOfCountry !== -1 && setActiveIndex(indexOfCountry);
    }
    if (provinceTitles.includes(name.split(".")[0])) {
      const indexOfRegion = chartData.findIndex(
        (dataItem) => dataItem.name === activeRegion
      );
      activeRegion && indexOfRegion !== -1 && setActiveIndex(indexOfRegion);
    }
  }, [activeRegion, chartData, name, setActiveIndex]);

  const [mapTooltip, setMapTooltip] = useState("");

  const generateMap = () => {
    if (name.includes("CountryCode")) {
      return (
        <CountryCodeMap
          chartData={chartData}
          colors={colors}
          activeCountryCode={get(chartData[activeIndex], "countryCode", "")}
          setActiveCountryCode={setActiveRegion}
          setTooltip={setMapTooltip}
        />
      );
    }

    if (provinceTitles.includes(name.split(".")[0])) {
      return (
        <ProvinceMap
          chartData={chartData}
          colors={colors}
          activeRegion={get(chartData[activeIndex], "name", "")}
          setActiveRegion={setActiveRegion}
          setTooltip={setMapTooltip}
          country={country}
          countryFormatters={formatGeoName}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <a data-tip data-for="map" data-testid="map-chart">
        {generateMap()}
      </a>
      {mapTooltip !== "" && !isPrintView && (
        <ReactTooltip id="map" data-testid={`${mapTooltip}-tooltip`}>
          <span>{mapTooltip}</span>
        </ReactTooltip>
      )}
    </>
  );
};

export default MapChart;
