export const WIDGET_TYPE_BAR = "bar";
export const WIDGET_TYPE_PIE = "pie";
export const WIDGET_TYPE_DONUT = "donut";
export const WIDGET_TYPE_MAP = "map";
export const WIDGET_TITLE_SICCODE = "SICCode";
export const WIDGET_TITLE_PROVINCE = "Province";
export const TITLE_PROVINCE_JP = "Province.title.jp";
export const WIDGET_TITLE_REGION = "Region";
export const TITLE_REGION_IT = "Region.title.it";
export const JOB_ACTION_TABS = ["enrichment", "manualMatching"];
export const JOB_RECORDS_ACTION_TABS = ["matchedRecords", "unmatchedRecords"];
export const TITLE_REGION_DE = "Region.title.de";
export const TITLE_REGION_SE = "Region.title.se";
export const TITLE_PROVINCE_UK = "Province.title.gb";
export const WIDGET_TITLE_STATES = "States";
export const TITLE_STATES_US = "States.title.us";
export const UNKNOWN = "Unknown";
export const UNMATCHED = "Unmatched";
export const TITLE_PROVINCE_NL = "Province.title.nl";
export const TITLE_PROVINCE_BE = "Province.title.be";
