import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  createStyles,
  makeStyles,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { getCreditTypeWidgets } from "pages/Jobs/Aggregates/widgets/getCreditTypeWidgets";
import { Status } from "store/types";

const useStyles = makeStyles((theme) =>
  createStyles({
    cartButton: {
      width: theme.spacing(14),
      display: "inline-flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    cartIcon: {
      fontSize: "1.8rem",
      margin: theme.spacing(0.5, 0, 0.5, 0.5),
    },
    addToCart: {
      background: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
    },
    addToCartText: {
      color: theme.palette.primary.contrastText,
    },
    removeFromCartText: {
      color: theme.palette.text.primary,
    },
    notAvaiableButton: {
      padding: "0.7rem",
      backgroundColor: "#f5f5f5",
      width: theme.spacing(14),
      display: "inline-flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: "#e5e5e5",
      },
    },
    closeDialogButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

const AddRemoveButton: React.FC<{
  title: string;
  creditType: string;
  isRequired: boolean;
  isAvaialable: boolean;
  isSelected: boolean;
  addText: string;
  removeText: string;
  addAction: (widgets: string[]) => void;
  removeAction: () => void;
  availableCreditTypes: { data?: string[]; status: Status };
  isAllowSelecting: boolean;
  onCreditTypeChange: (value: string) => void;
}> = ({
  title,
  creditType,
  addAction,
  removeAction,
  isRequired,
  isAvaialable,
  isSelected,
  addText,
  removeText,
  availableCreditTypes,
  isAllowSelecting,
  onCreditTypeChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["aggregates", "global"]);

  const [unavailable, setUnavailable] = useState("");
  const [unavailableDialogOpen, setUnavailableDialogOpen] = useState(false);

  useEffect(() => {
    if (availableCreditTypes.status === Status.loaded) {
      if (creditType === "") setUnavailable("");
      else if (
        availableCreditTypes.data?.some((c) => c.toLowerCase() === creditType)
      )
        setUnavailable("useAnotherCreditType");
      else setUnavailable("notInSubscription");
    }
  }, [t, creditType, availableCreditTypes]);

  return isAllowSelecting ? (
    isAvaialable ? (
      isRequired ? (
        <Tooltip
          data-testid="tooltip"
          title={
            t("cart.requiredAggregate", {
              aggregate: t(title),
            }) as string
          }
          disableHoverListener={!isRequired}
        >
          <>
            <Button
              variant="outlined"
              size="small"
              className={classes.cartButton}
              data-testid="added-to-cart-button"
              disabled={isRequired}
            >
              <Typography variant="body2">{t("cart.addedToCart")}</Typography>
              <AddCircleOutline
                className={`${classes.cartIcon} ${classes.removeFromCartText}`}
              />
            </Button>
          </>
        </Tooltip>
      ) : isSelected ? (
        <Button
          variant="outlined"
          size="small"
          className={classes.cartButton}
          onClick={removeAction}
          data-testid="remove-from-cart-button"
          disabled={isRequired}
        >
          <Typography variant="body2">{removeText}</Typography>
          <RemoveCircleOutline
            className={`${classes.cartIcon} ${classes.removeFromCartText}`}
          />
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          className={`${classes.cartButton} ${classes.addToCart}`}
          onClick={() => addAction([])}
          data-testid="add-to-cart-button"
        >
          <Typography variant="body2" className={classes.addToCartText}>
            {addText}
          </Typography>
          <AddCircleOutline className={classes.cartIcon} />
        </Button>
      )
    ) : (
      <>
        <Button
          variant="outlined"
          size="small"
          className={`${classes.notAvaiableButton}`}
          data-testid="unavailable-button"
          onClick={() => setUnavailableDialogOpen(true)}
        >
          <Typography variant="body2" style={{ margin: "auto" }}>
            {t("cart.notAvailable")}
          </Typography>
        </Button>
        <Dialog open={unavailableDialogOpen}>
          <DialogTitle>
            {t(`${unavailable}.title`)}
            <IconButton
              aria-label="close"
              className={classes.closeDialogButton}
              onClick={() => setUnavailableDialogOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: "bold" }}>
              {unavailable === "useAnotherCreditType" ? (
                <>
                  {t(`${unavailable}.content`, {
                    creditType: t(`global:creditTypes.${creditType}`),
                  })}
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "1rem" }}
                    className={`${classes.addToCart}`}
                    data-testid="switch-credit-type-button"
                    onClick={() => {
                      const newCreditType =
                        creditType === "basicplus" ? "basicPlus" : creditType;
                      onCreditTypeChange(newCreditType);
                      addAction(getCreditTypeWidgets(newCreditType));
                      setUnavailableDialogOpen(false);
                    }}
                  >
                    {t("cart.switch")}
                  </Button>
                </>
              ) : (
                t(`${unavailable}.content`)
              )}
            </Typography>
          </DialogContent>
        </Dialog>
      </>
    )
  ) : (
    <></>
  );
};

export default AddRemoveButton;
