export const JOB_RECORDS_HEADERS = [
  "",
  "companyId",
  "name",
  "addressLine1",
  "town",
  "region",
  "postcode",
  "orgNumber",
  "",
  "",
];

export const SORTABLE_COLUMNS = [
  "companyId",
  "name",
  "town",
  "region",
  "postcode",
  "orgNumber",
];

export enum MatchingStatus {
  Matched = "matched",
  Unmatched = "unmatched",
  ManuallyMatched = "manuallyMatched",
}
