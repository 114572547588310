import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as LanguageIcon } from "../../assets/language.svg";
import { getLocales } from "../../store/i18n/selectors";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { languageChangedAction } from "../../store/i18n/actions";
const menuId = "language-switcher";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    leftIcon: {
      marginRight: "2px",
    },
    selectedLang: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "inline",
      },
    },
    menu: {
      [theme.breakpoints.up("xs")]: {
        marginTop: "3rem",
      },
    },
  })
);

interface LanguageSwitcherProps {
  component?: React.ReactType;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  component: Component = Button,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("global");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const locales = useSelector(getLocales);

  const handleMenu = (event: React.ChangeEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const changeLanguage = (newLanguage: string) => {
    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClose}>
      <div className={classes.root}>
        <Component
          aria-label={t("sidebar.changeLanguage")}
          aria-haspopup="true"
          color="inherit"
          onClick={handleMenu}
          aria-controls={menuId}
          data-testid={`${menuId}-button`}
        >
          <svg height="24" width="30">
            <LanguageIcon />
          </svg>
          <span
            data-testid={`${menuId}-current-language`}
            className={classes.selectedLang}
          >
            {t("languages." + i18n.language)}
          </span>
        </Component>
        <Menu
          id={menuId}
          data-testid={`${menuId}-menu`}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          className={classes.menu}
        >
          {locales.map(({ code }: { code: string }) => (
            <MenuItem
              key={code}
              selected={code === i18n.language}
              onClick={() => {
                changeLanguage(code);
                dispatch(languageChangedAction(code));
              }}
              data-testid={`${code}-item`}
            >
              {t("languages." + code)}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default LanguageSwitcher;
